import React from "react";
import { css, StyleSheet } from "aphrodite/no-important";
import { CssColor, CssSize } from "../../shared/helpers/styles.helper";

interface IToggleSliderProps {
  value: boolean;
  onChange: (value: boolean) => void;
  readonly?: boolean;
}

export const ToggleSlider = (props: IToggleSliderProps) => {
  const {
    value,
    onChange,
    readonly = false
  } = props;

  return (
    <div className={css(styles.toggleContainer)}>
      <input
        className={css(styles.hideCheckbox)}
        id='toggle-slider'
        type='checkbox'
        checked={value}
        onChange={(event) => onChange(event.target.checked)}
        readOnly={readonly}
        disabled={readonly}
      />
      <label
        className={css([styles.toggleLabel, value && styles.isChecked, !readonly && styles.writableToggle])}
        htmlFor="toggle-slider"/>
      <span className={css(styles.toggleDescription)}>In Watchlist anzeigen</span>
    </div>
  );
};

const styles = StyleSheet.create({
  hideCheckbox: {
    visibility: "hidden",
    overflow: "hidden",
    position: "absolute",
    top: 0,
    left: 0
  },
  writableToggle: {
    cursor: "pointer"
  },
  toggleLabel: {
    position: "relative",
    width: CssSize.s40,
    height: CssSize.s20,
    borderRadius: CssSize.s45,
    backgroundColor: CssColor.LighterPrimary,
    display: "inline-block",
    marginRight: CssSize.s10,
    marginBottom: 0,
    ":after": {
      content: "\"\"",
      position: "absolute",
      top: 0,
      bottom: 0,
      left: 0,
      width: CssSize.s20,
      display: "block",
      backgroundColor: CssColor.Secondary,
      borderRadius: "50%",
      transition: "transform 300ms linear",
      transform: "translateX(0)"
    }
  },
  isChecked: {
    backgroundColor: CssColor.Green,
    ":after": {
      transform: "translateX(100%)"
    }
  },
  toggleDescription: {
    fontSize: 14,
    color: CssColor.Secondary,
    fontWeight: 600
  },
  toggleContainer: {
    display: "flex",
    alignItems: "center"
  }
});
