import React, {useEffect, useState} from "react";
import {BrowserRouter as Router, Redirect, Route, Switch, useHistory} from "react-router-dom";
import {PropertyDetailViewWithStore} from "./components/views/property/property-detail.view";
import {PropertyListViewWithStore} from "./components/views/property/property-list.view";
import {TenanciesListView} from "./components/views/tenancies/tenancies-list.view";
import {AppMenuContainer} from "./containers/app-menu/app-menu-container";
import {FlashMessages} from "./components/flash-messages/flash-messages";
import {useWindowResize} from "./hooks/use-window-resize";
import {CssCustomPropertyService} from "./shared/services/css-custom-property.service";
import {AdminMainViewWithStore} from "./components/views/admin/admin-main.view";
import AuthenticationContainer from "./containers/authentication.container";
import {AffiliateDetailView} from "./components/views/affiliate/affiliate-detail.view";
import {css, StyleSheet} from "aphrodite";
import {useUser} from "./contexts/user.context";
import LanguageContainer from "./containers/language.container";
import {CookiesProvider, useCookies} from "react-cookie";
import {connect} from "react-redux";
import {IState} from "./shared/interfaces/state.interface";
import {selectLanguage} from "./shared/redux/settings/settings.selector";
import {SettingsActions} from "./shared/redux/settings/settings.actions";
import {PrivateRoute} from "./components/auth/private-route";
import {ScrollTopOnMount} from "./components/scroll-top-on-mount/scroll-top-on-mount";
import {Auth} from "aws-amplify";


interface IAppProps {
  currentLanguage: string,
  setLanguage: (language: string) => void
}

function AppWithoutStore(props: IAppProps) {
  const {currentLanguage, setLanguage} = props;

  const [ready, setReady] = useState(false);

  useEffect(() => {
    if (!ready) {
      setReady(true);
    }
  }, [ready]);

  useEffect(() => {
    const setScrollBarWidth = () => {
      const scrollDiv = document.createElement("div");
      scrollDiv.className = "scrollbar-measure";
      scrollDiv.style.width = '100px';
      scrollDiv.style.height = '100px';
      scrollDiv.style.overflow = 'scroll';
      scrollDiv.style.position = 'absolute';
      scrollDiv.style.top = '-9999px';
      scrollDiv.style.left = '-9999px';
      document.body.appendChild(scrollDiv);

      // Get the scrollbar width
      const scrollbarWidth = scrollDiv.offsetWidth - scrollDiv.clientWidth;
      const scrollbarHeight = scrollDiv.offsetHeight - scrollDiv.clientHeight;

      // Delete the DIV
      document.body.removeChild(scrollDiv);

      document.documentElement.style.setProperty('--scrollbar-width', (scrollbarWidth) + "px");
      document.documentElement.style.setProperty('--scrollbar-height', (scrollbarHeight) + "px");
    };

    setScrollBarWidth();
  }, [ready]);

  const cssCustomPropertyService = CssCustomPropertyService.getInstance();

  //const googleMapServiceRef = useRef(null);

  useWindowResize(() => {
    const vh = window.innerHeight * 0.01;
    cssCustomPropertyService.addCustomProperty("--vh", `${vh}px`);
  });

  const {user, getGroups} = useUser();

  const [cookies, setCookies] = useCookies(["currentLanguage"]);

  useEffect(() => {
    if (!currentLanguage) {
      setLanguage(cookies.currentLanguage ?? "de");
      return;
    }

    if (cookies.currentLanguage !== currentLanguage) {
      setCookies("currentLanguage", currentLanguage);
    }
  }, [currentLanguage, setLanguage, setCookies, cookies.currentLanguage]);

  const history = useHistory();

  useEffect(() => {
    if (ready && user?.username) {
      const refreshSession = () =>
        Auth.currentSession()
          .then(_data => console.info("refreshed session"))
          .catch(_err => history.go(0));

      setTimeout(refreshSession, 25 * 60 * 1000);
    }
  }, [ready, user, history]);

  return (
    <CookiesProvider>
      <LanguageContainer>
        <AuthenticationContainer>
          <div className="App">
            {/*<GoogleMapApiContainer forwardRef={googleMapServiceRef}>*/}
            <Router>
              <ScrollTopOnMount/>
              {!!user?.username && <AppMenuContainer/>}
              <main className={css(styles.main)}>
                <Switch>
                  {!!user?.username && <>
                    <Route
                      exact={true}
                      path='/'>
                      <Redirect to={"/properties"}/>
                    </Route>
                    {getGroups()?.includes("admin") && <PrivateRoute
                      exact={true}
                      path='/admin'
                      component={AdminMainViewWithStore}
                    />}
                    <PrivateRoute
                      exact={true}
                      path='/properties'
                      component={PropertyListViewWithStore}
                    />
                    <PrivateRoute
                      exact={true}
                      path='/properties/:propertyId'
                      component={PropertyDetailViewWithStore}
                    />
                    <PrivateRoute
                      exact={true}
                      path='/affiliates/:affiliateId'
                      component={AffiliateDetailView}
                    />
                    <PrivateRoute
                      exact={true}
                      path='/tenancies'
                      component={TenanciesListView}
                    />
                  </>}
                  <Route path="*">
                    <h3>404 Page not found</h3>
                  </Route>
                </Switch>
                <FlashMessages/>
                <div id={"main-fixed-portal-root"}/>
              </main>
            </Router>
            {/*</GoogleMapApiContainer>
            <div ref={googleMapServiceRef}/>*/}
          </div>
        </AuthenticationContainer>
      </LanguageContainer>
    </CookiesProvider>
  );
}

const styles = StyleSheet.create({
  main: {
    position: "relative"
  }
});

const mapStateToProps = (state: IState) => {
  return {
    currentLanguage: selectLanguage(state)
  };
};

const mapDispatchToProps = (dispatch: any) => ({
  setLanguage: (language: string) => dispatch(SettingsActions.setLanguage(language))
});

export default connect(mapStateToProps, mapDispatchToProps)(AppWithoutStore);
