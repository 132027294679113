import React from 'react';
import { css, StyleSheet } from 'aphrodite/no-important';
import { SvgVariant } from './enum/svg-variant.enum';

import './icon.scss';

interface IPropTypes {
  children?: React.ReactNode;
  className?: string;
  component?: React.ElementType;
  viewBox?: string;
  /**
   * This property can be set for accessibility reasons.
   */
  titleAccess?: string;
  color?: SvgVariant;
}

/**
 * This functional Component acts as the base for all SVG icons.
 *
 * The size of each icon should be defined via CSS.
 */
export const SvgIcon = React.forwardRef((props: IPropTypes, ref: React.Ref<any>) => {
  const DEFAULT_ICON_SIZE = '16';

  const {
    component: Component = 'svg',
    className,
    viewBox = `0 0 ${DEFAULT_ICON_SIZE} ${DEFAULT_ICON_SIZE}`,
    color,
    titleAccess,
    children,
    ...rest
  } = props;

  return <Component
    className={`${css(svgVariants[color])} ${className}`}
    viewBox={viewBox}
    ref={ref}
    color={color}
    {...rest}
  >
    {titleAccess ? <title>{titleAccess}</title> : null}
    {children}
  </Component>
});

const svgVariants = StyleSheet.create({
  black: {
    fill: 'var(--black)',
  },
  white: {
    fill: 'var(--white)'
  },
  primary: {
    fill: 'var(--primary)'
  },
  secondary: {
    fill: 'var(--secondary)',
  },
  transparent: {
    fill: 'none'
  },
  grey: {
    fill: 'var(--grey)',
  },
  danger: {
    fill: 'var(--danger)'
  }
})
