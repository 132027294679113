
import { css, StyleSheet } from "aphrodite/no-important";
import React, { useCallback, useEffect, useState } from "react";
import { FieldCollapsableTitleBar } from "../../interfaces/field-collapsable.interface";
import { TitleBarItem } from "./title-bar-item/title-bar-item";

interface ITitleBarProps {
  fields: any,
  titleBar: FieldCollapsableTitleBar,
  isExpanded: boolean
}

const multipleTitleStyleChanger = 3;

export const TitleBar = (props: ITitleBarProps) => {
  const {
    titleBar,
    fields,
    isExpanded
  } = props;

  const [titleBarItems, setTitleBarItems] = useState<{ field, titleBarItem }[]>();

  const findFieldOrFieldsForTitleBarItem = useCallback((fields, searchTerm) => {
    let result = null;

    fields?.forEach((field) => {
      if (!!result) {
        return;
      }

      if (field?.name?.indexOf(searchTerm) !== -1) {
        result = field;
        return;
      }

      if ("fields" in field && !!field.fields) {
        result = findFieldOrFieldsForTitleBarItem(field?.fields, searchTerm);

        if (!!result) {
          return;
        }
      }
    });

    return result;
  }, []);

  useEffect(() => {
    const items: { field, titleBarItem }[] = [];
    titleBar?.map((titleBarGroup, _index) => {
      titleBarGroup?.forEach((titleBarItem, _i) => {
        const field = findFieldOrFieldsForTitleBarItem(fields, titleBarItem.replaceValue);
        items.push({ field, titleBarItem });
      });
    });

    setTitleBarItems(items);
  }, [titleBar, fields, findFieldOrFieldsForTitleBarItem]);

  const renderItems = (items: { field, titleBarItem }[], title: boolean = false) =>
    !!items && items?.map(({ titleBarItem, field }, index) => {
      const key = field + index;
      return (!!field && <TitleBarItem key={key}
                                      field={field}
                                      titleBarItem={titleBarItem}
                                       highlight={title}
                                       smaller={titleBarItems.length > multipleTitleStyleChanger}
                                       visible={!isExpanded || title}
                                       index={index}
                                      isExpanded={isExpanded}/>);
    });

  return (
    <div className={css(styles.container)}>
      {!!titleBarItems && !!titleBarItems[0] && <div className={css(styles.First)}>{renderItems(titleBarItems.slice(0,1), true)}</div>}
      {!!titleBarItems && titleBarItems?.length > 1 && <div className={css(styles.Second)}>{renderItems(titleBarItems.slice(1))}</div>}
    </div>
  );
};

const styles = StyleSheet.create({
  container: {
    display: "grid",
    gridTemplateColumns: "20% 1fr",
    gridTemplateRows: "1fr",
    gap: "10 10",
    gridTemplateAreas: '"First Second"',
  },
  First: {
    gridArea: "First",
    margin: "auto 0"
  },
  Second: {
    gridArea: "Second",
    display: "flex",
    flexWrap: "wrap",
    margin: "auto 0"
  },
});
