import React from 'react'
import { Button, IButtonProps } from './button';
import { ButtonSize } from './button-size.const';
import { ButtonColor } from './button-color.const';
import { ButtonIconPosition } from './button-icon-position.const';

interface IGhostButtonProps extends IButtonProps {
    label: string;
    onClick?: (event: any) => void;
    children?: any;
    disabled?: boolean;
    [rest: string]: any;
}

export const GhostButton = (props: IGhostButtonProps) => {
    const {
        label,
        onClick,
        children,
        disabled = false,
        ...rest
    } = props;

    return (
        <Button
            {...rest}
            onClick={onClick}
            btnClass={'medium-font-size bold margin-left-auto'}
            size={ButtonSize.Small}
            color={ButtonColor.Ghost}
            label={label}
            iconPosition={ButtonIconPosition.Left}
            disabled={disabled}
        >
            {children}
        </Button>
    )
}
