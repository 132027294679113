export const AffiliateActionTypes = {
    SET_AFFILIATE: '[Affiliate] Set affiliate',
    FETCH_IN_PROGRESS: '[Affiliate] Fetch In Progress',
    FETCH_FINISHED: '[Affiliate] Fetch Finished',
    SET_AFFILIATE_OWNERS: '[Affiliate] Set affiliate owners',
    UPDATE_AFFILIATE_OWNERS: '[Affiliate] Update affiliate owners',
    SET_AFFILIATE_TENANCIES: '[Affiliate] Set affiliate tenancies',
    UPDATE_AFFILIATE_TENANCIES: '[Affiliate] Update affiliate tenancies',
    SET_AFFILIATE_OWNER_AGENT_OBJECTS: '[Affiliate] Set affiliate ownerAgentObjects',
    UPDATE_AFFILIATE_OWNER_AGENT_OBJECTS: '[Affiliate] Update affiliate ownerAgentObjects',
    SET_AFFILIATE_TENANCY_AGENT_OBJECTS: '[Affiliate] Set affiliate tenancyAgentObjects',
    UPDATE_AFFILIATE_TENANCY_AGENT_OBJECTS: '[Affiliate] Update affiliate tenancyAgentObjects',
    TOUCH_AFFILIATE: '[Affiliate] Touch Affiliate',
}
