import { IState } from "../../interfaces/state.interface";
import { fromJS, List } from "immutable";
import { IReducer } from "../../interfaces/reducer.interface";
import { IAction } from "../../interfaces/action.interface";
import { mergeState } from "../../utils/reducer";
import { AffiliateActionTypes } from "./affiliate.action-types";
import { AffiliateModel } from "../../models/affiliate.model";
import { OwnerModel } from "../../models/owner.model";
import { TenancyModel } from "../../models/tenancy.model";
import { AgentModel } from "../../models/agent.model";

const initialState: IState = fromJS({
  data: new AffiliateModel(),
  owners: List<OwnerModel>(),
  tenancies: List<TenancyModel>(),
  ownerAgentObjects: List<AgentModel>(),
  tenancyAgentObjects: List<AgentModel>(),
  isLoading: false
});

export const affiliateReducer: IReducer = (state = initialState, action: IAction) => {
  switch (action.type) {
    case AffiliateActionTypes.SET_AFFILIATE:
      return mergeState(state, {
        data: new AffiliateModel(action.payload)
      });

    case AffiliateActionTypes.FETCH_IN_PROGRESS:
      return mergeState(state, {
        isLoading: true
      });

    case AffiliateActionTypes.FETCH_FINISHED:
      return mergeState(state, {
        isLoading: false
      });

    case AffiliateActionTypes.SET_AFFILIATE_OWNERS:
      return mergeState(state, {
        owners: List(action.payload?.map((owner) => new OwnerModel(owner)))
      });

    case AffiliateActionTypes.UPDATE_AFFILIATE_OWNERS:
      return mergeState(state, {
        owners: List(action.payload?.map((owner) => new OwnerModel(owner)))
      });

    case AffiliateActionTypes.SET_AFFILIATE_TENANCIES:
      return mergeState(state, {
        tenancies: List(action.payload?.map((tenancy) => new TenancyModel(tenancy)))
      });

    case AffiliateActionTypes.UPDATE_AFFILIATE_TENANCIES:
      return mergeState(state, {
        tenancies: List(action.payload?.map((tenancy) => new TenancyModel(tenancy)))
      });

    case AffiliateActionTypes.SET_AFFILIATE_OWNER_AGENT_OBJECTS:
      return mergeState(state, {
        ownerAgentObjects: List(action.payload?.map((tenancy) => new AgentModel(tenancy)))
      });

    case AffiliateActionTypes.UPDATE_AFFILIATE_OWNER_AGENT_OBJECTS:
      return mergeState(state, {
        ownerAgentObjects: List(action.payload?.map((agent) => new AgentModel(agent)))
      });

    case AffiliateActionTypes.SET_AFFILIATE_TENANCY_AGENT_OBJECTS:
      return mergeState(state, {
        tenancyAgentObjects: List(action.payload?.map((tenancy) => new TenancyModel(tenancy)))
      });

    case AffiliateActionTypes.UPDATE_AFFILIATE_TENANCY_AGENT_OBJECTS:
      return mergeState(state, {
        tenancyAgentObjects: List(action.payload?.map((agent) => new TenancyModel(agent)))
      });

    case AffiliateActionTypes.TOUCH_AFFILIATE:
      let data = state.get("data");
      data = data.set("updatedAt", new Date());
      data = data.set("updatedByTitle", action.payload?.updatedBy);
      return mergeState(state, {
        data
      });


    default:
      return state;
  }
};
