import { css, StyleSheet } from "aphrodite/no-important";
import React, { useEffect, useState } from "react";
import { IFieldSearchSuggestion } from "../../../interfaces/field-single.interface";
import { KeyCodeHelper } from "../../../../../shared/helpers/key-code.helper";
import { CssColor, CssSize } from "../../../../../shared/helpers/styles.helper";
import { FieldRelationSuggestionItem } from "./field-relation-suggestion-item";

interface IFieldRelationSuggestionsProps {
  suggestions: IFieldSearchSuggestion[]
  isFocused: boolean
  readonly: boolean //TODO rename to isReadonly
  onSelect?: (value, label) => void,
  onKeyDown?: (event) => void,
  label?: string
  infoText?: string
}

export const FieldRelationSuggestions = (props: IFieldRelationSuggestionsProps) => {
  const {
    suggestions,
    isFocused,
    readonly,
    onSelect,
    label,
    infoText
  } = props;

  const [cursor, setCursor] = useState(-1);

  useEffect(() => {
    const suggestionItems: any = document.querySelectorAll("li.suggest-item > button");
    if (!suggestionItems?.length) {
      return;
    }

    suggestionItems.forEach(item => item?.setAttribute("tabindex", "-1"));
    suggestionItems?.[cursor]?.setAttribute("tabindex", "0");
    suggestionItems?.[cursor]?.focus();
  }, [cursor]);

  const handleArrowKeys = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (KeyCodeHelper.isArrowUp(event.keyCode) || KeyCodeHelper.isArrowDown(event.keyCode)) {
      event.preventDefault();
      event.stopPropagation();
    }

    if (KeyCodeHelper.isArrowUp(event.keyCode)) {
      moveCursor();
    } else if (KeyCodeHelper.isArrowDown(event.keyCode)) {
      moveCursor(-1);
    }
  };

  const moveCursor = (direction = 1) => {
    const suggestionCount = suggestions?.length ?? 0;
    let newCursor = direction;

    if (newCursor < 0) {
      newCursor = suggestionCount - 1;
    }

    if (newCursor > suggestionCount - 1) {
      newCursor = 0;
    }

    setCursor(newCursor);
  };

  return <ul className={css(styles.suggestionsWrapper, (isFocused && !readonly) && styles.suggestionsWrapperVisible)}>
    {
      suggestions?.length ? (
        suggestions.map(({ id, label }, index) => {
          return <FieldRelationSuggestionItem key={index} id={id} label={label} index={index} handleArrowKeys={handleArrowKeys} onSelect={onSelect}/>
        })
      ) : (
        <li className={css(styles.result, styles.emptyResult)}>
          {infoText ?? `Bitte ${label} eingeben.`}
        </li>
      )
    }
  </ul>;
};

export const styles = StyleSheet.create({
  suggestionsWrapper: {
    opacity: 0,
    visibility: 'hidden',
    position: 'absolute',
    top: 'calc(100% + 5px)',
    minWidth: '100%',
    width: 'auto',
    filter: 'var(--base-drop-shadow-small)',
    maxHeight: 275,
    borderRadius: 'var(--base-radius)',
    overflow: 'auto',
    overflowScrolling: 'touch',
    willChange: 'filter, box-shadow',
    zIndex: 2,
    backgroundColor: CssColor.White
  },
  suggestionsWrapperVisible: {
    opacity: 1,
    visibility: 'visible'
  },
  result: {
    padding: `${CssSize.s10} ${CssSize.s15}`
  },
  emptyResult: {
    fontStyle: 'italic',
    textAlign: 'center',
    whiteSpace: 'nowrap',
    color: CssColor.Grey
  },
})
