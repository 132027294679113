import React, { useCallback, useEffect, useRef, useState } from "react";
import { css, StyleSheet } from "aphrodite";
import { CssColor } from "../../../shared/helpers/styles.helper";
import { useUser } from "../../../contexts/user.context";
import { ButtonGroup } from "../../../components/button-group/button-group";
import { Button, ButtonColor, ButtonIconPosition } from "../../../components/button";
import { MoreIcon } from "../../../components/icons/collection/more-icon";
import { useClickOutside } from "../../../hooks/use-click-outside";
import { LogOutIcon } from "../../../components/icons/collection/log-out-icon";
import { Auth } from "aws-amplify";
import { useHistory } from "react-router-dom";
import { connect } from "react-redux";
import { IState } from "../../../shared/interfaces/state.interface";
import { selectLanguage } from "../../../shared/redux/settings/settings.selector";
import { SettingsActions } from "../../../shared/redux/settings/settings.actions";
import { WorldIcon } from "../../../components/icons/collection/world-icon";

const iconUser = require("../../../assets/icon-user.png");

const flagDe = require("../../../assets/flag/flag-de.png");
const flagEn = require("../../../assets/flag/flag-en.png");

interface IUserMenuProps {
  isExpanded: boolean;
  currentLanguage: string;
  setLanguage: (language: string) => void;
}

const UserMenuWithoutStore = (props: IUserMenuProps) => {
  const {
    isExpanded,
    currentLanguage,
    setLanguage
  } = props;

  const { user } = useUser();

  const [userInformation, setUserInformation] = useState<string>();
  const [showExtendedMenu, setShowExtendedMenu] = useState<boolean>(false);

  useEffect(() => {
    if (!!user) {
      const names = [];
      const firstName = user.attributes?.["name"] ?? null;

      if (firstName) {
        names.push(firstName);
      }
      const lastName = user.attributes?.["family_name"] ?? null;

      if (lastName) {
        names.push(lastName);
      }

      const name = names.length ? names.join(" ") : null;
      const email = user.attributes?.["email"];
      setUserInformation(name ?? email);
    }
  }, [user]);

  const toggleShowExtendedMenu = useCallback(() => {
    setShowExtendedMenu(prevState => !prevState);
  }, []);

  useEffect(() => {
    if (!isExpanded) {
      setShowExtendedMenu(false);
    }
  }, [isExpanded]);

  const buttonRef = useRef();
  const buttonGroupRef = useRef();

  const useClickOutsideCallback = useCallback((event) => {
    // @ts-ignore
    const outside = !buttonGroupRef?.current?.contains(event.target);
    if (outside) {
      setShowExtendedMenu(false);
    }
  }, [buttonGroupRef]);

  useClickOutside(buttonRef, useClickOutsideCallback);

  const setCurrentLanguage = (language) => {
    setLanguage(language);
    setShowExtendedMenu(false);
  };

  const history = useHistory();

  const logout = async () => {
    try {
      await Auth.signOut();
      history.push("/");
    } catch (error) {
      console.error("error signing out: ", error);
    }
  };

  return <div className={css(styles.container)}>
    <div className={css(styles.iconContainer)}>
      <div className={css(styles.languageContainer)}>
        {currentLanguage === "de" && <img src={flagDe} alt={currentLanguage} className={css(styles.language)}/>}
        {currentLanguage === "en" && <img src={flagEn} alt={currentLanguage} className={css(styles.language)}/>}
      </div>
      <img src={iconUser} alt={userInformation} className={css(styles.icon)}/>
    </div>
    {isExpanded && <div className={css(styles.userInformationContainer)}><span className={css(styles.label)}>Eingeloggt als</span><br/>{userInformation}
    </div>}
    {isExpanded &&
    <div className={css(styles.extendedMenuContainer)}>
      <div className={css(styles.extendedMenu)}>
        <div className={css(styles.extendedMenuGroup, !showExtendedMenu && styles.hide)} ref={buttonGroupRef}>
          <ButtonGroup>
            {currentLanguage !== "en" &&
            <Button iconPosition={ButtonIconPosition.Left}
                    btnClass={"fullwidth"}
                    color={ButtonColor.Basic}
                    onClick={() => setCurrentLanguage("en")}
                    label={"Englisch"}><WorldIcon/></Button>}
            {currentLanguage !== "de" &&
            <Button iconPosition={ButtonIconPosition.Left}
                    btnClass={"fullwidth"}
                    color={ButtonColor.Basic}
                    onClick={() => setCurrentLanguage("de")}
                    label={"Deutsch"}><WorldIcon/></Button>}
            <Button iconPosition={ButtonIconPosition.Left}
                    btnClass={"fullwidth"}
                    color={ButtonColor.Basic}
                    onClick={logout}
                    label={"Logout"}><LogOutIcon/></Button>
          </ButtonGroup>
        </div>
        <div ref={buttonRef}><Button btnClass={`no-padding ${showExtendedMenu && "is-pressed"}`}
                                     color={ButtonColor.GhostDark} onClick={toggleShowExtendedMenu}><MoreIcon
          color={"white"}/></Button></div>
      </div>
    </div>}
  </div>;

};

const styles = StyleSheet.create({
  container: {
    display: "flex",
    padding: "var(--size-20) var(--size-25) var(--size-20) var(--size-20)",
    alignItems: "center",
    position: "absolute",
    bottom: 0,
    width: "100%"
  },
  iconContainer: {
    position: "relative"
  },
  languageContainer: {
    position: "absolute",
    bottom: "100%",
    left: "50%",
    transform: "translateX(-50%)",
    paddingBottom: "var(--size-10)"
  },
  language: {
    border: "1px solid " + CssColor.Primary,
    filter: "var(--base-drop-shadow-small)",
    borderRadius: "50%",
    display: "block",
    height: 20,
    width: 20
  },
  icon: {
    width: 50,
    height: 50
  },
  userInformationContainer: {
    color: CssColor.White,
    fontSize: "14px",
    lineHeight: "19px",
    paddingLeft: "var(--size-10)",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
    overflow: "hidden"
  },
  label: {
    fontWeight: "bold"
  },
  extendedMenuContainer: {
    flexGrow: 2,
    display: "flex",
    justifyContent: "flex-end"
  },
  extendedMenu: {
    position: "relative"
  },
  extendedMenuGroup: {
    position: "absolute",
    bottom: "100%",
    paddingBottom: "var(--size-10)",
    left: "50%",
    transform: "translateX(-50%)"
  },
  hide: {
    display: "none"
  }
});

const mapStateToProps = (state: IState) => {
  return {
    currentLanguage: selectLanguage(state)
  };
};

const mapDispatchToProps = (dispatch: any) => ({
  setLanguage: (language: string) => dispatch(SettingsActions.setLanguage(language))
});

export const UserMenu = connect(mapStateToProps, mapDispatchToProps)(UserMenuWithoutStore);
