import { css, StyleSheet } from "aphrodite/no-important";
import React from "react";
import { CssColor, CssSize } from "../../../../../shared/helpers/styles.helper";

interface IFieldRelationSuggestionItemsProps {
  index:number
  id: string | number,
  label: string
  handleArrowKeys: (event) => void,
  onSelect: (id, label) => void,
}

export const FieldRelationSuggestionItem = (props: IFieldRelationSuggestionItemsProps) => {
  const {
    index,
    id,
    label,
    handleArrowKeys,
    onSelect,
  } = props;

  const getLabelNice = () => `${label} (#${id})`;

  return <li className={`suggest-item`}>
              <button
                className={css(styles.resultButton, styles.result)}
                tabIndex={index === 0 ? 0 : -1}
                onKeyDown={handleArrowKeys}
                onClick={() => onSelect(id, getLabelNice())}>{getLabelNice()}
              </button>
            </li>;
};

export const styles = StyleSheet.create({
  result: {
    padding: `${CssSize.s10} ${CssSize.s15}`
  },
  resultButton: {
    textAlign: 'left',
    width: '100%',
    backgroundColor: CssColor.White,
    ':focus': {
      backgroundColor: CssColor.LighterGrey,
      outline: 'none'
    },
    ':hover': {
      backgroundColor: CssColor.LighterGrey,
    }
  }
})
