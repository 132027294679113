import React, { ReactNode } from 'react';
import { NavLink } from 'react-router-dom';
import { css, StyleSheet } from 'aphrodite';

interface INavigationItemProps {
  label: string;
  href: string;
  isExpanded: boolean;
  children?: ReactNode;
  [key: string]: any;
}

export const NavigationItem = React.memo((props: INavigationItemProps) => {
  const {
    label,
    href,
    isExpanded,
    children,
    ...rest
  } = props;

  const navItemClassName = `${rest?.className ?? ''} ${css(styles.navItem, !isExpanded && styles.centered)}`;

  return (
    <NavLink className={navItemClassName} activeClassName={css(styles.navItemActive)} to={href}>
      {children}
      <span className={css(isExpanded ? styles.showLabel : styles.hideLabel)}>
        {label}
      </span>
    </NavLink>
  )
})

const styles = StyleSheet.create({
  navItem: {
    display: 'flex',
    alignItems: 'center',
    width: '100%',
    color: '#ffffff',
    textDecoration: 'none',
    borderRadius: 'var(--base-radius)',
    ':hover': {
      '@media (hover: hover)': {
        backgroundColor: 'rgba(0,0,0, 0.15)',
      }
    }
  },
  centered: {
    justifyContent: 'center'
  },
  navItemActive: {
    backgroundColor: 'rgba(0,0,0, 0.15)'
  },
  hideLabel: {
    display: 'none'
  },
  showLabel: {
    display: 'inline-block'
  }
})
