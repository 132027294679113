import React, { Dispatch, ReactNode, SetStateAction } from 'react';

import './overlay.scss';

interface IOverlayProps {
  isActive?: boolean | Dispatch<SetStateAction<boolean>>;
  children: ReactNode;
  [rest: string]: any;
}

export const Overlay = React.memo(
  React.forwardRef(
    (props: IOverlayProps, ref: React.Ref<HTMLDivElement>) => {
      const {
        isActive = true,
        children,
        ...rest
      } = props;

      return (
        <div
          ref={ref}
          className={`overlay ${isActive ? 'show' : 'hide'}`}
          {...rest}
        >
          {children}
        </div>
      )
    }
  )
)
