import { IState } from '../../../interfaces/state.interface';
import { List } from 'immutable';
import { RemarkModel } from '../../../models/remark.model';
import { PropertyIntroTextModel } from '../../../models/property-intro-text.model';

const basePropertyTextsSelector = ['propertyTexts'];

export const selectPropertyTextsRemarks = (state: IState): List<RemarkModel> => state.getIn([...basePropertyTextsSelector, 'remarks']);

export const selectPropertyIntroTexts = (state: IState): List<PropertyIntroTextModel> => state.getIn([...basePropertyTextsSelector, 'introTexts']);

export const selectActiveIntroTextTemplate = (state: IState): string => state.getIn([...basePropertyTextsSelector, 'activeIntroTextTemplate']);

export const selectShowIntroTextInWatchlist = (state: IState): boolean => state.getIn([...basePropertyTextsSelector, 'showIntroTextInWatchlist']);