import React, { Component } from 'react'
import { NoRenderingError } from '../../../shared/constants/no-rendering.error';

export interface IDialogControlProps {
  label: string;
  color?: string;
  value?: any;
  onClick?: (...params) => void;
}

export class DialogControl extends Component<IDialogControlProps> {
  render() {
    throw new NoRenderingError('DialogControl');
    // eslint-disable-next-line no-unreachable
    return <></>;
  }
}
