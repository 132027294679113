import { Component } from 'react';
import { IDatasetConfig } from './interfaces/dataset-config.interface';
import { KeyValuePairs } from '../../shared/types/key-value-pairs.type';
import { HttpService } from '../../shared/services/http.service';

export abstract class DatasetBase<TProps, TState = any> extends Component<TProps, TState> implements IDatasetConfig {
  readonly createPath: string = '';

  protected async createDataset(dataset: KeyValuePairs, pathSuffix: string = ''): Promise<any> {
    return await HttpService.performPostRequest(this.createPath + pathSuffix, dataset);
  }
}
