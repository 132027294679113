import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { PropertyTextsDataService } from "../../../../../shared/services/property-texts-data.service";
import { PropertyTextsActions } from "../../../../../shared/redux/property/property-texts/property-texts.actions";
import { RemarkModel } from "../../../../../shared/models/remark.model";
import { selectPropertyId } from "../../../../../shared/redux/property/property.selectors";
import { selectPropertyTextsRemarks } from "../../../../../shared/redux/property/property-texts/property-texts.selector";
import { IState } from "../../../../../shared/interfaces/state.interface";
import { Fieldset } from "../../../../fieldset/fieldset";
import { List } from "immutable";
import { Button, ButtonColor, ButtonIconPosition, ButtonSize } from "../../../../button";
import { SvgVariant } from "../../../../icons/enum/svg-variant.enum";
import { AddIcon } from "../../../../icons/collection/add-icon";
import { css, StyleSheet } from "aphrodite/no-important";
import { RemarkItemWithStore } from "./remark-item";
import { DeleteEntryDialog } from "../../../../overlay/dialog/variants/delete-entry-dialog";
import { useUser } from "../../../../../contexts/user.context";

interface IRemarksListProps {
  propertyId: number;
  remarks: List<RemarkModel>;
  fetchRemarks: (propertyId: number) => void;
  addRemark: (propertyId: number) => void;
  deleteRemark: (propertyId: number, id: string) => void;
}

export const RemarksList = (props: IRemarksListProps) => {
  const {
    propertyId,
    remarks,
    fetchRemarks,
    addRemark,
    deleteRemark
  } = props;

  const [showDeleteDialog, setShowDeleteDialog] = useState(false);
  const [deleteIndex, setDeleteIndex] = useState(null);

  const { getGroups } = useUser();

  const [writePermissions, setWritePermissions] = useState(false);

  useEffect(() => {
    if (getGroups()?.includes("admin") || getGroups()?.includes("editor")) {
      setWritePermissions(true);
    }
  }, [getGroups]);

  useEffect(() => {
    if (!propertyId) return;

    fetchRemarks(propertyId);
  }, [fetchRemarks, propertyId]);

  const onDelete = (id: string) => {
    if (!writePermissions) {
      return;
    }

    setDeleteIndex(id);
    setShowDeleteDialog(true);
  };

  const onDialogClick = (isDeleteClick: boolean) => {
    if (!writePermissions) {
      return;
    }

    if (isDeleteClick) {
      deleteRemark(propertyId, deleteIndex);
    }

    setShowDeleteDialog(false);
  };

  return (
    <>
      <Fieldset>
        <Fieldset.Legend withSeparator={false}>
          <div className={css(styles.legendTitle)}>Bemerkungen ({remarks?.size})</div>
          {writePermissions && <Button
            onClick={() => addRemark(propertyId)}
            btnClass={"medium-font-size bold margin-left-auto"}
            size={ButtonSize.Small}
            color={ButtonColor.Ghost}
            label={"Bemerkung hinzufügen"}
            iconPosition={ButtonIconPosition.Left}>
            <AddIcon
              viewBox='0 0 30 30'
              color={SvgVariant.Secondary}
              titleAccess='Eintrag hinzufügen'
            />
          </Button>}
        </Fieldset.Legend>
        <Fieldset.Content>
          <div className={css(styles.remarksList)}>
            {remarks?.map((remark: RemarkModel, index: number) =>
              <RemarkItemWithStore
                key={remark.get("id")}
                remark={remark}
                propertyId={propertyId}
                enumeration={remarks?.size - index}
                onDelete={onDelete}/>
            )}
          </div>
        </Fieldset.Content>
      </Fieldset>
      <DeleteEntryDialog
        onControlClick={onDialogClick}
        showDialog={showDeleteDialog}
        onClose={() => setShowDeleteDialog(false)}
      />
    </>
  );
};

const styles = StyleSheet.create({
  legendTitle: {
    fontSize: "var(--font-size-title)",
    fontWeight: "bold"
  },
  remarksList: {
    display: "grid",
    gridTemplateColumns: "1fr",
    gridGap: 10
  }
});

const mapStateToProps = (state: IState) => {
  return {
    propertyId: selectPropertyId(state),
    remarks: selectPropertyTextsRemarks(state)
  };
};

const mapDispatchToProps = (dispatch: any) => ({
  fetchRemarks: async (propertyId: number) => {
    const res = await PropertyTextsDataService.fetchRemarks(propertyId);
    dispatch(PropertyTextsActions.setRemarks(res?.data));
  },
  addRemark: async (propertyId: number) => {
    const res = await PropertyTextsDataService.addRemark(propertyId);
    dispatch(PropertyTextsActions.addRemark(res?.data));
  },
  deleteRemark: async (propertyId: number, id: string) => {
    const res = await PropertyTextsDataService.deleteRemark(propertyId, id);
    dispatch(PropertyTextsActions.deleteRemark(res?.data?.id));
  }
});

export const RemarksListWithStore = connect(mapStateToProps, mapDispatchToProps)(RemarksList);
