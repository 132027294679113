import React, { useContext } from 'react';
import { css, StyleSheet } from 'aphrodite';
import { CssSize, CssColor } from '../../shared/helpers/styles.helper';
import { SuggestionDropdownContext, OnSelectFn } from '../../contexts/suggestion-dropdown.context';


interface ISuggestionDropdownListProps {
  suggestions: string[];
  onSelect: OnSelectFn;
}

export const SuggestionDropdownList = (props: ISuggestionDropdownListProps) => {
  const {
    suggestions,
    onSelect
  } = props;

  const {
    selectedSuggestions
  } = useContext(SuggestionDropdownContext);

  const isSelected = (value: string) => {
    return !!selectedSuggestions?.find((selectedValue) => selectedValue === value);
  }

  return (
    <ul className={css(styles.suggestionList)}>
      {suggestions.map(value => (
        <li key={value} className={css(styles.suggestionItem)}>
          <button
            onClick={() => onSelect(value)}
            className={css(styles.suggestionItemButton)}
            disabled={isSelected(value)}
          >
            {value}
          </button>
        </li>
      ))}
    </ul>
  );
};


const styles = StyleSheet.create({
  suggestionList: {
    padding: 0,
    margin: 0,
    maxHeight: `calc(${CssSize.s50} * 6)`,
    overflowX: 'hidden',
    overfloxY: 'auto'
  },
  suggestionItem: {
  },
  suggestionItemButton: {
    display: 'flex',
    alignItems: 'center',
    textAlign: 'left',
    height: CssSize.s50,
    width: '100%',
    padding: `0 ${CssSize.s20}`,
    background: 'none',
    outline: 'none',
    ':not([disabled])': {
      ':hover': {
        backgroundColor: CssColor.LighterPrimary,
        cursor: 'pointer'
      },
    }
  }
});
