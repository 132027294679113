import { IUserModel } from "../../models/user.model";
import { UserActionTypes } from "./users.action-types";
import { SortOrder } from "../../constants/sort-order.enum";

export abstract class UsersActions {

  static setUsers(payload: IUserModel[]) {
    return {
      type: UserActionTypes.SET_USERS,
      payload
    };
  }

  static setUsersMeta(updatedAt: string, updatedByTitle: string) {
    return {
      type: UserActionTypes.SET_USERS_META,
      payload: { updatedAt, updatedByTitle }
    };
  }

  static sortUsers(sortByColumn: string, sortOrder: SortOrder) {
    return {
      type: UserActionTypes.SORT_USERS,
      payload: {
        sortByColumn,
        sortOrder
      }
    };
  }

  static addEmpty() {
    return {
      type: UserActionTypes.ADD_EMPTY_USER
    };
  }

  static create(user: Partial<IUserModel>, index: number) {
    return {
      type: UserActionTypes.CONFIRM_USER,
      payload: {
        user,
        index
      }
    };
  }

  static update(user: Partial<IUserModel>, index: number) {
    return {
      type: UserActionTypes.UPDATE_USER,
      payload: {
        user,
        index
      }
    };
  }

  static delete(index: number) {
    return {
      type: UserActionTypes.DELETE_USER,
      payload: index
    };
  }

  static enable(index: number) {
    return {
      type: UserActionTypes.ENABLE_USER,
      payload: index
    };
  }

  static disable(index: number) {
    return {
      type: UserActionTypes.DISABLE_USER,
      payload: index
    };
  }

  static startFetching() {
    return {
      type: UserActionTypes.FETCH_IN_PROGRESS
    };
  }

}
