import React from 'react';

interface IFieldArrayContext {
  existingDataRegistry: string[];
}

const FIELD_ARRAY_DEFAULT_VALUE: IFieldArrayContext = {
  existingDataRegistry: null
};

export const FieldArrayContext = React.createContext(FIELD_ARRAY_DEFAULT_VALUE);
