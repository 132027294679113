import { mergeFilter } from '../../utils/filter-utils';
import { PropertiesActionTypes } from './properties.action-types';
import { IState } from '../../interfaces/state.interface';
import { IReducer } from '../../interfaces/reducer.interface';
import { fromJS, List } from 'immutable';
import { mergeState } from '../../utils/reducer';
import { PropertyMetaDataModel } from '../../models/property-meta-data.model';
import { IAction } from '../../interfaces/action.interface';
import { PropertyModel } from '../../models/property.model';
import { ActiveFilterModel } from '../../models/active-filter.model';
import { PropertiesSideEffectActions } from './properties-side-effect.actions';
import { LoadingStatus } from '../../constants/loading-status.enum';
import { SortOrder } from '../../constants/sort-order.enum';

const initialState: IState = fromJS({
  list: List<PropertyModel>([]),
  meta: new PropertyMetaDataModel(),
  activeFilter: [],
  loadingStatus: LoadingStatus.READY,
  offset: 0,
  sortOrder: SortOrder.DESC,
  sortByColumn: 'propertyId',
  scrollTop: 0,
});

export const propertiesReducer: IReducer = (state = initialState, action: IAction) => {
  switch (action.type) {
    case PropertiesActionTypes.SET_LIST: {
      const data = action.payload?.data;
      const meta = action.payload?.meta;
      return mergeState(state, {
        list: List<PropertyModel>(data.map(data => {
          const constructionYear = data?.constructionYear ? new Date(data.constructionYear).getFullYear() : null;
          const allFullStreets = data?.allFullStreets ? data?.allFullStreets.replaceAll(',', '<br/>') : null;

          return new PropertyModel({
            ...data,
            constructionYear,
            allFullStreets
          });
        })),
        meta: new PropertyMetaDataModel(meta),
        loadingStatus: action.loadingStatus
      });
    }
    case PropertiesActionTypes.SET_FILTER:
      return mergeState(state, {
        activeFilter: mergeFilter(state.get('activeFilter'), action.payload),
        offset: 0
      });

    case PropertiesActionTypes.SET_OFFICE_AREA_TYPE_FILTER:
      return PropertiesSideEffectActions.setOfficeAreaTypeFilter(state, action);

    case PropertiesActionTypes.RESET_OFFICE_AREA_TYPE_FILTER:
      return PropertiesSideEffectActions.resetOfficeAreaTypeFilter(state);

    case PropertiesActionTypes.RESET_OFFICE_AREA_FILTER:
      return PropertiesSideEffectActions.resetOfficeAreaFilter(state);

    case PropertiesActionTypes.RESET_FILTER:
      const activeFilterState = state.get('activeFilter') as List<ActiveFilterModel>;
      const removeFilterIndex = activeFilterState.findIndex((currentFilter: ActiveFilterModel) => currentFilter.groupKey === action.payload);
      if (removeFilterIndex === -1) {
        return state;
      }

      return mergeState(state, {
        activeFilter: activeFilterState.remove(removeFilterIndex),
        offset: 0
      });

    case PropertiesActionTypes.RESET_ALL_FILTER:
      return mergeState(state, {
        activeFilter: [],
        offset: 0
      });

    case PropertiesActionTypes.FETCH_IN_PROGRESS:
      return mergeState(state, {
        loadingStatus: action.loadingStatus
      });

    case PropertiesActionTypes.FETCH_FINISHED:
      return mergeState(state, {
        loadingStatus: action.loadingStatus
      });

    case PropertiesActionTypes.SET_SORTING:
      return mergeState(state, {
        sortOrder: action.payload.sortOrder,
        sortByColumn: action.payload.sortByColumn,
        offset: 0,
        loadingStatus: action.loadingStatus
      });

    case PropertiesActionTypes.SET_SORTED_LIST:
      return mergeState(state, {
        list: List<PropertyModel>(action.payload.map(data => {
          const constructionYear = data?.constructionYear ? new Date(data.constructionYear).getFullYear() : null;
          const allFullStreets = data?.allFullStreets ? data?.allFullStreets.replaceAll(',', '<br/>') : null;

          return new PropertyModel({
            ...data,
            constructionYear,
            allFullStreets
          });
        }))
      });

    case PropertiesActionTypes.SET_OFFSET:
      return mergeState(state, {
        offset: action.payload,
        loadingStatus: action.loadingStatus
      });

    case PropertiesActionTypes.RESET_DATA: {
      const currentList = state.get('list');

      return mergeState(state, {
        data: currentList.slice(0, +process.env.REACT_APP_LOAD_MORE_OFFSET)
      });
    }

    case PropertiesActionTypes.SET_SCROLL_TOP: {
      return mergeState(state, {
        scrollTop: action.payload
      });
    }

    case PropertiesActionTypes.SET_LOADED_DATA:
      const currentList = state.get('list');
      const newList = [
        ...currentList,
        ...action.payload.map(data => {
          const constructionYear = data?.constructionYear ? new Date(data.constructionYear).getFullYear() : null;
          const allFullStreets = data?.allFullStreets ? data?.allFullStreets.replaceAll(',', '<br/>') : null;


          return new PropertyModel({
            ...data,
            constructionYear,
            allFullStreets
          });
        })
      ];

      return mergeState(state, {
        list: newList
      });

    default:
      return state;
  }
}
