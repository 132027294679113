import React from 'react';
import { css, StyleSheet } from 'aphrodite';
import { FormattingHelper } from '../../shared/services/formatting.helper';
import { CssSize } from '../../shared/helpers/styles.helper';

interface IPropertyMediaHeadlineProps {
    title: string;
    lastEditedAt?: Date | string;
}

export const PropertyMediaHeadline = (props: IPropertyMediaHeadlineProps) => {
    const {
        title,
        lastEditedAt,
    } = props;

    return (
        <header className={css(styles.headerWrapper)}>
            <h3 className={css(styles.title)}>
                {title}
            </h3>
            {
                !!lastEditedAt && (
                    <h4 className={css(styles.lastEdited)}>
                        Letzte Änderung am <span className={css(styles.lastEditedDate)}>{FormattingHelper.formatDateTime(lastEditedAt)}</span>
                    </h4>
                )
            }
        </header>
    );
}

const styles = StyleSheet.create({
    headerWrapper: {
        display: 'grid',
        gridAutoFlow: 'column',
        columnGap: CssSize.s30,
        alignItems: 'baseline',
    },
    title: {
        fontSize: CssSize.titleFontSize,
        marginBottom: 0,
    },
    lastEdited: {
        fontSize: CssSize.baseFontSize,
        fontWeight: 'normal',
        marginBottom: 0,
    },
    lastEditedDate: {
        fontWeight: 'bold',
    }
});