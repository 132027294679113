
export const defaultRotation = 90;

export const canvasAspectRatio = 16 / 9;

export const canvasDimensions = {
    width: 600 * canvasAspectRatio,
    height: 600
};

export const sliderSettings = {
    min: 20,
    max: 170,
    defaultValue: 'none',
};