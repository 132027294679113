import React, { useEffect, useState } from "react";
import {
  IPropertyIntroTextsParams,
  PropertyIntroTextModel
} from "../../../../../shared/models/property-intro-text.model";
import { CollapsableElements } from "../../../../collapsable-elements/collapsable-elements";
import { ButtonIconPosition } from "../../../../button";
import { css, StyleSheet } from "aphrodite/no-important";
import { CssColor, CssSize } from "../../../../../shared/helpers/styles.helper";
import { SvgVariant } from "../../../../icons/enum/svg-variant.enum";
import { CheckWithBackgroundIcon } from "../../../../icons/collection/check-with-background-icon";
import { connect } from "react-redux";
import { PropertyTextsDataService } from "../../../../../shared/services/property-texts-data.service";
import { PropertyTextsActions } from "../../../../../shared/redux/property/property-texts/property-texts.actions";
import { KeyCodeHelper } from "../../../../../shared/helpers/key-code.helper";
import { IState } from "../../../../../shared/interfaces/state.interface";
import { selectActiveIntroTextTemplate } from "../../../../../shared/redux/property/property-texts/property-texts.selector";
import { Language } from "../../../../../shared/constants/language.enum";
import { Translated } from "../../../../translated/translated";
import { PropertyActions } from "../../../../../shared/redux/property/property.actions";
import { useUser } from "../../../../../contexts/user.context";

interface IIntroTextItemProps {
  index: string;
  propertyId: number;
  introText: PropertyIntroTextModel;
  activeIntroTextTemplate: string;
  updateOwnIntroText: (propertyId: number, data: IPropertyIntroTextsParams) => void;
  updateActiveIntroTextTemplate: (propertyId: number, value: string) => void;
}

export const IntroTextItem = (props: IIntroTextItemProps) => {
  const {
    index,
    propertyId,
    introText,
    activeIntroTextTemplate,
    updateOwnIntroText,
    updateActiveIntroTextTemplate
  } = props;

  const { getGroups } = useUser();

  const [writePermissions, setWritePermissions] = useState(false);

  useEffect(() => {
    if (getGroups()?.includes("admin") || getGroups()?.includes("editor")) {
      setWritePermissions(true);
    }
  }, [getGroups]);

  const handleOnBlur = (text: string, language: Language) => {
    if(!writePermissions) {
      return;
    }

    const key = "template_" + language;
    // @ts-ignore
    const distinctText = text !== introText.get(key);

    if (distinctText && introText.get("isEditable")) {
      const updateObject = {};
      updateObject[key] = text;
      updateOwnIntroText(propertyId, introText.merge(updateObject));

      return;
    }
  };

  const handleOnKeyDown = (event) => {
    if(!writePermissions) {
      return;
    }

    if (KeyCodeHelper.isEnter(event.keyCode)) {
      event.target.blur();
    }
  };

  const isChecked = () => {
    return getActiveIntroTextTemplateValue() === activeIntroTextTemplate;
  };

  const getActiveIntroTextTemplateValue = () => {
    return `${introText.get("introTextId")}_${introText.get("introTextTemplate")}`;
  };

  return (
    <CollapsableElements
      chevronDownPosition={ButtonIconPosition.Left}
      header={(
        <div className={css(styles.introTextTitleBar)}>
          <Translated translations={[
            {
              language: Language.GERMAN,
              value: <p className={css(styles.noMargin)}>{introText.get("title_de")}</p>
            },
            {
              language: Language.ENGLISH,
              value: <p className={css(styles.noMargin)}>{introText.get("title_en")}</p>
            }
            ]}/>
          <input
            className={css(styles.hideRadioBox)}
            id={index}
            name={`${propertyId}`}
            checked={isChecked()}
            value={getActiveIntroTextTemplateValue()}
            onChange={({ target: { value } }) => updateActiveIntroTextTemplate(propertyId, value)}
            readOnly={!writePermissions}
            disabled={!writePermissions}
            type='radio'/>
          <label
            htmlFor={index}
            className={css([styles.activeIndicator, writePermissions && styles.writableIntroTexts])}>
            {isChecked() && (
              <CheckWithBackgroundIcon
                className={css(styles.activeIndicatorSvg)}
                viewBox='0 0 20 20'
                color={SvgVariant.Secondary}
                titleAccess={"Introtext aktivieren/deaktivieren"}/>
            )}
          </label>
        </div>
      )}
      content={(
        <div className={css(styles.introTextContent)}>
          <Translated translations={[
            {
              language: Language.GERMAN,
              value: <textarea
                cols={20}
                rows={10}
                readOnly={!introText.get("isEditable") || !writePermissions}
                disabled={!writePermissions}
                className={css(!introText.get("isEditable") && styles.readOnly)}
                defaultValue={introText.get("template_de") ?? ""}
                onKeyDown={(event) => handleOnKeyDown(event)}
                onBlur={({ target: { value } }) => handleOnBlur(value, Language.GERMAN)}/>
            },
            {
              language: Language.ENGLISH,
              value: <textarea
                cols={20}
                rows={10}
                readOnly={!introText.get("isEditable") || !writePermissions}
                disabled={!writePermissions}
                className={css(!introText.get("isEditable") && styles.readOnly)}
                defaultValue={introText.get("template_en") ?? ""}
                onKeyDown={(event) => handleOnKeyDown(event)}
                onBlur={({ target: { value } }) => handleOnBlur(value, Language.ENGLISH)}/>
            }
          ]}/>
        </div>
      )}
    />
  );
};

const styles = StyleSheet.create({
  hideRadioBox: {
    visibility: "hidden",
    overflow: "hidden",
    position: "absolute",
    top: 0,
    left: 0
  },
  activeIndicator: {
    width: CssSize.s20,
    height: CssSize.s20,
    backgroundColor: "transparent",
    borderRadius: "50%",
    border: `var(--base-border) solid ${CssColor.Secondary}`,
    marginBottom: 0,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    position: "relative"
  },
  writableIntroTexts: {
    cursor: "pointer",
  },
  activeIndicatorSvg: {
    width: 20,
    height: 20
  },
  noMargin: {
    marginBottom: 0
  },
  introTextTitleBar: {
    display: "grid",
    gridTemplateColumns: `1fr ${CssSize.s20}`,
    gridGap: CssSize.s10,
    alignItems: "center",
    paddingRight: CssSize.s10
  },
  introTextContent: {
    padding: `${CssSize.s20} ${CssSize.s40}`
  },
  readOnly: {
    color: CssColor.Grey
  }
});

const mapStateToProps = (state: IState) => {
  return {
    activeIntroTextTemplate: selectActiveIntroTextTemplate(state),
  };
};

const mapDispatchToProps = (dispatch: any) => ({
  updateActiveIntroTextTemplate: async (propertyId: number, value: string) => {
    const res = await PropertyTextsDataService.updateActiveIntroTextTemplate(propertyId, value)
    dispatch(PropertyTextsActions.setActiveIntroTextTemplate(value));
    dispatch(PropertyActions.touch(res?.data?.updatedByTitle));
  },
  updateOwnIntroText: async (propertyId: number, data: IPropertyIntroTextsParams) => {
    const res = await PropertyTextsDataService.updateOwnIntroText(propertyId, data);
    dispatch(PropertyTextsActions.updateOwnIntroText(data));
    dispatch(PropertyActions.touch(res?.data?.updatedByTitle));
  }
});

export const IntroTextItemWithStore = connect(mapStateToProps, mapDispatchToProps)(IntroTextItem);
