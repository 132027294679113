import { Record } from 'immutable';

interface IPropertyMetaDataParams {
  total?: number;
  isPublity?: number;
  isPipeline?: number;
  data?: IMetaDataItem[];
}

export interface IMetaDataItem {
  label: string;
  value: number;
}

export class PropertyMetaDataModel extends Record<IPropertyMetaDataParams>({
  total: 0,
  isPublity: 0,
  isPipeline: 0,
  data: [],
}) {
  constructor(params?: IPropertyMetaDataParams) {
    params ? super(params) : super();
  }

  with(values: IPropertyMetaDataParams): this {
    return this.merge(values) as this;
  }
}
