import {IState} from "../../interfaces/state.interface";
import {IReducer} from "../../interfaces/reducer.interface";
import {fromJS, List} from "immutable";
import {IAction} from "../../interfaces/action.interface";
import {TenancyModel} from "../../models/tenancy.model";
import {LoadingStatus} from "../../constants/loading-status.enum";
import {SortOrder} from "../../constants/sort-order.enum";
import {mergeState} from "../../utils/reducer";
import {TenanciesActionTypes} from "./tenancies.action-types";
import {TenanciesMetaDataModel} from "../../models/tenancies-meta-data.model";
import {mergeFilter} from "../../utils/filter-utils";
import {ActiveFilterModel} from "../../models/active-filter.model";

const initialState: IState = fromJS({
  loadingStatus: LoadingStatus.READY,
  data: List<TenancyModel>([]),
  meta: new TenanciesMetaDataModel(),
  offset: 0,
  sortOrder: SortOrder.DESC,
  sortByColumn: "_affiliateId",
  activeFilter: [],
  scrollTop: 0,
});

export const tenanciesReducer: IReducer = (state = initialState, action: IAction) => {
  switch (action.type) {
    case TenanciesActionTypes.SET_DATA: {
      const data = action.payload?.data;
      const meta = action.payload?.meta;

      return mergeState(state, {
        data: List<TenancyModel>(data?.map(data => new TenancyModel(data))),
        meta: new TenanciesMetaDataModel(meta),
        loadingStatus: action.loadingStatus
      });
    }
    case TenanciesActionTypes.SET_SORTED_LIST:
      return mergeState(state, {
        data: List<TenancyModel>(action.payload?.map(data => new TenancyModel(data)))
      });

    case TenanciesActionTypes.SET_FILTER:
      return mergeState(state, {
        activeFilter: mergeFilter(state.get("activeFilter"), action.payload),
        offset: 0
      });

    case TenanciesActionTypes.RESET_FILTER:
      const activeFilterState = state.get("activeFilter") as List<ActiveFilterModel>;
      const removeFilterIndex = activeFilterState.findIndex((currentFilter: ActiveFilterModel) => currentFilter.groupKey === action.payload);
      if (removeFilterIndex === -1) {
        return state;
      }

      return mergeState(state, {
        activeFilter: activeFilterState.remove(removeFilterIndex),
        offset: 0
      });

    case TenanciesActionTypes.RESET_ALL_FILTER:
      return mergeState(state, {
        activeFilter: [],
        offset: 0
      });


    case TenanciesActionTypes.FETCH_IN_PROGRESS:
      return mergeState(state, {
        loadingStatus: action.loadingStatus
      });

    case TenanciesActionTypes.FETCH_FINISHED:
      return mergeState(state, {
        loadingStatus: action.loadingStatus
      });

    case TenanciesActionTypes.SET_SORTING:
      return mergeState(state, {
        sortOrder: action.payload?.sortOrder,
        sortByColumn: action.payload?.sortByColumn,
        offset: 0,
        loadingStatus: action.loadingStatus
      });

    case TenanciesActionTypes.SET_OFFSET: {
      return mergeState(state, {
        offset: action.payload,
        loadingStatus: action.loadingStatus
      });
    }

    case TenanciesActionTypes.RESET_DATA: {
      const currentList = state.get('data');

      return mergeState(state, {
        data: currentList.slice(0, +process.env.REACT_APP_LOAD_MORE_OFFSET)
      });
    }

    case TenanciesActionTypes.SET_SCROLL_TOP: {
      return mergeState(state, {
        scrollTop: action.payload
      });
    }

    case TenanciesActionTypes.SET_MORE_DATA: {
      const currentList = state.get('data');

      return mergeState(state, {
        data: [
          ...currentList,
          ...action.payload.map(data => {
            return new TenancyModel({
              ...data,
            });
          })
        ]
      });
    }

    default:
      return state;
  }
};
