import React from 'react';
import { IFieldSet } from "../interfaces/field-set.interface";
import { FormFieldSets } from "../form-field-sets";
import { IFormFieldset } from "../interfaces/form-fieldset.interface";

interface IFieldSetProps extends IFieldSet {
  formValues;
  changeHandler;
  refetchSourceData;
  sets: IFormFieldset[];
}

export const FieldSets = (props: IFieldSetProps) => {
  const {
    formValues,
    changeHandler,
    refetchSourceData,
    sets
  } = props;

  return <FormFieldSets formValues={formValues} sets={sets} changeHandler={changeHandler} refetchSourceData={refetchSourceData} />;
}
