import { Record } from 'immutable';
import { IPropertyMedia } from '../interfaces/property-media.interface';

const defaults: IPropertyMedia = {
    id: undefined,
    _propertyId: undefined,
    title: undefined,
    s3Key: undefined,
    thumbnailS3Key: undefined,
    fileSize: undefined,
    fileType: undefined,
    position: undefined,

    createdAt: undefined,
    deletedAt: undefined,
    updatedAt: undefined,
};

export class MediaElementModel extends Record<IPropertyMedia>(defaults) {
    constructor(params?: IPropertyMedia) {
        params ? super(params) : super();
    }

    with(values: IPropertyMedia): this {
        return this.merge(values) as this;
    }
}
