import { IState } from '../../interfaces/state.interface';
import { createSelector } from 'reselect';

const basePropertyMediasSelector = ['propertyMediaImage'];
const selectPropertyMediaImageBase = (state: IState): IState => state.getIn([...basePropertyMediasSelector]);

export const selectPropertyMediaImages = createSelector(
  [selectPropertyMediaImageBase],
  (propertyMediaState) => {
    return propertyMediaState.get('images');
  }
);

export const selectPropertyMediaImageUpdatedAt = createSelector(
  [selectPropertyMediaImageBase],
  (propertyMediaState) => {
    return propertyMediaState.get('updatedAt');
  }
);

export const selectMediaImageHightlight = createSelector(
  [selectPropertyMediaImageBase],
  (propertyMediaState) => {
    return propertyMediaState.get('highlightId') as string;
  }
)

export const selectMediaImageSize = createSelector(
  [selectPropertyMediaImageBase],
  (propertyMediaState) => {
    return propertyMediaState.get('images').size as number;
  }
)
