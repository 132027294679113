import { FieldArrayActionTypes } from './field-array.action-types';

export function setData(data: any[]) {
  return {
    type: FieldArrayActionTypes.SET_DATA,
    data
  }
}

export function setPreviousData(previousData: any[]) {
  return {
    type: FieldArrayActionTypes.SET_PREVIOUS_DATA,
    previousData
  }
}

export function setIsDataAddAllowed(isDataAddAllowed: boolean) {
  return {
    type: FieldArrayActionTypes.SET_IS_DATA_ADD_ALLOWED,
    isDataAddAllowed
  }
}

export function setRegistry(existingData: string[]) {
  return {
    type: FieldArrayActionTypes.SET_EXISTING_DATA_REGISTRY,
    existingDataRegistry: existingData
  }
}
