import React, { useEffect, useState } from "react";
import { css, StyleSheet } from "aphrodite/no-important";
import { SvgVariant } from "../../icons/enum/svg-variant.enum";
import { ChevronDownIcon } from "../../icons/collection/chevron-down-icon";
import { IFieldCollapsable } from "../interfaces/field-collapsable.interface";
import { Button, ButtonColor, ButtonSize } from "../../button";
import { CloseIcon } from "../../icons/collection/close-icon";
import { FormHelper } from "../helpers/form.helper";
import { TemplateType } from "../enums/template-type.enum";
import { TitleBar } from "./title-bar/title-bar";

interface IFieldCollapsableProps extends IFieldCollapsable {
  additionalClickHandler?: () => void;
  triggerDataRefetch?: () => void;
  sourceId?: string;
  readonly?: boolean;
}

const fullWidthTemplateType = [
  TemplateType.FIELD_TABLE,
  TemplateType.FIELD_TAB_ARRAY,
  TemplateType.FIELD_ARRAY,
  TemplateType.FIELD_ADDRESS_ARRAY
];

export const FieldCollapsable = (props: IFieldCollapsableProps) => {
  const {
    label,
    fields,
    changeHandler,
    titleBar,
    additionalClickHandler,
    showAdditionalButton,
    initialExpand,
    sourceId,
    triggerDataRefetch,
    readonly = false,
  } = props;

  const [isExpanded, setIsExpanded] = useState(false);

  useEffect(() => {
    setIsExpanded(initialExpand);
  }, [initialExpand]);

  return (
    <div className={css(styles.fieldWrapper)}>
      <span className={css(styles.fieldArrayLabels)}>
        {label}
      </span>
      <div className={css(styles.collapsableContainer, isExpanded ? styles.active : null)}>
        <div className={css(styles.collapsableTitleContainer)}>
          <Button type={"button"}
                  btnClass={"no-padding"}
                  size={ButtonSize.Medium}
                  color={ButtonColor.Ghost}
                  showLabel={false}
                  label={null}
                  onClick={() => {
                    setIsExpanded(!isExpanded);
                  }}>
            <ChevronDownIcon
              viewBox='0 0 40 40'
              color={SvgVariant.Secondary}
            />
          </Button>
          <TitleBar fields={fields} titleBar={titleBar} isExpanded={isExpanded}/>
          {showAdditionalButton && !readonly && (
            <Button type={"button"}
                    btnClass={"no-padding"}
                    size={ButtonSize.Small}
                    color={ButtonColor.Ghost}
                    showLabel={false}
                    label={null}
                    style={{ gridColumn: 3 }}
                    onClick={() => {
                      additionalClickHandler?.();
                    }}>
              <CloseIcon
                color={SvgVariant.Secondary}
                viewBox={"0 0 40 40"}
                titleAccess='Datensatz verschieben'
              />
            </Button>
          )}
        </div>
        <div className={css(styles.collapsableContentContainer, isExpanded && styles.show)}>
          {fields?.map((field, index) => {
            const key = ('name' in field && field?.name) ?? index;
            if (fullWidthTemplateType.includes(field.templateType) || ('fullWidth' in field && field?.fullWidth)) {
              return (
                <div key={key}
                     className={css(styles.fullWidthItem, 'attachedTop' in field && field?.attachedTop && styles.fullWidthItemAttachedTop)}>
                  {
                    FormHelper.renderFieldByTemplateType(
                      field,
                      {
                        changeHandler,
                        parentRelationId: sourceId,
                        triggerDataRefetch
                      }
                    )
                  }
                </div>
              );
            }

            return FormHelper.renderFieldByTemplateType(field, { key, parentRelationId: sourceId, changeHandler, triggerDataRefetch });
          })}
        </div>
      </div>
    </div>
  );
};

const styles = StyleSheet.create({
  fieldWrapper: {
    display: "grid",
    alignItems: "flex-start",
    gridTemplateColumns: "var(--form-label) 1fr",
    gridGap: "var(--size-20)",
    marginBottom: "var(--size-10)"
  },
  fieldArrayLabels: {
    textAlign: "right",
    padding: "var(--size-10) 0"
  },
  collapsableContainer: {
    border: "var(--base-border) solid var(--ice-primary)",
    borderRadius: "var(--base-radius)",
    width: "100%"
  },
  active: {
    border: "var(--base-border) solid var(--secondary)"
  },
  collapsableContentContainer: {
    padding: "var(--size-40)",
    display: "none",
    gridTemplateColumns: "repeat(2, 1fr)",
    alignItems: "flex-start",
    gridGap: "0 / var(--size-10)"
  },
  show: {
    display: "grid"
  },
  additionalButtonPosition: {
    gridColumn: 3
  },
  collapsableTitleContainer: {
    display: "grid",
    gridTemplateColumns: "var(--size-40) 1fr var(--size-30)",
    alignItems: "center",
    gridGap: 10,
    padding: 2
  },
  contentField: {
    display: "grid",
    alignItems: "center",
    gridTemplateColumns: "var(--form-label) 1fr",
    gridGap: "var(--size-10)"
  },
  fullWidthItem: {
    gridColumn: "1 / span 2",
    marginTop: "var(--size-60)"
  },
  fullWidthItemAttachedTop: {
    gridColumn: "1 / span 2",
    marginTop: 0
  }
});
