import { IState } from "../../interfaces/state.interface";
import { List } from "immutable";
import { LoadingStatus } from "../../constants/loading-status.enum";
import { SortOrder } from "../../constants/sort-order.enum";
import { TenancyModel } from "../../models/tenancy.model";
import { TenanciesMetaDataModel } from "../../models/tenancies-meta-data.model";
import { ActiveFilterModel } from "../../models/active-filter.model";
import { createSelector } from "../../utils/reducer";
import { TenanciesFilterKey } from "./tenancies-filter-key.enum";

const baseTenanciesSelector = ["tenancies"];

export const selectData = (state: IState): List<TenancyModel> => state.getIn([...baseTenanciesSelector, "data"]);

export const selectMetaData = (state: IState): TenanciesMetaDataModel => state.getIn([...baseTenanciesSelector, "meta"]);

export const selectIsLoading = (state: IState): LoadingStatus => state.getIn([...baseTenanciesSelector, "loadingStatus"]);

export const selectOffset = (state: IState): number => state.getIn([...baseTenanciesSelector, "offset"]);

export const selectSortOrder = (state: IState): SortOrder => state.getIn([...baseTenanciesSelector, "sortOrder"]);

export const selectSortByColumn = (state: IState): string => state.getIn([...baseTenanciesSelector, "sortByColumn"]);

export const selectActiveFilter = (state: IState): List<ActiveFilterModel> => state.getIn([...baseTenanciesSelector, "activeFilter"]);

export const selectScrollTop = (state: IState): number => state.getIn([...baseTenanciesSelector, 'scrollTop']);

export const selectAffiliateNameActiveFilter = createSelector(
  [selectActiveFilter],
  (activeFilter) => activeFilter.find((filter) => filter.groupKey === TenanciesFilterKey.AFFILIATE_NAME)
);
export const selectStreetActiveFilter = createSelector(
  [selectActiveFilter],
  (activeFilter) => activeFilter.find((filter) => filter.groupKey === TenanciesFilterKey.STREET)
);

export const selectPostalCodeActiveFilter = createSelector(
  [selectActiveFilter],
  (activeFilter) => {
    return activeFilter.find((filter) => filter.groupKey === TenanciesFilterKey.POSTAL_CODE);
  }
);

export const selectLocationActiveFilter = createSelector(
  [selectActiveFilter],
  (activeFilter) => {
    return activeFilter.find((filter) => filter.groupKey === TenanciesFilterKey.LOCATION);
  }
);
export const selectCountryActiveFilter = createSelector(
  [selectActiveFilter],
  (activeFilter) => {
    return activeFilter.find((filter) => filter.groupKey === TenanciesFilterKey.COUNTRY);
  }
);

export const selectRegionActiveFilter = createSelector(
  [selectActiveFilter],
  (activeFilter) => {
    return activeFilter.find((filter) => filter.groupKey === TenanciesFilterKey.REGION);
  }
);

export const selectRentalStartActiveFilter = createSelector(
  [selectActiveFilter],
  (activeFilter) => {
    return activeFilter.find((filter) => filter.groupKey === TenanciesFilterKey.RENTAL_START);
  }
);

export const selectTotalAreaActiveFilter = createSelector(
  [selectActiveFilter],
  (activeFilter) => {
    return activeFilter.find((filter) => filter.groupKey === TenanciesFilterKey.TOTAL_AREA);
  }
);

export const selectRentalEndActiveFilter = createSelector(
  [selectActiveFilter],
  (activeFilter) => {
    return activeFilter.find((filter) => filter.groupKey === TenanciesFilterKey.RENTAL_END);
  }
);

export const selectOfficeAreaActiveFilter = createSelector(
  [selectActiveFilter],
  (activeFilter) => {
    return activeFilter.find((filter) => filter.groupKey === TenanciesFilterKey.OFFICE_AREA);
  }
);
export const selectRentPriceActiveFilter = createSelector(
  [selectActiveFilter],
  (activeFilter) => {
    return activeFilter.find((filter) => filter.groupKey === TenanciesFilterKey.RENT_PRICE);
  }
);

