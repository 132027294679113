import { GroupSize } from '../../enums/group-size.enum';
import { IFormFieldset } from '../../interfaces/form-fieldset.interface';
import { AffiliateInputDefinition } from './affiliate-input-definition.const';
import { Language } from "../../../../shared/constants/language.enum";

export const AffiliateFormDefinition: IFormFieldset[] = [
  {
    name: 'basics',
    customCss: 'highlight',
    groups: [
      {
        title: 'Basics',
        size: GroupSize.Half,
        fields: [
          AffiliateInputDefinition.AffiliateName,
          AffiliateInputDefinition.AffilaiteId,
          AffiliateInputDefinition.AffiliateAddress,
          AffiliateInputDefinition.getAffiliateRegion(Language.GERMAN),
          AffiliateInputDefinition.getAffiliateRegion(Language.ENGLISH),
          AffiliateInputDefinition.getAffiliateCountry(Language.GERMAN),
          AffiliateInputDefinition.getAffiliateCountry(Language.ENGLISH),
        ]
      },

      {
        title: 'Vertretungsberechtigter',
        size: GroupSize.Half,
        fields: [
          AffiliateInputDefinition.AffiliateAuthorizedRepresentiveNamePhone,
          AffiliateInputDefinition.AffiliateAuthorizedRepresentiveEmailWebsite
        ]
      }
    ],
  }
];
