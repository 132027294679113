import React from 'react'
import { AdminTab } from '../../../shared/constants/admin-tab.enum';
import { UserListViewWithStore } from './user-list.view';
import { IntroTextsViewWithStore } from './intro-texts/intro-texts.view';
import { PersonGroupsViewWithStore } from './person-groups/person-groups.view';
import { RegionsViewWithStore } from './regions/regions.view';
import {DevToolsView} from "./dev-tools/devToolsView";


interface IAdminTabViewsProps {
  activeTab: string
}

export const AdminTabViews = React.memo((props: IAdminTabViewsProps) => {
  const {
      activeTab
    } = props;

    const renderTab = (activeTab) => {
      switch (activeTab) {
        case AdminTab.User: {
          return <UserListViewWithStore />;
        }
        case AdminTab.IntroTexts: {
          return <IntroTextsViewWithStore />;
        }
        case AdminTab.Regions: {
          return <RegionsViewWithStore />;
        }
        case AdminTab.PersonGroups: {
          return <PersonGroupsViewWithStore />;
        }
        case AdminTab.DevTools: {
          return <DevToolsView />;
        }
        default: return (<h2>No Content for: {activeTab}</h2>);
      }
    };

  return renderTab(activeTab);
});
