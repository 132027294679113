import { PropertiesActionTypes } from './properties.action-types';
import { IAction } from '../../interfaces/action.interface';
import { IFilterParam } from '../../interfaces/filter-param.interface';
import { PropertyFilterKey } from '../../constants/property-filter-key.enum';

export const setPropertiesFilterAction = (filter: IFilterParam): IAction => {
  return {
    type: PropertiesActionTypes.SET_FILTER,
    payload: filter
  };
};

export const setOfficeAreaTypeFilterAction = () => {
  return {
    type: PropertiesActionTypes.SET_OFFICE_AREA_TYPE_FILTER,
    payload: {
      groupKey: PropertyFilterKey.AREA_TYPE,
      groupLabel: 'Objektarten',
      list: [
        {
          value: 'office',
          label: 'Büro'
        }
      ]
    }
  };
}

export const resetOfficeAreaTypeFilterAction = () => {
  return {
    type: PropertiesActionTypes.RESET_OFFICE_AREA_TYPE_FILTER
  };
}

export const resetOfficeAreaFilterAction = () => {
  return {
    type: PropertiesActionTypes.RESET_OFFICE_AREA_FILTER
  };
}

export const resetPropertiesFilterAction = (groupKey: string): IAction => {
  return {
    type: PropertiesActionTypes.RESET_FILTER,
    payload: groupKey
  }
};

export const resetAllPropertiesFilterAction = (): IAction => {
  return {
    type: PropertiesActionTypes.RESET_ALL_FILTER
  }
};

export const setScrollTopAction = (yPosition: number): IAction => {
  return {
    type: PropertiesActionTypes.SET_SCROLL_TOP,
    payload: yPosition
  }
};

