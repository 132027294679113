import React, { useEffect } from 'react';
import { fromEvent } from 'rxjs';
import { debounceTime } from 'rxjs/operators';

/**
 * Custom Hook to get the current position of the given ref in window
 * inspired by @MarcoTraspel
 * @param ref
 * @param windowSections
 * @param callback
 */
export function useEndOfViewport(ref: React.MutableRefObject<HTMLElement>, windowSections: number, callback: (...props) => void) {

  useEffect(() => {
    const detectPosition = () => {
      const refBounds = ref.current?.getBoundingClientRect();
      const refWidth = ref.current?.clientWidth;
      const xAxis = refBounds.x;

      const windowWidth = window.innerWidth;
      const parts = windowWidth / windowSections;

      const isEndOfViewport = parts * (windowSections - 1) <= refWidth + xAxis;

      callback?.(isEndOfViewport);
    };

    detectPosition();

    const windowSub = fromEvent(window, 'resize')
      .pipe(
        debounceTime(300)
      ).subscribe(() => detectPosition());

    return () => {
      windowSub.unsubscribe();
    };
  })

}
