import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { selectPropertyId } from "../../../../../shared/redux/property/property.selectors";
import { IState } from "../../../../../shared/interfaces/state.interface";
import { Fieldset } from "../../../../fieldset/fieldset";
import { css, StyleSheet } from "aphrodite/no-important";
import { PropertyTextsDataService } from "../../../../../shared/services/property-texts-data.service";
import { PropertyTextsActions } from "../../../../../shared/redux/property/property-texts/property-texts.actions";
import {
  selectPropertyIntroTexts,
  selectShowIntroTextInWatchlist
} from "../../../../../shared/redux/property/property-texts/property-texts.selector";
import { PropertyIntroTextModel } from "../../../../../shared/models/property-intro-text.model";
import { List } from "immutable";
import { ToggleSlider } from "../../../../toggle-slider/toggle-slider";
import { IntroTextItemWithStore } from "./intro-text-item";
import { PropertyActions } from "../../../../../shared/redux/property/property.actions";
import { useUser } from "../../../../../contexts/user.context";

interface IIntroTextsListProps {
  propertyId: number;
  introTexts: List<PropertyIntroTextModel>,
  showIntroTextInWatchlist: boolean;
  fetchPropertyIntroTexts: (propertyId: number) => void;
  updateShowIntroTextInWatchlist: (propertyId: number, value: boolean) => void;
}

export const IntroTextsList = (props: IIntroTextsListProps) => {
  const {
    propertyId,
    introTexts,
    fetchPropertyIntroTexts,
    showIntroTextInWatchlist,
    updateShowIntroTextInWatchlist
  } = props;

  const { getGroups } = useUser();

  const [writePermissions, setWritePermissions] = useState(false);

  useEffect(() => {
    if (getGroups()?.includes("admin") || getGroups()?.includes("editor")) {
      setWritePermissions(true);
    }
  }, [getGroups]);

  useEffect(() => {
    if (!propertyId) return;

    fetchPropertyIntroTexts(propertyId);
  }, [fetchPropertyIntroTexts, propertyId]);

  return (
    <>
      <Fieldset>
        <Fieldset.Legend withSeparator={false}>
          <div className={css(styles.legendTitle)}>Introtexte ({introTexts?.size})</div>
          <ToggleSlider
            value={!!showIntroTextInWatchlist ? showIntroTextInWatchlist : false}
            onChange={(value: boolean) => updateShowIntroTextInWatchlist(propertyId, value)}
            readonly={!writePermissions}
          />
        </Fieldset.Legend>
        <Fieldset.Content>
          <div className={css(styles.introTextsList)}>
            {introTexts?.map((introText, index) => {
              const key = `${introText.introTextId}-${index}`;
              return <IntroTextItemWithStore
                key={key}
                index={key}
                propertyId={propertyId}
                introText={introText}/>;
            })}
          </div>
        </Fieldset.Content>
      </Fieldset>
    </>
  );
};

const styles = StyleSheet.create({
  legendTitle: {
    fontSize: "var(--font-size-title)",
    fontWeight: "bold"
  },
  introTextsList: {
    display: "grid",
    gridTemplateColumns: "1fr",
    gridGap: 10
  }
});

const mapStateToProps = (state: IState) => {
  return {
    propertyId: selectPropertyId(state),
    introTexts: selectPropertyIntroTexts(state),
    showIntroTextInWatchlist: selectShowIntroTextInWatchlist(state)
  };
};

const mapDispatchToProps = (dispatch: any) => ({
  fetchPropertyIntroTexts: async (propertyId: number) => {
    const res = await PropertyTextsDataService.fetchPropertyIntroTexts(propertyId);
    dispatch(PropertyTextsActions.setIntroTexts(res?.data));
  },
  updateShowIntroTextInWatchlist: async (propertyId: number, value: boolean) => {
    const res = await PropertyTextsDataService.updateShowIntroTextInWatchlist(propertyId, value);
    dispatch(PropertyTextsActions.toggleShowIntroTextInWatchlist(value));
    dispatch(PropertyActions.touch(res?.data?.updatedByTitle))
  }
});

export const IntroTextsListWithStore = connect(mapStateToProps, mapDispatchToProps)(IntroTextsList);
