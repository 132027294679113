import { AddressAutofill } from '../enums/autofill-address.enum';
import { InputType } from '../enums/input-type.enum';
import { TemplateType } from '../enums/template-type.enum';
import { IFormFieldset } from '../interfaces/form-fieldset.interface';
import { PropertyInputDefinitions } from './property-input-definitions.const';
import { GroupSize } from '../enums/group-size.enum';
import { Language } from "../../../shared/constants/language.enum";

export const CreateAffiliateFormDefinition: IFormFieldset[] = [
  {
    name: 'basics',
    groups: [
      {
        title: 'Basics',
        size: GroupSize.Full,
        fields: [
          {
            templateType: TemplateType.FIELD_SINGLE,
            inputType: InputType.TEXT,
            name: 'affiliateName',
            label: 'Name *',
            value: '',
          },
          {
            ...PropertyInputDefinitions.MapboxAddress,
            searchInEveryCountry: true
          },
          {
            templateType: TemplateType.FIELD_SINGLE,
            inputType: InputType.TEXT,
            name: AddressAutofill.federalState,
            label: 'Bundesland',
            placeholderText: 'Wird durch Postleitzahl befüllt',
            isAutofill: true,
            value: '',
          },
          {
            templateType: TemplateType.FIELD_SINGLE,
            inputType: InputType.TEXT,
            name: AddressAutofill.region,
            label: 'Region',
            placeholderText: 'Wird durch Regionenraster befüllt',
            isAutofill: true,
            value: '',
          },
          {
            templateType: TemplateType.FIELD_SINGLE,
            inputType: InputType.TEXT,
            name: AddressAutofill.country_de,
            i18n: Language.GERMAN,
            label: 'Land',
            placeholderText: 'Wird durch Postleitzahl befüllt',
            isAutofill: true,
            value: '',
          },
          {
            templateType: TemplateType.FIELD_SINGLE,
            inputType: InputType.TEXT,
            name: AddressAutofill.country_en,
            i18n: Language.ENGLISH,
            label: 'Land',
            placeholderText: 'Wird durch Postleitzahl befüllt',
            isAutofill: true,
            value: '',
          },
        ],
      },
    ],
  },
  {
    name: 'address',
    groups: [
      {
        title: 'Vertretungsberechtigter',
        size: GroupSize.Full,
        fields: [
            {
                templateType: TemplateType.FIELD_SINGLE,
                inputType: InputType.TEXT,
                name: 'authorizedRepresentiveName',
                label: 'Name',
                value: '',
            },
            {
                templateType: TemplateType.FIELD_SINGLE,
                inputType: InputType.TEL,
                name: 'authorizedRepresentivePhone',
                label: 'Telefon Nr.',
                value: '',
            },
            {
                templateType: TemplateType.FIELD_SINGLE,
                inputType: InputType.EMAIL,
                name: 'authorizedRepresentiveEmail',
                label: 'E-Mail',
                value: '',
            },
            {
                templateType: TemplateType.FIELD_SINGLE,
                inputType: InputType.TEXT,
                name: 'authorizedRepresentiveWebsite',
                label: 'Website',
                value: '',
            },
        ],
      },
    ],
  },
];
