import { ISelectable, ISelectableObject } from "../interfaces/selectable.interface";
import { Record } from "immutable";

export interface IAffiliateParams {
  id: number;
  affiliateName: string;
  address: string;
  street: string;
  streetNumber: string;
  postalCode: string;
  location: string;
  region_de: string;
  region_en: string;
  country_de: string,
  country_en: string,
  country_alpha2Code: string,
  federalState: string;
  authorizedRepresentiveName: string;
  authorizedRepresentivePhone: string;
  authorizedRepresentiveEmail: string;
  authorizedRepresentiveWebsite: string;
  tenancies: [];
  updatedAt: string;
  createdAt: string;
  deletedAt: string;
  updatedByTitle: string;
}

export class AffiliateModel extends Record<IAffiliateParams>({
  id: undefined,
  affiliateName: undefined,
  address: undefined,
  street: undefined,
  streetNumber: undefined,
  postalCode: undefined,
  location: undefined,
  region_de: undefined,
  region_en: undefined,
  country_de: undefined,
  country_en: undefined,
  country_alpha2Code: undefined,
  federalState: undefined,
  authorizedRepresentiveName: undefined,
  authorizedRepresentivePhone: undefined,
  authorizedRepresentiveEmail: undefined,
  authorizedRepresentiveWebsite: undefined,
  tenancies: undefined,
  updatedAt: undefined,
  createdAt: undefined,
  deletedAt: undefined,
  updatedByTitle: undefined,
}) implements ISelectable {
  constructor(params?: IAffiliateParams) {
    params ? super(params) : super();
  }

  with(values: IAffiliateParams): this {
    return this.merge(values) as this;
  }

  get fullAddress() {
    let fullAddress = '';

    if (!!this.street && !!this.streetNumber) {
      fullAddress += `${this.street} ${this.streetNumber}`;
    }

    if (!!this.postalCode) {
      fullAddress += `, ${this.postalCode}`;
    }

    if (!!this.location) {
      fullAddress += ` ${this.location}`;
    }

    return fullAddress;
  }

  getNameNice() {
    return `${this.affiliateName} (${this.id})`;
  }

  get selectableObject() {
    return AffiliateModel.getSelectableObject({...this});
  }

  static getSelectableObject(affiliateObject: Partial<{id: number, affiliateName: string}>): ISelectableObject {
    if(!affiliateObject?.id || !affiliateObject.affiliateName) {
      return null;
    }

    const {
      id,
      affiliateName
    } = affiliateObject;



    return {id: id, label: `${affiliateName} (${id})`}
  };

}
