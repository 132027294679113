import React from "react";
import { IFormFieldsetGroup } from "./interfaces/form-fieldset.interface";
import { FormikValues } from "formik";
import { css, StyleSheet } from "aphrodite/no-important";
import Col from "react-bootstrap/Col";
import { FieldTypeUnion } from "./types/field-type.union";
import { FormHelper } from "./helpers/form.helper";

interface IFormSetGroupsProps {
  groups: IFormFieldsetGroup;
  formValues: FormikValues;
  changeHandler
  refetchSourceData
}

export const FormFieldSetGroups = React.memo((props: IFormSetGroupsProps) => {
  const {
    groups,
    formValues,
    changeHandler,
    refetchSourceData
  } = props;

  const fieldsetTitleClassName = css([styles.fieldsetTitle, groups?.hideTitle && styles.hideFieldsetTitle]);

  const renderGroup = () => { return groups.fields.map((field: FieldTypeUnion, index: number) => {
    const fieldElement: JSX.Element = FormHelper.renderFieldByTemplateType(
      field,
      {
        changeHandler,
        triggerDataRefetch: refetchSourceData,
        formValues
      }
    );

    return (
      <Col key={(!!field && 'name' in field && field?.name) || `${groups.title}-${index}`} xs={12}>
        {fieldElement}
      </Col>
    );
  })};

  return <>
    <Col xs={12}>
      <div className={css(styles.fieldsetWrapper)}>
        {!!groups.title && <p className={fieldsetTitleClassName}>{groups.title}</p>}
      </div>
    </Col>
    {renderGroup()}
  </>
});

const styles = StyleSheet.create({
  fieldsetWrapper: {
    display: "grid",
    gridGap: "var(--size-20)",
    gridTemplateColumns: "var(--form-label) 1fr"
  },
  fieldsetTitle: {
    gridColumn: 2,
    fontSize: "var(--font-size-title)",
    fontWeight: "bold",
    marginBottom: "var(--size-15)"
  },
  hideFieldsetTitle: {
    visibility: "hidden"
  }
});
