import { IState } from '../../interfaces/state.interface';
import { fromJS, List } from 'immutable';
import { IReducer } from '../../interfaces/reducer.interface';
import { IAction } from '../../interfaces/action.interface';
import { PropertyMediaImageActionTypes } from './property-media-image.action-types';
import { mergeState } from '../../utils/reducer';
import { MediaElementModel } from '../../models/media-element.model';
import { getLatestUpdatedEntry } from '../../helpers/lastest-updated-at.helper';
import { IPropertyMedia } from '../../interfaces/property-media.interface';
import { PropertyMediaStoreHelper } from './property-media-image-store.helper';

export enum PropertyMediaStatus {
  Initial = 'initial',
  Completed = 'completed',
}

const jsonToMediaElementModels = (images: IPropertyMedia[]): MediaElementModel[] => {
  return images?.map(data => new MediaElementModel(data));
}

const initialState: IState = fromJS({
  images: List<MediaElementModel>(),
  updatedAt: null,
  status: PropertyMediaStatus.Initial,
  highlightId: null,
});

export const propertyMediaImageReducer: IReducer = (state = initialState, action: IAction) => {
  switch (action.type) {
    case PropertyMediaImageActionTypes.SET_MEDIA_IMAGE_LIST:
      const latestUpdatedImage = getLatestUpdatedEntry(action.payload?.slice()); // clean copy via slice, as sort modifies the original array
      return mergeState(state, {
        updatedAt: latestUpdatedImage?.updatedAt ?? null,
        images: List<MediaElementModel>(jsonToMediaElementModels(action.payload)),
        status: PropertyMediaStatus.Completed,
      });

    case PropertyMediaImageActionTypes.SAVE_MEDIA_IMAGES:
      const savedImages = List<MediaElementModel>([...jsonToMediaElementModels(action.payload), ...state.get('images')]);
      return mergeState(state, {
        updatedAt: new Date(),
        images: PropertyMediaStoreHelper.recalculatePositions(savedImages),
        status: PropertyMediaStatus.Completed,
      });

    case PropertyMediaImageActionTypes.UPDATE_IMAGE: {
      const updatedIndex = (state.get('images') as List<MediaElementModel>).findIndex(element => element.id === action.payload?.id);
      const updatedImages = state.get('images').update(updatedIndex, () => action.payload);
      return mergeState(state, {
        updatedAt: new Date(),
        status: PropertyMediaStatus.Completed,
        images: updatedImages
      });
    }
    case PropertyMediaImageActionTypes.DELETE_IMAGE: {
      const imageList = state.get('images') as List<MediaElementModel>;
      const deleteIndex = imageList.findIndex(element => element.id === action.payload);
      const updatedImages = state.get('images').delete(deleteIndex);
      return mergeState(state, {
        updatedAt: new Date(),
        images: PropertyMediaStoreHelper.recalculatePositions(updatedImages),
        status: PropertyMediaStatus.Completed,
      });
    }
    case PropertyMediaImageActionTypes.UPDATE_POSITION:
      return mergeState(state, {
        updatedAt: new Date(),
        images: PropertyMediaStoreHelper.updatePosition(action.payload, state.get('images') as List<MediaElementModel>),
        status: PropertyMediaStatus.Completed,
        highlightId: action.payload.mediaId
      });

    case PropertyMediaImageActionTypes.CLEAR_HIGHLIGHTED:
      return mergeState(state, { highlightId: null });

    default:
      return state;
  }
}
