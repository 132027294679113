export enum PropertyFilterKey {
  TOTAL_AREA = 'totalArea',
  OFFICE_AREA = 'officeArea',
  CONSTRUCTION_YEAR = 'constructionYear',
  AVERAGE_RENTAL_CHARGE = 'averageRentalCharge',
  PURCHASE_PRICE = 'purchasePrice',
  PURCHASE_DATE = 'purchaseDate',
  VACANCY_RATE = 'vacancyRate',
  AREA_TYPE = 'areaType',
  POSTAL_CODE = 'postalCode',
  PROPERTY_ID = 'propertyId',
  TITLE = 'title',
  STREET = 'street',
  LOCATION = 'location',
  REGION = 'region',
  TENANT_TYPE = 'tenantType',
  CONDITION = 'condition',
  STATUS = 'status',
  HAS_IMAGES = 'hasImages',
  OWNER = 'owner',
  TENANT = 'tenant',
  AGENT = 'agent',
}
