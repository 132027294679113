import { SearchActionTypes } from './search.action-types';
import { KeyValuePairs } from '../../../../shared/types/key-value-pairs.type';

export interface ISearchInputState {
  value: string;
  valueObject: object;
  suggestions: KeyValuePairs<string | number>[];
  isLoading: boolean;
  hasSelected: boolean;
}

export function searchReducer(state: ISearchInputState, action: any) {
  switch (action.type) {
    case SearchActionTypes.SET_VALUE:
      return {
        ...state,
        value: action.value,
        valueObject: action.valueObject,
        isLoading: action.isLoading,
        hasSelected: action.hasSelected
      }
    case SearchActionTypes.SET_SUGGESTIONS:
      return {
        ...state,
        suggestions: action.suggestions,
        isLoading: false
      }
    case SearchActionTypes.RESET:
      return {
        ...state,
        value: '',
        valueObject: null,
        suggestions: [],
        hasSelected: false,
        isLoading: false
      }
    default:
      return state;
  }
}
