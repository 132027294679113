import React, { useEffect, useState } from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import { connect } from 'react-redux';
import { IWithRouterProps } from '../../../../shared/interfaces/with-router-props.interface';
import { AffiliateModel, IAffiliateParams } from '../../../../shared/models/affiliate.model';
import { withRouter } from 'react-router-dom';
import { performGetRequest, performPutRequest } from '../../../../shared/redux/middleware/api.middleware';
import { ApiEndpoint } from '../../../../shared/constants/api-endpoint.enum';
import { AffiliateActionTypes } from '../../../../shared/redux/affiliate/affiliate.action-types';
import { IState } from '../../../../shared/interfaces/state.interface';
import { selectAffiliateWithAddress } from '../../../../shared/redux/affiliate/affiliate.selectors';
import { FormService } from '../../../../shared/services/form.service';
import { AffiliateFormDefinition } from '../../../form/definitions/affiliate/affiliate.form-definition';
import { Form } from '../../../form/form';
import { AffiliateValidationSchema } from '../../../form/validation-schema/affiliate.validation-schema';
import { IFieldChangeEvent } from '../../../form/interfaces/field-single.interface';
import { AddressAutofill } from '../../../form/enums/autofill-address.enum';
import { CountryService } from "../../../../shared/services/country.service";
import { useCurrentLanguage } from "../../../../contexts/current-language.context";
import { useUser } from "../../../../contexts/user.context";
import {MapboxglGeocodingService} from "../../../../shared/services/mapboxglGeocodingService";

const countryService = CountryService.getInstance();

interface IAffiliateDetailInfoViewProps extends IWithRouterProps<{ affiliateId: number }> {
  affiliate: AffiliateModel;
  fetchAffiliate: (affiliateId: number) => Promise<AffiliateModel>;
  updateAffiliate: (affiliate: Partial<IAffiliateParams>) => Promise<AffiliateModel>;
}

export const AffiliateDetailInfoView = React.memo(withRouter((props: IAffiliateDetailInfoViewProps) => {
  const {
    affiliate,
    updateAffiliate,
    match
  } = props;

  const [affiliateId, setAffiliateId] = useState<number>(null);
  const [formDefinition, setFormDefinition] = useState(null);

  const currentLanguage = useCurrentLanguage();

  const { getGroups } = useUser();

  const [writePermissions, setWritePermissions] = useState(false)

  useEffect(() => {
    if(getGroups()?.includes('admin') || getGroups()?.includes('editor')) {
      setWritePermissions(true);
    }
  }, [getGroups]);

  useEffect(() => {
    setAffiliateId(match.params.affiliateId);
  }, [match]);

  useEffect(() => {
    if (!affiliate.id) {
      return;
    }

    const formService = new FormService();
    const updatedFormDefinition = formService.updateFormSets(AffiliateFormDefinition, affiliate.toJS(), !writePermissions ? { readonly: true } : {});

    setFormDefinition(updatedFormDefinition);
  }, [affiliate, writePermissions]);

  const handleInputChange = async (changeEvent: IFieldChangeEvent) => {
    if(!writePermissions) {
      return;
    }

    let changeValue = changeEvent.value;
    const distinctValue = affiliate?.[changeEvent.name];

    let updateData = {
      [changeEvent.name]: changeValue
    };

    if (changeEvent.name === AddressAutofill.address) {
      const places = await MapboxglGeocodingService.getInstance().findPublityPlacesForQuery(changeValue, null, ['address'], currentLanguage, true);
      const place = places?.[0];

      if(!place) {
        return;
      }

      const countryCode = countryService.getAlpha2Code(place.country, currentLanguage);
      const countryDe = countryService.getName(countryCode, "de");
      const countryEn = countryService.getName(countryCode, "en");

      changeValue = place.address;
      updateData = {
        ...place,
        country_de: countryDe,
        country_en: countryEn,
        country_alpha2Code: countryCode
      };
    }

    if (FormService.hasValueChanged(changeValue, distinctValue)) {
      updateAffiliate({
        ...updateData,
        id: affiliateId,
      });
    }
  };

  return (
    <Container fluid>
      <Row>
        <Form showButtonControls={false}
          refetchSourceData={() => {}} //TODO why?
          validationSchema={AffiliateValidationSchema}
          changeHandler={handleInputChange}
          formDefinition={formDefinition}/>
      </Row>
    </Container>
  );
}));

const mapStateToProps = (state: IState) => {
  return {
    affiliate: selectAffiliateWithAddress(state)
  }
};

const mapDispatchToProps = (dispatch: any) => ({
  fetchAffiliate: (affiliateId: number) => dispatch(
    performGetRequest(
      `${ApiEndpoint.AFFILIATE}/${affiliateId}`,
      AffiliateActionTypes.FETCH_IN_PROGRESS,
      AffiliateActionTypes.SET_AFFILIATE,
      AffiliateActionTypes.FETCH_FINISHED
    )
  ),
  updateAffiliate: (data: Partial<IAffiliateParams>) => dispatch(
    performPutRequest(
      ApiEndpoint.AFFILIATE,
      data,
      AffiliateActionTypes.FETCH_IN_PROGRESS,
      AffiliateActionTypes.SET_AFFILIATE,
      AffiliateActionTypes.FETCH_FINISHED
    )
  )
});

export const AffiliateDetailInfoViewWithStore = connect(mapStateToProps, mapDispatchToProps)(AffiliateDetailInfoView);
