import React, { useRef } from 'react'
import { css, StyleSheet } from 'aphrodite';
import { CssSize } from '../../shared/helpers/styles.helper';

interface IIntroTextTemplateProps {
  title: string;
  text: string;
  name?: string;
  onChange: (event) => void;
  onBlur?: () => void;
}

const INITIAL_ROWS = 3;

export const IntroTextTemplate = React.memo((props: IIntroTextTemplateProps) => {
  const {
    title,
    text,
    name = title,
    onChange,
    onBlur,
  } = props;

  const previousValue = useRef(text);

  const distinctOnBlur = () => {
    if (text !== previousValue.current) {
      onBlur();
      previousValue.current = text;
    }
  }

  return (
    <div className={css(styles.container)}>
      <label htmlFor={title} className={css(styles.label)}>
        {title}
      </label>

      <textarea
        className={css(styles.textarea)}
        name={name}
        id={title}
        value={text}
        onChange={onChange}
        onBlur={distinctOnBlur}
        rows={INITIAL_ROWS}
      ></textarea>

    </div>
  );
});

const sharedStyles = {
  backgroundColor: 'var(--ice-primary)',
  padding: CssSize.s10,
};

const styles = StyleSheet.create({
  container: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'stretch'
  },
  label: {
    width: '100%',
    borderRadius: `${CssSize.s05} ${CssSize.s05} 0 0`,
    marginBottom: 2,
    ...sharedStyles
  },
  textarea: {
    width: '100%',
    borderRadius: `0 0 ${CssSize.s05} ${CssSize.s05}`,
    ...sharedStyles,
  }
});
