import { HttpService } from "./http.service";
import { ApiEndpoint } from "../constants/api-endpoint.enum";
import { IFieldSearchSuggestion } from "../../components/form/interfaces/field-single.interface";
import { ISelectableObject } from "../interfaces/selectable.interface";

export class AffiliateDataService {

  static async addData(
    routeParam: string | number,
    relationPath: string,
    data = null): Promise<void> {
    return HttpService.performPostRequest(`${ApiEndpoint.AFFILIATE}/${routeParam}/${relationPath}`, data);
  }

  static async updateData(
    routeParam: string | number,
    relationPath: string,
    identifier: string,
    data: any): Promise<void> {
    await HttpService.performPutRequest(`${ApiEndpoint.AFFILIATE}/${routeParam}/${relationPath}/${identifier}`, data);
  }

  static async deleteData(
    routeParam: string | number,
    relationPath: string,
    identifier: string,
    data: any): Promise<void> {
    await HttpService.performDeleteRequest(`${ApiEndpoint.AFFILIATE}/${routeParam}/${relationPath}/${identifier}`, data);
  }

  static async getIndexSignature(identifier: number): Promise<ISelectableObject> {
    return await HttpService.performGetRequest(`${ApiEndpoint.AFFILIATE}/${identifier}`).then((result) => ({
      id: result?.id,
      label: `${result?.affiliateName} (${result?.id})`
    }));
  }

  static async searchAffiliate(
    searchTerm: string
  ): Promise<IFieldSearchSuggestion[]> {
    const data = await HttpService.performGetRequest(`${ApiEndpoint.AFFILIATE}/suggestions?searchTerm=${searchTerm}`) ?? [];

    return data?.map(({ affiliateName, id }) => ({ id, label: affiliateName }));
  }
}
