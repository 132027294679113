import { KeyValuePairs } from '../../../../shared/types/key-value-pairs.type';
import { FieldTableActionTypes } from './field-table.action-types';

export interface IFieldTableState {
  tableData: KeyValuePairs[];
  previousTableData: KeyValuePairs[];
}

export function fieldTableReducer(state: IFieldTableState, action: KeyValuePairs) {
  switch (action.type) {
    case FieldTableActionTypes.SET_TABLE_DATA:
      return {
        ...state,
        tableData: action.tableData
      };
    case FieldTableActionTypes.SET_PREVIOUS_TABLE_DATA:
      return {
        ...state,
        previousTableData: action.previousTableData
      };
    default:
      return state;
  }
}
