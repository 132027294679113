import React, { ReactNode, useContext } from 'react';
import { WizardContext } from '../../contexts/wizard.context';

import './wizard-step.scss';

interface IWizardStepProps {
  step: number;
  children: ReactNode;
}

export const WizardStep = (props: IWizardStepProps) => {
  const {
    step,
    children
  } = props;
  const { currentStep } = useContext(WizardContext);

  return (
    <div className={`wizard-step ${step === currentStep ? 'is-active' : ''}`}>
      {children}
    </div>
  )
}
