import { combineReducers } from 'redux-immutable';
import { propertiesReducer } from './properties/properties.reducer'
import { propertyReducer } from './property/property.reducer';
import { adminTabsReducer } from './admin-tabs/admin-tabs.reducer';
import { userReducer } from './users/users.reducer';
import { introTextsReducer } from './intro-texts/intro-texts.reducer';
import { personGroupReducer } from './person-groups/person-groups.reducer';
import { regionsReducer } from './regions/regions.reducer';
import { propertyTextsReducer } from './property/property-texts/property-texts.reducer';
import { tenanciesReducer } from './tenancies/tenancies.reducer';
import { propertyMediaImageReducer } from './property-media-image/property-media-image.reducer';
import { propertyMediaVideoReducer } from './property-media-video/property-media-video.reducer';
import { affiliateReducer } from './affiliate/affiliate.reducer';
import { settingsReducer } from './settings/settings.reducer';

export const rootReducer = combineReducers({
  properties: propertiesReducer,
  property: propertyReducer,
  adminTabs: adminTabsReducer,
  users: userReducer,
  introTexts: introTextsReducer,
  personGroups: personGroupReducer,
  regions: regionsReducer,
  propertyTexts: propertyTextsReducer,
  tenancies: tenanciesReducer,
  propertyMediaImage: propertyMediaImageReducer,
  propertyMediaVideo: propertyMediaVideoReducer,
  affiliate: affiliateReducer,
  settings: settingsReducer,
});
