import React from "react";
import { FormHelper } from "../helpers/form.helper";

export const FieldTemplate = (props:any) => {
  const {
    fields,
    field,
    options
  } = props;

  const hideLabel = options?.hideLabel ?? false;

  const fieldOptions = (field) => ({ ...options, label: hideLabel ? "" : field.label })

  return <>
    {!!fields && fields?.map((field, index) => (FormHelper.renderFieldByTemplateType(field, {...fieldOptions(field), key: index})))}
    {!!field && FormHelper.renderFieldByTemplateType(field, fieldOptions(field))}
  </>
}
