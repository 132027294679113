import React, { useEffect, useRef, useState } from "react";
import { useField } from "formik";
import { DatePickerIcon } from "../../icons/collection/datepicker-icon";
import { SvgVariant } from "../../icons/enum/svg-variant.enum";
import { css, StyleSheet } from "aphrodite/no-important";
import { IFieldSingle } from "../interfaces/field-single.interface";
import DatePicker, { registerLocale } from "react-datepicker";
import { DatePickerType } from "../enums/datepicker-type.enum";
import { LogService } from "../../../shared/services/log.service";
import { isValid, parseISO } from "date-fns";
import { KeyCodeHelper } from "../../../shared/helpers/key-code.helper";
import de from "date-fns/locale/de";
import { FormattingHelper } from "../../../shared/services/formatting.helper";

import "./date-input.scss";

registerLocale("de", de);

interface IDateInputProps extends IFieldSingle {
  emitChangeHandler?: (value) => void;
  rawValue?: boolean;
}

export const DateInput = (props: IDateInputProps) => {
  const {
    emitChangeHandler,
    attributes,
    name,
    readonly,
    rawValue
  } = props;

  const [field, , { setValue: setFieldValue }] = useField(name);

  const [proxyValue, setProxyValue] = useState(null);

  useEffect(() => {
    if (rawValue === true && !!props.value && props.value !== "") {
      setProxyValue(props.value);
    } else if (!!field.value && field.value !== "") {
      setProxyValue(field.value);
    }

    return () => {
      setProxyValue(null);
    }
  }, [field.value, props.value, rawValue]);

  const dateRef = useRef(null);
  const [config, setConfig] = useState(null);

  const updateDateInputConfig = () => {
    switch (attributes?.datePickerType) {
      case DatePickerType.Year:
        setConfig({
          showYearPicker: true,
          dateFormat: "yyyy"
        });
        break;
      case DatePickerType.Month:
        setConfig({
          dateFormat: "MMMM, yyyy",
          showMonthYearPicker: true,
          showFullMonthYearPicker: true
        });
        break;
      case DatePickerType.Full:
        setConfig({
          dateFormat: "dd.MM.yyyy"
        });
        break;
      default:
        throw new Error("[DateInput] Invalid DatePickerType - cannot set specific DatePicker config.");
    }
  };
  useEffect(updateDateInputConfig, [attributes?.datePickerType]);

  const handleOnChange = (event: any) => {
    if (readonly) {
      return;
    }

    if (KeyCodeHelper.isEnter(event.keyCode)) {
      event?.target?.blur();
    }
  };

  const handleOnBlur = ({ target: { value } }) => {
    if (readonly) {
      return;
    }

    if (value === "") {
      setFieldValue('');
      setProxyValue(null);
      emitChangeHandler(null);
    } else {
      const formattedValue = FormattingHelper.formatDateInput(attributes?.datePickerType, value).trim();
      const newDate = parseISO(formattedValue);

      if (isValid(newDate)) {
        setCorrectedDate(newDate);

      } else {
        LogService.error(`[DateInput] Invalid date - ${newDate}.`);
      }
    }
  };

  const setCorrectedDate = (selectedDate: Date) => {
    const year = selectedDate.getFullYear();
    const month = selectedDate.getMonth();
    const date = selectedDate.getDate();
    const newDate = new Date(year, month, date, 12);

    setFieldValue(newDate.toISOString());
    setProxyValue(newDate.toISOString());
    emitChangeHandler(newDate.toISOString());
  };

  return (
    <div className={css(styles.inputWrapper)}>
      <DatePicker
        name={name}
        ref={dateRef}
        locale='de'
        selected={proxyValue ? new Date(proxyValue) : null}
        placeholderText={"Datum auswählen"}
        closeOnScroll={true}
        onSelect={setCorrectedDate}
        onChangeRaw={handleOnChange}
        onBlur={handleOnBlur}
        autocomplete={"off"}
        readonly={readonly}
        disabled={readonly}
        {...config} />

      <DatePickerIcon
        className={css(styles.svg)}
        viewBox='0 0 40 40'
        color={SvgVariant.Secondary}
        titleAccess='Datum'/>
    </div>
  );
};

const styles = StyleSheet.create({
  inputWrapper: {
    position: "relative"
  },
  svg: {
    position: "absolute",
    right: 0,
    top: 0,
    width: "var(--size-40)",
    height: "var(--size-40)",
    pointerEvents: "none"
  }
});
