import React from "react";
import { css, StyleSheet } from "aphrodite/no-important";
import { IFieldCompose } from "../interfaces/field-double.interface";
import { FieldTemplate } from "./field-template";

export const FieldCompose = (props: IFieldCompose) => {
  const {
    fields,
    headers,
    changeHandler,
    customCss,
    condensed
  } = props;

  const repeat = props.repeat === 'auto' ? fields.length : props.repeat;

  const dynStyles = StyleSheet.create({
    fieldWrapper: {
      display: "grid",
      alignItems: "center",
      gridTemplateColumns: repeat <= 1 ? "1fr" : "var(--form-label) 1fr",
      gridColumnGap: repeat <= 1 ? 0 : "var(--size-20)",
      gridRowGap: repeat <= 1 ? 0 : "var(--size-20)",
      marginBottom: condensed ? 0 : "var(--size-10)"
    },
    fieldArrayWrapper: {
      display: "grid",
      gridTemplateColumns: "repeat("+ repeat +", 1fr)",
      gridGap: "var(--size-10)"
    }
  });


  const getFieldArrayLabels = () => {
    if(repeat <= 1) {
      return [];
    }

    const labels = fields.reduce((accumulator, value) => accumulator.concat(value?.label), []).filter((label) => !!label);
    return labels.join(labels.length > 1 ? " / " : null);
  };

  const renderHeaders = () => {
    return headers?.length > 0 && <div className={css(dynStyles.fieldWrapper, styles[customCss])}>
        <div className={css(dynStyles.fieldArrayWrapper, styles.fieldArrayHeadersPosition)}>
          {headers.map((header) => {
            return (
              <span key={header} className={css(styles.fieldArrayHeader)} dangerouslySetInnerHTML={{ __html: header }}/>
            );
          })}
        </div>
      </div>
  };

  return (
    <div>
      {renderHeaders()}
      <div className={css(dynStyles.fieldWrapper)}>
        <span className={css(styles.fieldArrayLabels)}>
          {getFieldArrayLabels()}
        </span>
        <div className={css(dynStyles.fieldArrayWrapper)}>
          {/*TODO what about changling?*/ }
          <FieldTemplate fields={fields} options={{changeHandler: changeHandler, hideLabel: repeat > 1}}/>
        </div>
      </div>
    </div>
  );
};

const styles = StyleSheet.create({

  fieldArrayLabels: {
    textAlign: "right"
  },
  fieldArrayHeader: {
    padding: "var(--size-10)",
    borderBottom: "var(--base-border) solid var(--lighter-grey)",
    textAlign: "left",
    fontSize: "14px",
    fontWeight: "bold"
  },
  marginTop: {
    marginTop: "var(--size-30)"
  },
  fieldArrayHeadersPosition: {
    gridColumn: 2
  },
});
