import React, {useEffect, useRef, useState} from "react";
import {useField} from "formik";
import {KeyCodeHelper} from "../../../shared/helpers/key-code.helper";
import {InputType} from "../enums/input-type.enum";
import {NumberInput} from "./number-input";
import {Select} from "./select";
import {DateInput} from "./date-input";
import {IFieldSingle} from "../interfaces/field-single.interface";
import {StylesHelper} from "../../../shared/helpers/styles.helper";
import {css, StyleSheet} from "aphrodite";
import "./base-input.scss";

interface IBaseInputProps extends IFieldSingle {
  autoComplete?: string;
  tabIndex?: number;
  icon?: JSX.Element;
  isRequired?: boolean;
  onInputChange?: (event: React.FormEvent<HTMLInputElement>) => void;
  onKeyDown?: (event: React.KeyboardEvent<HTMLInputElement>) => void;
  onFocus?: (...props) => void;
  withoutWrapper?: boolean,
  rawValue?: boolean
}

export const BaseInputField = React.memo((props: IBaseInputProps) => {
  const {
    inputType,
    readonly = false,
    customCss = "",
    changeHandler,
    fallbackName,
    isFallback,
    isRequired = false,
    fallbackValue,
    onInputChange,
    onFocus,
    onKeyDown,
    autoComplete = "",
    tabIndex,
    icon,
    isAutofill = false,
    placeholderText = "",
    withoutWrapper = false,
    rawValue = false, //use this to set value directly. Otherwise it always based on field.value which is preferred
  } = props;

  const [field, meta, {setValue: setFieldValue, setTouched}] = useField(props?.name);

  const inputRef = useRef();

  const caret = useRef(null);

  const [proxyValue, setProxyValue] = useState(null);

  useEffect(() => {
    if (rawValue === true && props.value !== null) {
      setProxyValue(props.value);
    } else if (field.value !== null) {
      setProxyValue(field.value);
    }

  }, [field.value, props.value, rawValue]);

  useEffect(() => {
    // @ts-ignore
    if (caret.current) {

      // @ts-ignore
      inputRef.current?.focus();

      // @ts-ignore
      if (inputRef.current?.setSelectionRange) {
        // @ts-ignore
        inputRef.current?.setSelectionRange(caret.current, caret.current)
      }
      //@ts-ignore
      else if(inputRef.current?.createTextRange()) {
        // @ts-ignore
        let range = inputRef.current?.createTextRange();
        range.collapse(true);
        range.moveEnd('character', caret.current);
        range.moveStart('character', caret.current);
        range.select();
      }
    }

  }, [proxyValue])

  const handleOnBlur = () => {
    if (readonly) {
      return;
    }

    setTouched(true);

    const isRequiredReset = isRequired && !proxyValue?.length && meta?.initialValue?.length;
    if (isRequiredReset) {
      setFieldValue(meta.initialValue);
    }

    if (!!meta.error) {
      return;
    }

    let changeValue = proxyValue;

    if (meta.initialValue === changeValue) {
      return;
    }

    if (!proxyValue && isFallback) {
      setFieldValue(fallbackValue);

      return;
    }

    if (!proxyValue && !!fallbackValue) {
      changeValue = fallbackValue;
    }

    emitChange(changeValue);
  };

  const emitChange = (value) => {
    changeHandler?.({
      name: field.name,
      value,
      isFallback,
      fallbackName
    });
  }

  const handleKeyTextAreaInputPress = (event: any) => {
    let currentCaret = event.target.selectionStart;

    if (KeyCodeHelper.isBackspace(event.keyCode)) {
      currentCaret--;
    } else if (!KeyCodeHelper.isDelete(event.keyCode)) {
      currentCaret++;
    }

    caret.current = currentCaret;
  };

  const handleKeyTextInputPress = (event: any) => {
    let currentCaret = event.target.selectionStart;

    if (KeyCodeHelper.isBackspace(event.keyCode)) {
      currentCaret--;
    } else if (!KeyCodeHelper.isDelete(event.keyCode)) {
      currentCaret++;
    }

    caret.current = currentCaret;

    if (readonly) {
      return;
    }

    if (onKeyDown) {
      onKeyDown(event);
    }

    if (KeyCodeHelper.isEnter(event.keyCode)) {
      event.target.blur();
    }
  };

  switch (inputType) {
    case InputType.SELECT:
      return <Select {...props} />;
    case InputType.NUMBER:
      return <NumberInput {...props}
                          field={field}
                          onBlur={handleOnBlur}
                          onKeyDown={handleKeyTextInputPress}
                          readonly={readonly}
      />;
    case InputType.DATE:
      return <DateInput {...props} emitChangeHandler={emitChange} readonly={readonly}/>;
    case InputType.TEXTAREA:
      return <textarea {...field}
                       ref={inputRef}
                       value={proxyValue ?? ""}
                       onBlur={handleOnBlur}
                       onKeyDown={handleKeyTextAreaInputPress}
                       rows={1}
                       readOnly={readonly}
                       disabled={readonly}
                       placeholder={placeholderText}
                       className={css(styles.textarea)}
      />;
    default:
      const inputClassName = `${
        customCss
      } ${
        icon ? "with-icon" : ""
      } ${
        isAutofill ? "autofill" : ""
      } ${
        meta.touched && meta.error ? css(styles.validationError) : ""
      }`;

      const renderInput = () => {
        return <><input
          ref={inputRef}
          {...field}
          //value={directValue ?? field.value ?? ""}
          value={proxyValue ?? ""}
          type={inputType}
          className={inputClassName}
          readOnly={readonly}
          onBlur={handleOnBlur}
          onKeyDown={handleKeyTextInputPress}
          onInput={onInputChange}
          onFocus={onFocus}
          autoComplete={autoComplete}
          tabIndex={tabIndex}
          disabled={isAutofill || readonly}
          placeholder={placeholderText}
        />{icon}</>;

      };

      return withoutWrapper ? renderInput() : (<div className="base-input">
        {renderInput()}
      </div>);
  }
});

const styles = StyleSheet.create({
  textarea: {
    minHeight: 40,
    maxHeight: 40
  },
  validationError: {
    ...StylesHelper.insetBorder("var(--danger)", 2)
  }
});
