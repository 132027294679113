import { IState } from '../../shared/interfaces/state.interface';
import { DatasetHistory } from './dataset-history';
import { connect } from 'react-redux';
import { selectIntroTextsUpdatedAt, selectIntroTextsUpdatedBy } from '../../shared/redux/intro-texts/intro-texts.selectors';

const mapStateToProps = (state: IState) => {
  return {
    updatedAt: selectIntroTextsUpdatedAt(state),
    updatedBy: selectIntroTextsUpdatedBy(state)
  };
};

export const IntroTextsDatasetHistory = connect(mapStateToProps, null)(DatasetHistory);