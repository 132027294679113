import React, { useEffect } from 'react';

/**
 * Custom Hook to listen to click outside events
 * inspired by @MarcoTraspel
 * @param ref
 * @param callback
 */
export function useClickOutside(ref: React.MutableRefObject<HTMLElement>, callback: (...props) => void) {

  useEffect(() => {
    const handleClickOutside = (event: any) => {
      if (ref.current && !ref?.current?.contains(event.target)) {
        callback(event);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [ref, callback])

}
