import { useRef, useState, useEffect } from 'react';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { LogService } from '../../shared/services/log.service';
import { BehaviorSubject } from 'rxjs';
import { GetSuggestionsFn } from '../../contexts/suggestion-dropdown.context';

/**
 * This custom hook triggers the suggestions fetches via an obersvable pipe everytime the inputValue is changing.
 */
export function useGetSuggestions(inputValue: string, getSuggestions: GetSuggestionsFn) {
  const searchTerm$ = useRef<BehaviorSubject<string>>(new BehaviorSubject(''));
  const [searchTerm, setSearchTerm] = useState('');
  const [suggestions, setSuggestions] = useState<string[]>(null);

  /**
   * On component will mount:
   * Subscribe to the searchTerm$ BehaviorSubject and trigger the async getSuggestions method.
   * Once the getSuggestions method is resolved update the current suggestions.
   */
  useEffect(() => {
      const subscription = searchTerm$.current
          .pipe(
              debounceTime(300),
              distinctUntilChanged(),
          )
          .subscribe(value => {
              if (!value?.length) {
                  setSuggestions(null);
                  return;
              }

              setSearchTerm(value);
              getSuggestions(value)
                  .then(result => setSuggestions(result))
                  .catch((error) => LogService.error('Failed fetching suggestion', error));
          });

      return () => {
          subscription?.unsubscribe()
      };
  }, [getSuggestions]);

  /**
   * On input change, emit new value into the BehaviorSubject
   */
  useEffect(() => {
      if (!searchTerm$?.current) return;
      searchTerm$.current.next(inputValue);
  }, [inputValue])

  /**
   * The searchTerm is currently not in use via the hook call/s, but may be handy in the future to provide.
   */
  return {
      searchTerm,
      suggestions,
  };
}
