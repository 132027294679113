import React from 'react';
import { css, StyleSheet } from 'aphrodite/no-important';
import { CssSize } from '../../shared/helpers/styles.helper';
import { defaultGridTemplateColumns } from '../../shared/constants/media-grid.const';

interface IGridTableHeadProps {
  headers: string[];
  cssTemplateColumns?: string;
}

export const GridTableHead = React.memo((props: IGridTableHeadProps) => {
  const {
    headers,
    cssTemplateColumns = defaultGridTemplateColumns,
  } = props;
  const styles = computeStyles(cssTemplateColumns);

  return (
    <div className={css(styles.gridWrapper)}>
      {headers?.map((head, index) =>
        <div key={index} className={css(styles.tableHead)}>
          <p className={css(styles.tableHeaderParagraph)}>
            {head}
          </p>
        </div>
      )}
      <div className={css(styles.tableHead)} />
    </div>
  );
});

const computeStyles = (cssTemplateColumns: string) => StyleSheet.create({
  gridWrapper: {
    width: '100%',
    display: 'grid',
    gridTemplateColumns: cssTemplateColumns,
    gridGap: CssSize.s10
  },
  tableHead: {
    padding: CssSize.s10,
    borderBottom: '2px solid var(--lighter-grey)',
    fontWeight: 600,
    fontSize: '14px'
  },
  tableHeaderParagraph: {
    marginBottom: 0
  }
});
