import React, { ReactNode, useState } from 'react';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { ChevronDownIcon } from '../icons/collection/chevron-down-icon';
import { Button } from '../button';
import { SvgVariant } from '../icons/enum/svg-variant.enum';

export interface ICollapsableContainerProps {
  children?: {
    baseFilter: ReactNode,
    wrappedFilter: ReactNode
  },
  toggleButtonProps?: any,
  toggleIconProps?: any,
}

export const CollapsableContainer = (props: ICollapsableContainerProps) => {
  const {
    toggleButtonProps,
    toggleIconProps,
    children = {
      baseFilter: (<h1>Empty Base</h1>),
      wrappedFilter: (<h1>Empty Filter</h1>)
    },
  } = props;
  const [isFilterFolded, setIsFilterFolded] = useState(true);
  const btnFilterToggleLabel = isFilterFolded ? 'Mehr Filter' : 'Weniger Filter';

  return (
    <Row className="collapsable-container">
      <div className='toggle-button'>
        <Button
          onClick={() => setIsFilterFolded(!isFilterFolded)}
          btnClass='fullwidth'
          size='lg'
          label={btnFilterToggleLabel}
          color='secondary'
          iconPosition='left'
          {...toggleButtonProps}
        >
          <ChevronDownIcon
            style={!isFilterFolded ? { transform: 'rotate(180deg)' } : null}
            viewBox='0 0 40 40'
            color={SvgVariant.White}
            titleAccess={btnFilterToggleLabel}
            {...toggleIconProps}
          />
        </Button>
      </div>
      <Col className='base-filter'>
        <div className='visible-filters'>
          {children.baseFilter}
        </div>
        <div className={`toggle-filters ${isFilterFolded ? '' : 'expand'}`}>
          {children.wrappedFilter}
        </div>
      </Col>
    </Row >
  );
}
