import { connect } from 'react-redux';
import { resetAllPropertiesFilterAction } from '../../../shared/redux/properties/properties.action';
import { selectActiveFilter } from '../../../shared/redux/properties/properties.selectors';
import { IState } from '../../../shared/interfaces/state.interface';
import { resetPropertiesFilter } from '../../../shared/redux/middleware/filter.middleware';
import { FilterSelectionWithoutStore } from "../filter-selection-without-store";

const mapStateToProps = (state: IState) => ({
  activeFilter: selectActiveFilter(state)
});

const mapDispatchToProps = (dispatch: any) => ({
  resetFilter: (groupKey: string ) => dispatch(resetPropertiesFilter(groupKey)),
  resetAllFilter: () => dispatch(resetAllPropertiesFilterAction())
});

export const PropertiesFilterSelection = connect(
  mapStateToProps,
  mapDispatchToProps,
  null,
  { forwardRef: true }
)(FilterSelectionWithoutStore);
