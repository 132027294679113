import { useCallback } from "react";
import { useCurrentLanguage } from "../contexts/current-language.context";

interface IUseI18nLabelFromObjectProps {
  i18n?: boolean;
  labelKey?: string;
  valueKey?: string;
  value: string | number;
  valueObject?: object;
}

export const useI18nLabelFromObject = (props: IUseI18nLabelFromObjectProps) => {
  const {
    i18n,
    labelKey,
    valueKey,
    value,
    valueObject
  } = props;

  const currentLanguage = useCurrentLanguage();

  return useCallback(() => {
    if(!!valueObject) {
      const labelKeyI18n = !!i18n ? labelKey + '_' + currentLanguage : labelKey;

      if (!!labelKeyI18n && labelKeyI18n in valueObject && !!valueObject[labelKeyI18n]) {
        return valueObject[labelKeyI18n];
      }

      if (!!valueKey && valueKey in valueObject && !!valueObject[valueKey]) {
        return valueObject[valueKey];
      }
    }

    return value;
  }, [value, valueObject, i18n, labelKey, valueKey, currentLanguage])
}
