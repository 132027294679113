import React, { ReactNode } from 'react'
import { css, StyleSheet } from 'aphrodite';

interface IFieldsetLegendProps {
  children: ReactNode;
  withSeparator?: boolean;
}

export const FieldsetLegend = (props: IFieldsetLegendProps) => {
  const {
    children,
    withSeparator = true
  } = props;

  return (
    <legend>
      <div className={css(styles.fieldsetLegend, withSeparator && styles.withSeperator)}>
        {children}
      </div>
    </legend>
  )
}

const styles = StyleSheet.create({
  fieldsetLegend: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    paddingBottom: 'var(--size-15)', // TODO: Update CssSize once https://webartig.atlassian.net/browse/PUB-114 is merged
  },
  withSeperator: {
    borderBottom: `2px solid var(--lighter-grey)`,
  }
});
