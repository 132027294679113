import React, { useState, useEffect } from 'react'
import { Dialog, DialogControl } from '../index'
import { ButtonColor } from '../../../button'

interface IDeleteEntryDialog {
    onControlClick: (value: boolean) => void;
    showDialog: boolean;
    onClose: () => void;
    children?: any;
}

export const DeleteEntryDialog = React.memo((props: IDeleteEntryDialog) => {

    const {
        onControlClick,
        showDialog,
        onClose,
        children,
    } = props;

    const [isDialogVisible, setIsDialogVisible] = useState(false);

    useEffect(() => {
        setIsDialogVisible(showDialog);
    }, [showDialog])

    return (
        isDialogVisible && (
            <Dialog
                title='Sind Sie sicher?'
                onClose={() => {
                    setIsDialogVisible(false);
                    onClose();
                }}
            >
                <Dialog.Message>
                    {children ?? <>
                        Wenn sie auf „Löschen“ klicken wird der Eintrag<br />
                        gelöscht und kann nicht wiederhergestellt werden.
                    </>}
                </Dialog.Message>
                <Dialog.Controls>
                    <DialogControl label='Abbrechen' value={false} color={ButtonColor.Basic} onClick={onControlClick}></DialogControl>
                    <DialogControl label='Löschen' value={true} color={ButtonColor.Danger} onClick={onControlClick}></DialogControl>
                </Dialog.Controls>
            </Dialog>
        )
    );
});
