import MBGeocoding, {GeocodeRequest, GeocodeService} from "@mapbox/mapbox-sdk/services/geocoding";
import {ISearchResultSuggestion} from "../interfaces/search-result-suggestion.interface";

export type PlaceTypes = 'address' | 'place';

export class MapboxglGeocodingService {
  private static instance: MapboxglGeocodingService;

  private readonly accessToken: string;
  protected readonly geocodingClient: GeocodeService;

  constructor(accessToken: string = process.env.REACT_APP_MAPBOX_ACCESS_TOKEN as string) {

    this.accessToken = accessToken;
    this.geocodingClient = MBGeocoding({accessToken: this.accessToken});
  }

  async findStreetByCoordinates(coordinates: [number, number]) {
    const response = await this.geocodingClient.reverseGeocode({
      query: coordinates,
      types: ["address"]
    } as GeocodeRequest).send();

    const body = response?.body;

    if (!body || !body.features || !body.features[0]) return;

    return body.features[0];
  };

  async findPublityPlacesForQuery(query: string, proximity?: [number, number], placeTypes = ['address', 'place'], language = 'de', searchEverywhere = false): Promise<ISearchResultSuggestion[]> {
    let enhQuery: string = query;

    if (!searchEverywhere && (enhQuery.toLowerCase().indexOf('de') < 0 || enhQuery.toLowerCase().indexOf('deutschland') < 0)) {
      enhQuery += ' DE';
    }

    try {
      const response = await this.geocodingClient.forwardGeocode({
        //mode: 'mapbox.places-permanent',
        countries: searchEverywhere ? null : ['de'],
        language: [language],
        query: enhQuery,
        proximity,
        types: placeTypes
      } as GeocodeRequest).send();

      const body = response?.body;

      if (body.features.length > 0) {
        return MapboxglGeocodingService.mapFeatureToPublityPlaces(body.features, searchEverywhere);
      }

      return [];

    } catch (error) {
      //console.error(error);
      return [];
    }
  }

  static mapFeatureToPublityPlaces = (features: any[], everywhere = false): ISearchResultSuggestion[] => {
    const places = [];
    for (const feature of features) {
      const place = MapboxglGeocodingService.mapFeatureToPublityPlace(feature, everywhere);
      places.push(place);
    }
    return places;
  }

  static mapFeatureToPublityPlace = (feature: any, everywhere = false): ISearchResultSuggestion => {
    const type = feature['place_type']?.[0]

    const text = feature['text']; //street or city
    const bbox = feature['bbox'];
    const address = feature['address'];
    const postcode = MapboxglGeocodingService.getContextValue(feature, 'postcode');
    const place = MapboxglGeocodingService.getContextValue(feature, 'place');
    const region = MapboxglGeocodingService.getContextValue(feature, 'region');
    const country = MapboxglGeocodingService.getContextValue(feature, 'country');

    const queryArray = [];
    let query = '';

    if (type === 'address') {
      let streetWithNo = '';

      if (text) {
        streetWithNo += text;
      }

      if (address) {
        if (streetWithNo.length > 0) {
          streetWithNo += ' ';
        }

        streetWithNo += address;

      }
      queryArray.push(streetWithNo);

      if (postcode) {
        queryArray.push(postcode)
      }

      if (place) {
        queryArray.push(place)
      }

      query = queryArray.join(', ');
    } else if (type === 'place') {
      if (text) {
        queryArray.push(text)
      }

      if (region) {
        queryArray.push(region)
      }

      query = queryArray.join(', ');
    } else {
      query += feature['place_name'];
    }

    if (everywhere) {
      query += ' ' + country;
    }

    const publityPlace: ISearchResultSuggestion = {
      type: feature['place_type']?.[0],
      bbox,
      address: query,
      geometry: {type: "Point", coordinates: feature['center']},
      country_alpha2Code: MapboxglGeocodingService.getContextValue(feature, 'country', 'short_code'),
      country,
      federalState: region,
      location: type === 'place' ? text : place,
      postalCode: postcode,
      street: type === 'address' ? text : null,
      streetNumber: address,
    };

    return publityPlace;
  }

  private static getContextValue(addressObject: any, type: string, field = 'text'): string {

    const context = addressObject?.context?.find((context: any) => {
      return context?.id?.includes(type);
    });

    return context?.[field];
  }


  static getInstance(): MapboxglGeocodingService {
    if (!MapboxglGeocodingService.instance) {
      MapboxglGeocodingService.instance = new MapboxglGeocodingService();
    }

    return MapboxglGeocodingService.instance;
  }

}
