import React, { useEffect, useReducer, useState } from 'react';
import { css, StyleSheet } from 'aphrodite/no-important';
import { withRouter } from 'react-router-dom';
import { LogService } from '../../../../shared/services/log.service';
import { Button, ButtonColor, ButtonIconPosition, ButtonSize } from '../../../button';
import { Dialog, DialogControl } from '../../../overlay/dialog';
import { IWithRouterProps } from '../../../../shared/interfaces/with-router-props.interface';
import { IFieldChangeEvent } from '../../interfaces/field-single.interface';
import { IFieldTable } from '../../interfaces/field-table.interface';
import { AddIcon } from '../../../icons/collection/add-icon';
import { SvgVariant } from '../../../icons/enum/svg-variant.enum';
import { KeyValuePairs } from '../../../../shared/types/key-value-pairs.type';
import { FormHelper } from '../../helpers/form.helper';
import { FieldArray as FormikFieldArray } from 'formik';
import { BinIcon } from '../../../icons/collection/bin-icon';
import { fieldTableReducer, IFieldTableState } from './field-table.reducer';
import { setPreviousTableData, setTableData } from './field-table.actions';
import { FieldArrayHelper } from '../../helpers/field-array.helper';

interface IFieldTableProps extends IFieldTable, IWithRouterProps<{ propertyId: number }> {
  parentRelationId?: string;
  readonly?: boolean;
}

const FIELD_TABLE_DEFAULT_STATE: IFieldTableState = {
  tableData: null,
  previousTableData: null
}

export const FieldTable = React.memo(withRouter((props: IFieldTableProps) => {
  const {
    name,
    value,
    tableStructure,
    addLabel,
    rowLabel,
    onDataDelete,
    onDataAdd,
    triggerDataRefetch,
    match,
    parentRelationId,
    changeHandler,
    readonly = false,
  } = props;

  const [ state, dispatch ] = useReducer(fieldTableReducer, FIELD_TABLE_DEFAULT_STATE);
  const { tableData, previousTableData } = state;

  const [ deleteTargetIdentifier, setDeleteTargetIdentifier ] = useState(null);
  const [ showDeleteDialog, setShowDeleteDialog ] = useState(false);
  const [ propertyId, setPropertyId ] = useState<number>(null);

  useEffect(() => {
    setPropertyId(match.params.propertyId); //TODO WTF?
  }, [match]);

  const updateTableState = () => {
    if (!previousTableData?.length || previousTableData?.length !== tableData?.length) {
      dispatch(setPreviousTableData(value));
    }

    dispatch(setTableData(value));
  }

  useEffect(updateTableState, [value]);

  const handleChange = (change: IFieldChangeEvent) => {
    if(readonly) {
      return;
    }

    // This Solution is for nested Relations, will not work for first level relations
    const [,, relationName, dataIndex, fieldName] = change.name.split('.');
    const updatedData = FieldArrayHelper.onDataUpdate(
      { ...change, name: `${relationName}.${dataIndex}.${fieldName}` },
      tableData,
      previousTableData
    );

    if (!updatedData?.length) {
      return;
    }

    dispatch(setPreviousTableData(updatedData));

    changeHandler({
      name,
      value: updatedData,
      isFallback: null,
      fallbackName: null
    })
  };

  const onDialogControlClick = async (submitDelete: boolean) => {
    if (submitDelete && !readonly) {
      try {
        await onDataDelete?.(propertyId, parentRelationId, deleteTargetIdentifier);
        await triggerDataRefetch?.();
      } catch (error) {
        LogService.error('onDialogControlClick: Http request failed.', error);
      }
    }
    setDeleteTargetIdentifier(null);
    setShowDeleteDialog(false);
  };

  return (
    <>
      { !!showDeleteDialog && !readonly && <Dialog
            title='Sind Sie sicher?'
            onClose={() => setShowDeleteDialog(false)}
          >
            <Dialog.Message>
              Wenn sie auf „Löschen“ klicken wird der Eintrag<br />
              gelöscht und kann nicht wiederhergestellt werden.
            </Dialog.Message>
            <Dialog.Controls>
              <DialogControl label='Abbrechen' value={false} color={ButtonColor.Basic} onClick={onDialogControlClick}></DialogControl>
              <DialogControl label='Löschen' value={true} color={ButtonColor.Danger} onClick={onDialogControlClick}></DialogControl>
            </Dialog.Controls>
          </Dialog>
      }
      <div className={css(styles.tableWrapper)}>
        {!!onDataAdd && !readonly && <Button type={'button'}
                btnClass={`medium-font-size bold margin-left-auto ${css(styles.addButton)}`}
                size={ButtonSize.Small}
                color={ButtonColor.Ghost}
                iconPosition={ButtonIconPosition.Left}
                label={addLabel}
                onClick={async () => {
                  await onDataAdd?.(propertyId, parentRelationId);
                  await triggerDataRefetch?.();
                }}>
          <AddIcon
            viewBox='0 0 30 30'
            color={SvgVariant.Secondary}
            titleAccess='Eintrag hinzufügen'
          />
        </Button>}
        <FormikFieldArray name={name}
          render={_ => (
            <table className={css(styles.table)}>
              <thead>
                <tr>
                  <td className={css(styles.labelPlaceHolderTd)}/>
                  {tableStructure?.map(({ key, header }) => {
                    return (
                      <td key={key}
                          className={css(styles.header)}
                          dangerouslySetInnerHTML={{__html: header ?? ''}} />
                    )
                  })}
                  {!!onDataDelete && !readonly &&<td className={css(styles.deletePlaceHolderTd)} />}
                </tr>
              </thead>
              <tbody>
              {tableData?.map((item: KeyValuePairs, rowIndex) => {
                const isFirstRowTd = rowIndex === 0;

                return (
                  <tr key={item?.id || rowIndex}>
                    <td className={css(styles.labelTd, isFirstRowTd && styles.firstRowTd)}>
                      {rowLabel} ({rowIndex + 1})
                    </td>
                    {tableStructure?.map(({ key, dataRef }) => {
                      if (item.hasOwnProperty(key)) {
                        return (
                          <td key={key} className={css([styles.row, isFirstRowTd && styles.firstRowTd])}>
                            {FormHelper.renderFieldByTemplateType(
                              dataRef,
                              {
                                name: `${name}.${rowIndex}.${key}`,
                                value: item?.[key] ?? '',
                                changeHandler: handleChange,
                                readonly
                              })}
                          </td>
                        )
                      }
                    })}
                    {!readonly && <td className={css(styles.deleteTd, isFirstRowTd && styles.firstRowTd)}>
                      <Button type={'button'}
                              btnClass={'no-padding'}
                              size={ButtonSize.Medium}
                              color={ButtonColor.Basic}
                              showLabel={false}
                              label={null}
                              onClick={() => {
                                setDeleteTargetIdentifier(item?.id);
                                setShowDeleteDialog(true);
                              }}>
                        <BinIcon
                          viewBox='0 0 40 40'
                          color={SvgVariant.Secondary}
                          titleAccess={'Eintrag löschen'}
                        />
                      </Button>
                    </td>}
                  </tr>
                )
              })}
              </tbody>
            </table>
          )} />
      </div>
    </>
  );
}))

const styles = StyleSheet.create({
  tableWrapper: {
    position: 'relative'
  },
  addButton: {
    position: 'absolute',
    top: 5,
    right: 0,
    zIndex: 1
  },
  table: {
    position: 'relative',
    width: '100%',
    borderCollapse: 'separate',
    borderSpacing: '10px 0px',
    tableLayout: 'fixed',
    userSelect: 'none',
    zIndex: 0
  },
  header: {
    padding: 'var(--size-10)',
    textAlign: 'left',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    //width: 'calc(100% - var(--size-10))',
    fontWeight: 600,
    fontSize: 14,
    borderBottom: 'var(--base-border) solid var(--lighter-grey)'
  },
  deletePlaceHolderTd: {
    width: 40,
    padding: 'var(--size-10)',
    borderBottom: 'var(--base-border) solid var(--lighter-grey)'
  },
  labelPlaceHolderTd: {
    width: 'var(--form-label)',
    padding: 'var(--size-10)',
    borderBottom: 'var(--base-border) solid transparent'
  },
  noMargin: {
    marginBottom: 0
  },
  row: {
    verticalAlign: 'top',
  },
  firstRowTd: {
    paddingTop: 'var(--size-10)'
  },
  labelTd: {
    textAlign: 'right',
    paddingRight: 'var(--size-10)',
    paddingBottom: 'var(--size-10)',
    verticalAlign: 'middle'
  },
  deleteTd: {
    verticalAlign: 'top'
  }
})
