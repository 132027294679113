import React, {Children} from 'react'
import { associateSection } from './associate-setion';
import './controls-header.scss';

interface IControlsHeaderProps {
  children: any | any[];
  className?: string;
}

export const ControlsHeader = (props: IControlsHeaderProps) => {
  const {
    children,
    className = ''
  } = props;

  const childrenCount = Children.count(children);
  if (childrenCount < 1 || childrenCount > 3) {
    throw new Error('You are only allowed to pass betweend 1 to 3 children to the ControlsHeader component.');
  }

  return (
    <div className={`controls-header ${className}`}>
      {Children.map(children, (child, index) => {
        const sectionName = associateSection(index, childrenCount);
        return (<div className={sectionName}>
          {child}
        </div>)
      })}
    </div>
  )
}
