import { IState } from '../interfaces/state.interface';
import { fromJS, is } from 'immutable';
import { createSelector as cs, createSelectorCreator, defaultMemoize } from 'reselect';

/**
 * This method can be used to merge the application state via immutableJs.
 * Using this method is highly recommended as it is efficient and is effectively taking constant time.
 */
export function mergeState(state: IState, newState: any): IState {
  return state.merge(fromJS(newState));
}

/**
 * This method is a creator for createSelectors, this enables us to multiple selectors with different inputs.
 * As only the last emit is memoized we would need to create new copy for each usage as they would overwrite each other.
 * The createSelectorCreator does this job for us by default.
 */
export const createSelector: typeof cs = createSelectorCreator(defaultMemoize, is);
