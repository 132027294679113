import { ITableHeaderAndFooterConfig } from '../interfaces/table-header-config.interface';
import {UnitSymbol} from "../../components/form/enums/unit-symbol.enum";

export const PropertyHeadersConfig: ITableHeaderAndFooterConfig[] = [
  {
    label: "Objekt-ID",
    width: 150,
    columnKey: "propertyId",
  },
  {
    label: "Objektname",
    width: 250,
    columnKey: "title",
  },
  {
    label: "Straße / Nr.",
    width: 250,
    columnKey: "allFullStreets",
    allowWordWrap: true
  },
  {
    label: "PLZ",
    width: 120,
    columnKey: "primaryAddress.postalCode",
  },
  {
    label: "Ort",
    columnKey: "primaryAddress.location",
  },
  {
    label: "Baujahr",
    width: 120,
    align: 'right',
    columnKey: "constructionYear"
  },
  {
    label: "Gesamtfl. (m<sup>2</sup>)",
    align: 'right',
    width: 200,
    columnKey: "totalArea",
    formatNumber: true
  },
  {
    label: "Bürofl. (m<sup>2</sup>)",
    align: 'right',
    width: 200,
    columnKey: "officeArea",
    formatNumber: true
  },
  {
    label: "Kaufpreis (€)",
    width: 200,
    align: 'right',
    columnKey: "purchasePrice"
  },
  {
    label: "Leerstand (%)",
    width: 200,
    align: 'right',
    columnKey: "vacancyRate"
  },
  {
    label: UnitSymbol.Average + " Mietpreis in " + UnitSymbol.SquareMeter,
    width: 200,
    align: 'right',
    columnKey: "averageRentalCharge"
  },
];
