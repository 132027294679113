import { TemplateType } from '../enums/template-type.enum';
import { IFieldTabArray } from '../interfaces/field-tab-array.interface';

export abstract class FieldTabArrayConfig {
  public static DEFAULT_CONFIG: IFieldTabArray = {
    templateType: TemplateType.FIELD_TAB_ARRAY,
    name: null,
    value: [],
    tabs: null,
    addLabel: null,
    identifier: null,
    fields: null,
    displayLabelCount: true,
    isDeleteAllowed: true,
    transformMessage: '',
    deleteMessage: "Wenn sie auf „Löschen“ klicken wird der Eintrag gelöscht und kann nicht wiederhergestellt werden."
  };

  public static RELATIONS_CONFIG: IFieldTabArray = {
    ...(FieldTabArrayConfig.DEFAULT_CONFIG),
    identifier: 'id'
  };

  static getConfig(options: Partial<IFieldTabArray>, defaults = FieldTabArrayConfig.DEFAULT_CONFIG) {
    return {
      ...defaults,
      ...options
    }
  }
}
