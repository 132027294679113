import React from "react";
import { Button, ButtonColor, ButtonSize } from "../button";
import { BinIcon } from "../icons/collection/bin-icon";
import { SvgVariant } from "../icons/enum/svg-variant.enum";
import { PencilIcon } from "../icons/collection/pencil-icon";
import { CssColor } from "../../shared/helpers/styles.helper";
import { ChevronDownIcon } from "../icons/collection/chevron-down-icon";
import { css, StyleSheet } from "aphrodite";

interface IPropertyMediaRowControlsProps {
  isEditable?: boolean;
  isFirst?: boolean;
  isLast?: boolean;
  onDelete?: () => void;
  onEdit?: () => void;
  onMoveUp?: () => void;
  onMoveDown?: () => void;
  readonly?: boolean;
}

export const PropertyMediaRowControls = (props: IPropertyMediaRowControlsProps) => {
  const {
    isEditable = true,
    isFirst = false,
    isLast = false,
    onDelete = () => null,
    onEdit = () => null,
    onMoveUp = () => null,
    onMoveDown = () => null,
    readonly = false
  } = props;

  return (
    <>{!readonly && <>
      <Button
        disabled={isFirst}
        type={"button"}
        btnClass={`no-padding ${css(styles.rotateUpArrow)}`}
        size={ButtonSize.Medium}
        color={ButtonColor.Ghost}
        showLabel={false}
        label={"Bild verschieben"}
        onClick={onMoveUp}>
        <ChevronDownIcon
          viewBox='0 0 40 40'
          color={SvgVariant.Secondary}
          titleAccess={"Eintrag verschieben"}
        />
      </Button>
      <Button
        disabled={isLast}
        type={"button"}
        btnClass={"no-padding"}
        size={ButtonSize.Medium}
        color={ButtonColor.Ghost}
        showLabel={false}
        label={"Bild verschieben"}
        onClick={onMoveDown}>
        <ChevronDownIcon
          viewBox='0 0 40 40'
          color={SvgVariant.Secondary}
          titleAccess={"Eintrag verschieben"}
        />
      </Button>
      {isEditable && <Button
        type={"button"}
        btnClass={"no-padding"}
        size={ButtonSize.Medium}
        color={ButtonColor.Ghost}
        showLabel={false}
        label={"Bild editieren"}
        onClick={onEdit}>
        <PencilIcon
          viewBox='0 0 40 40'
          strokeWidth={.5}
          stroke={CssColor.Secondary}
          color={SvgVariant.Secondary}
          titleAccess={"Eintrag editieren"}
        />
      </Button>}
      <Button
        type={"button"}
        btnClass={"no-padding"}
        size={ButtonSize.Medium}
        color={ButtonColor.Ghost}
        showLabel={false}
        label={"Bild löschen"}
        onClick={onDelete}>
        <BinIcon
          viewBox='0 0 40 40'
          color={SvgVariant.Secondary}
          titleAccess={"Eintrag löschen"}
        />
      </Button>
    </>}
    </>
  );
};

const styles = StyleSheet.create({
  rotateUpArrow: {
    transform: "rotate(180deg)"
  }
});
