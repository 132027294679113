import {UnitSymbol} from "../../../enums/unit-symbol.enum";
import {FieldSingleConfig} from "../../../config/field-single.config";
import {InputType} from "../../../enums/input-type.enum";
import {DatePickerType} from "../../../enums/datepicker-type.enum";
import {FieldTableConfig} from "../../../config/field-table.config";
import {PropertyService} from "../../../../../shared/services/property.service";
import {PropertyInputDefinitions} from "../../property-input-definitions.const";
import {AgentInputDefinitions} from "../../agent-definitions.const";
import {IState} from "../../../../../shared/interfaces/state.interface";
import {
  selectAffiliateTenancyAgentSelectorObject,
  selectAffiliateTenancyPropertySelectorObject
} from "../../../../../shared/redux/affiliate/affiliate.selectors";
import {FieldComposeConfig} from "../../../config/field-compose.config";
import {FieldCollapsableConfig} from "../../../config/field-collapsable.config";
import {RelationshipInputFieldFactory} from "../../relationship-input-field-factory";
import {IFieldTabArrayState} from "../../../fields/field-tab-array/field-tab-array.reducer";
import {selectCurrent, selectFormer} from "../../../fields/field-tab-array/field-tab-array.selector";
import {TitleBarNumberType} from "../../../interfaces/field-collapsable.interface";

export abstract class AffiliateTenanciesInputDefinitions {
  static get AffiliateTenancies() {
    const tabFactory = (key: string, label: string, selector: Function, showCreateButton: boolean) => ({
      key: key + "_item",
      label,
      selector: selector,
      newDataRef: {
        ...FieldCollapsableConfig.getConfig({
          label,
          hideInputEnumeration: false,
          showAdditionalButton: showCreateButton,
          titleBar: AffiliateTenanciesInputDefinitions.AffiliateTenanciesInputTitles,
          fields: AffiliateTenanciesInputDefinitions.AffiliateTenanciesInputFields
        })
      }
    });
    return RelationshipInputFieldFactory.TwoTabsWithFormerCollapsibleFactory(
      "tenancies",
      "Mietobjekt",
      "Mietobjekte",
      (state: IFieldTabArrayState) => selectCurrent(state),
      (state: IFieldTabArrayState) => selectFormer(state),
      tabFactory
    );
  }

  static get AffiliateTenanciesInputTitles() {
    return [
      [
        {
          replaceValue: "_propertyId",
          title: async (state, id) => (selectAffiliateTenancyPropertySelectorObject(state, id)?.label)
        }
      ],
      [
        {
          replaceValue: "totalArea",
          title: `<strong>Gesamtmietfläche (${UnitSymbol.SquareMeter})</strong><br>$0`
        },
        {
          replaceValue: "officeArea",
          title: `<strong>Bürofläche (${UnitSymbol.SquareMeter})</strong><br>$0`
        },
        {
          replaceValue: "totalMonthlyRent",
          title: `<strong>Mtl. Gesamtmiete (${UnitSymbol.Euro})</strong><br>$0`
        },
        {
          replaceValue: "averageRent",
          title: `<strong>Ø Mietpreis pro ${UnitSymbol.SquareMeter}</strong><br>$0`,
          numberType: TitleBarNumberType.DECIMAL,
        },
        {
          replaceValue: "rentalStart",
          title: "<strong>Mietbeginn</strong><br>$0"
        },
        {
          replaceValue: "rentalEnd",
          title: "<strong>Mietende</strong><br>$0"
        },
        {
          replaceValue: "agentId",
          title: async (state, id) => "<strong>Makler</strong><br/>" + (selectAffiliateTenancyAgentSelectorObject(state, id)?.label)
        },
        {
          replaceValue: "agentId2",
          title: async (state, id) => "<strong>Makler 2</strong><br/>" + (selectAffiliateTenancyAgentSelectorObject(state, id, '2')?.label)
        },
        {
          replaceValue: "agentId3",
          title: async (state, id) => "<strong>Makler 3</strong><br/>" + (selectAffiliateTenancyAgentSelectorObject(state, id, '3')?.label)
        },
        {
          replaceValue: "agentId4",
          title: async (state, id) => "<strong>Makler 4</strong><br/>" + (selectAffiliateTenancyAgentSelectorObject(state, id, '4')?.label)
        },
      ]
    ];
  }

  static get AffiliateTenanciesInputFields() {
    return [
      ...AffiliateTenanciesInputDefinitions.PropertyInformationInputFields,
      ...AffiliateTenanciesInputDefinitions.BasicInformationInputFields,
      ...AffiliateTenanciesInputDefinitions.AreaInformationInputFields
    ];
  }

  private static get PropertyInformationInputFields() {
    return [
      {
        ...FieldComposeConfig.getConfig({
          name: "propertyBaseInformationComposition",
          repeat: 1,
          fields: [
            PropertyInputDefinitions.PropertySelectorInputField(async (state: IState, id) => ({
              id,
              label: selectAffiliateTenancyPropertySelectorObject(state, id)?.label
            })),
            PropertyInputDefinitions.Address("property.primaryAddress.fullAddress", true, false)
          ]
        })
      },
      {
        ...FieldComposeConfig.getConfig({
          name: "propertyMoreInformationComposition",
          repeat: 1,
          fields: [
            {
              ...FieldComposeConfig.getConfig({
                name: "propertyContactNamePhoneInformationComposition",
                repeat: 2,
                fields: [
                  {
                    ...FieldSingleConfig.getConfig({
                      name: "property.name",
                      label: "Name",
                      readonly: true,
                      condensed: true
                    })
                  },
                  {
                    ...FieldSingleConfig.getConfig({
                      name: "property.phone",
                      label: "Tel.",
                      readonly: true,
                      condensed: true
                    })
                  }]
              })
            },
            {
              ...FieldComposeConfig.getConfig({
                name: "propertyContactEmailWebsiteInformationComposition",
                repeat: 2,
                condensed: true,
                fields: [
                  {
                    ...FieldSingleConfig.getConfig({
                      name: "property.email",
                      label: "E-Mail",
                      readonly: true,
                      condensed: true
                    })
                  },
                  {
                    ...FieldSingleConfig.getConfig({
                      name: "property.website",
                      label: "Website",
                      readonly: true,
                      condensed: true
                    })
                  }
                ]
              })
            },
            {
              ...FieldComposeConfig.getConfig({
                name: "propertyParkingInformationComposition",
                repeat: 2,
                headers: ["Tiefgargage", "Außenfläche"],
                fields: [
                  {
                    ...FieldSingleConfig.getConfig({
                      inputType: InputType.NUMBER,
                      name: "undergroundParkingSpace",
                      label: "Parkplätze",
                      condensed: true,
                      attributes: {
                        abbreviation: UnitSymbol.Pieces,
                        allowDecimal: false,
                        max: 99999
                      }
                    })
                  },
                  {
                    ...FieldSingleConfig.getConfig({
                      inputType: InputType.NUMBER,
                      name: "outdoorParkingSpace",
                      condensed: true,
                      attributes: {
                        abbreviation: UnitSymbol.Pieces,
                        allowDecimal: false,
                        max: 99999
                      }
                    })
                  }
                ]
              })
            }
          ]
        })
      }
    ];
  }

  private static get BasicInformationInputFields() {
    return [
      {
        ...FieldComposeConfig.getConfig({
          fullWidth: true,
          repeat: "auto",
          name: "basicInformationComposition",
          headers: [
            `Gesamtmietfläche (${UnitSymbol.SquareMeter})`,
            "Bürofläche",
            `Mtl. Gesamtmiete`,
            `${UnitSymbol.Average} Mietpreis in ${UnitSymbol.SquareMeter}`,
            "Mietbeginn",
            "Mietende",
          ],
          fields: [
            FieldSingleConfig.getConfig({
              inputType: InputType.NUMBER,
              name: "totalArea",
              attributes: {
                abbreviation: UnitSymbol.SquareMeter,
                max: 999999999
              }
            }),
            FieldSingleConfig.getConfig({
              inputType: InputType.NUMBER,
              name: "officeArea",
              attributes: {
                abbreviation: UnitSymbol.SquareMeter,
                max: 999999999
              }
            }),
            FieldSingleConfig.getConfig({
              inputType: InputType.NUMBER,
              name: "totalMonthlyRent",
              attributes: {
                abbreviation: UnitSymbol.Euro,
                max: 999999999
              }
            }),
            FieldSingleConfig.getConfig({
              inputType: InputType.NUMBER,
              name: "averageRent",
              attributes: {
                abbreviation: UnitSymbol.Euro,
                max: 99.99,
                allowDecimal: true
              }
            }),
            FieldSingleConfig.getConfig({
              inputType: InputType.DATE,
              name: "rentalStart",
              attributes: {
                datePickerType: DatePickerType.Full
              }
            }),
            FieldSingleConfig.getConfig({
              inputType: InputType.DATE,
              name: "rentalEnd",
              attributes: {
                datePickerType: DatePickerType.Full
              }
            }),
          ]
        })
      },
      {
        ...FieldComposeConfig.getConfig({
          fullWidth: true,
          repeat: "auto",
          name: "agentsComposition",
          headers: [
            `Makler 1`,
            "Makler 2",
            `Makler 3`,
            `Makler 4`,
          ],
          attachedTop: true,
          fields: [
            AgentInputDefinitions.SelectorInputField("", "_agentId", async (state, id) => {
              return selectAffiliateTenancyAgentSelectorObject(state, id);
            }),
            AgentInputDefinitions.SelectorInputField("", "_agentId2", async (state, id) => {
              return selectAffiliateTenancyAgentSelectorObject(state, id, "2");
            }),
            AgentInputDefinitions.SelectorInputField("", "_agentId3", async (state, id) => {
              return selectAffiliateTenancyAgentSelectorObject(state, id, "3");
            }),
            AgentInputDefinitions.SelectorInputField("", "_agentId4", async (state, id) => {
              return selectAffiliateTenancyAgentSelectorObject(state, id, "4");
            }),
          ]
        })
      },
      {
        ...FieldSingleConfig.getConfig({
          inputType: InputType.TEXTAREA,
          name: "additionalInformation",
          label: "Zusatzinfo",
          placeholderText: "Zusatzinfo eingeben",
          fullWidth: true,
          attachedTop: true
        })
      }
    ];
  }

  private static get AreaInformationInputFields() {
    return [
      {
        ...FieldTableConfig.getConfig({
          name: "rentalAreas",
          addLabel: "Fläche hinzufügen",
          rowLabel: "Fläche",
          tableStructure: [
            {
              key: "type",
              header: "Flächenart",
              dataRef: {
                ...FieldSingleConfig.getConfig({
                  inputType: InputType.SELECT,
                  name: "type",
                  options: [
                    {
                      label: "Bitte auswählen",
                      value: null
                    },
                    {
                      label: "Wohnen",
                      value: "housing"
                    },
                    {
                      label: "Gastro",
                      value: "gastronomy"
                    },
                    {
                      label: "Hotel",
                      value: "hotel"
                    },
                    {
                      label: "Sonstiges",
                      value: "other"
                    },
                    {
                      label: "Büro",
                      value: "office"
                    },
                    {
                      label: "Lager",
                      value: "warehouse"
                    },
                    {
                      label: "Einzelhandel",
                      value: "retail"
                    },
                    {
                      label: "TG-Stellplatz",
                      value: "undergroundParkingSpace"
                    },
                    {
                      label: "Stellplatz außen",
                      value: "outdoorParkingSpace"
                    }
                  ]
                })
              }
            },
            {
              key: "rentalArea",
              header: `Mietfläche (${UnitSymbol.SquareMeter})`,
              dataRef: {
                ...FieldSingleConfig.getConfig({
                  inputType: InputType.NUMBER,
                  name: "rentalArea",
                  attributes: {
                    abbreviation: UnitSymbol.SquareMeter,
                    max: 999999999
                  }
                })
              }
            },
            {
              key: "price",
              header: `Preis (${UnitSymbol.SquareMeter})`,
              dataRef: {
                ...FieldSingleConfig.getConfig({
                  inputType: InputType.NUMBER,
                  name: "price",
                  attributes: {
                    abbreviation: UnitSymbol.Euro,
                    max: 999.99,
                    allowDecimal: true
                  }
                })
              }
            },
            {
              key: "additionalCosts",
              header: `Nebenkosten (${UnitSymbol.SquareMeter})`,
              dataRef: {
                ...FieldSingleConfig.getConfig({
                  inputType: InputType.NUMBER,
                  name: "additionalCosts",
                  attributes: {
                    abbreviation: UnitSymbol.Euro,
                    max: 99.99,
                    allowDecimal: true
                  }
                })
              }
            },
            {
              key: "rentalStart",
              header: "Mietbeginn",
              dataRef: {
                ...FieldSingleConfig.getConfig({
                  inputType: InputType.DATE,
                  name: "rentalStart",
                  attributes: {
                    datePickerType: DatePickerType.Full
                  }
                })
              }
            },
            {
              key: "rentalEnd",
              header: "Mietende",
              dataRef: {
                ...FieldSingleConfig.getConfig({
                  inputType: InputType.DATE,
                  name: "rentalEnd",
                  attributes: {
                    datePickerType: DatePickerType.Full
                  }
                })
              }
            },
            {
              key: "additionalInformation",
              header: "",
              dataRef: {
                ...FieldSingleConfig.getConfig({
                  inputType: InputType.TEXTAREA,
                  name: "additionalInformation",
                  placeholderText: "Zusatzinfo eingeben"
                })
              }
            }
          ],
          onDataAdd: async (routeParam: string | number, tenancyId) => {
            await PropertyService.addData(routeParam, `tenancies/${tenancyId}/rentalareas`);
          },
          onDataDelete: async (routeParam: string | number, tenancyId, id) => {
            await PropertyService.deleteData(routeParam, `tenancies/${tenancyId}/rentalareas/${id}`);
          }
        })
      }
    ];
  }
}
