import { ITableRow } from "../interfaces/table-row.interface";
import { KeyValuePairs } from "../types/key-value-pairs.type";
import { LogService } from "./log.service";
import { FlashMessagesService } from "./flash-messages.service";
import { IResponseStatusCode } from "../interfaces/flash-message.interface";
import { API } from "aws-amplify";

export class HttpService {

  static getApiHost() {
    return process.env.REACT_APP_API_HOST;
  }

  public static async performGetRequest(path: string): Promise<any> {
    return new Promise<ITableRow[]>(async (resolve, reject) => {
      API
        .get("api", "/" + path, {})
        .then((res) => {
          resolve(res);
        })
        .catch((error) => {
          LogService.error(
            "HttpService: The Server responded with an error",
            error
          );
          reject(error);
        });
    });
  }

  public static async performPostRequest(path: string, body: any): Promise<any> {
    return new Promise<KeyValuePairs>(async (resolve, reject) => {
      API
        .post("api", "/" + path, { body })
        .then((res) => {
          if (res?.message) {
            HttpService.sendResponseMessage(res.status, res.message);
          }

          resolve(res);
        })
        .catch((error) => {
          LogService.error(
            "HttpService: The Server responded with an error",
            error
          );
          reject(error);
        });
    });
  }

  public static async performDeleteRequest(path: string, body?: any): Promise<any> {
    return new Promise<ITableRow[]>(async (resolve, reject) => {
      API
        .del("api", "/" + path, {body})
        .then((res) => {
          if (res?.message) {
            HttpService.sendResponseMessage(res.status, res.message);
          }

          resolve(res);
        })
        .catch((error) => {
          LogService.error(
            "HttpService: The Server responded with an error",
            error
          );
          reject(error);
        });
    });
  }

  public static async performPutRequest(path: string, body: any, loud = true): Promise<any> {
    return new Promise<KeyValuePairs>(async (resolve, reject) => {
      API
        .put("api", "/" + path, { body })
        .then((res) => {
          if (res?.message && loud) {
            HttpService.sendResponseMessage(res.status, res.message);
          }

          resolve(res);
        })
        .catch((error) => {
          LogService.error(
            "HttpService: The Server responded with an error",
            error
          );
          HttpService.sendResponseMessage(IResponseStatusCode.ERROR, "Fehler! Etwas hat nicht geklappt.");
          reject(error);
        });
    });
  }

  private static sendResponseMessage(status: number, message: string): void {
    const flashMessageService = FlashMessagesService.getInstance();

    flashMessageService.addMessage({ id: Date.now(), status, text: message });
  }
}
