import React, { useEffect, useRef, useState } from "react";
import { css, StyleSheet } from "aphrodite";
import { CssColor, CssSize } from "../../../../shared/helpers/styles.helper";
import { useEscapeKey } from "../../../../hooks/use-escape-key";
import { ImageEditor } from "../../../image-editor/image-editor";
import { MediaElementModel } from "../../../../shared/models/media-element.model";
import { useFile } from "../../../../hooks/use-file";
import { Portal } from "../../../portal/portal";
import useBodyClass from "../../../../hooks/use-body-class";
import useResizeObserver from "../../../../hooks/use-resize-observer";

interface IEditImageModelProps {
  imageElement: MediaElementModel;
  onUpdate?: (media: MediaElementModel, base64: string) => Promise<Function>;
  close?: () => void;
}

export const EditImageModel = React.memo((props: IEditImageModelProps) => {
  const {
    imageElement,
    onUpdate,
    close = () => {
    }
  } = props;

  const [mainRect, setMainRect] = useState({ top: 0, left: 0, width: 0, height: 0 });

  useBodyClass(`no-scroll`);
  const imageUrl = useFile(imageElement.s3Key);

  useEscapeKey(() => close());

  const handleOnSave = (base64): Promise<Function> => {
    return onUpdate?.(imageElement, base64);
  };

  const [mainEl, setMainEl] = useState<any>();

  const modalRef = useRef<any>();

  useEffect(() => {
    const portal = modalRef?.current?.parentElement;
    const main = portal?.parentElement;
    if (main) {
      setMainEl(main);
    }
  }, [modalRef]);

  useResizeObserver(mainEl, (entries) => {
    const main = entries[0];

    if (main) {
      setMainRect(main.contentRect);
    }
  });

  const [offsetTop, setOffsetTop] = useState<number>(0);

  useEffect(() => {
    const offsetTop = modalRef?.current?.getBoundingClientRect().top;
    if (offsetTop > 0) {
      setOffsetTop(offsetTop);
    }
  }, [setOffsetTop]);

  const dynamicStyles = StyleSheet.create({
    mainFixedPortalRootMarginTop: {
      position: "fixed",
      width: mainRect.width,
      height: "100vh",
      marginTop: -offsetTop
    }
  });

  return (
    <Portal rootId={"main-fixed-portal-root"}>
      <div ref={modalRef} className={css(dynamicStyles?.mainFixedPortalRootMarginTop)}>
        <div className={css(styles.editorOverlay)}>
          <ImageEditor
            src={imageUrl}
            fileName={imageElement.title}
            close={close}
            onSave={handleOnSave}
          />
        </div>
      </div>
    </Portal>
  );
});

const styles = StyleSheet.create({
  editorOverlay: {
    position: "absolute",
    top: 0,
    bottom: 0,
    right: 0,
    left: 0,
    height: "100vh",
    zIndex: 9,
    overflow: "hidden",
    backgroundColor: CssColor.DarkerGrey,
    padding: `0 ${CssSize.s30}`,
    display: "flex",
    justifyContent: "center",
    flexDirection: "row"
  }
});
