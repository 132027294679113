import React from "react";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import { FormFieldSetGroups } from "./form-field-set-groups";
import { IFormFieldset } from "./interfaces/form-fieldset.interface";

interface IFormSetProps {
  sets: IFormFieldset[];
  formValues;
  changeHandler;
  refetchSourceData;
}

export const FormFieldSets = React.memo((props: IFormSetProps) => {
  const {
    sets,
    formValues,
    changeHandler,
    refetchSourceData,
  } = props;

  return (<>{sets.map(({ name, groups, customCss }) => {
    return (
        <fieldset key={name} className={customCss}>
          <Row>
            {groups.map((group, i) => {
              return (
                <Col xs={12} md={group?.size === "full" ? 12 : 6} key={i}>
                  <Row>
                    <FormFieldSetGroups groups={group} formValues={formValues} changeHandler={changeHandler} refetchSourceData={refetchSourceData}/>
                  </Row>
                </Col>
              );
            })}
          </Row>
        </fieldset>
      );
    })}</>);
});
