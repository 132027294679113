import { connect } from 'react-redux';
import { IState } from '../../shared/interfaces/state.interface';
import { InfoBadge } from './info-badge';
import { selectAffiliateName, selectAffiliateId } from '../../shared/redux/affiliate/affiliate.selectors';

const mapStateToProps = (state: IState) => {
    return {
      title: selectAffiliateId(state)?.toString(),
      subTitle: selectAffiliateName(state),
    }
  };
  
  export const AffiliateInfoBadgeWithStore = connect(mapStateToProps, null)(InfoBadge);