import { MediaElementModel } from '../../models/media-element.model';
import { List } from 'immutable';
import { MoveMediaPosition } from '../../services/media-data.service';

export abstract class PropertyMediaStoreHelper {
    static recalculatePositions(mediaElements: List<MediaElementModel>): List<MediaElementModel> {
        return mediaElements
            .map((element, key) => element.merge({
                position: key + 1
            }));
    }

    static updatePosition(payload: { mediaId: string, move: MoveMediaPosition }, mediaElements: List<MediaElementModel>) {
        const targetMedia = this.getMediaById(payload.mediaId, mediaElements);
        const repalcePosition = targetMedia.position + payload.move;

        return mediaElements.map((mediaElement, key) => {
            // key is 0 based, that's why we need to substract - 1.
            const isCurrent = key === targetMedia.position - 1;
            if (isCurrent) {
                return mediaElement.merge({
                    position: mediaElement.position + payload.move
                });
            }

            const isReplace = key === repalcePosition - 1;
            if (isReplace) {
                return mediaElement.merge({
                    position: mediaElement.position - payload.move
                });
            }

            return mediaElement;
        })
        .sortBy(mediaElement => mediaElement.position);
    }

    private static getMediaById(mediaId: string, mediaElements: List<MediaElementModel>) {
        return mediaElements.find(mediaElement => mediaElement.id === mediaId);
    }

}