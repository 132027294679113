import React, { useContext, useEffect, useRef, useState } from "react";
import { IUserModel, UserModel } from "../../shared/models/user.model";
import { useFormik } from "formik";
import { Button, ButtonColor, ButtonSize } from "../button";
import { SvgVariant } from "../icons/enum/svg-variant.enum";
import { BinIcon } from "../icons/collection/bin-icon";
import { css, StyleSheet } from "aphrodite/no-important";
import { CssSize, StylesHelper } from "../../shared/helpers/styles.helper";
import { UserService } from "../../shared/services/user.service";
import { UsersActions } from "../../shared/redux/users/users.actions";
import { connect, shallowEqual } from "react-redux";
import { ConfirmIcon } from "../icons/collection/confirm-icon";
import { UserStatus } from "../../shared/constants/user-status.enum";
import { DeleteUserDialog } from "./delete-user-dialog";
import { DisableIcon } from "../icons/collection/disable-icon";
import { EnableIcon } from "../icons/collection/enable-icon";
import { Dropdown } from "../dropdown/dropdown";
import { UserRole, UserRolesOptions } from "../../shared/constants/user-role.enum";
import { useUser } from "../../contexts/user.context";
import { TableContext } from "../../contexts/table.context";
import { UserValidationSchema } from "../form/validation-schema/user.validation-schema";

interface IUserTableRowProps {
  userModel: UserModel,
  autoFocus: boolean,
  index: number,
  createUser: (user: IUserModel, index: number) => void,
  updateUser: (user: Partial<IUserModel>, index: number) => void,
  deleteUser: (email: string, index: number) => void,
  enableUser: (email: string, index: number) => void,
  disableUser: (email: string, index: number) => void,
}

export const UserTableRow = React.memo((props: IUserTableRowProps) => {
  const {
    userModel = new UserModel(),
    autoFocus = false,
    index,
    createUser,
    updateUser,
    deleteUser,
    enableUser,
    disableUser
  } = props;

  const { sortByColumn } = useContext(TableContext);

  const { user: currentUser } = useUser();

  const [dropdownLabel, setDropdownLabel] = useState(null);
  const [dropdownValue, setDropdownValue] = useState(null);

  const [showDeleteDialog, setShowDeleteDialog] = useState(false);

  const emailInputRef = useRef<HTMLInputElement>();
  const [initialValues, setInitialValues] = useState({
    email: "",
    firstName: "",
    lastName: "",
    role: UserRole.Unknown
  });

  useEffect(() => {
    if (autoFocus) {
      emailInputRef.current.focus();
    }
  }, [autoFocus]);

  useEffect(() => {
    setInitialValues({
      email: !!userModel?.email ? userModel?.email : "",
      firstName: !!userModel?.firstName ? userModel?.firstName : "",
      lastName: !!userModel?.lastName ? userModel?.lastName : "",
      role: !!userModel?.role ? userModel?.role : UserRole.Unknown
    });
  }, [userModel]);

  const formik = useFormik({
    initialValues,
    enableReinitialize: true,
    validateOnBlur: true,
    validationSchema: UserValidationSchema,
    onSubmit: () => {
      const formikValues = formik.values;
      if (formik.dirty) {
        userModel?.principalId ? updateUser(formikValues, index) : createUser(formikValues as IUserModel, index);
      }
    }
  });


  const handleUserUpdate = () => {
    const dropdownValue = formik.values.role;
    const { label, value } = UserRolesOptions.find((option) => option.value === dropdownValue);

    setDropdownLabel(label);
    setDropdownValue(value);

    const roleHasChanged = !shallowEqual(initialValues.role, formik.values.role);
    if (roleHasChanged && userModel?.principalId) {
      formik.submitForm();
    }
  };

  useEffect(handleUserUpdate, [formik.values, userModel]);

  const handleBlur = (eventOrString) => {
    formik.handleBlur(eventOrString);

    if (userModel?.principalId) {
      formik.submitForm();
    }
  };

  const inputProps = {
    onChange: formik.handleChange,
    onBlur: handleBlur
  };

  const onDialogControlClick = (value: boolean) => {
    if (value) {
      deleteUser(formik.values.email, index);
    }

    setShowDeleteDialog(false);
  };

  const statusClass = () => {
    if (userModel?.status === UserStatus.Locked) {
      return styles.statusLocked;
    } else if (userModel?.status === UserStatus.Active) {
      return styles.statusConfirmed;
    } else {
      return styles.statusInProgress;
    }
  };

  const readOnlyWhenCurrentUser = () => {
    return userModel?.principalId === currentUser?.username;
  };

  const actionButtonByStatus = () => {
    if (userModel?.status === UserStatus.Locked) {
      return (
        <Button type={"button"}
                btnClass={"no-padding fullwidth alignCenter"}
                size={ButtonSize.Medium}
                color={ButtonColor.Basic}
                disabled={readOnlyWhenCurrentUser()}
                onClick={() => enableUser(formik.values?.email, index)}>
          <EnableIcon
            viewBox='0 0 40 40'
            color={SvgVariant.Secondary}
            titleAccess={"Benutzer aktivieren"}
          />
        </Button>
      );
    } else if (userModel?.status === UserStatus.Active) {
      return (
        <Button type={"button"}
                btnClass={"no-padding fullwidth alignCenter"}
                size={ButtonSize.Medium}
                color={ButtonColor.Basic}
                disabled={readOnlyWhenCurrentUser()}
                onClick={() => disableUser(formik.values?.email, index)}>
          <DisableIcon
            viewBox='0 0 40 40'
            color={SvgVariant.Secondary}
            titleAccess={"Benutzer deaktivieren"}
          />
        </Button>
      );
    } else {
      return (
        <Button type={"button"}
                btnClass={"no-padding fullwidth alignCenter"}
                size={ButtonSize.Medium}
                color={ButtonColor.Basic}
                disabled={!formik.isValid}
                onClick={() => formik.submitForm()}>
          <ConfirmIcon
            viewBox='0 0 40 40'
            color={SvgVariant.Secondary}
            titleAccess={"Benutzer erstellen"}
          />
        </Button>
      );
    }
  };

  return (
    <tr className={css(styles.tr)}>
      <td className={css([styles.td, styles.tdSpacingRight]) + ("email" === sortByColumn ? " is-sorted" : "")}>
        <div>
          <input ref={emailInputRef}
                 type="email"
                 name={"email"}
                 data-touched={formik.getFieldMeta("email").touched}
                 data-error={formik.getFieldMeta("email").error}
                 className={css((formik.getFieldMeta("email").touched && formik.getFieldMeta("email").error) && styles.validationError)}
                 placeholder={"Bitte E-Mail eingeben"}
                 value={formik.values?.email}
                 disabled={!!userModel?.principalId}
                 required={true}
                 {...inputProps}/>
        </div>
      </td>
      <td className={css([styles.td, styles.tdSpacingRight]) + ("firstname" === sortByColumn ? " is-sorted" : "")}>
        <div>
          <input type="text"
                 name={"firstName"}
                 className={css((formik.getFieldMeta("firstName").touched && formik.getFieldMeta("firstName").error) && styles.validationError)}
                 placeholder={"Bitte Vornamen eingeben"}
                 value={formik.values?.firstName}
                 required={true}
                 {...inputProps}/>
        </div>
      </td>
      <td className={css([styles.td, styles.tdSpacingRight]) + ("lastName" === sortByColumn ? " is-sorted" : "")}>
        <div>
          <input type="text"
                 name={"lastName"}
                 className={css((formik.getFieldMeta("lastName").touched && formik.getFieldMeta("lastName").error) && styles.validationError)}
                 placeholder={"Bitte Nachnamen eingeben"}
                 value={formik.values?.lastName}
                 required={true}
                 {...inputProps}/>
        </div>
      </td>
      <td className={css([styles.td, styles.tdSpacingRight]) + ("role" === sortByColumn ? " is-sorted" : "")}>
        <Dropdown
          btnClass={`fullwidth ${!dropdownValue && "readonly-label"} ` + css((formik.getFieldMeta("role").touched && formik.getFieldMeta("role").error) && styles.validationError)}
          dropdownClass={css(styles.roleDropdownContent)}
          collapseOnChange={true}
          buttonDisabled={readOnlyWhenCurrentUser()}
          buttonLabel={dropdownLabel}>
          {UserRolesOptions.map((option) => {
            return (
              <Button
                key={option.value}
                onClick={() => formik.setFieldValue("role", option.value)}
                btnClass={"fullwidth radius-none"}
                color={ButtonColor.GhostSelect}
                value={formik.values?.role}
                label={option.label}/>
            );
          })}
        </Dropdown>
      </td>
      <td className={css([styles.td, styles.tdSpacingRight]) + ("status" === sortByColumn ? " is-sorted" : "")}>
        <div>
          <p className={css(styles.status, statusClass())}>
            {userModel?.status}
          </p>
        </div>
      </td>
      <td className={css([styles.td, styles.alignTop, styles.iconCol1])}>
        <div className={css(styles.iconContainer)}>
          {actionButtonByStatus()}
        </div>
      </td>
      <td className={css([styles.td, styles.alignTop, styles.iconCol2])}>
        <div className={css(styles.iconContainer)}>
          <Button type={"button"}
                  btnClass={"no-padding fullwidth alignCenter"}
                  size={ButtonSize.Medium}
                  color={ButtonColor.Basic}
                  disabled={readOnlyWhenCurrentUser()}
                  onClick={() => setShowDeleteDialog(true)}>
            <BinIcon
              viewBox='0 0 40 40'
              color={SvgVariant.Secondary}
              titleAccess={"Benutzer löschen"}
            />
          </Button>
        </div>
        <DeleteUserDialog onControlClick={onDialogControlClick} showDialog={showDeleteDialog}/>
      </td>
    </tr>
  );
});

const styles = StyleSheet.create({
  tr: {
    ":last-child > td": {
      paddingBottom: 0
    }
  },
  td: {
    paddingBottom: CssSize.s10
  },
  tdSpacingRight: {
    paddingRight: CssSize.s10
  },
  iconCol1: {
    width: 50,
    paddingRight: CssSize.s10
  },
  iconCol2: {
    width: 40
  },
  iconContainer: {
    width: 40
  },
  alignTop: {
    verticalAlign: "top"
  },
  status: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    padding: CssSize.s10,
    marginBottom: 0,
    borderRadius: CssSize.s05,
    minHeight: CssSize.s40,
    ...StylesHelper.insetBorder("var(--lighter-grey)", 2),
    "::after": {
      content: "\"\"",
      display: "block",
      borderRadius: "50%",
      width: CssSize.s10,
      height: CssSize.s10
    }
  },
  statusInProgress: {
    "::after": {
      backgroundColor: "var(--warning)"
    }
  },
  statusLocked: {
    "::after": {
      backgroundColor: "var(--danger)"
    }
  },
  statusConfirmed: {
    "::after": {
      backgroundColor: "var(--success)"
    }
  },
  roleDropdownContent: {
    minWidth: "100%",
    padding: "0 !important"
  },
  validationError: {
    ...StylesHelper.insetBorder("var(--danger)", 2)
  }
});

const mapDispatchToProps = (dispatch: any) => ({
  createUser: async (user: IUserModel, index: number) => {
    await UserService.create(user);
    dispatch(UsersActions.create(user, index));
    const usersMetaRes = await UserService.updateMeta();
    dispatch(UsersActions.setUsersMeta(usersMetaRes?.data?.updatedAt, usersMetaRes?.data?.updatedByTitle));
  },
  updateUser: async (user: Partial<IUserModel>, index: number) => {
    await UserService.update(user);
    dispatch(UsersActions.update(user, index));
    const usersMetaRes = await UserService.updateMeta();
    dispatch(UsersActions.setUsersMeta(usersMetaRes?.data?.updatedAt, usersMetaRes?.data?.updatedByTitle));
  },
  deleteUser: async (email: string, index: number) => {
    await UserService.delete(email)
    dispatch(UsersActions.delete(index));
    const usersMetaRes = await UserService.updateMeta();
    dispatch(UsersActions.setUsersMeta(usersMetaRes?.data?.updatedAt, usersMetaRes?.data?.updatedByTitle));
  },
  enableUser: async (email: string, index: number) => {
    await UserService.enable(email);
    dispatch(UsersActions.enable(index));
    const usersMetaRes = await UserService.updateMeta();
    dispatch(UsersActions.setUsersMeta(usersMetaRes?.data?.updatedAt, usersMetaRes?.data?.updatedByTitle));
  },
  disableUser: async (email: string, index: number) => {
    await UserService.disable(email)
    dispatch(UsersActions.disable(index));
    const usersMetaRes = await UserService.updateMeta();
    dispatch(UsersActions.setUsersMeta(usersMetaRes?.data?.updatedAt, usersMetaRes?.data?.updatedByTitle));
  }
});

export const UserTableRowWithStore = connect(null, mapDispatchToProps)(UserTableRow);
