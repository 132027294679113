import { IState } from '../../shared/interfaces/state.interface';
import { DatasetHistory } from './dataset-history';
import { connect } from 'react-redux';
import { selectRegionsUpdatedAt, selectRegionsUpdatedBy } from '../../shared/redux/regions/regions.selectors';

const mapStateToProps = (state: IState) => {
  return {
    updatedAt: selectRegionsUpdatedAt(state),
    updatedBy: selectRegionsUpdatedBy(state)
  };
};

export const RegionsDatasetHistory = connect(mapStateToProps, null)(DatasetHistory);