import { FieldTabArrayConfig } from "../config/field-tab-array.config";
import { AffiliateDataService } from "../../../shared/services/affiliate-data.service";

export class RelationshipInputFieldFactory {
  static TwoTabsWithFormerCollapsibleFactory(name: string, label: string, labelPlural: string, selector: Function, selectorFormer: Function, tabFactory: Function, apiPath:string = null) {
    return FieldTabArrayConfig.getConfig({
      name: name,
      addLabel: label + " hinzufügen",
      transformMessage: "Wenn sie auf „Umwandeln“ klicken wird das „" + label + "“ zum „Alt-" + label + "“ umgewandelt.",
      tabs: [
        tabFactory(name, labelPlural, selector, true),
        tabFactory("former" + name, "Alt-" + labelPlural, selectorFormer, true)
      ],
      onDataAdd: async (routeParam: string | number) =>
        await AffiliateDataService.addData(routeParam, apiPath ?? name),
      onDataTransform: async (routeParam: string | number, identifier: string) =>
        await AffiliateDataService.updateData(routeParam, apiPath ?? name, identifier, {
          id: identifier,
          isFormer: true
        }),
      onDataDelete: async (routeParam: string | number, identifier: string) =>
        await AffiliateDataService.deleteData(routeParam, apiPath ?? name, identifier, {
          id: identifier,
        })
    }, FieldTabArrayConfig.RELATIONS_CONFIG);
  }
}
