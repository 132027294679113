export function associateSection(index: number, size: number) {
  const variations = {
    single: 'full',
    double: ['left', 'right'],
    tripple: ['left', 'middle', 'right'],
  };

  switch (size) {
    case 1:
      return variations.single;
    case 2:
      return variations.double[index];
    case 3:
    default:
      return variations.tripple[index];
  }
}
