import React, {ReactNode, useEffect, useRef, useState} from 'react';
import { useClickOutside } from '../../hooks/use-click-outside';
import { useEndOfViewport } from '../../hooks/use-end-of-viewport';
import { ChevronDownIcon } from '../icons/collection/chevron-down-icon';
import { ButtonIconPosition, ButtonSize, Button, ButtonColor } from '../button';
import { SvgVariant } from '../icons/enum/svg-variant.enum';

import './dropdown.scss';

interface IDropdownProps {
  buttonLabel: string;
  buttonIconPosition?: ButtonIconPosition;
  buttonSize?: ButtonSize;
  btnClass?: string;
  buttonIcon?: ReactNode;
  buttonDisabled?: boolean;
  collapseOnChange?: boolean;
  dropdownClass?: string;
  children: ReactNode
}

export const Dropdown = (props: IDropdownProps) => {
  const {
    buttonLabel,
    buttonIconPosition = ButtonIconPosition.Right,
    buttonSize = ButtonSize.Medium,
    btnClass,
    buttonIcon = <ChevronDownIcon color={SvgVariant.Secondary} viewBox={'0 0 40 40'} titleAccess={buttonLabel} />,
    dropdownClass,
    children,
    buttonDisabled = false,
    collapseOnChange
  } = props;

  const [isCollapsed, setCollapse] = useState<boolean>(true);
  const [dropdownAlignment, setDropdownAlignment] = useState('left');

  const dropdownRef = useRef(null);
  const expandWrapper = !isCollapsed ? 'expand' : '';

  const handleButtonClick = () => {
    setCollapse(!isCollapsed);
  };

  useEndOfViewport(dropdownRef, 10, (isEndOfViewport: boolean) => {
    setDropdownAlignment(isEndOfViewport ? 'right' : 'left');
  });

  useClickOutside(dropdownRef, (clickedOutside) => {
    setCollapse(clickedOutside);
  });

  useEffect(() => {
    if (collapseOnChange) {
      setCollapse(true);
    }
  }, [collapseOnChange, buttonLabel])

  return (
    <div className='dropdown'
      ref={dropdownRef}>
      <Button onClick={handleButtonClick}
        size={buttonSize}
        color={ButtonColor.Basic}
        borderRadius={'small'}
        isPressed={!isCollapsed}
        label={buttonLabel}
        disabled={buttonDisabled}
        iconPosition={buttonIconPosition}
        btnClass={btnClass}>
        {buttonIcon}
      </Button>
      <div className={`dropdown-content-wrapper ${dropdownClass} ${expandWrapper} ${dropdownAlignment}`}>
        {children}
      </div>
    </div>
  )
}
