import { ActiveFilterModel } from "../../../shared/models/active-filter.model";
import React, { ReactNode } from "react";
import { LogService } from "../../../shared/services/log.service";
import { List } from "immutable";
import { Button, ButtonColor, ButtonSize } from "../../button";
import { DeleteIcon } from "../../icons/collection/delete-icon";
import { SvgVariant } from "../../icons/enum/svg-variant.enum";
import { useI18nLabelFromObject } from "../../../hooks/use-i18n-label-from-object";

interface IFilterNodeProps {
  filter: ActiveFilterModel;
  resetFilter: (groupKey: string) => void
}

export const FilterNode = (props: IFilterNodeProps) => {
  const { filter, resetFilter} = props;

  const filterJS = filter.toJS();

  const i18n = filterJS.meta?.i18n;
  const valueKey = filterJS.meta?.valueKey;
  const labelKey = filterJS?.meta?.labelKey;
  const value = filterJS.value;
  const valueObject = filterJS.valueObject;
  const i18nLabel = useI18nLabelFromObject({i18n, valueKey, labelKey, value, valueObject});

  const buildNodeByFilterKey = (filter: ActiveFilterModel): ReactNode => {

    if(!!filter.from || !!filter.to) {
      return renderRangeFilterNode(filter);
    }
    else if(!!filter.list && Object.keys(filter.list).length !== 0) {
      return renderListFilterNode(filter);
    }
    else if(!!filter.value) {
      return renderSingleValueFilterNode(filter);
    }
    else {
      LogService.error('filter-selection: No GroupKey defined - to build query params!');
      return;
    }
  }

  const renderListFilterNode = (filter: ActiveFilterModel): ReactNode => {
    const getListLabel = (list: List<Map<string, number | string>>): string => {
      return list.map((data) => data.get('label') as any)?.toArray()?.join(', ');
    };
    const label = `${filter.groupLabel} (${getListLabel(filter.list)})`;

    return renderFilterNode(label, filter);
  }

  const renderSingleValueFilterNode = (filter: ActiveFilterModel): ReactNode => {

    const label=`${filter.groupLabel} (${i18nLabel()})`;

    return renderFilterNode(label, filter);
  }

  const renderRangeFilterNode = (filter: ActiveFilterModel): ReactNode => {
    const filterValues = [filter.from, filter.to].filter((value) => !!value).map((value) => {
      if(value instanceof Date) {
        return value.toLocaleDateString();
      }

      return value;
    });
    const label = `${filter.groupLabel} (${filterValues.join(' – ')})`;

    return renderFilterNode(label, filter);
  }

  const renderFilterNode = (label, filter: ActiveFilterModel): ReactNode => {
    return (
      <li>
        <Button onClick={() => resetFilter?.(filter.groupKey)}
                size={ButtonSize.Small}
                color={ButtonColor.Warning}
                borderRadius={'large'}
                label={label}>
          <DeleteIcon color={SvgVariant.White} viewBox={'0 0 30 30'} titleAccess={`${filter.groupLabel}`} />
        </Button>
      </li>
    )
  }

  return <>{buildNodeByFilterKey(filter)}</>;
}
