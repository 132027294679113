import { IState } from '../../interfaces/state.interface';
import { createSelector } from 'reselect';
import { List } from 'immutable';
import { PersonGroupModel } from '../../models/person-group.model';

const basePersonGroupSelector = ['personGroups'];

const selectPersonGroupsBase = (state: IState): IState => state.getIn([...basePersonGroupSelector]);

export const selectPersonGroupsUpdatedAt = createSelector(
  [selectPersonGroupsBase],
  (personGroupState) => {
    return personGroupState.get('updatedAt');
  }
);

export const selectPersonGroupsUpdatedBy = createSelector(
  [selectPersonGroupsBase],
  (personGroupState) => {
    return personGroupState.get('updatedByTitle');
  }
);

export const selectPersonGroups = createSelector(
  [selectPersonGroupsBase],
  (personGroupState) => {
    return personGroupState.get('data')
  }
);

export const selectPersonGroupById = (personGroupId: string) => createSelector(
  [selectPersonGroupsBase],
  (personGroupState) => {
    const personGroups =  personGroupState.get('data') as List<PersonGroupModel>;
    return personGroups.find((personGroup) => personGroup.id === personGroupId);
  }
);

export const selectPropertiesCountByPersonGroup = (personGroupId: string) => createSelector(
  [selectPersonGroupById(personGroupId)],
  (personGroup) => {
    return personGroup?.properties?.length;
  }
);
