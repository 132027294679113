import { HttpService } from './http.service';
import { IPropertyMedia } from '../interfaces/property-media.interface';
import { ApiEndpointHelper } from '../helpers/api-endpoint.helper';

interface IPropertyMediaResponse<TData> {
    data: TData;
    meta?: any;
    message: string;
    status: number
}

export enum MoveMediaPosition {
    Up = -1,
    Down = 1,
};

export class MediaDataService {
    //IMAGES

    static async saveImagesByPropertyId(images: IPropertyMedia[], propertyId: number): Promise<any> {
        return HttpService.performPostRequest(`${ApiEndpointHelper.getMediaImagePath(propertyId)}`, images);
    }

    static async fetchImagesByPropertyId(propertyId: number): Promise<any> {
        return HttpService.performGetRequest(`${ApiEndpointHelper.getMediaImagePath(propertyId)}`);
    }

    static async deleteImage(image: IPropertyMedia, propertyId: number): Promise<any> {
        return HttpService.performDeleteRequest(`${ApiEndpointHelper.getMediaImagePath(propertyId)}/${image.id}`);
    }

    static async updateImage(image: IPropertyMedia): Promise<any> {
        return HttpService.performPutRequest(`${ApiEndpointHelper.getMediaImagePath(image?._propertyId)}/${image.id}`, image);
    }

    static async updateImagePosition(move: MoveMediaPosition, imageId: string, propertyId: number): Promise<any> {
        return HttpService.performPutRequest(`${ApiEndpointHelper.getMediaImagePath(propertyId)}/${imageId}/position`, { move });
    }

    //VIDEOS

    static async saveVideosByPropertyId(videos: IPropertyMedia[], propertyId: number): Promise<any> {
        return HttpService.performPostRequest(`${ApiEndpointHelper.getMediaVideoPath(propertyId)}`, videos);
    }

    static async fetchVideosByPropertyId(propertyId: number): Promise<any> {
        return HttpService.performGetRequest(`${ApiEndpointHelper.getMediaVideoPath(propertyId)}`);
    }

    static async deleteVideo(video: IPropertyMedia, propertyId: number): Promise<any> {
      return HttpService.performDeleteRequest(`${ApiEndpointHelper.getMediaVideoPath(propertyId)}/${video.id}`);
    }

    static async updateVideoPosition(move: MoveMediaPosition, imageId: string, propertyId: number): Promise<IPropertyMediaResponse<IPropertyMedia[]>> {
        return HttpService.performPutRequest(`${ApiEndpointHelper.getMediaVideoPath(propertyId)}/${imageId}/position`, { move });
    }
}
