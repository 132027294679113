import { css, StyleSheet } from "aphrodite/no-important";
import { LoadingSpinnerBlueIcon } from "../icons";
import React from "react";
import { CssSize } from "../../shared/helpers/styles.helper";

export const Loader = () => {
  return <div className={css(styles.loadingContainer)}>
    <div className={`${css(styles.loadingIconContainer)} icon-container`}>
      <LoadingSpinnerBlueIcon titleAccess='Test'
                              viewBox={"0 0 16 16"}
                              className='loading'/>
    </div>
  </div>;
};

const styles = StyleSheet.create({
  loadingContainer: {},
  loadingIconContainer: {
    width: CssSize.s20,
    zIndex: 1,
    position: "absolute",
    left: "50%",
    top: "50%",
    transform: "translateX(-50%) translateY(-50%)"
  }
});

export default Loader;
