import { IState } from '../../shared/interfaces/state.interface';
import { DatasetHistory } from './dataset-history';
import { connect } from 'react-redux';
import { selectUsersUpdatedAt, selectUsersUpdatedBy } from '../../shared/redux/users/users.selector';

const mapStateToProps = (state: IState) => {
  return {
    updatedAt: selectUsersUpdatedAt(state),
    updatedBy: selectUsersUpdatedBy(state)
  };
};

export const UserDatasetHistory = connect(mapStateToProps, null)(DatasetHistory);
