import { IState } from '../../interfaces/state.interface';
import { createSelector } from 'reselect';
import {LoadingStatus} from "../../constants/loading-status.enum";

const baseUserSelector = ['users'];

const selectUsersBase = (state: IState): IState => state.getIn([...baseUserSelector]);

export const selectUsers = createSelector(
  [selectUsersBase],
  (usersState) => {
    return usersState.get('data')
  }
)

export const selectIsUsersLoading = (state: IState): LoadingStatus => state.getIn([...baseUserSelector, 'loadingStatus']);

export const selectUsersUpdatedAt = createSelector(
  [selectUsersBase],
  (usersState) => {
    return usersState.get('updatedAt')
  }
)

export const selectUsersUpdatedBy = createSelector(
  [selectUsersBase],
  (usersState) => {
    return usersState.get('updatedByTitle')
  }
)
