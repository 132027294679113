import React, { useEffect, useState } from "react";
import { css, StyleSheet } from "aphrodite";
import { CssSize } from "../../../shared/helpers/styles.helper";
import { MediaElementModel } from "../../../shared/models/media-element.model";
import { FileService } from "../../../shared/services/file-service";

export interface S3VideoPreviewProps {
  mediaElement: MediaElementModel
}

export const S3VideoPreview = React.memo((props: S3VideoPreviewProps) => {
  const [s3VideoUrl, setS3VideoUrl] = useState<string>(null);

  useEffect(() => {
    FileService.get(props.mediaElement.s3Key)
      .then(result => {
        if (typeof result === "string") {
          setS3VideoUrl(result);
        }
      })
      .catch(err => console.error(err));

  }, [props.mediaElement.s3Key]);

  return <div className={css(styles.container)}>
    {s3VideoUrl && <video className={css(styles.video)} autoPlay={false} controls={false} muted={true} preload={'meta'}>
      {[s3VideoUrl].map((video) => <source key={video} src={video} type={props?.mediaElement?.fileType} />)}
    </video>
    }
  </div>
});

const styles = StyleSheet.create({
  container: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    overflow: "hidden",
    height: CssSize.s60,
    width: "95px"
  },
  video: {
    width: "100%",
    objectFit: "contain"
  },
});
