import { IAction } from '../../interfaces/action.interface';
import { IIntroTextsParams } from '../../models/intro-text.model';
import { IntroTextsActionTypes } from './intro-texts.action-types';

export abstract class IntroTextActions {
  static update(template: IIntroTextsParams): any {
    return {
      type: IntroTextsActionTypes.UPDATE_INTRO_TEXT,
      payload: template
    }
  }

  static create(payload: IIntroTextsParams): IAction {
    return {
      type: IntroTextsActionTypes.CREATE_INTRO_TEXT,
      payload
    };
  }

  static set(payload: IIntroTextsParams[]): IAction {
    return {
      type: IntroTextsActionTypes.SET_INTRO_TEXTS,
      payload
    }
  }

  static delete(id: string): IAction {
    return {
      type: IntroTextsActionTypes.DELETE_INTRO_TEXT,
      payload: id
    }
  }

  static addPostalCode(introTextId: string, postalCode: string, updatedByTitle: string): IAction {
    return {
      type: IntroTextsActionTypes.ADD_POSTAL_CODE,
      payload: { postalCode, introTextId, updatedByTitle }
    }
  }

  static removePostalCode(introTextId: string, postalCode: string, updatedByTitle: string): IAction {
    return {
      type: IntroTextsActionTypes.REMOVE_POSTAL_CODE,
      payload: { postalCode, introTextId, updatedByTitle }
    }
  }

}
