import React from 'react';

export type OnSelectFn = (selectedValue: string) => void;
export type GetSuggestionsFn = (searchTerm: string) => Promise<string[]>;

interface ISuggestionDropdownContext {
  buttonLabel: string;
  placeholderText: string;
  noDataText: string;
  maxInputLength?: number;
  selectedSuggestions?: string[];
  onSelect: OnSelectFn;
  getSuggestions: GetSuggestionsFn;
}

const SUGGESTION_DROPDOWN_DEFAULT_VALUE: ISuggestionDropdownContext = {
  buttonLabel: null,
  placeholderText: null,
  noDataText: 'Keine Ergebnisse gefunden.',
  maxInputLength: null,
  selectedSuggestions: null,
  onSelect: null,
  getSuggestions: null
};

export const SuggestionDropdownContext = React.createContext(SUGGESTION_DROPDOWN_DEFAULT_VALUE);
