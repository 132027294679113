import { IState } from '../../interfaces/state.interface';
import { PropertyModel } from '../../models/property.model';
import { createSelector } from '../../utils/reducer';
import { IPlace } from "../../interfaces/place.interface";
import { AffiliateModel } from "../../models/affiliate.model";
import { TenancyModel } from "../../models/tenancy.model";
import { ISelectableObject } from "../../interfaces/selectable.interface";
import { OwnerModel } from "../../models/owner.model";

const basePropertySelector = ['property'];

export const selectProperty = (state: IState): PropertyModel => state.getIn([...basePropertySelector, 'data']);
export const selectIsLoading = (state: IState): PropertyModel => state.getIn([...basePropertySelector, 'isLoading']);

export const selectPropertyWithAddress = createSelector(
  [selectProperty],
  (property: PropertyModel) => {
    return property.merge({
      // TODO: https://webartig.atlassian.net/browse/PUB-415
      addresses: property.addresses.map((address: IPlace) => {
        let fullAddress = '';
        if (address.street && address.postalCode && address.location) {
          fullAddress = `${address?.street} ${address.streetNumber}, ${address?.postalCode} ${address?.location}`;
        }

        return {
          ...address,
          address: fullAddress
        };
      })
    });
  }
);

export const selectPropertyId = createSelector(
  [selectProperty],
  (property) => {
    return property.propertyId;
  }
);

export const selectPropertyTitle = createSelector(
  [selectProperty],
  (property) => {
    return property.title;
  }
);

export const selectPropertyUpdatedAt = createSelector(
  [selectProperty],
  (property) => {
    return property.updatedAt;
  }
);

export const selectPropertyUpdatedBy = createSelector(
  [selectProperty],
  (property) => {
    return property.updatedByTitle;
  }
);

export const selectPropertyStatus = createSelector(
  [selectProperty],
  (property) => {
    return property.status;
  }
);

export const selectPropertyHasImages = createSelector(
  [selectProperty],
  (property) => {
    return property.hasImages;
  }
);

export const selectPropertyOwners = (state: IState): OwnerModel[] => selectProperty(state)?.owners;

export const selectPropertyOwnersAffiliateSelectorObject = (state: IState, id: number): ISelectableObject => {
  const ownerList: OwnerModel[] = selectPropertyOwners(state);
  const owner: OwnerModel = ownerList?.find((owner) => owner._affiliateId === id);
  return AffiliateModel.getSelectableObject(owner?.affiliate);
};

export const selectPropertyOwnersAgentSelectorObject = (state: IState, id: number, agentSuffix = ''): ISelectableObject => {
  const ownerList: OwnerModel[] = selectPropertyOwners(state);
  const owner: OwnerModel = ownerList?.find((owner) => owner?.["_agentId" + agentSuffix] === id);
  return AffiliateModel.getSelectableObject(owner?.["agent" + agentSuffix]);
};

export const selectPropertyTenancies = (state: IState): TenancyModel[] => selectProperty(state)?.tenancies;

export const selectPropertyTenanciesAffiliateSelectorObject = (state: IState, id: number): ISelectableObject => {
  const tenancyList: TenancyModel[] = selectPropertyTenancies(state);
  const tenancy: TenancyModel = tenancyList?.find((tenancy) => tenancy._affiliateId === id);
  return AffiliateModel.getSelectableObject(tenancy?.affiliate);
};

export const selectPropertyTenanciesAgentSelectorObject = (state: IState, id: number, agentSuffix = ''): ISelectableObject => {
  const tenancyList: TenancyModel[] = selectPropertyTenancies(state);
  const tenancy: TenancyModel = tenancyList?.find((tenancy) => tenancy?.["_agentId" + agentSuffix] === id);
  return AffiliateModel.getSelectableObject(tenancy?.["agent" + agentSuffix]);
};
