import React, { useContext, useEffect, useRef, useState } from 'react';
import { css, StyleSheet } from 'aphrodite/no-important';
import { useClickOutside } from '../../../hooks/use-click-outside';
import { SvgVariant } from '../../icons/enum/svg-variant.enum';
import { ChevronDownIcon } from '../../icons/collection/chevron-down-icon';
import { useField } from 'formik';
import { IFieldSingle } from '../interfaces/field-single.interface';
import { FieldArrayContext } from '../context/field-array.context';

export const Select = (props: IFieldSingle) => {
  const {
    name,
    options,
    changeHandler,
    isFallback,
    fallbackName,
    readonly
  } = props;
  const selectRef = useRef(null);
  const [ showOptions, setShowOptions ] = useState(false);
  const [ optionLabel, setOptionLabel ] = useState('');
  const [ field ] = useField(name);

  const { existingDataRegistry } = useContext(FieldArrayContext);

  useEffect(() => {
    const activeOption = options?.find((option) => option.value === field.value);

    setOptionLabel(activeOption?.label);
  }, [field.value, options])

  const handleOptionSelect = (value: string | boolean) => {
    if(readonly) {
      return;
    }

    changeHandler({
      name,
      value,
      isFallback,
      fallbackName
    });
    setShowOptions(false);
  }

  useClickOutside(selectRef, (clickedOutside) => {
    setShowOptions(!clickedOutside);
  })

  return (
    <div ref={selectRef} className={css(styles.selectWrapper)}>
      <button type='button'
              onClick={() => setShowOptions(!showOptions)}
              className={css(styles.selectButton)}>
        <div className={css(styles.buttonInner)}>
        {optionLabel}
        <ChevronDownIcon
          className={css(styles.buttonSvg)}
          style={showOptions ? { transform: 'rotate(180deg)' } : null}
          viewBox='0 0 40 40'
          color={SvgVariant.Secondary}
        />
        </div>
      </button>
      <ul className={css(styles.selectOptions, showOptions ? styles.showOptions : null)}>
        {options?.map((option) => {
          return (
            <li key={option.value}
                className={css(styles.selectOptionItem)}>
              <button type='button'
                      disabled={readonly || existingDataRegistry?.includes(option.value)}
                      className={css(styles.selectOptionItemButton)}
                      onClick={() => handleOptionSelect(option.value)}>
                {option.label}
              </button>
            </li>
          );
        })}
      </ul>
    </div>
  );
}

const styles = StyleSheet.create({
  selectWrapper: {
    width: '100%',
    position: 'relative'
  },
  selectButton: {
    color: 'var(--black)',
    backgroundColor: 'var(--ice-primary)',
    borderRadius: 'var(--base-radius)',
    whiteSpace: 'nowrap',
    display: 'block',
    width: '100%',
    overflow: 'hidden',
    ':focus': {
      outline: 'none'
    },
    ':hover': {
      '@media (hover: hover)': {
        backgroundColor: 'var(--lighter-primary)',
      }
    }
  },
  buttonInner: {
    width: '100%',
    textAlign: 'left',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    position: 'relative',
    paddingLeft: 'var(--size-10)',
  },
  buttonSvg: {
    width: 'var(--size-40)',
    height: 'var(--size-40)',
  },
  selectOptions: {
    position: 'absolute',
    left: '0',
    top: 'calc(100% + var(--size-05))',
    zIndex: 1,
    minWidth: '100%',
    borderRadius: 'var(--base-radius)',
    overflow: 'hidden',
    overflowY: 'auto',
    maxHeight: '0px',
    opacity: 0,
    visibility: 'hidden',
    filter: 'var(--base-drop-shadow-small)',
    willChange: 'filter, opacity, visibility'
  },
  showOptions: {
    opacity: 1,
    visibility: 'visible',
    maxHeight: '275px'
  },
  selectOptionItem: {
    width: '100%'
  },
  selectOptionItemButton: {
    width: '100%',
    textAlign: 'left',
    padding: 'var(--size-10)',
    color: 'var(--black)',
    backgroundColor: 'var(--white)',
    ':disabled': {
      color: 'var(--grey)',
      cursor: 'not-allowed',
      ':hover': {
        '@media (hover: hover)': {
          backgroundColor: 'var(--white)',
        }
      }
    },
    ':focus': {
      outline: 'none'
    },
    ':hover': {
      '@media (hover: hover)': {
        backgroundColor: 'var(--lighter-primary)',
      }
    }
  }
});
