import { IRemarkParams } from "../../../models/remark.model";
import { IAction } from "../../../interfaces/action.interface";
import { PropertyTextsActionTypes } from "./property-texts.action-types";
import { IPropertyIntroTextsParams } from "../../../models/property-intro-text.model";

export abstract class PropertyTextsActions {

    static setRemarks(remarks: IRemarkParams[]): IAction {
        return {
            type: PropertyTextsActionTypes.SET_REMARKS,
            payload: remarks
        };
    }

    static addRemark(remark: IRemarkParams): IAction {
        return {
            type: PropertyTextsActionTypes.ADD_REMARK,
            payload: remark
        };
    }

    static updateRemark(remark: IRemarkParams): IAction {
        return {
            type: PropertyTextsActionTypes.UPDATE_REMARK,
            payload: remark
        };
    }

    static deleteRemark(id: string): IAction {
        return {
            type: PropertyTextsActionTypes.DELETE_REMARK,
            payload: id
        };
    }

    static setIntroTexts(payload): IAction {
        return {
            type: PropertyTextsActionTypes.SET_INTROTEXTS,
            payload
        }
    }

    static toggleShowIntroTextInWatchlist(value: boolean): IAction {
        return {
            type: PropertyTextsActionTypes.TOGGLE_SHOW_INTROTEXT_IN_WATCHLIST,
            payload: value
        };
    }

    static updateOwnIntroText(data: IPropertyIntroTextsParams): IAction {
        return {
            type: PropertyTextsActionTypes.UPDATE_OWN_INTROTEXT,
            payload: data
        };
    }

    static setActiveIntroTextTemplate(value: string): IAction {
        return {
            type: PropertyTextsActionTypes.SET_ACTIVE_INTRO_TEXT_TEMPLATE,
            payload: value
        };
    }
}
