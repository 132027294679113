import { TemplateType } from '../enums/template-type.enum';
import { IFieldChangeEvent } from './field-single.interface';
import { FieldTypeUnion } from '../types/field-type.union';
import { IField } from "./field.interface";
import { IState } from "../../../shared/interfaces/state.interface";

export enum TitleBarNumberType {
  FLOAT,
  DECIMAL
}

export enum TitleBarDateType {
  Day,
  Month
}

export interface IFieldCollapsableTitleBar {
  replaceValue: string;
  title: string | ((state: IState, value, niceValue) => Promise<any>);
  numberType?: TitleBarNumberType
  dateType?: TitleBarDateType
}

export type FieldCollapsableTitleBar = Array<Array<IFieldCollapsableTitleBar>>

export interface IFieldCollapsable extends IField {
  templateType?: TemplateType;
  label?: string;
  value?: any;
  hideInputEnumeration?: boolean;
  titleBar?: FieldCollapsableTitleBar;
  fields: FieldTypeUnion[];
  changeHandler?: (change: IFieldChangeEvent) => void;
  showAdditionalButton?: boolean;
  initialExpand?: boolean;
}
