import React, {
  ReactNode,
  useContext,
  Children,
  cloneElement,
  isValidElement,
  Context
} from 'react';
import { Button, ButtonColor, ButtonIconPosition, ButtonSize } from '../../button';
import { ArrowRightIcon } from '../../icons/collection/arrow-right-icon';
import { CloseIcon } from '../../icons/collection/close-icon';
import { SvgVariant } from '../../icons/enum/svg-variant.enum';

interface IModalViewProps {
  viewTitle: string;
  closeEvent: () => void;
  children: ReactNode;
  gridClass: 'full' | 'two-column';
  backButtonLabel?: string;
  context?: Context<any>;
  view?: { prev: number, next: number[] };
}

export const ModalView = (props: IModalViewProps) => {
  const {
    viewTitle,
    backButtonLabel,
    closeEvent,
    children,
    gridClass,
    context,
    view
  } = props;

  const { setStep } = useContext(context);

  return (
    <>
      <div className='modal-header'>
        <div className="left-modal-header">
          {backButtonLabel?.length
            ? <Button
              onClick={() => setStep(view?.prev)}
              btnClass='modal-back-button'
              color={ButtonColor.Ghost}
              label={backButtonLabel}
              iconPosition={ButtonIconPosition.Left}
            >
              <ArrowRightIcon
                color={SvgVariant.Secondary}
                viewBox='0 0 40 40'
                style={{ transform: 'rotate(180deg)' }}
                titleAccess='Zurück'
              />
            </Button>
            : null}
        </div>
        <p className='modal-title'><span>{viewTitle}</span></p>
        <div className="right-modal-header">
          <Button
            onClick={closeEvent}
            size={ButtonSize.Small}
            color={ButtonColor.None}
            borderRadius={'large'}
            label='Close'
            btnClass={'no-padding'}
            showLabel={false}
          >
            <CloseIcon
              color={SvgVariant.Secondary}
              viewBox={'0 0 40 40'}
              titleAccess='Modal schließen'
            />
          </Button>
        </div>
      </div>
      <div className={`modal-body ${gridClass}`}>
        {Children.map(children, (child, index) => {
          if (isValidElement(child)) {
            return (
              <>
                {cloneElement(child, {
                  onClick: () => setStep(view?.next?.[index]),
                  closeModal: () => closeEvent(),
                })}
              </>
            );
          }

          return null;
        })}
      </div>
    </>
  );
};
