/* eslint-disable no-console */

export class LogService {
    public static log(msg: string, ...optionParams: any[]): void {
        if (process.env.NODE_ENV === 'development') {
            console.log(msg, ...optionParams);
        }
    }

    public static warn(msg: string, ...optionParams: any[]): void {
        if (process.env.NODE_ENV === 'development') {
            console.warn(msg, ...optionParams);
        }
    }

    public static error(msg: string, ...optionParams: any[]): void {
        if (process.env.NODE_ENV === 'development') {
            console.error(msg, ...optionParams);
        }
    }
}
