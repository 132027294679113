import React, { useEffect, useState } from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import { connect } from "react-redux";
import { IState } from "../../../../shared/interfaces/state.interface";
import { selectPropertyWithAddress } from "../../../../shared/redux/property/property.selectors";
import { PropertyModel } from "../../../../shared/models/property.model";
import { Form } from "../../../form/form";
import { PropertyFormDefinition } from "../../../form/definitions/property.form-definition";
import { KeyValuePairs } from "../../../../shared/types/key-value-pairs.type";
import { performGetRequest, performPutRequest } from "../../../../shared/redux/middleware/api.middleware";
import { PropertyActionTypes } from "../../../../shared/redux/property/property.action-types";
import { FormService } from "../../../../shared/services/form.service";
import { IFieldChangeEvent } from "../../../form/interfaces/field-single.interface";
import { ApiEndpoint } from "../../../../shared/constants/api-endpoint.enum";
import { PropertyValidationSchema } from "../../../form/validation-schema/property.validation-schema";
import { useUser } from "../../../../contexts/user.context";

interface IPropertyDetailInfoViewProps {
  property: PropertyModel;
  updateProperty?: (data: KeyValuePairs) => void;
  refetchProperty?: (path: string) => void;
}

export const PropertyDetailInfoView = (props: IPropertyDetailInfoViewProps) => {
  const {
    property,
    updateProperty,
    refetchProperty
  } = props;

  const [formDefinition, setFormDefinition] = useState(null);

  const { getGroups } = useUser();

  const [writePermissions, setWritePermissions] = useState(false);

  useEffect(() => {
    if (getGroups()?.includes("admin") || getGroups()?.includes("editor")) {
      setWritePermissions(true);
    }
  }, [getGroups]);

  useEffect(() => {
    if (!property.id) {
      return;
    }

    const formService = new FormService();
    const updatedFormDefinition = formService.updateFormSets(PropertyFormDefinition, property, !writePermissions ? { readonly: true } : {});

    setFormDefinition(updatedFormDefinition);
  }, [property, writePermissions]);

  const handleInputChange = async (changeEvent: IFieldChangeEvent) => {
    if (!writePermissions) {
      return;
    }

    let currentValue = changeEvent.value;
    const distinctValue = property?.[changeEvent.name];

    let updateData = {
      [changeEvent.name]: currentValue
    };

    if (!currentValue && !distinctValue) {
      return;
    }

    if (typeof currentValue === "string") {
      currentValue = changeEvent.value.slice().trim();
    }

    if (currentValue !== distinctValue) {
      updateProperty({
        ...updateData,
        propertyId: property.propertyId
      });
    }
  };

  return (
    <Container fluid>
      <Row>
        <Form showButtonControls={false}
              refetchSourceData={() => refetchProperty(`${ApiEndpoint.PROPERTY}/${property.propertyId}`)}
              validationSchema={PropertyValidationSchema}
              changeHandler={handleInputChange}
              formDefinition={formDefinition}/>
      </Row>
    </Container>
  );
};

const mapStateToProps = (state: IState) => {
  return {
    property: selectPropertyWithAddress(state)
  };
};

const mapDispatchToProps = (dispatch: any) => ({
  updateProperty: (data: KeyValuePairs) => dispatch(
    performPutRequest(
      ApiEndpoint.PROPERTY,
      data,
      PropertyActionTypes.FETCH_IN_PROGRESS,
      PropertyActionTypes.SET_PROPERTY,
      PropertyActionTypes.FETCH_FINISHED
    )
  ),
  refetchProperty: (path: string) => dispatch(
    performGetRequest(
      path,
      PropertyActionTypes.FETCH_IN_PROGRESS,
      PropertyActionTypes.SET_PROPERTY,
      PropertyActionTypes.FETCH_FINISHED
    )
  )
});

export const PropertyDetailInfoViewWithStore = connect(mapStateToProps, mapDispatchToProps)(PropertyDetailInfoView);
