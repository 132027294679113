import { HttpService } from "./http.service";
import { ApiEndpoint } from "../constants/api-endpoint.enum";
import { IFieldSearchSuggestion } from "../../components/form/interfaces/field-single.interface";

export class PropertyService {
  static async addData(
    routeParam: string | number,
    relationPath: string,
    data = null): Promise<void> {
    return HttpService.performPostRequest(`${ApiEndpoint.PROPERTY}/${routeParam}/${relationPath}`, data);
  }

  static async deleteData(
    routeParam: string | number,
    relationPath: string,
    identifier?: string,
    data?: any): Promise<void> {
    await HttpService.performDeleteRequest(`${ApiEndpoint.PROPERTY}/${routeParam}/${relationPath}${!!identifier ? '/' + identifier : ''}`, data);
  }

  static async updateData(
    routeParam: string | number,
    relationPath: string,
    identifier: string,
    data: any): Promise<void> {
    await HttpService.performPutRequest(`${ApiEndpoint.PROPERTY}/${routeParam}/${relationPath}/${identifier}`, data);
  }

  static async searchProperty(
    searchTerm: string
  ): Promise<IFieldSearchSuggestion[]> {
    const { data } = await HttpService.performGetRequest(`${ApiEndpoint.PROPERTY}/suggestions?propertySearch=${searchTerm}`) ?? [];

    return data?.map((result) => this.makeSuggestionObject(result));
  }

  static async getIndexSignature(identifier: string | number): Promise<{ id: string | number, label: string }> {
    return await HttpService.performGetRequest(`${ApiEndpoint.PROPERTY}/${identifier}`).then((result) => this.makeSuggestionObject(result));
  }

  private static makeSuggestionObject(result) {
    const primaryAddress = result?.addresses.find((item) => item.isPrimary);
    const titleIfDifferentThanStreet = !!primaryAddress && result?.title !== primaryAddress?.fullStreet ? ' (' + result?.title + ')' : '';

    return ({
      id: result?.propertyId || result?.id,
      label: `${!!primaryAddress?.fullStreet ? primaryAddress?.fullStreet + ', ' + primaryAddress?.location : result?.title}${titleIfDifferentThanStreet}`
    });

  }
}
