import React, { ReactNode } from 'react';
import { ArrowRightIcon } from '../icons/collection/arrow-right-icon';
import { SvgVariant } from '../icons/enum/svg-variant.enum';

import './action-teaser.scss';

interface IActionTeaserProps {
  teaserTitle: string;
  teaserText?: string;
  children: ReactNode;
  onClick?: any;
}

export const ActionTeaser = (props: IActionTeaserProps) => {
  const { teaserTitle, teaserText, children, onClick } = props;

  return (
    <button className='action-teaser'
            type='button'
            onClick={onClick}>
      <span className="action-teaser-icon">
        {children}
      </span>
      <p className='action-teaser-title'>
        {teaserTitle}
      </p>
      <div className='action-teaser-text'
           dangerouslySetInnerHTML={{__html: teaserText}}/>
      <span className="action-teaser-trigger-label">
        Auswählen <ArrowRightIcon color={SvgVariant.Secondary} viewBox='0 0 40 40' titleAccess={`${teaserTitle} auswählen`}/>
      </span>
    </button>
  );
}
