import { IState } from '../../interfaces/state.interface';
import { fromJS } from 'immutable';
import { PropertyModel } from '../../models/property.model';
import { IReducer } from '../../interfaces/reducer.interface';
import { IAction } from '../../interfaces/action.interface';
import { PropertyActionTypes } from './property.action-types';
import { mergeState } from '../../utils/reducer';

const initialState: IState = fromJS({
  data: new PropertyModel(),
  isLoading: false
});

export const propertyReducer: IReducer = (state = initialState, action: IAction) => {
  switch (action.type) {
    case PropertyActionTypes.SET_PROPERTY:
      return mergeState(state, {
        data: new PropertyModel({ ...action.payload }),
        isLoading: false
      });

    case PropertyActionTypes.FETCH_IN_PROGRESS:
      return mergeState(state, {
        isLoading: true
      });

    case PropertyActionTypes.FETCH_FINISHED:
      return mergeState(state, {
        isLoading: false
      });

    case PropertyActionTypes.TOUCH_PROPERTY:
      let data = state.get('data');
      data = data.set('updatedAt', new Date());
      data = data.set('updatedByTitle', action.payload?.updatedBy);
      return mergeState(state, {
        data
      });

    default:
      return state;
  }
}
