import React from 'react'
import { css, StyleSheet } from 'aphrodite';
import { useSelector, useDispatch } from 'react-redux';
import { selectIntroTextById } from '../../shared/redux/intro-texts/intro-texts.selectors';
import { CssSize } from '../../shared/helpers/styles.helper';
import { SvgVariant } from '../icons/enum/svg-variant.enum';
import { DeleteIcon } from '../icons/collection/delete-icon';
import { Button, ButtonSize, ButtonColor } from '../button';
import { IntroTextActions } from '../../shared/redux/intro-texts/intro-texts.action';
import { IntroTextsDataService } from '../../shared/services/intro-texts-data.service';
import { LogService } from '../../shared/services/log.service';

interface IIntroTextPostalCodesProps {
  introTextId: string;
}

export const IntroTextPostalCodes = React.memo((props: IIntroTextPostalCodesProps) => {
  const {
    introTextId,
  } = props;

  const { postalCodes } = useSelector(selectIntroTextById(introTextId));
  const dispatch = useDispatch();

  const onDeselect = async (postalCode) => {
    try {
      const res = await IntroTextsDataService.removePostalCode(introTextId, postalCode);
      dispatch(IntroTextActions.removePostalCode(introTextId, postalCode, res?.data?.updatedByTitle));
    } catch (error) {
      LogService.error(error);
    }
  };

  return (
    !postalCodes?.length ? null : (
      <ul className={css(styles.list)}>
        {postalCodes?.map((postalCode) => {
          return (
            <li key={postalCode} className={css(styles.listItem)}>
              <Button onClick={() => onDeselect(postalCode)}
                size={ButtonSize.Small}
                color={ButtonColor.Secondary}
                borderRadius={'large'}
                label={postalCode}>
                <DeleteIcon color={SvgVariant.White} viewBox={'0 0 30 30'} titleAccess={`${postalCode} abwählen`} />
              </Button>
            </li>
          );
        })}
      </ul>
    )
  );
});

const styles = StyleSheet.create({
  list: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    margin: `0 0 ${CssSize.s10} ${CssSize.s10}`,
    padding: 0,
  },
  listItem: {
    marginRight: CssSize.s10,
    marginBottom: CssSize.s10,
  }
});
