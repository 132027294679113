import { Record } from 'immutable';
import { PropertyModel } from "./property.model";
import { AffiliateModel } from "./affiliate.model";

export interface IOwnerParams {
  id: string;
  _affiliateId: number;
  affiliate: AffiliateModel,

  _propertyId: number;
  property: PropertyModel;

  _agentId: number;
  agent: AffiliateModel;

  _agentId2: number;
  agent2: AffiliateModel;

  _agentId3: number;
  agent3: AffiliateModel;

  _agentId4: number;
  agent4: AffiliateModel;

  purchaseDate: string;
  total: number;
  propertyAddress: string;
  isFormer: boolean;
}

export class OwnerModel extends Record<IOwnerParams>({
  id: undefined,
  _affiliateId: undefined,
  affiliate: undefined,
  purchaseDate: undefined,
  _agentId: undefined,
  agent: undefined,
  _agentId2: undefined,
  agent2: undefined,
  _agentId3: undefined,
  agent3: undefined,
  _agentId4: undefined,
  agent4: undefined,
  _propertyId: undefined,
  property: undefined,
  propertyAddress: undefined,
  isFormer: undefined,
  total: undefined
}) {
  constructor(params?: IOwnerParams) {
    params ? super(params) : super();
  }

  with(values: IOwnerParams): this {
    return this.merge(values) as this;
  }

}
