import React, { RefObject } from 'react';
import { SortOrder } from '../shared/constants/sort-order.enum';
import { ITableHeaderAndFooterConfig } from '../shared/interfaces/table-header-config.interface';
import { List } from 'immutable';
import { TenanciesMetaDataModel } from '../shared/models/tenancies-meta-data.model';
import { PropertyMetaDataModel } from '../shared/models/property-meta-data.model';
import { LoadingStatus } from '../shared/constants/loading-status.enum';

/**
 * This Context is written,
 * to prevent multiple data passes through multiple instances of children.
 */

interface ITableContext {
  tableTitle: string;
  headers: ITableHeaderAndFooterConfig[];
  rows?: List<any>;
  metaData?: TenanciesMetaDataModel | PropertyMetaDataModel;
  setSortByColumn: (column: string) => void;
  sortByColumn: string;
  sortOrder: SortOrder;
  affixRef?: RefObject<any>;
  loadingStatus?: LoadingStatus;
  csvExportFileName?: string;
  csvExportUrl?: string | false;
  csvExportVisible?: boolean;
  xScrollable?: boolean;
}

const TABLE_DEFAULT_VALUE: ITableContext = {
  tableTitle: "",
  headers: [],
  rows: List([]),
  metaData: null,
  setSortByColumn: (_column: string) => {},
  sortByColumn: "",
  sortOrder: SortOrder.DESC,
  affixRef: { current: null },
  loadingStatus: LoadingStatus.READY,
  xScrollable: true
};

export const TableContext = React.createContext(TABLE_DEFAULT_VALUE);
