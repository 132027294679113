import * as React from "react";
import {Redirect, Route} from "react-router-dom";
import { useUser } from "../../contexts/user.context";

export const PrivateRoute = (props: any) => {

  const {children, ...rest} = props;
  const {user} = useUser();

  let attrs = {
    ...rest
  };

  if (!props?.component) {
    attrs = {
      ...attrs, ...{
        render: (({location}: any) =>
            !!user?.username ? children : <Redirect
              to={{
                pathname: "/",
                state: {from: location}
              }}
            />
        )
      }
    }
  }

  return (
    <Route {...attrs}/>
  );
}
