import { ISelectItem } from '../../components/inputs/interfaces/select-item.interface';

export const PropertyHasImagesCollectionFilter: ISelectItem[] = [
  { label: 'Alle', isChecked: true, value: '' },
  { label: 'Bebildert', isChecked: false, value: 'true' },
  { label: 'Unbebildert', isChecked: false, value: 'false' },

].map((item, index) => {
  return {
    id: `has-images-${index+1}`,
    ...item
  }
});
