import {Record} from 'immutable';
import {UserRole} from '../constants/user-role.enum';
import {UserStatus} from '../constants/user-status.enum';

export interface IAwsUserParams {
  Attributes?: IAwsUserAttributes[];
  Enabled?: boolean;
  UserCreateData?: string;
  UserLastModifiedDate?: string;
  UserStatus?: string;
  Username?: string;
}

export interface IAwsUserAttributes {
  Name: string;
  Value: string;
}

export interface IUserModel {
  principalId: string;
  email: string;
  firstName: string;
  lastName: string;
  status: UserStatus;
  role: UserRole;
  updatedAt: string;
}

const defaults: IUserModel = {
  principalId: null,
  email: null,
  firstName: null,
  lastName: null,
  status: UserStatus.InProgress,
  role: UserRole.Unknown,
  updatedAt: null
};

export class UserModel extends Record<IUserModel>(defaults) implements IUserModel {

}
