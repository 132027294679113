/* eslint-disable react-hooks/exhaustive-deps */

import React  from "react";
import { Button, ButtonColor, ButtonIconPosition, ButtonSize } from "../../button";
import { CssColor, CssSize } from "../../../shared/helpers/styles.helper";
import { SvgVariant } from "../../icons/enum/svg-variant.enum";
import { CropIcon } from "../../icons/collection/crop-icon";
import { css, StyleSheet } from "aphrodite";

interface ICropImageProps {
  status,
  newBase64,
  setCurrentImage: (image) => Promise<null>,
  setIsCropper,
  setIsCanvas,
  setIsTouched: (boolean) => void,
  disabled?: boolean;
}

export const CropImageControl = React.memo((props: ICropImageProps) => {
  const {
    status,
    newBase64,
    setCurrentImage,
    setIsCropper,
    setIsCanvas,
    setIsTouched,
    disabled = false
  } = props;

  const initiateCropMode = () => {
    setIsCropper();
  };

  const abortCropMode = () => {
    setIsCanvas();
  };

  const cropAndReturnToCanvas = () => {
    setCurrentImage(newBase64).then(() => {
      setIsTouched(true);
      setIsCanvas();
    });
  };

  return <>
    <label htmlFor='crop'>Zuscheiden</label>
    <div className={css(styles.buttonContainer)}>
      {!status.isCropping && <Button
        onClick={initiateCropMode}
        btnClass={"no-padding"}
        showLabel={false}
        label={"Zuscheiden"}
        color={ButtonColor.Transparent}
        iconPosition={ButtonIconPosition.Right}
        size={ButtonSize.Medium}
        disabled={disabled}
        tabIndex={0}
      >
        <CropIcon stroke={CssColor.White} color={SvgVariant.White} viewBox={"0 0 40 40"}
                  titleAccess={"Zuscheiden"}/>
      </Button>}
      {status.isCropping && <>
        <div className={css(styles.abortButton)}>
          <Button
            onClick={abortCropMode}
            label={"Abbrechen"}
            color={ButtonColor.Default}
            size={ButtonSize.Medium}
            disabled={disabled}
            tabIndex={0}
          />
        </div>
        <Button
          onClick={cropAndReturnToCanvas}
          label={"Akzeptieren"}
          color={ButtonColor.Secondary}
          size={ButtonSize.Medium}
          disabled={disabled}
          tabIndex={0}
        />
      </>}
    </div>
  </>;
});

const styles = StyleSheet.create({
  buttonContainer: {
    display: "flex"
  },
  abortButton: {
    marginRight: CssSize.s10
  }
});
