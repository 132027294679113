import {
  selectAffiliateOwnerPropertySelectorObject,
  selectAffiliateOwnersAgentAffiliateSelectorObject
} from "../../../../../shared/redux/affiliate/affiliate.selectors";
import {FieldSingleConfig} from "../../../config/field-single.config";
import {TemplateType} from "../../../enums/template-type.enum";
import {PropertyService} from "../../../../../shared/services/property.service";
import {InputType} from "../../../enums/input-type.enum";
import {DatePickerType} from "../../../enums/datepicker-type.enum";
import {FieldCollapsableConfig} from "../../../config/field-collapsable.config";
import {RelationshipInputFieldFactory} from "../../relationship-input-field-factory";
import {IFieldTabArrayState} from "../../../fields/field-tab-array/field-tab-array.reducer";
import {selectCurrent, selectFormer} from "../../../fields/field-tab-array/field-tab-array.selector";
import {FieldComposeConfig} from "../../../config/field-compose.config";
import {AgentInputDefinitions} from "../../agent-definitions.const";
import {UnitSymbol} from "../../../enums/unit-symbol.enum";

export abstract class AffiliateOwnersFormDefinitions {
  static get AffiliateOwners() {
    const titleBar = [
      [
        {
          replaceValue: "propertyId",
          title: async (state, id) => (selectAffiliateOwnerPropertySelectorObject(state, id)?.label)
        }
      ],
      [
        {
          replaceValue: "total",
          title: `<strong>Kaufpreis (${UnitSymbol.Euro})</strong><br>$0`
        },
        {
          replaceValue: "purchaseDate",
          title: `<strong>Kaufdatum</strong><br>$0`
        },
        {
          replaceValue: "agentId",
          title: async (state, id) => "<strong>Makler</strong><br/>" + (selectAffiliateOwnersAgentAffiliateSelectorObject(state, id)?.label)
        },
        {
          replaceValue: "agentId2",
          title: async (state, id) => "<strong>Makler 2</strong><br/>" + (selectAffiliateOwnersAgentAffiliateSelectorObject(state, id, '2')?.label)
        },
        {
          replaceValue: "agentId3",
          title: async (state, id) => "<strong>Makler 3</strong><br/>" + (selectAffiliateOwnersAgentAffiliateSelectorObject(state, id, '3')?.label)
        },
        {
          replaceValue: "agentId4",
          title: async (state, id) => "<strong>Makler 4</strong><br/>" + (selectAffiliateOwnersAgentAffiliateSelectorObject(state, id, '4')?.label)
        }
      ]
    ];

    const fields = [
      {
        ...FieldSingleConfig.getConfig({
          templateType: TemplateType.FIELD_RELATION_CONNECTOR,
          name: "_propertyId",
          label: "Objekt",
          relationUrlPath: "/properties",
          onRelationSearch: async (searchTerm: string) => await PropertyService.searchProperty(searchTerm),
          selectableObject: async (state, id: number) => (selectAffiliateOwnerPropertySelectorObject(state, id))
        })
      },
      {
        ...FieldSingleConfig.getConfig({
          inputType: InputType.NUMBER,
          name: "total",
          label: "Preis",
          attributes: {
            abbreviation: UnitSymbol.Euro,
            allowDecimal: false,
            max: 999999999999
          }
        })
      },
      {
        ...FieldSingleConfig.getConfig({
          name: "propertyAddress",
          label: "Adresse",
          readonly: true
        })
      },
      {
        ...FieldSingleConfig.getConfig({
          inputType: InputType.DATE,
          name: "purchaseDate",
          label: "Kaufdatum",
          attributes: {
            datePickerType: DatePickerType.Month
          }
        })
      },
      {
        ...FieldComposeConfig.getConfig({
          fullWidth: true,
          repeat: "auto",
          name: "agentsComposition",
          headers: [
            `Makler 1`,
            "Makler 2",
            `Makler 3`,
            `Makler 4`,
          ],
          attachedTop: true,
          fields: [
            AgentInputDefinitions.SelectorInputField("", "_agentId", async (state, id) => {
              return selectAffiliateOwnersAgentAffiliateSelectorObject(state, id);
            }),
            AgentInputDefinitions.SelectorInputField("", "_agentId2", async (state, id) => {
              return selectAffiliateOwnersAgentAffiliateSelectorObject(state, id, "2");
            }),
            AgentInputDefinitions.SelectorInputField("", "_agentId3", async (state, id) => {
              return selectAffiliateOwnersAgentAffiliateSelectorObject(state, id, "3");
            }),
            AgentInputDefinitions.SelectorInputField("", "_agentId4", async (state, id) => {
              return selectAffiliateOwnersAgentAffiliateSelectorObject(state, id, "4");
            }),
          ]
        })
      },
    ];

    const tabFactory = (key, label, selector, showCreateButton) => {
      return {
        key: key + "_item",
        label: label,
        selector: selector,
        newDataRef: {
          ...FieldCollapsableConfig.getConfig({
            label: label,
            hideInputEnumeration: false,
            showAdditionalButton: showCreateButton,
            titleBar,
            fields,
          })
        }
      };
    };

    return RelationshipInputFieldFactory.TwoTabsWithFormerCollapsibleFactory(
      "owners",
      "Eigentümer-Objekt",
      "Eigentümer-Objekte",
      (state: IFieldTabArrayState) => selectCurrent(state),
      (state: IFieldTabArrayState) => selectFormer(state),
      tabFactory
    );
  }
}
