import React, {useLayoutEffect, useRef, useState} from "react";
import {withRouter} from "react-router-dom";
import {ActionTeaser} from "../../components/action-teaser/action-teaser";
import {ChevronLeftIcon} from "../../components/icons/collection/chevron-left-icon";
import {PlusCircleIcon} from "../../components/icons/collection/plus-circle-icon";
import {RealEstateIcon} from "../../components/icons/collection/real-estate-icon";
import {UserIcon} from "../../components/icons/collection/user-icon";
import {Modal} from "../../components/overlay/modal/modal";
import {ModalView} from "../../components/overlay/modal/modal-view";
import {Wizard} from "../../components/wizard/wizard";
import {CreatePropertyDataset} from "../../components/datasets/property/create/create-property.dataset";
import {CreateAffiliateDatesetWithRouter} from "../../components/datasets/affiliate/create/create-affiliate.dataset";
import {Button, ButtonColor, ButtonIconPosition, ButtonSize} from "../../components/button";
import {Navigation} from "../../components/navigation/navigation";
import {SvgVariant} from "../../components/icons/enum/svg-variant.enum";

import "./app-menu-container.scss";
import {css, StyleSheet} from "aphrodite";
import {UserMenu} from "./user-menu/user-menu";
import {useCurrentLanguage} from "../../contexts/current-language.context";
import {useUser} from "../../contexts/user.context";

const logo = require('../../assets/logo/logo.png');
const logoSmall = require('../../assets/logo/logo-small.png');

const WIZARD_VIEW_DEPENDENCIES = [
  {
    prev: null,
    next: [1, 2]
  },
  {
    prev: 0,
    next: null
  },
  {
    prev: 0,
    next: null
  }
];

export const AppMenuContainer = withRouter(() => {

  const appMenuRef = useRef();
  const [isExpanded, toggleIsExpand] = useState(false);
  const [showModal, setShowModal] = useState(false);

  const currentLanguage = useCurrentLanguage();

  const {getGroups} = useUser();

  useLayoutEffect(() => {
    window.requestAnimationFrame(() => {
      // @ts-ignore // dirty fallback
      const appMenuClientWidth = appMenuRef.current?.clientWidth > 0 ? appMenuRef.current?.clientWidth : 100;
      document.documentElement.style.setProperty('--app-menu-width', (appMenuClientWidth) + "px");
    });
  })

  const renderModal = () => {
    return (getGroups()?.includes('admin') || getGroups()?.includes('editor')) && (
      <Modal closeEvent={() => setShowModal(false)}>
        <Wizard viewDependencies={WIZARD_VIEW_DEPENDENCIES}>
          <ModalView viewTitle={"Neuen Datensatz anlegen"}
                     gridClass={"two-column"}
                     closeEvent={() => setShowModal(false)}>
            <ActionTeaser teaserTitle={"Objekt"}>
              <RealEstateIcon viewBox='0 0 140 140'
                              color={SvgVariant.Secondary}
                              titleAccess='Objektdatensatz anlegen'/>
            </ActionTeaser>
            <ActionTeaser teaserTitle={"Firma / Person"}>
              <UserIcon viewBox='0 0 140 140'
                        color={SvgVariant.Secondary}
                        titleAccess='Firmendatensatz anlegen'/>
            </ActionTeaser>
          </ModalView>
          <ModalView gridClass={"full"}
                     viewTitle={"Objekt anlegen"}
                     backButtonLabel={"Zurück"}
                     closeEvent={() => setShowModal(false)}>
            <CreatePropertyDataset currentLanguage={currentLanguage}/>
          </ModalView>
          <ModalView gridClass={"full"}
                     viewTitle={"Firma / Person anlegen"}
                     backButtonLabel={"Zurück"}
                     closeEvent={() => setShowModal(false)}>
            <CreateAffiliateDatesetWithRouter currentLanguage={currentLanguage}/>
          </ModalView>
        </Wizard>
      </Modal>
    );
  };

  return (
    <>
      <div ref={appMenuRef} className={`app-menu ${isExpanded ? "is-expanded" : ""}`}>
        <div className='top-box'>
          <div className={css(styles.logoContainer, !isExpanded && styles.centered)}>
            <img src={logo}
                 alt="Publity - Real Estate Management"
                 className={css(styles.logo, !isExpanded && styles.hidden)}/>
            <img src={logoSmall}
                 alt="Publity - Real Estate Management"
                 className={css(styles.logoSmall, isExpanded && styles.hidden)}/>
          </div>
          {(getGroups()?.includes('admin') || getGroups()?.includes('editor')) && <Button
            onClick={() => setShowModal(true)}
            btnClass={`fullwidth ${!isExpanded ? "no-padding" : ""}`}
            showLabel={isExpanded}
            size={ButtonSize.XLarge}
            label='Datensatz anlegen'
            color={ButtonColor.Transparent}
            iconPosition={isExpanded ? ButtonIconPosition.Left : ButtonIconPosition.Center}
          >
            <PlusCircleIcon
              viewBox='0 0 60 60'
              color={SvgVariant.White}
              titleAccess='Datensatz anlegen'
            />
          </Button>}
        </div>
        <Navigation isExpanded={isExpanded}/>
        <button className={`toggle-expand ${isExpanded ? "is-expanded" : ""}`}
                type='button'
                onClick={() => toggleIsExpand(!isExpanded)}>
          <ChevronLeftIcon viewBox='0 0 30 30'
                           color={SvgVariant.White}
                           titleAccess='Menü ausklappen'/>
        </button>
        <UserMenu isExpanded={isExpanded}/>
      </div>
      {showModal && renderModal()}
    </>
  );
});

const styles = StyleSheet.create({
  logoContainer: {
    paddingBottom: 46
  },
  centered: {
    textAlign: "center"
  },
  logoSmall: {
    width: 60,
  },
  logo: {
    width: 243,
  },
  hidden: {
    display: 'none'
  }
});
