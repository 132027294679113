import React, { useContext } from "react";
import { TableContext } from "../../../contexts/table.context";
import { SortOrder } from "../../../shared/constants/sort-order.enum";
import { ITableHeaderAndFooterConfig } from "../../../shared/interfaces/table-header-config.interface";
import {StyleSheet} from "aphrodite/no-important";
import {css} from "aphrodite";

/**
 * This is a generic TableHeader Component rendering
 * header columns given in its Context.
 */
export const TableFooters = (props: any) => {

  const {tdMinWidths = []} = props;

  const {
    headers,
    sortByColumn,
    sortOrder
  } = useContext(TableContext);

  return (
    <>
      <tr>
        {headers?.map((column: ITableHeaderAndFooterConfig, index: number) => {
          const orderClassName = sortOrder === SortOrder.DESC ? "sort-by-desc" : "sort-by-asc";
          const sortClassName = column?.columnKey === sortByColumn ? `is-sorted ${orderClassName}` : "";

          let dynamicStyles = StyleSheet.create({
            td: {
              width: column?.width,
              minWidth: tdMinWidths?.[index] ?? 'inherit',
            },
          });

          return (
            <td key={column?.columnKey}
                className={css(dynamicStyles.td) + ` ${sortClassName}`}
                colSpan={column.colSpan}>
            </td>
          );
        })}
      </tr>
    </>
  );
}
