export enum TemplateType {
  FIELD_SETS,
  FIELD_SINGLE,
  FIELD_COMPOSE,
  FIELD_ARRAY,
  FIELD_TAB_ARRAY,
  FIELD_COLLAPSABLE,
  //@Deprecated
  GOOGLE_ADDRESS,
  MAPBOX_ADDRESS,
  FIELD_ADDRESS_ARRAY,
  FIELD_TABLE,
  FIELD_RELATION_CONNECTOR
}
