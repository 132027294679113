import React from 'react';
import { IFilterParam } from '../../shared/interfaces/filter-param.interface';
import { ISelectItem } from '../inputs/interfaces/select-item.interface';
import { SelectList } from '../inputs/select-list/select-list';
import { ActiveFilterModel } from '../../shared/models/active-filter.model';

interface IFilterSelectListProps {
  storedSelection: ActiveFilterModel | undefined;
  dependencySelection?: { selectListValue: string, dependency: Map<string, string> | any }[];
  groupKey: string;
  title: string;
  list: ISelectItem[];
  type?: 'checkbox' | 'radio';
  setFilter: (filter: IFilterParam) => void;
  resetFilter: (groupKey: string) => void;
}

export const FilterSelectList = (props: IFilterSelectListProps) => {
  const {
    storedSelection,
    groupKey,
    title,
    setFilter,
    resetFilter
  } = props;

  const handleListUpdate = (list: ISelectItem[]) => {
    const checkedList = list?.filter((item: ISelectItem) => item.isChecked && item.value.length)
      .map((item: ISelectItem) => {
        return {
          label: item.label,
          value: item.value
        }
      });

    if (!checkedList.length) {
      resetFilter(groupKey);
      return;
    }

    setFilter({
      groupKey: groupKey,
      groupLabel: title,
      list: checkedList
    });
  };

  return (
    <SelectList emitList={handleListUpdate} storedList={storedSelection} {...props} />
  );
}
