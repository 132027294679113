import { css, StyleSheet } from "aphrodite";
import React, { ChangeEvent, useRef, useState } from "react";
import { FileType } from "../../shared/constants/file-type.enum";
import { GhostButton } from "../button/ghost-button";
import { UploadIcon } from "../icons/collection/upload-icon";
import { SvgVariant } from "../icons/enum/svg-variant.enum";
import { LoadingSpinnerBlueIcon } from "../icons";
import { CssSize } from "../../shared/helpers/styles.helper";

interface IFileUploadProps {
  type: FileType;
  label: string;
  id?: string;
  name?: string;
  onChange?: (images: FileList) => Promise<boolean>;
}

const createIdentifierByLabel = (label) => {
  return label.trim().toLowerCase();
};

export const FileUpload = (props: IFileUploadProps) => {
  const {
    type,
    label,
    id = createIdentifierByLabel(label),
    name = createIdentifierByLabel(label),
    onChange
  } = props;

  const [inputKey, setInputKey] = useState<any>();
  const [isLoading, setIsLoading] = useState<boolean>(false); //isLoading

  const fileInputRef = useRef<HTMLInputElement>();

  const getAcceptTypes = () => {
    switch (type) {
      case FileType.Image:
        return "image/png,image/jpg,image/jpeg";
      case FileType.Video:
        return "video/mp4";
    }
  };

  const onFileChange = async (event: ChangeEvent<HTMLInputElement>) => {
    if(event.target?.files) {
      setIsLoading(true);
      await onChange(event.target.files);
      setIsLoading(false);
      setInputKey(new Date());
    }
  };

  const onClick = (_e) => {
    //fileInputRef?.current?.click();
  }


  return (
    <>
      <label htmlFor={id}>
        <GhostButton
          label={label}
          onClick={onClick}
          disabled={isLoading}
        >
          {!isLoading ?
            <UploadIcon viewBox='0 0 30 30' color={SvgVariant.Secondary} titleAccess={label}/>
            :
            <span className={`${css(styles.loadingIcon)} icon-container`}>
                            <LoadingSpinnerBlueIcon titleAccess='Test' viewBox={"0 0 16 16"} className='loading'/>
                        </span>
          }
        </GhostButton>
      </label>

      <input
        key={inputKey}
        ref={fileInputRef}
        className={css(styles.fileInput)}
        onChange={onFileChange}
        type="file"
        accept={getAcceptTypes()}
        name={name}
        id={id}
        multiple
        disabled={isLoading}
      />
    </>
  );
};

const styles = StyleSheet.create({
  fileInput: {
    display: "none",
    visibility: "hidden",
    zIndex: -1
  },
  loadingIcon: {
    marginRight: CssSize.s10
  }
});
