import React, { useEffect, useState } from 'react';
import { FormattingHelper } from '../../shared/services/formatting.helper';
import './dataset-history.scss';

interface IDatasetHistoryProps {
  updatedAt: string;
  updatedBy: string;
}

export const DatasetHistory = React.memo((props: IDatasetHistoryProps) => {
  const {
    updatedAt,
    updatedBy = ''
  } = props;

  const [formattedDateTime, setFormattedDateTime] = useState('')

  useEffect(() => {
    if (updatedAt) {
      const formattedDateTime = FormattingHelper.formatDateTime(updatedAt);
      setFormattedDateTime(formattedDateTime);
    }
  }, [updatedAt]);

  return <div className='dataset-history'>
    {(updatedAt || formattedDateTime) && <><span>Zuletzt bearbeitet von</span>
      <span className='text-bold'>
        {updatedBy}{formattedDateTime && ` (${formattedDateTime})`}
      </span></>}
    </div>;
});
