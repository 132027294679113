import React from 'react';
import { FieldInputProps, useField } from 'formik';
import NumberFormat, { NumberFormatValues } from 'react-number-format';
import { css, StyleSheet } from 'aphrodite';
import { NumericSeparator } from '../../inputs/constants/numeric-separators.enum';
import { IFieldSingle } from '../interfaces/field-single.interface';

interface INumberInputProps extends IFieldSingle {
  onBlur: () => void;
  onKeyDown: (event: any) => void;
  field: FieldInputProps<any>
}

export const NumberInput = (props: INumberInputProps) => {
  const {
    attributes,
    onBlur,
    onKeyDown,
    readonly = false
  } = props;
  const [ field,, helpers ] = useField(props?.name);

  const handleValueChange = (values: NumberFormatValues) => {
    if(readonly) {
      return;
    }

    const { floatValue, value } = values;

    if (floatValue && attributes?.max) {
      const maxValue = value.charAt(0) === '0' ? attributes.max.toString().slice(0, -1) : attributes.max;

      if (!attributes?.allowDecimal) {
        return floatValue <= maxValue && floatValue % 1 === 0 && value.indexOf('.') === -1;
      }

      return floatValue <= maxValue;
    }

    return true;
  }

  const numberFormatProps = {
    thousandSeparator: NumericSeparator.Period,
    decimalSeparator: NumericSeparator.Comma,
    allowedDecimalSeparators: [NumericSeparator.Comma],
    isAllowed: handleValueChange
  };

  return (
    <div className={css(styles.wrapper)}>
      <NumberFormat {...numberFormatProps}
                    value={field.value}
                    onValueChange={(values: NumberFormatValues) => helpers.setValue(values.floatValue ?? null)}
                    onBlur={onBlur}
                    onKeyDown={onKeyDown}
                    readOnly={readonly || attributes?.readonly}
                    disabled={readonly}
      />
      <span className={css(styles.abbreviation)} dangerouslySetInnerHTML={{__html: attributes?.abbreviation}} />
    </div>
  );
}

const styles = StyleSheet.create({
  wrapper: {
    position: 'relative'
  },
  abbreviation: {
    position: 'absolute',
    top: '50%',
    transform: 'translateY(-50%)',
    right: 'var(--size-10)',
    pointerEvents: 'none',
    color: 'var(--grey)'
  }
})
