import { IAction } from '../../interfaces/action.interface';
import { PropertyMediaVideoActionTypes } from './property-media-video.action-types';
import { IPropertyMedia } from '../../interfaces/property-media.interface';
import { MoveMediaPosition } from '../../services/media-data.service';

export abstract class PropertyMediaVideoActions {
  static deleteVideo(videoId: string): any {
    return {
      type: PropertyMediaVideoActionTypes.DELETE_VIDEO,
      payload: videoId
    }
  }

  static setVideos(payload: IPropertyMedia[]): IAction {
    return {
      type: PropertyMediaVideoActionTypes.SET_MEDIA_VIDEO_LIST,
      payload
    }
  }

  static saveVideos(payload: IPropertyMedia[]): IAction {
    return {
      type: PropertyMediaVideoActionTypes.SAVE_MEDIA_VIDEOS,
      payload
    }
  }

  static updatePosition(videoId: string, move: MoveMediaPosition): IAction {
    return {
      type: PropertyMediaVideoActionTypes.UPDATE_POSITION,
      payload: {
        mediaId: videoId,
        move
      }
    };
  }

  static clearHighlightId(): IAction {
    return { type: PropertyMediaVideoActionTypes.CLEAR_HIGHLIGHTED };
  }
}
