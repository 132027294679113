import React, {useContext} from 'react';
import {TableContext} from '../../../contexts/table.context';
import {SortOrder} from '../../../shared/constants/sort-order.enum';
import {ITableHeaderAndFooterConfig} from '../../../shared/interfaces/table-header-config.interface';
import {StyleSheet} from "aphrodite/no-important";
import {css} from "aphrodite";

/**
 * This is a generic TableHeader Component rendering
 * header columns given in its Context.
 */
export const TableHeaders = (props: any) => {

  const {tdMinWidths = []} = props;

  const {
    headers,
    setSortByColumn,
    sortByColumn,
    sortOrder,
  } = useContext(TableContext);


  const labelOrSortButton = (column: ITableHeaderAndFooterConfig) => {
    if (column.sortable !== false) {
      return (<>
          <button onClick={() => setSortByColumn(column.columnKey)} dangerouslySetInnerHTML={{__html: column?.label}}
                  type={'button'} style={{textAlign: column.align ?? 'left'}}/>
          <span className={'sort-asc ' + (column.align ?? 'left')}/>
          <span className={'sort-desc ' + (column.align  ?? 'left')}/>
        </>
      );
    } else {
      return (<span className="label" dangerouslySetInnerHTML={{__html: column?.label}}/>);
    }
  };

  return (
    <>
      <tr>
        {headers?.map((column: ITableHeaderAndFooterConfig, index: number) => {
          const orderClassName = sortOrder === SortOrder.DESC ? 'sort-by-desc' : 'sort-by-asc';
          const sortClassName = column?.columnKey === sortByColumn ? `is-sorted ${orderClassName}` : '';

          let dynamicStyles = StyleSheet.create({
            td: {
              width: column?.width,
              minWidth: tdMinWidths?.[index] ?? 'inherit',
              textAlign: column?.align ?? 'left'
            },
          });

          return (
            <th key={column?.columnKey}
                className={css(dynamicStyles.td) + ` ${sortClassName}`}
                colSpan={column.colSpan}>
              {labelOrSortButton(column)}
            </th>
          )
        })}
      </tr>
    </>
  )
};
