import React from "react";
import { css, StyleSheet } from "aphrodite";

interface IButtonGroupProps {
  children: any;
  borderRadius?: boolean;
  showDivider?: boolean;
}

export const ButtonGroup = (props: IButtonGroupProps) => {
  const {
    children
  } = props;

  const borderRadius = props?.borderRadius ?? true;
  const showDivider = props?.showDivider ?? true;

  return <div className={css(borderRadius && styles.radius) + " button-group"}>
    {children.map((item, index) => {
      const divider = showDivider && index > 0 && children.length > 1 && !!item ?
        <hr className={css(styles.hr)}/> : null;
      return <React.Fragment key={index}>{divider}{item}</React.Fragment>;
    })}
  </div>;
};

const styles = StyleSheet.create({
  container: {
    display: "flex"
  },
  radius: {
    borderRadius: "var(--base-radius)",
    overflow: "hidden",
    filter: "var(--base-drop-shadow-small)"
  },
  hr: {
    backgroundColor: "var(--lighter-primary)",
    height: 1
  }
});
