import React, { useState, useEffect } from 'react';
import { TabControl } from './tab-control';
import './tabs.scss';

interface ITabsProps {
  children: any[];
  activeTab?: string;
  className?: string;
  controlsOnly?: boolean;
  onSelect?: (tab: string) => any;
}

/**
 * This component was inspired by:
 *  - https://alligator.io/react/tabs-component/
 *  - https://github.com/react-bootstrap/react-bootstrap/blob/master/src/Tabs.js
 */
export const Tabs = (props: ITabsProps) => {
  const {
    className,
    children,
    activeTab,
    controlsOnly = false,
    onSelect = null,
  } = props;

  const [tab, setTab] = useState(null);

  useEffect(() => {
    setTab(activeTab);
  }, [activeTab]);

  const onTabClick = (tab: string) => {
    setTab(tab);
    onSelect?.(tab);
  };

  return (
    <div className={`tab-container ${className ?? ''}`}>
      <div className={'controls-container'}>
        <ul className={'tabs'}>
          {children.map((child: any) => {
            return (
              <TabControl
                activeTab={tab}
                key={child.props.label}
                label={child.props.label}
                onClick={onTabClick}
              />
            );
          })}
        </ul>
      </div>
      {
        controlsOnly ? null :
          (<div className={'content-container'}>
            {children.map((child) => {
              return child.props.label === activeTab ? child.props.children : undefined;
            })}
          </div>)
      }
    </div>
  );
}
