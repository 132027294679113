import { IState } from '../../interfaces/state.interface';
import { fromJS } from 'immutable';
import { IReducer } from '../../interfaces/reducer.interface';
import { IAction } from '../../interfaces/action.interface';
import { mergeState } from '../../utils/reducer';
import { AdminTabsActionTypes } from './admin-tabs.action-types';
import { AdminTab } from '../../constants/admin-tab.enum';

const initialState: IState = fromJS({
  activeTab: AdminTab.User
});

export const adminTabsReducer: IReducer = (state = initialState, action: IAction) => {
  switch (action.type) {
    case AdminTabsActionTypes.SET_ADMIN_TAB:
      return mergeState(state, {
        activeTab: action.payload
      })
    default:
      return state;
  }
}
