import { applyMiddleware, createStore, compose } from 'redux';
import { rootReducer } from './root.reducer';
import thunk from 'redux-thunk';
import { composeWithDevTools } from 'redux-devtools-extension';

const composeEnhancers: any = (process.env.NODE_ENV !== 'production' && typeof window !== 'undefined' ? composeWithDevTools : compose);

export const store = createStore(
  rootReducer,
  composeEnhancers(
    applyMiddleware(thunk)
  )
);
