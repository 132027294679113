import React, { useState, useEffect } from 'react'
import { Dialog, DialogControl } from '../overlay/dialog'
import { ButtonColor } from '../button'

interface ICloseImageDialogProps {
  onControlClick: (value: boolean) => void;
  showDialog: boolean;
}

//TODO Auslagern und wiederverwenden
export const CloseImageDialog = React.memo((props: ICloseImageDialogProps) => {

  const {
    onControlClick,
    showDialog,
  } = props;

  const [isDialogVisible, setIsDialogVisible] = useState(false);

  useEffect(() => {
    setIsDialogVisible(showDialog);
  }, [showDialog])

  return (
    isDialogVisible && <Dialog
      title='Sind Sie sicher?'
      onClose={() => onControlClick(false)}
    >
      <Dialog.Message>
        Wenn sie auf „Schließen“ klicken, verlassen Sie<br />
        den Bearbeitungsmodus. Ihre Änderungen gehen verloren und können nicht wiederhergestellt werden.
      </Dialog.Message>
      <Dialog.Controls>
        <DialogControl label='Abbrechen' value={false} color={ButtonColor.Basic} onClick={onControlClick}></DialogControl>
        <DialogControl label='Schließen' value={true} color={ButtonColor.Danger} onClick={onControlClick}></DialogControl>
      </Dialog.Controls>
    </Dialog>
  );
});
