import { Record, List } from 'immutable';

export interface IActiveFilterParams {
  groupKey: string;
  groupLabel: string;
  value?: string |  number;
  valueObject?: object;
  // {label: string, value: string | number}
  list?: List<Map<string, string | number>>
  from?: string | Date;
  to?: string | Date;
  meta?: {labelKey?: string, valueKey?: string, i18n: boolean}
}

export class ActiveFilterModel extends Record<IActiveFilterParams>({
  groupKey: null,
  groupLabel: null,
  value: null,
  valueObject: null,
  list: null,
  from: null,
  to: null,
  meta: null,
}) {
  constructor(params?: IActiveFilterParams) {
    params ? super(params) : super();
  }

  with(values: IActiveFilterParams): this {
    return this.merge(values) as this;
  }
}
