export class CountryService {
  private static instance: CountryService;

  private readonly countries;

  constructor(languages: string[] = ["de", "en"]) {
    this.countries = require("i18n-iso-countries");

    for (const language of languages) {
      this.countries.registerLocale(require("i18n-iso-countries/langs/" + language + ".json"));
    }
  }

  getName(isoCode: string, language: string): string {
    return this.countries.getName(isoCode, language);
  }

  getAlpha2Code(country: string, language: string): string {
    return this.countries.getAlpha2Code(country, language);
  }

  static getInstance(): CountryService {
    if (!CountryService.instance) {
      CountryService.instance = new CountryService();
    }

    return CountryService.instance;
  }

}
