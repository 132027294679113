import React, {Dispatch, SetStateAction} from 'react';

interface IWizardContext {
  currentStep: number;
  setStep: Dispatch<SetStateAction<number>>;
}

const WIZARD_DEFAULT_VALUE: IWizardContext = {
  currentStep: 0,
  setStep: null
};

export const WizardContext = React.createContext(WIZARD_DEFAULT_VALUE);
