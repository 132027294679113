import React, { useEffect, useRef, useState } from "react";
import { connect } from "react-redux";
import { IRemarkParams, RemarkModel } from "../../../../../shared/models/remark.model";
import { CollapsableElements } from "../../../../collapsable-elements/collapsable-elements";
import { css, StyleSheet } from "aphrodite";
import { Button, ButtonColor, ButtonIconPosition, ButtonSize } from "../../../../button";
import { BinIcon } from "../../../../icons/collection/bin-icon";
import { VisibleIcon } from "../../../../icons/collection/visible-icon";
import { InVisibleIcon } from "../../../../icons/collection/invisible-icon";
import { SvgVariant } from "../../../../icons/enum/svg-variant.enum";
import { CssColor, CssSize } from "../../../../../shared/helpers/styles.helper";
import { PropertyTextsDataService } from "../../../../../shared/services/property-texts-data.service";
import { PropertyTextsActions } from "../../../../../shared/redux/property/property-texts/property-texts.actions";
import { Formik } from "formik";
import { DateInput } from "../../../../form/fields/date-input";
import { DatePickerType } from "../../../../form/enums/datepicker-type.enum";
import { FormattingHelper } from "../../../../../shared/services/formatting.helper";
import { RichTextEditor } from "../../../../inputs/rich-text-editor/rich-text-editor";
import { Language } from "../../../../../shared/constants/language.enum";
import { Translated } from "../../../../translated/translated";
import { PropertyActions } from "../../../../../shared/redux/property/property.actions";
import { useUser } from "../../../../../contexts/user.context";

interface IRemarkItemProps {
  enumeration: number;
  remark: RemarkModel;
  propertyId: number;
  updateRemark: (propertyId: number, data: Partial<IRemarkParams>) => void;
  onDelete: (id: string) => void;
}

export const RemarkItem = (props: IRemarkItemProps) => {
  const {
    enumeration,
    remark,
    propertyId,
    updateRemark,
    onDelete
  } = props;

  const { getGroups } = useUser();

  const [writePermissions, setWritePermissions] = useState(false);

  useEffect(() => {
    if (getGroups()?.includes("admin") || getGroups()?.includes("editor")) {
      setWritePermissions(true);
    }
  }, [getGroups]);

  const [formattedDateTime, setFormattedDateTime] = useState("");

  useEffect(() => {
    if (remark?.get("updatedAt")) {
      const formattedDateTime = FormattingHelper.formatDateTime(remark?.get("updatedAt"));
      setFormattedDateTime(formattedDateTime);
    }
  }, [remark]);

  const initialFormikValues = useRef({
    content_de: remark?.get("content_de") ?? "",
    content_en: remark?.get("content_en") ?? "",
    date: remark?.get("date") ?? null
  });

  const handleChange = (key: any, value: string | Date) => {
    if(!writePermissions) {
      return;
    }

    const distinctValue = remark.get(key) !== value;

    if (distinctValue) {
      updateRemark(propertyId, { id: remark.get("id"), [key]: value });
    }
  };

  const formattedDateWithMonthName = () => {
    const date = remark?.get("date");
    return !!date ? FormattingHelper.formatEditorialDateWithMonthName(remark?.get("date")) : "";
  };

  const getVisibleActionButton = () => {
    return writePermissions && <Button
      type={"button"}
      btnClass={"no-padding"}
      size={ButtonSize.Medium}
      color={ButtonColor.Ghost}
      showLabel={false}
      label={""}
      onClick={() => {
        updateRemark(propertyId, { id: remark.get("id"), isVisible: !remark.get("isVisible") });
      }}>
      {remark.get("isVisible") ? (
        <VisibleIcon
          viewBox='0 0 40 40'
          color={SvgVariant.Secondary}
          titleAccess={"Eintrag unsichtbar machen"}
        />
      ) : (
        <InVisibleIcon
          viewBox='0 0 40 40'
          color={SvgVariant.Danger}
          titleAccess={"Eintrag sichtbar machen"}
        />
      )}
    </Button>;
  };

  return (
    <CollapsableElements
      chevronDownPosition={ButtonIconPosition.Left}
      header={(
        <div className={css(styles.remarkTitleBar)}>
          <p className={css(styles.noMargin)}>Bemerkung {enumeration}</p>
          <p className={css(styles.noMargin, styles.titleBarDate)}>{formattedDateWithMonthName()}</p>
          {writePermissions && <Button type={"button"}
                  btnClass={"no-padding"}
                  size={ButtonSize.Medium}
                  color={ButtonColor.Ghost}
                  showLabel={false}
                  label={"Bemerkung löschen"}
                  onClick={() => onDelete(remark.get("id"))}>
            <BinIcon
              viewBox='0 0 40 40'
              color={SvgVariant.Secondary}
              titleAccess={"Eintrag löschen"}
            />
          </Button>}
          {getVisibleActionButton()}
        </div>
      )}
      content={(
        <div className={css(styles.remarkContent)}>
          <Formik
            enableReinitialize={true}
            initialValues={initialFormikValues.current}
            onSubmit={null}>
            {({ values, setFieldValue }) => (
              <>
                <Translated translations={[
                  {
                    language: Language.GERMAN,
                    value: <RichTextEditor
                      value={values.content_de}
                      onChange={(content) => setFieldValue("content_de", content, false)}
                      onBlur={() => handleChange("content_de", values.content_de)}
                      readOnly={!writePermissions}
                    />
                  },
                  {
                    language: Language.ENGLISH,
                    value: <RichTextEditor
                      value={values.content_en}
                      onChange={(content) => setFieldValue("content_en", content, false)}
                      onBlur={() => handleChange("content_en", values.content_en)}
                      readOnly={!writePermissions}
                    />
                  }
                ]}/>
                <DateInput
                  name='date'
                  attributes={{
                    datePickerType: DatePickerType.Full
                  }}
                  readonly={!writePermissions}
                  emitChangeHandler={(value) => handleChange("date", value)}/>
              </>
            )}
          </Formik>
          <p className={css(styles.remarkHint)}>
            Zuletzt bearbeitet
            von <strong>{remark?.get("updatedByTitle")}{formattedDateTime && ` (${formattedDateTime})`}</strong>
          </p>
        </div>
      )}
    />
  );
};

const styles = StyleSheet.create({
  noMargin: {
    marginBottom: 0
  },
  remarkTitleBar: {
    display: "grid",
    gridTemplateColumns: `repeat(2, 1fr) repeat(2, ${CssSize.s40})`,
    gridGap: CssSize.s10,
    alignItems: "center"
  },
  remarkContent: {
    padding: `${CssSize.s20} ${CssSize.s40}`
  },
  remarkHint: {
    color: CssColor.Grey,
    fontSize: 12,
    marginTop: 20
  },
  titleBarDate: {
    color: CssColor.Grey
  }
});

const mapDispatchToProps = (dispatch: any) => ({
  updateRemark: async (propertyId: number, data: Partial<IRemarkParams>) => {
    const res = await PropertyTextsDataService.updateRemark(propertyId, data);
    dispatch(PropertyTextsActions.updateRemark(res?.data));
    dispatch(PropertyActions.touch(res?.data?.updatedByTitle))
  }
});

export const RemarkItemWithStore = connect(null, mapDispatchToProps)(RemarkItem);
