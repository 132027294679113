import React from 'react';
import { DownloadIcon, LoadingSpinnerBlueIcon } from '../icons';
import { SvgVariant } from '../icons/enum/svg-variant.enum';

import './download-link.scss';

interface IPropTypes {
  label: string;
  href?: string;
  fileName?: string
  isLoading?: boolean;
  [rest: string]: any;
}

/**
 * This component is a special variant of the IconButton. It's behavior is defined the isLoading.
 *
 * If isLoading is set to true, the set icon will be repalced with a loading spinner.
 */
export const DownloadLink = (props: IPropTypes) => {
  const {
    label,
    href,
    fileName,
    isLoading,
    ...rest
  } = props;

  const isLoadingClassName = isLoading ? 'loading' : '';

  return <a
    href={href}
    download={fileName}
    className={`link download ${isLoadingClassName}`}
    {...rest}
  >
    <span className='icon-container'>
      {!isLoading ? (
        <DownloadIcon color={SvgVariant.Secondary} titleAccess='Test' />
      ) : (
          <LoadingSpinnerBlueIcon titleAccess='Test' className={isLoadingClassName} />
        )}

    </span>
    {label}
  </a>;
};
