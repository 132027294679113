import { SortOrder } from '../constants/sort-order.enum';

export abstract class SortService {

    static handleSortChange(
        currentSortOrder: SortOrder,
        currentSortByColumn: string,
        column: string
    ): { newSortOrder: SortOrder, column: string } {
        let newSortOrder = currentSortOrder;

        if (SortService.hasSortByColumnChanged(currentSortByColumn, column)) {
            newSortOrder = SortService.resetSortOrder();
        } else {
            newSortOrder = SortService.toggleSortOrder(newSortOrder);
        }

        return {
            newSortOrder,
            column
        };
    }

    private static resetSortOrder(): SortOrder {
        return SortOrder.ASC;
    }

    private static toggleSortOrder(currentSortOrder: SortOrder): SortOrder {
        return currentSortOrder === SortOrder.ASC ? SortOrder.DESC : SortOrder.ASC;
    }

    private static hasSortByColumnChanged(currentSortByColumn: string, newSortByColumn: string): boolean {
        return currentSortByColumn !== newSortByColumn;
    }
}