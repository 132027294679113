import { KeyValuePairs } from '../../../../shared/types/key-value-pairs.type';
import { FieldTableActionTypes } from './field-table.action-types';

export function setTableData(tableData: KeyValuePairs[]) {
  return {
    type: FieldTableActionTypes.SET_TABLE_DATA,
    tableData
  };
}

export function setPreviousTableData(previousTableData: KeyValuePairs[]) {
  return {
    type: FieldTableActionTypes.SET_PREVIOUS_TABLE_DATA,
    previousTableData
  };
}
