import React, { useCallback, useEffect, useState } from "react";
import NumberFormat, { NumberFormatValues } from "react-number-format";
import { NumericSeparator } from "../constants/numeric-separators.enum";
import { IRangeValue } from "../interfaces/numeric-range-value.interface";
import "./range-input.scss";
import { ActiveFilterModel } from "../../../shared/models/active-filter.model";
import { IFilterParam } from "../../../shared/interfaces/filter-param.interface";
import { DatePickerIcon } from "../../icons/collection/datepicker-icon";
import { css, StyleSheet } from "aphrodite/no-important";
import { SvgVariant } from "../../icons/enum/svg-variant.enum";
import DatePicker from "react-datepicker";

export enum RangeInputType {
  NUMERIC = "numeric",
  DATE = "date"
}

interface IRangeInputProps {
  storedSelection: ActiveFilterModel | undefined;
  setFilter: (filter: IFilterParam) => void;
  resetFilter: (groupKey: string) => void;
  groupKey: string;
  label: string;
  showLabel?: boolean;
  size?: "small" | "large";
  max?: number;
  step?: number;
  allowLeadingZeros?: boolean;
  thousandSeparator?: NumericSeparator;
  decimalSeparator?: NumericSeparator;
  allowedDecimalSeparators?: NumericSeparator[];
  allowNegative?: boolean;
  allowDecimal?: boolean;
  relatedFilter?: ActiveFilterModel | undefined;
  relatedFilterKey?: string;
  type?: RangeInputType;
}

/**
 * This functional component uses a npm package 'NumberFormat' to
 * handle the entered value and format it correctly.
 *
 * https://github.com/s-yadav/react-number-format
 * @param props
 */
export const RangeInput = (props: IRangeInputProps) => {
  const {
    setFilter,
    resetFilter,
    storedSelection,
    max,
    label,
    groupKey,
    step,
    showLabel = props.showLabel ?? true,
    size = "small",
    allowLeadingZeros,
    thousandSeparator = props.thousandSeparator ?? NumericSeparator.Period,
    decimalSeparator = NumericSeparator.Comma,
    allowedDecimalSeparators = [NumericSeparator.Comma],
    allowNegative = false,
    allowDecimal = props.allowDecimal ?? true
  } = props;

  const type = props.type ?? RangeInputType.NUMERIC;

  const emptyInput = { from: "", to: "" };
  const [inputValue, setInputValue] = useState<IRangeValue>(storedSelection?.from || storedSelection?.to ? {from: storedSelection?.from ?? '', to: storedSelection?.to ?? ''} : emptyInput);
  const [previous, setPrevious] = useState<IRangeValue>(inputValue);

  useEffect(() => {
    if (!storedSelection) {
      setPrevious({ from: "", to: "" });
      setInputValue({ from: "", to: "" });
    }
  }, [storedSelection]);

  /**
   * This method validates the value as number against the step property,
   * if the later is present.
   *
   * - If no changes need to be made we just return the value itself.
   * - If the value is not valid we modify it by rounding towards the next
   *   larger step value.
   *
   * @param {string} [value] We can be sure that the given value is a number
   * as this is already validated by the NumberFormat component itself.
   */
  const getValueBySteps = useCallback((value: string): string => {
    const num = +value;
    if (!step || num % step === 0) {
      return value;
    }

    const modulo = num % step;
    return `${num - modulo + step}`;
  }, [step]);

  /**
   * This method modifies the inputValues of the NumberFormat components to fit accordingly to the given step's property value.
   */
  const getInputValueBySteps = useCallback(() => {
    if(typeof inputValue.from !== "string" || typeof inputValue.to !== "string") {
      return inputValue;
    }

    let stepwiseValue: IRangeValue | null = null;
    const stepwiseFrom = getValueBySteps(inputValue.from);
    const stepwiseTo = getValueBySteps(inputValue.to);

    if (stepwiseFrom || stepwiseTo) {
      stepwiseValue = {
        from: stepwiseFrom,
        to: stepwiseTo
      };
      setInputValue(stepwiseValue);
    }

    return stepwiseValue;
  }, [inputValue, getValueBySteps]);

  /**
   * This method handles the update process of the NumberFormat components.
   *
   * An update gets triggered either on onBlur or a submission via the 'Enter' key.
   * If the step property is defined the input data get modified accordingly.
   */
  const handleUpdate = useCallback(() => {
    if (!inputValue.from && !inputValue.to) {
      resetFilter(groupKey);

      return;
    }

    if (previous.to === inputValue.to && previous.from === inputValue.from) {
      return;
    }

    const stepwiseInputValue = !!step ? getInputValueBySteps() : null;
    setPrevious(stepwiseInputValue ?? inputValue);
    setFilter({
      groupKey: groupKey,
      groupLabel: label,
      ...(stepwiseInputValue ?? inputValue)
    });
  }, [inputValue, previous, step, getInputValueBySteps, groupKey, label, resetFilter, setFilter]);

  useEffect(() => {
    if (type === RangeInputType.DATE && inputValue !== previous) {
      handleUpdate();
    }
  }, [inputValue, previous, type, handleUpdate]);


  /**
   * This method gets called on each key press of any of the NumberFormat components.
   *
   * If the 'Enter' key is pressed we submit the value.
   */
  const handleEnterKey = (event: any) => {
    if (event.key === "Enter") {
      event.target.blur();
    }
  };

  /**
   * This method gets called to validte the users input.
   *
   * If it returns true, the users value gets processed furhter.
   */
  const handleInput = (values: any) => {
    const { floatValue, value } = values;

    //allow reset
    if(value?.length === 0) {
      return true;
    }

    // value is empty or null
    if (!allowDecimal && !floatValue) {
      return false;
    }
    // has decimals
    else if (!allowDecimal && !!value && !!floatValue && (floatValue % 1 !== 0 || value.indexOf(".") !== -1)) {
      return false;
    }

    //max value is set
    if (max) {
      const maxValue = value.charAt(0) === "0" ? max.toString().slice(0, -1) : max;

      //float/value is greater max value
      if (floatValue > maxValue) {
        return false;
      }
    }

    return true;
  };

  /**
   * This method is required to enable the step property handling.
   */
  const localizeNumber = (num: string): string => {
    if (allowLeadingZeros) {
      return num;
    }

    if (!num) return "";
    const value = +num;
    const formatter = Intl.NumberFormat("de-DE");
    return formatter.format(value);
  };


  /**
   * This constant summarizes all props required for the NumberFormat components to prevent repetitions.
   */
  const numericFormatProps = {
    thousandSeparator,
    decimalSeparator,
    allowedDecimalSeparators,
    allowNegative,
    allowLeadingZeros,
    isAllowed: handleInput,
    onBlur: handleUpdate,
    onKeyPress: handleEnterKey,
    className: `${size} range-input`
  };

  const inputTitle = showLabel ? <p className='range-input-title' dangerouslySetInnerHTML={{ __html: label }}/> : null;

  const input = (key) => {
    return type === RangeInputType.NUMERIC ? numericInput(key) : dateInput(key);
  };

  const numericInput = (key) => {
    return <div className={"input-wrapper"}><NumberFormat id={`${key}-${groupKey}`}
                         name={`${key}-${groupKey}`}
                         value={localizeNumber(inputValue[key])}
                         onValueChange={({ value }: NumberFormatValues) => setInputValue({
                           ...inputValue,
                           [key]: value
                         })}
                         {...numericFormatProps}
    /></div>;
  };

  const dateInput = (key) => {
    const value = inputValue[key];
    const initialSelected = () => {
      return value ? new Date(value) : null;
    };

    const setAdjustedDateValue = (value: Date) => {
      if (!!value && key === "to") {
        value.setHours(23);
        value.setMinutes(59);
      } else if (!!value && key === "from") {
        value.setHours(0);
        value.setMinutes(0);
      }

      setInputValue({ ...inputValue, [key]: value });
    };


    return <div className={'input-wrapper ' + css(styles.inputWrapper)} id={`${key}-${groupKey}`}>
      <DatePicker
        name={`${key}-${groupKey}`}
        locale='de'
        selected={initialSelected()}
        closeOnScroll={true}
        onSelect={setAdjustedDateValue}
        autocomplete={"off"}
        dateFormat={"dd.MM.yyyy"}
      />
      <DatePickerIcon
        className={css(styles.svg)}
        viewBox='0 0 40 40'
        color={SvgVariant.Secondary}
        titleAccess='Datum'/>
    </div>;
  };

  return (
    <div className='range-input-wrapper'>
      {inputTitle}
      {input("from")}
      <span className='separator-label'>
                bis
            </span>
      {input("to")}
    </div>
  );
};

const styles = StyleSheet.create({
  inputWrapper: {
    position: "relative",
    maxWidth: "8.5rem"
  },
  svg: {
    position: "absolute",
    right: 0,
    top: 0,
    width: "var(--size-40)",
    height: "var(--size-40)",
    pointerEvents: "none"
  }
});
