import React, { useEffect, useState } from "react";
import Image from "react-graceful-image";
import axios from "axios";
import { LoadingSpinnerBlueIcon } from "../icons";
import { css, StyleSheet } from "aphrodite";
import { CssSize } from "../../shared/helpers/styles.helper";

const MAX_RETRY = 3;
const RETRY_DELAY = 2000;

export const GracefulImage = React.memo((props: any) => {
  const [src, setSrc] = useState(null);
  const [rerender, setRerender] = useState(0);
  const [showSpinner, setShowSpinner] = useState(props.showSpinner ?? false);

  useEffect(() => {
    let didCancel = false;
    let timeout;

    //TODO should we switch to API.get
    async function loadImageOrSetTimerForRetry() {
      if (src === null) {
        setShowSpinner(true);
        axios.get(props.src)
          .then((response) => {
            if (response.status === 200 && !didCancel) {
              setSrc(props.src);
              setShowSpinner(false);
            }
          })
          .catch(() => {
            if (rerender < MAX_RETRY) {
              timeout = setTimeout(() => {
                setRerender(prevState => prevState + 1);
              }, RETRY_DELAY);
            }
            else {
              setShowSpinner(false);
            }
          });
      }
    }

    loadImageOrSetTimerForRetry();
    return function cleanUp() {
      if (timeout) {
        clearTimeout(timeout);
      }

      didCancel = true;
    };
  }, [rerender, src, props.src, setSrc, setRerender, setShowSpinner]);

  return <div className={css(styles.container)}>{showSpinner && <div className={`${css(styles.loadingIconContainer)} icon-container`}><LoadingSpinnerBlueIcon titleAccess='Test' viewBox={"0 0 16 16"} className='loading'/></div>}{src && <Image {...props} src={src}/>}</div>;
});

const styles = StyleSheet.create({
  container: {
    position: "relative",
  },
  loadingIconContainer: {
    width: CssSize.s20,
    zIndex: 1,
    position: "absolute",
    left: "50%",
    top: "50%",
    transform: "translateX(-50%) translateY(-50%)"
  }
});
