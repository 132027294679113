import { useEffect } from 'react';
import { KeyCodeHelper } from '../shared/helpers/key-code.helper';

export function useEscapeKey(callback: (...props) => void) {

  useEffect(() => {
    const onEscapeKeyDown = ({ keyCode }: KeyboardEvent): void => {
      if (KeyCodeHelper.isEscape(keyCode)) {
        callback?.();
      }
    };

    window.addEventListener('keydown', onEscapeKeyDown);

    return () => {
      window.removeEventListener('keydown', onEscapeKeyDown);
    }
  }, [callback]);
}
