import { Record } from 'immutable';
import { KeyValuePairs } from '../types/key-value-pairs.type';

export interface IPersonGroupParams {
  id: string;
  title: string;

  properties?: KeyValuePairs[];

  updatedAt?: Date;
  updatedByTitle?: string;
}

const defaults: IPersonGroupParams = {
  id: '',
  title: '',
  properties: null,
  updatedAt: null,
  updatedByTitle: null,
};

export class PersonGroupModel extends Record<IPersonGroupParams>(defaults) {
  constructor(params?: IPersonGroupParams) {
    params ? super(params) : super();
  }

  with(values: IPersonGroupParams): this {
    return this.merge(values) as this;
  }
}
