/* eslint-disable react-hooks/exhaustive-deps */

import React from "react";
import { Cropper } from "../cropper";

interface IImageCropperProps {
  status: any, //TODO improve any
  setNewBase64: (base64) => void, //controls weather to set current cropped image as new image
  currentBase64: string,
  width: number,
  height: number,
}

export const isSetBase64 = (base64: string) => {
  const EMPTY_BASE64 = "data:,";
  return !!base64?.length && base64 !== EMPTY_BASE64;
};

export const ImageCropper = React.memo((props: IImageCropperProps) => {
  const {
    status,
    setNewBase64,
    width,
    height,
    currentBase64,
  } = props;

  const onCropHandler = (croppedImageAsBase64: string) => {
    if (isSetBase64(croppedImageAsBase64)) {
      setNewBase64(croppedImageAsBase64);
    }
  };

  return <>
    {
      status.isCropping && <Cropper
        width={width}
        height={height}
        src={currentBase64}
        onCropHandler={onCropHandler}
      />
    }
  </>;
});
