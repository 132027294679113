import React, { useEffect, useState } from "react";
import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import { useHistory, withRouter } from "react-router-dom";
import { IWithRouterProps } from "../../../shared/interfaces/with-router-props.interface";
import { HttpService } from "../../../shared/services/http.service";
import { LogService } from "../../../shared/services/log.service";
import { ControlsHeader } from "../../controls-header/controls-header";
import { BinIcon } from "../../icons/collection/bin-icon";
import { TableIcon } from "../../icons/collection/table-icon";
import { CompanyIcon } from "../../icons/collection/company-icon";
import { AffiliateInfoBadgeWithStore } from "../../info-badge/affiliate-info-badge";
import { Tab, Tabs } from "../../tabs";
import { Button, ButtonColor, ButtonIconPosition } from "../../button";
import { Dialog, DialogControl } from "../../overlay/dialog";
import { SvgVariant } from "../../icons/enum/svg-variant.enum";
import { AffiliateDatasetHistory } from "../../dataset-history/affiliate-dataset-history";
import { ApiEndpoint } from "../../../shared/constants/api-endpoint.enum";
import { css, StyleSheet } from "aphrodite/no-important";
import { AffiliateDetailInfoViewWithStore } from "./affiliate-detail-info/affiliate-detail-info.view";
import { AffiliateDetailOwnerViewWithStore } from "./affiliate-detail-owner/affiliate-detail-owner.view";
import { AffiliateDetailTenanciesViewWithStore } from "./affiliate-detail-tenancy/affiliate-detail-tenancy.view";
import { AffiliateDetailAgentObjectsViewWithStore } from "./affiliate-detail-agent/affiliate-detail-agent-objects.view";
import { useUser } from "../../../contexts/user.context";
import { connect } from "react-redux";
import { performGetRequest } from "../../../shared/redux/middleware/api.middleware";
import { AffiliateActionTypes } from "../../../shared/redux/affiliate/affiliate.action-types";
import { AffiliateModel } from "../../../shared/models/affiliate.model";
import { IState } from "../../../shared/interfaces/state.interface";
import { selectAffiliateWithAddress, selectIsLoading } from "../../../shared/redux/affiliate/affiliate.selectors";
import { Loader } from "../../loader/loader";

interface IAffiliateDetailViewProps extends IWithRouterProps<{ affiliateId: number }> {
  fetchAffiliate: (affiliateId: number) => Promise<AffiliateModel>;
  affiliate: AffiliateModel;
  isLoading: any;
}

export const AffiliateDetailViewWithoutStore = React.memo(withRouter((props: IAffiliateDetailViewProps) => {
  const {
    match,
    fetchAffiliate,
    affiliate,
    isLoading
  } = props;
  const [affiliateId, setAffiliateId] = useState<number>(null);
  const [showDeleteDialog, setShowDeleteDialog] = useState(false);
  const [activeTab, setActiveTab] = useState("Info");
  const history = useHistory();

  useEffect(() => {
    setAffiliateId(match.params.affiliateId);
  }, [match]);

  useEffect(() => {
    if (affiliateId !== null) {
      fetchAffiliate(affiliateId).then();
    }
  }, [affiliateId, fetchAffiliate]);

  const { getGroups } = useUser();

  const [writePermissions, setWritePermissions] = useState(false);

  useEffect(() => {
    if (getGroups()?.includes("admin") || getGroups()?.includes("editor")) {
      setWritePermissions(true);
    }
  }, [getGroups]);

  const onDialogControlClick = async (submitDelete: boolean) => {
    if (submitDelete && writePermissions) {
      try {
        await HttpService.performDeleteRequest(`${ApiEndpoint.AFFILIATE}/${affiliateId}`);
        history.push("/tenancies");
      } catch (error) {
        LogService.error("onDialogControlClick: Http request failed.", error);
      }
    }
    setShowDeleteDialog(false);
  };

  const getContentByTab = (tab: string) => {
    switch (tab) {
      case "Info":
        return <AffiliateDetailInfoViewWithStore/>;
      case "Eigentümer":
        return <AffiliateDetailOwnerViewWithStore/>;
      case "Mieter":
        return <AffiliateDetailTenanciesViewWithStore/>;
      case "Makler":
        return <AffiliateDetailAgentObjectsViewWithStore/>;
      default:
        return <h2>No Content for: {tab}</h2>;
    }
  };

  return (
    <>
      {
        showDeleteDialog && writePermissions &&
        <Dialog
          title='Sind Sie sicher?'
          onClose={() => setShowDeleteDialog(false)}
        >
          <Dialog.Message>
            Wenn sie auf „Ja“ klicken wird der vollständige Datensatz<br/>
            gelöscht und kann nicht wiederhergestellt werden.
          </Dialog.Message>
          <Dialog.Controls>
            <DialogControl label='Abbrechen' value={false} color={ButtonColor.Basic}
                           onClick={onDialogControlClick}></DialogControl>
            <DialogControl label='Löschen' value={true} color={ButtonColor.Danger}
                           onClick={onDialogControlClick}></DialogControl>
          </Dialog.Controls>
        </Dialog>
      }
      <>{!!affiliate?.id && <Container fluid className='affiliate-detail-view' style={{ paddingTop: "30px" }}>
        <Row>
          <Col className={css(styles.overflowWrap)}>
            <ControlsHeader>
              <div className={css(styles.badgeWrapper)}>
                <Button
                  btnClass={css(styles.marginRight)}
                  label='Übersicht'
                  color={ButtonColor.Default}
                  iconPosition={ButtonIconPosition.Left}
                  onClick={() => history.push("/tenancies")}>
                  <TableIcon
                    viewBox='0 0 40 40'
                    color={SvgVariant.Secondary}
                    titleAccess={"Zur Übersicht"}
                  />
                </Button>
                <AffiliateInfoBadgeWithStore>
                  <CompanyIcon viewBox={"0 0 40 40"} titleAccess={"Firma/Person Info Badge"}></CompanyIcon>
                </AffiliateInfoBadgeWithStore>
              </div>
              <Tabs
                controlsOnly={true}
                onSelect={(tab) => setActiveTab(tab)}
                activeTab={activeTab}
              >
                <Tab label='Info'></Tab>
                <Tab label='Eigentümer'></Tab>
                <Tab label='Mieter'></Tab>
                <Tab label='Makler'></Tab>
              </Tabs>
              {writePermissions && <Button
                label='Datensatz löschen'
                color={ButtonColor.Default}
                iconPosition={ButtonIconPosition.Left}
                onClick={() => setShowDeleteDialog(true)}>
                <BinIcon
                  viewBox='0 0 40 40'
                  color={SvgVariant.Secondary}
                  titleAccess={"Datensatz löschen"}
                />
              </Button>}
            </ControlsHeader>
          </Col>
        </Row>
        <Row>
          <Col className={css(styles.overflowWrap)}>
            <AffiliateDatasetHistory/>
          </Col>
        </Row>
        <Row>
          <Col className={css(styles.overflowWrap)}>
            {getContentByTab(activeTab)}
          </Col>
        </Row>
      </Container>}
        {(!!affiliate?.id && !!isLoading) && <Loader/>}
        {!affiliate?.id && !isLoading && <h3>404 Page not found</h3>}
      </>
    </>
  );
}));

const styles = StyleSheet.create({
  marginRight: {
    marginRight: 10
  },
  badgeWrapper: {
    display: "flex",
    alignItems: "center"
  },
  overflowWrap: {
    overflowWrap: "anywhere"
  }
});

const mapStateToProps = (state: IState) => {
  return {
    affiliate: selectAffiliateWithAddress(state),
    isLoading: selectIsLoading(state)
  }
};

const mapDispatchToProps = (dispatch: any) => ({
  fetchAffiliate: (affiliateId: number) => dispatch(
    performGetRequest(
      `${ApiEndpoint.AFFILIATE}/${affiliateId}`,
      AffiliateActionTypes.FETCH_IN_PROGRESS,
      AffiliateActionTypes.SET_AFFILIATE,
      AffiliateActionTypes.FETCH_FINISHED
    )
  )
});

export const AffiliateDetailView = connect(mapStateToProps, mapDispatchToProps)(AffiliateDetailViewWithoutStore);
