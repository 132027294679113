import React, { useState, useEffect } from 'react'
import { Dialog, DialogControl } from '../overlay/dialog'
import { ButtonColor } from '../button'

interface IDeleteUserDialog {
  onControlClick: (value: boolean) => void;
  showDialog: boolean;
}

export const DeleteUserDialog = React.memo((props: IDeleteUserDialog) => {

  const {
    onControlClick,
    showDialog,
  } = props;

  const [isDialogVisible, setIsDialogVisible] = useState(false);

  useEffect(() => {
    setIsDialogVisible(showDialog);
  }, [showDialog])

  return (
    isDialogVisible && <Dialog
      title='Sind Sie sicher?'
      onClose={() => onControlClick(false)}
    >
      <Dialog.Message>
        Wenn sie auf „Löschen“ klicken wird der Eintrag<br />
        gelöscht und kann nicht wiederhergestellt werden.
      </Dialog.Message>
      <Dialog.Controls>
        <DialogControl label='Abbrechen' value={false} color={ButtonColor.Basic} onClick={onControlClick}></DialogControl>
        <DialogControl label='Löschen' value={true} color={ButtonColor.Danger} onClick={onControlClick}></DialogControl>
      </Dialog.Controls>
    </Dialog>
  );
});
