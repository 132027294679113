import React from 'react';
import { css, StyleSheet } from 'aphrodite';
import { CssColor, CssSize } from '../../shared/helpers/styles.helper';

interface IBadgeProps {
    value: string | number;
    backColor?: CssColor;
    textColor?: CssColor;
}

export const Badge = React.memo((props: IBadgeProps) => {
    const {
        value,
        backColor = CssColor.Secondary,
        textColor = CssColor.White,
    } = props;

    return (
        <div className={css(styles(backColor, textColor).container)}>
            {value}
        </div>
    );
});

const badgeDimensions = {
    height: CssSize.s20,
    width: CssSize.s40,
};

const styles = (backColor: CssColor, textColor: CssColor) => StyleSheet.create({
    container: {
        backgroundColor: backColor,
        color: textColor,
        borderRadius: CssSize.s10,
        fontSize: '14px',
        textAlign: 'center',
        lineHeight: badgeDimensions.height,
        height: badgeDimensions.height,
        width: badgeDimensions.width
    }
});