export enum ApiEndpoint {
  // ### Property
  PROPERTY = 'properties',
  PROPERTY_SUGGESTIONS = 'properties/suggestions',

  // ## Media
  MEDIA_IMAGE = 'properties/$:propertyId/media/images',
  MEDIA_VIDEO = 'properties/$:propertyId/media/videos',

  // ### IntroTexts
  INTRO_TEXTS = 'introtexts',

  // ### PersonGroups
  PERSON_GROUPS = 'persongroups',

  // ### Regions
  REGION = "regions",

  // ### Autocomplete
  AUTOCOMPLETE_POSTAL_CODES = 'autocomplete/postalcodes',
  AUTOCOMPLETE_PROPERTIES = 'autocomplete/properties',

  // ### Affiliates
  AFFILIATE = 'affiliates',

  // ### Tenancies
  TENANCY = 'tenancies',
  TENANCY_SUGGESTIONS = 'tenancies/suggestions',

  // ### Owners
  OWNERS = 'owners',

  // ## PLACEHOLDERS
  PropertyIdPlaceholder = '$:propertyId',

  // ### Regions
  USERS_META = "users-meta",

}
