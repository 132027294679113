import { KeyValuePairs } from '../../../../shared/types/key-value-pairs.type';
import { IFieldAddressArrayState } from './field-address-array.reducer';

export function selectPrimaryAddress(state: IFieldAddressArrayState): KeyValuePairs {
  return state.addresses?.find((address) => address.isPrimary);
}

export function selectSecondaryAddresses(state: IFieldAddressArrayState): KeyValuePairs[] {
  return state.addresses?.filter((address) => !address.isPrimary);
}
