import React from 'react';
import { TemplateType } from '../enums/template-type.enum';
import { FieldSingle } from '../fields/field-single';
import { FieldCompose } from '../fields/field-compose';
import { FieldArray } from '../fields/field-array/field-array';
import { FieldTabArray } from '../fields/field-tab-array/field-tab-array';
import { GoogleAddressInput } from '../../inputs/google-address-input/google-address-input';
import { LogService } from '../../../shared/services/log.service';
import { FieldCollapsable } from '../fields/field-collapsable';
import { FieldTypeUnion } from '../types/field-type.union';
import { FieldAddressArrayWithRouter} from '../fields/field-address-array/field-address-array';
import { FieldTable } from '../fields/field-table/field-table';
import { FieldRelationConnector } from '../fields/field-relation-connector/field-relation-connector';
import { FieldSets } from "../fields/field-sets";
import {MapboxAddressInput} from "../../inputs/mapbox-address-input/mapbox-address-input";


export abstract class FormHelper {

  static renderFieldByTemplateType(
    field: FieldTypeUnion,
    options?: any
  ): JSX.Element {
    switch (field?.templateType) {
      case TemplateType.FIELD_SINGLE:
        return <FieldSingle {...field} {...options}/>;
      case TemplateType.FIELD_COMPOSE:
        return <FieldCompose {...field} {...options}/>;
      case TemplateType.FIELD_COLLAPSABLE:
        return <FieldCollapsable {...field} {...options}/>;
      case TemplateType.FIELD_ARRAY:
        return <FieldArray {...field} {...options}/>;
      case TemplateType.FIELD_SETS:
        return <FieldSets {...field} {...options}/>;
      case TemplateType.FIELD_TAB_ARRAY:
        return <FieldTabArray {...field} {...options}/>;
      case TemplateType.GOOGLE_ADDRESS:
        return <FieldSingle {...field} {...options}><GoogleAddressInput {...field} {...options}/></FieldSingle>
      case TemplateType.MAPBOX_ADDRESS:
        return <FieldSingle {...field} {...options}><MapboxAddressInput {...field} {...options}/></FieldSingle>
      case TemplateType.FIELD_ADDRESS_ARRAY:
        return <FieldAddressArrayWithRouter {...field} {...options}/>
      case TemplateType.FIELD_TABLE:
        return <FieldTable {...field} {...options}/>
      case TemplateType.FIELD_RELATION_CONNECTOR:
        return <FieldRelationConnector {...field} {...options}/>
      default:
        LogService.error('FormHelper: Invalid Data. Template Type is missing.');
        break;
    }
  }

}
