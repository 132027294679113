import { IFlashMessage } from '../../shared/interfaces/flash-message.interface';
import { FlashMessagesActionTypes } from './flash-messages.action-types';

export function addMessage(message: IFlashMessage) {
  return {
    type: FlashMessagesActionTypes.ADD_MESSAGE,
    message
  }
}

export function removeMessage(id: number) {
  return {
    type: FlashMessagesActionTypes.REMOVE_MESSAGE,
    id
  }
}
