import React, {useContext} from 'react';
import {useHistory, withRouter} from 'react-router-dom';
import {TableContext} from '../../../contexts/table.context';
import {ITableHeaderAndFooterConfig} from '../../../shared/interfaces/table-header-config.interface';
import {IWithRouterProps} from '../../../shared/interfaces/with-router-props.interface';
import _ from "mudash";
import {StyleSheet} from "aphrodite/no-important";
import {css} from "aphrodite";
import {DateTimeFormat, FormattingHelper} from "../../../shared/services/formatting.helper";

interface ITableRowProps extends IWithRouterProps {
  urlPath: string;
  urlPathDetailValue: string;
}

/**
 * This is a generic TableRow Component rendering
 * multiple entities given in its Context
 */
export const TableRow = withRouter((props: ITableRowProps) => {
  const {
    urlPath,
    urlPathDetailValue
  } = props;
  const { headers, rows, sortByColumn } = useContext(TableContext);
  const history = useHistory();

  const formatNumber = (value: number) => {
    const formatter = Intl.NumberFormat();
    return formatter.format(Math.floor(value));
  };

  return (
    <>
      {rows?.map((row, index) => {
        return (
          <tr key={index} onClick={() => history.push(`/${urlPath}/${row?.[urlPathDetailValue]}`)}>
            {headers?.map((column: ITableHeaderAndFooterConfig) => {
              if (_.has(row, column.columnKey)) {
                const rawValue = _.get(row, column.columnKey);

                let displayValue;

                if(!!rawValue && column?.formatNumber) {
                  displayValue = formatNumber(parseFloat(rawValue));
                }
                else if(!!rawValue && column?.formatDate) {
                  displayValue =  FormattingHelper.formatDateTime(rawValue, DateTimeFormat.DD_MM_YYY, column?.hideTime ? null : DateTimeFormat.HH_MM);
                }
                else {
                  displayValue = rawValue;
                }

                return (
                  <td key={column.columnKey}
                    className={column.columnKey === sortByColumn ? 'is-sorted' : ''}
                    style={column?.styles ?? {width: column?.width, minWidth: column?.width ?? null, textAlign: column?.align ?? 'left'}}>
                    <span className={css(column.allowWordWrap && styles.wrap)} dangerouslySetInnerHTML={{__html: displayValue}}/>
                  </td>
                )
              }

              return (
                <td key={Math.random()} style={column?.styles ?? {width: column?.width, minWidth: column?.width ?? null, textAlign: column?.align ?? 'left'}} />
              )
            })}
          </tr>
        )
      })}
    </>
  )
});

const styles = StyleSheet.create({
  wrap: {
    whiteSpace: 'normal'
  },
});

