import { SearchActionTypes } from './search.action-types';

export function setValue(value: string, valueObject: object, isLoading: boolean, hasSelected: boolean) {
  return {
    type: SearchActionTypes.SET_VALUE,
    value,
    valueObject,
    isLoading,
    hasSelected
  }
}

export function setSuggestions(suggestions: []) {
  return {
    type: SearchActionTypes.SET_SUGGESTIONS,
    suggestions,
    isLoading: false
  }
}

export function resetSearch() {
  return {
    type: SearchActionTypes.RESET
  }
}
