import { Record } from 'immutable';
import { IAction } from '../../../../shared/interfaces/action.interface';
import { useReducer } from 'react';

/*
This custom hook is a plain copy of the useIntroTextsState. Please consider generalizing the implementation and re-use properly.
*/


enum RegionsStatus {
    initial = 'initial',
    createNew = 'createNew',
    ready = 'ready',
}

interface IRegionstateParams {
    status?: RegionsStatus;
}

enum RegionsStateActionTypes {
    Set = 'Set',
    CreateNew = 'CreateNew',
}

const defaults: IRegionstateParams = {
    status: RegionsStatus.initial,
};

class RegionsState extends Record<IRegionstateParams>(defaults) {
    constructor(params?: IRegionstateParams) {
        params ? super(params) : super();
    }

    with(values: IRegionstateParams): this {
        return this.merge(values) as this;
    }
}

function reducer(state: RegionsState, action: IAction): RegionsState {
    switch (action.type) {
        case RegionsStateActionTypes.Set:
            return new RegionsState({
                ...state,
                status: RegionsStatus.ready
            });


        case RegionsStateActionTypes.CreateNew:
            return new RegionsState({
                ...state,
                status: RegionsStatus.createNew
            });

        default: return state;
    }
}

abstract class RegionsStateActions {
    static get set() { return { type: RegionsStateActionTypes.Set }; }
    static get add() { return { type: RegionsStateActionTypes.CreateNew }; }
}

const initialRegionsState: RegionsState = new RegionsState(defaults);

export function useRegionsState() {
    const [state, dispatch] = useReducer(reducer, initialRegionsState);

    const isInitial = state.status === RegionsStatus.initial;
    const isNew = state.status === RegionsStatus.createNew;
    const isReady = state.status === RegionsStatus.ready;

    return {
        state: { isInitial, isNew, isReady, ...state },
        setCreateNewState: () => dispatch(RegionsStateActions.add),
        setUpdateState: () => dispatch(RegionsStateActions.set)
    };
}