import { css, StyleSheet } from "aphrodite/no-important";
import React, { useEffect, useMemo, useState } from "react";
import {
  IFieldCollapsableTitleBar,
  TitleBarDateType,
  TitleBarNumberType
} from "../../../interfaces/field-collapsable.interface";
import { FormattingHelper } from "../../../../../shared/services/formatting.helper";
import { connect } from "react-redux";
import { IState } from "../../../../../shared/interfaces/state.interface";

interface ITitleBarItemProps {
  field: any;
  titleBarItem: IFieldCollapsableTitleBar;
  index: number;
  isExpanded: boolean;
  state: IState;

  smaller?: boolean,
  highlight?: boolean,
  visible?: boolean

}

const dateFields = ["purchaseDate", "rentalStart", "rentalEnd"];

const TitleBarItemWithoutState = (props: ITitleBarItemProps) => {
  const {
    state,
    field,
    titleBarItem,
    index,
    isExpanded,
    smaller = false,
    highlight = false,
    visible = true
  } = props;

  const [niceValue, setNiceValue] = useState<any>();

  const value = useMemo(() => field?.value, [field]);

  useEffect(() => {
    let didCancel = false;

    let newVal;

    const renderNiceTitle = async () => {
      if (!didCancel) {
        let niceValue = value;

        if (!niceValue) {
          return;
        }

        if (!isNaN(niceValue)) {
          if(titleBarItem.numberType === TitleBarNumberType.DECIMAL) {
            //niceValue = niceValue;
          }
          else {
            niceValue = FormattingHelper.formatNumber(parseFloat(niceValue));
          }
        }

        if (!!niceValue && dateFields.includes(titleBarItem.replaceValue)) {
          if (titleBarItem.dateType === TitleBarDateType.Month) {
            niceValue = FormattingHelper.formatEditorialMonthYearDate(niceValue);
          } else {
            niceValue = FormattingHelper.formatDate(niceValue);
          }
        }

        if (typeof titleBarItem.title === "function") {
          newVal = await titleBarItem.title(state, value, niceValue);
          setNiceValue(newVal);
        } else if (typeof titleBarItem.title === "string") {
          newVal = titleBarItem.title.replace("$0", niceValue ?? "");
          setNiceValue(newVal);
        }
      }
    };

    renderNiceTitle();

    return () => {
      didCancel = true;
      newVal = false;
    };
  }, [value, titleBarItem, state]);

  return (
    <div key={index} className={css(
      styles.container,
      highlight && styles.highlight,
      !highlight && smaller && styles.smaller,
      !visible && styles.hide
    )}>
      <p className={css(!isExpanded && highlight ? styles.wrapInnerTitle : styles.singleTitle)}
         dangerouslySetInnerHTML={{ __html: niceValue }}/>
    </div>
  );
};

const mapStateToProps = (state: IState) => ({ state });

export const TitleBarItem = connect(mapStateToProps)(TitleBarItemWithoutState);

const styles = StyleSheet.create({
  container: {
    display: "flex",
    alignItems: "center",
    color: "var(--grey)",
    margin: '0.2rem 0'
  },
  highlight: {
    color: "var(--black)"
  },
  smaller: {
    fontSize: 12
  },
  wrapInnerTitle: {
    maxWidth: "100%",
    margin: 0,
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
    overflow: "hidden"
  },
  singleTitle: {
    ":not(:empty)": {
      marginRight: "var(--size-50)",
      marginBottom: 0,
      whiteSpace: "nowrap"
    }
  },
  hide: {
    display: "none"
  }
});
