export interface IFlashMessage {
  id: number;
  status: IResponseStatusCode;
  text: string;
}

export enum IResponseStatusCode {
  SUCCESS = 200,
  ERROR = 400
}
