import { ISelectItem } from '../../components/inputs/interfaces/select-item.interface';

export const PropertyConditionCollectionFilter: ISelectItem[] = [
  { label: 'Alle', isChecked: true, value: '' },
  { label: 'Neubau', isChecked: false, value: 'newBuilding' },
  { label: 'Neuwertig', isChecked: false, value: 'asNew' },
  { label: 'Gepflegt', isChecked: false, value: 'wellKept' },
  { label: 'Saniert', isChecked: false, value: 'renovated' },
  { label: 'Sanierungsbedürftig', isChecked: false, value: 'unrenovated' },
].map((item, index) => {
  return {
    id: `property-condition-${index+1}`,
    ...item
  }
});
