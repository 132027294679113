import { IFieldSingle } from '../interfaces/field-single.interface';
import { InputType } from '../enums/input-type.enum';
import { TemplateType } from '../enums/template-type.enum';

export abstract class FieldSingleConfig {
  private static DEFAULT_CONFIG: IFieldSingle = {
    templateType: TemplateType.FIELD_SINGLE,
    inputType: InputType.TEXT,
    name: null,
    value: null,
    label: null,
    fallbackName: null,
    isFallback: false,
    readonly: false,
    isAutofill: false,
    placeholderText: null,
    hideInputEnumeration: true,
    options: null,
    attributes: null,
    fields: null,
    condensed: false,
  };

  static getConfig(options: Partial<IFieldSingle>) {
    return {
      ...FieldSingleConfig.DEFAULT_CONFIG,
      ...options
    }
  }
}
