import React, { useState, useRef, useContext } from 'react';
import { AddSuggestionButton } from './add-suggestion-button';
import { css, StyleSheet } from 'aphrodite';
import { SuggestionOverlay } from './suggestion-overlay';
import { useClickOutside } from '../../hooks/use-click-outside';
import { SuggestionDropdownContext } from '../../contexts/suggestion-dropdown.context';

export const SuggestionDropdown = () => {
  const {
    onSelect
  } = useContext(SuggestionDropdownContext);
 
  const [showOverlay, setShowOverlay] = useState(false);
  const overlayRef = useRef(null);
  useClickOutside(overlayRef, () => setShowOverlay(false));

  const handleOnSelect = (selectedValue: string) => {
    onSelect(selectedValue);
    setShowOverlay(false);
  }

  return (
    <div className={css(styles.container)} >
      <AddSuggestionButton
        isPressed={showOverlay}
        onClick={() => setShowOverlay(true)}
      />
      <SuggestionOverlay
        isOpen={showOverlay}
        onSelect={handleOnSelect}
        ref={overlayRef}
      />
    </div>
  );
}

const styles = StyleSheet.create({
  container: {
    position: 'relative'
  }
});