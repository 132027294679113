import { IState } from "../../../shared/interfaces/state.interface";
import { FieldSingleConfig } from "../config/field-single.config";
import { TemplateType } from "../enums/template-type.enum";
import { AffiliateDataService } from "../../../shared/services/affiliate-data.service";
import { ISelectableObject } from "../../../shared/interfaces/selectable.interface";

export abstract class TenancyInputDefinitions {
  static AffiliateSelectorInputField = (selectableObject: (state: IState, id: number) => Promise<ISelectableObject>) => {
    return {
      ...FieldSingleConfig.getConfig({
        templateType: TemplateType.FIELD_RELATION_CONNECTOR,
        name: "_affiliateId",
        label: "Name (ID)",
        relationUrlPath: "/affiliates",
        onRelationSearch: async (searchTerm: string) => await AffiliateDataService.searchAffiliate(searchTerm), //TODO use searchservice
        selectableObject
      })
    };
  };
}
