import React, { useEffect, useRef } from "react";
import { css, StyleSheet } from "aphrodite/no-important";
import { CssSize } from "../../shared/helpers/styles.helper";
import { Button, ButtonColor, ButtonSize } from "../button";
import { BinIcon } from "../icons/collection/bin-icon";
import { SvgVariant } from "../icons/enum/svg-variant.enum";
import { useFormik } from "formik";
import { IRegionsParams, RegionModel } from "../../shared/models/region.model";
import { EntryTitleInput } from "../form/fields/entry-title-input";
import { RegionsDataService } from "../../shared/services/regions-data.service";
import { RegionActions } from "../../shared/redux/regions/regions.action";
import { useDispatch } from "react-redux";
import { CollapsableElements } from "../collapsable-elements/collapsable-elements";
import { SuggestionDropdown } from "../suggestion-dropdown/suggestion-dropdown";
import { PostalCodeDataService } from "../../shared/services/postal-code-data.service";
import { Badge } from "../badge/badge";
import { RegionPostalCodes } from "./region-postal-codes";
import { SuggestionDropdownContext } from "../../contexts/suggestion-dropdown.context";
import { defaultGridTemplateColumns } from "../../shared/constants/media-grid.const";
import { Translated } from "../translated/translated";
import { Language } from "../../shared/constants/language.enum";
import { useCurrentLanguage } from "../../contexts/current-language.context";

interface IRegionRowProps {
  data: RegionModel;
  autoFocus?: boolean,
  onDelete: () => void;
  onUpdate: (region: IRegionsParams) => void;
}

export const RegionRow = (props: IRegionRowProps) => {
  const {
    data = new RegionModel(),
    autoFocus = false,
    onDelete,
    onUpdate
  } = props;

  const titleInputDeRef = useRef<HTMLInputElement>();
  const titleInputEnRef = useRef<HTMLInputElement>();

  const currentLanguage = useCurrentLanguage();

  // Pre-Set the formik field values with empty strings.
  const initialFormikValues = useRef(data.merge({
    title_de: data?.title_de ?? "",
    title_en: data?.title_en ?? ""
  }).toJS());

  useEffect(() => {
    if (autoFocus) {
      if (currentLanguage === Language.GERMAN) {
        titleInputDeRef.current.focus();
      }
      if (currentLanguage === Language.ENGLISH) {
        titleInputEnRef.current.focus();
      }
    }
  }, [autoFocus, currentLanguage]);

  const formik = useFormik({
    initialValues: initialFormikValues.current,
    onSubmit: (values) => onUpdate(values)
  });

  const handleOnBlur = () => {
    const isDistinctValue = !data.equals(new RegionModel(formik.values));
    if (isDistinctValue) {
      formik.submitForm();
    }
  };

  const inputProps = {
    onChange: formik.handleChange,
    onBlur: handleOnBlur
  };

  const dispatch = useDispatch();

  const onSelect = async (postalCode: string) => {
    try {
      const res = await RegionsDataService.addPostalCode(data.id, postalCode);
      dispatch(RegionActions.addPostalCode(data.id, postalCode, res?.data?.updatedByTitle));
    } catch (error) {
      console.error("[RegionRow] onSelect: Failed adding postal-code", error);
    }
  };

  return (
    <div className={css(styles.container)}>

      <div>
        <Translated translations={[
          {
            language: Language.GERMAN,
            value: <EntryTitleInput
              name='title_de'
              ref={titleInputDeRef}
              value={formik.values.title_de}
              {...inputProps}
            />
          },
          {
            language: Language.ENGLISH,
            value: <EntryTitleInput
              name='title_en'
              ref={titleInputEnRef}
              value={formik.values.title_en}
              {...inputProps}
            />
          }
        ]}/>

      </div>

      <div>
        <CollapsableElements
          header={(
            <SuggestionDropdownContext.Provider value={{
              buttonLabel: "Postleitzahl hinzufügen",
              placeholderText: "Postleitzahl",
              noDataText: "Bitte Postleitzahl eingeben",
              maxInputLength: 5,
              selectedSuggestions: data?.postalCodes,
              onSelect,
              getSuggestions: async (searchTerm) => await PostalCodeDataService.fetch(searchTerm)
            }}>
              <div className={css(styles.collapsableHeader)}>
                <SuggestionDropdown/>
                {data?.postalCodes?.length !== null && (
                  <Badge value={data?.postalCodes?.length || 0}/>
                )}
              </div>
            </SuggestionDropdownContext.Provider>
          )}
          content={(
            <RegionPostalCodes regionId={data.id}/>
          )}
        />
      </div>

      <div>
        <Button type={"button"}
                btnClass={"no-padding"}
                size={ButtonSize.Medium}
                color={ButtonColor.Basic}
                showLabel={false}
                label={"Region löschen"}
                onClick={() => onDelete()}>
          <BinIcon
            viewBox='0 0 40 40'
            color={SvgVariant.Secondary}
            titleAccess={"Eintrag löschen"}
          />
        </Button>
      </div>

    </div>
  );
};

const styles = StyleSheet.create({
  container: {
    width: "100%",
    display: "grid",
    gridTemplateColumns: defaultGridTemplateColumns,
    gridGap: CssSize.s10
  },
  nestedGrid: {
    display: "grid",
    gridTemplateColumns: "repeat(3, 1fr)",
    gridGap: CssSize.s10
  },
  collapsableHeader: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center"
  }
});
