import React from 'react';
import { SvgIcon } from '../../components/icons/svg-icon';

/**
 * This method creates a component via React.memo. The outcome component is using
 * forwardRef to be able to access the svg element itself via the parents.
 *
 * @param {SVGPathElement} [svg] This property accepts a svg path element and gets placed into a wrapping svg element.
 * @param {string} [displayName] This property is only for debugging purposes for the dev environment.
 */
export default function createSvgIcon(svg: any, displayName: string) {
  const Component = React.memo(
    React.forwardRef((props: any, ref: React.Ref<any>) => (
      <SvgIcon ref={ref} {...props} >
        {svg}
      </SvgIcon>
    )),
  );

  if (process.env.NODE_ENV !== 'production') {
    Component.displayName = `${displayName}Icon`;
  }

  return Component;
};
