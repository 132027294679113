import {FormikValues} from "formik";
import React from "react";
import {withRouter} from "react-router-dom";
import {IWithRouterProps} from "../../../../shared/interfaces/with-router-props.interface";
import {PropertyModel} from "../../../../shared/models/property.model";
import {FlashMessagesService} from "../../../../shared/services/flash-messages.service";
import {FormService} from "../../../../shared/services/form.service";
import {LogService} from "../../../../shared/services/log.service";
import {CreatePropertyFormDefinition} from "../../../form/definitions/create-property.form-definition";
import {AddressAutofill} from "../../../form/enums/autofill-address.enum";
import {Form} from "../../../form/form";
import {IFieldChangeEvent} from "../../../form/interfaces/field-single.interface";
import {DatasetBase} from "../../dataset-base";
import {ApiEndpoint} from "../../../../shared/constants/api-endpoint.enum";
import {CreatePropertyValidationSchema} from "../../../form/validation-schema/create-property.validation-schema";
import {CountryService} from "../../../../shared/services/country.service";
import {Language} from "../../../../shared/constants/language.enum";
import {MapboxglGeocodingService} from "../../../../shared/services/mapboxglGeocodingService";

const countryService = CountryService.getInstance();

interface ICreatePropertyDatasetProps extends IWithRouterProps {
  currentLanguage: Language;
  closeModal?: () => void;
}

export class CreatePropertyDatasetWithoutRouter extends DatasetBase<ICreatePropertyDatasetProps> {
  readonly createPath = ApiEndpoint.PROPERTY;

  constructor(props: ICreatePropertyDatasetProps) {
    super(props);
    this.state = {
      formDefinition: CreatePropertyFormDefinition,
      addressData: null,
      force: false
    };
  }

  private async handleSubmit(values: FormikValues): Promise<boolean> {

    const flashMessageService = FlashMessagesService.getInstance();

    if (!this.state.addressData) {
      LogService.error("CreatePropertyDataset: Unable to create primary address.");
      flashMessageService.addMessage({
        id: (new Date().getTime()),
        status: 400,
        text: "Es ist ein Fehler aufgetreten, bitte versuchen Sie es erneut"
      });
      return false;
    }

    const country = this.state.addressData.country;
    const countryCode = countryService.getAlpha2Code(country, this.props.currentLanguage);
    const countryDe = countryService.getName(countryCode, "de");
    const countryEn = countryService.getName(countryCode, "en");
    const addressObject = {
      ...this.state.addressData,
      country_de: countryDe,
      country_en: countryEn,
      country_alpha2Code: countryCode
    };

    try {
      const data = await this.createDataset(
        {
          ...values,
          country_de: countryDe,
          country_en: countryEn,
          country_alpha2Code: countryCode,
          addresses: [
            {
              ...addressObject,
              isPrimary: true
            }
          ],
          geometry: addressObject.geometry
        }, '?force=' + this.state.force);


      const record = new PropertyModel(data);
      const propertyId = record.get("propertyId");

      this.setState({...this.state, force: false});

      this.props.history.push(`/properties/${propertyId}`);

      return true;
    } catch (error) {
      LogService.error("Dataset could not be created!", error);

      flashMessageService.addMessage({
        id: (new Date().getTime()),
        status: 400,
        text: error.response?.data?.message ?? error
      });

      this.setState({...this.state, force: true});

      return false;
    }
  }

  private async handleChange(changeEvent: IFieldChangeEvent) {
    const formService = new FormService();

    let value = changeEvent.value;
    let place = null;

    let formChangeObject = {
      [changeEvent.name]: value
    };

    if (changeEvent.name === AddressAutofill.address) {
      const places = await MapboxglGeocodingService.getInstance().findPublityPlacesForQuery(changeEvent?.value, null, ['address'], this.props.currentLanguage);
      place = places?.[0];

      if (!place) {
        return;
      }

      place = {...place, ["country_" + this.props.currentLanguage]: place.country};

      formChangeObject = {...formChangeObject, ...place};
    }

    const updatedFormDefinition = formService.updateFormSets(
      this.state.formDefinition,
      formChangeObject
    );

    let changeObject = {
      ...this.state,
      formDefinition: updatedFormDefinition
    };

    if (!!place) {
      changeObject = {...changeObject, ...{addressData: place}};
    }

    this.setState(changeObject);
  }

  render() {
    return (
      <div className='create-dataset'>
        <Form showButtonControls={true}
              submitButtonLabel={"Anlegen"}
              cancelButtonLabel={"Abbrechen"}
              validationSchema={CreatePropertyValidationSchema}
              submitHandler={this.handleSubmit.bind(this)}
              changeHandler={this.handleChange.bind(this)}
              cancelHandler={this.props.closeModal}
              formDefinition={this.state.formDefinition}
              submitDisabled={!this.state.addressData}
        />
      </div>
    );
  }
}

export const CreatePropertyDataset = withRouter(CreatePropertyDatasetWithoutRouter);
