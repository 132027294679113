import { IState } from '../../interfaces/state.interface';
import { createSelector } from 'reselect';
import { IntroTextModel } from '../../models/intro-text.model';
import { List } from 'immutable';

const baseIntroTextsSelector = ['introTexts'];

const selectIntroTextsBase = (state: IState): IState => state.getIn([...baseIntroTextsSelector]);

export const selectIntroTextsUpdatedAt = createSelector(
  [selectIntroTextsBase],
  (introTextsState) => {
    return introTextsState.get('updatedAt');
  }
);

export const selectIntroTextsUpdatedBy = createSelector(
  [selectIntroTextsBase],
  (introTextsState) => {
    return introTextsState.get('updatedByTitle');
  }
);

export const selectIntroTextTemplates = createSelector(
  [selectIntroTextsBase],
  (introTextsState) => {
    return introTextsState.get('templates')
  }
);

export const selectIntroTextById = (introTextId: string) => createSelector(
  [selectIntroTextsBase],
  (introTextsState) => {
    const introTexts =  introTextsState.get('templates') as List<IntroTextModel>;
    return introTexts.find((introText) => introText.id === introTextId);
  }
);

export const selectPostalCodeCountByIntroText = (introTextId: string) => createSelector(
  [selectIntroTextById(introTextId)],
  (introTextTemplate) => {
    return introTextTemplate?.postalCodes?.length;
  }
);
