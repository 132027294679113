import React, {useEffect, useRef, useState} from 'react';
import {BaseInputField} from '../../form/fields/base-input-field';
import {useClickOutside} from '../../../hooks/use-click-outside';
import {InputType} from '../../form/enums/input-type.enum';
import {LogService} from '../../../shared/services/log.service';
import {FlashMessagesService} from '../../../shared/services/flash-messages.service';
import {MapboxSuggestionItem} from './mapbox-suggestion-item';
import {LoadingSpinnerBlueIcon} from '../../icons';
import {SearchIcon} from '../../icons/collection/search-icon';
import {KeyCodeHelper} from '../../../shared/helpers/key-code.helper';
import {IFieldSingle} from '../../form/interfaces/field-single.interface';
import {AddressValidationHelper} from '../../../shared/helpers/address-validation.helper';
import {useField} from 'formik';
import {BehaviorSubject, from, Subject, Subscription} from "rxjs";
import {debounceTime, distinctUntilChanged, filter, switchMap} from "rxjs/operators";

import './mapbox-address-input.scss';
import {MapboxglGeocodingService} from "../../../shared/services/mapboxglGeocodingService";
import {ISearchResultSuggestion} from "../../../shared/interfaces/search-result-suggestion.interface";
import {useCurrentLanguage} from "../../../contexts/current-language.context";

interface IMapboxAddressInputProps extends IFieldSingle {
  validateForm;
  showInputIcon: boolean;
  searchInEveryCountry?: boolean;
}

const SEARCH_TERM_MIN_LENGTH = 3;
const flashMessageService = FlashMessagesService.getInstance();


const getInputIcon = (isLoading: boolean, label: string): JSX.Element => {
  return isLoading ? (
    <span className='icon-container'>
      <LoadingSpinnerBlueIcon titleAccess='Test' viewBox={'0 0 16 16'} className='loading'/>
    </span>
  ) : (
    <SearchIcon color={'secondary'} viewBox={'0 0 40 40'} titleAccess={label}/>
  );
}

export const MapboxAddressInput = (props: IMapboxAddressInputProps) => {
  const rootElement = useRef(null);
  const {
    value,
    name,
    label,
    placeholderText,
    customCss = '',
    changeHandler,
    validateForm,
    readonly,
    showInputIcon = true,
    searchInEveryCountry = false,
  } = props;

  const [, , {setTouched}] = useField(name);
  const [isFocused, setIsFocused] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [showSuggestions, setShowSuggestions] = useState(false);
  const [suggestions, setSuggestions] = useState<ISearchResultSuggestion[]>([]);
  const [cursor, setCursor] = useState(-1);
  const [infoText, setInfoText] = useState(null);

  const [searchTerm, setSearchTerm] = useState(''); //readonly searchTerm$: BehaviorSubject<string> = new BehaviorSubject('');
  const [subject$,] = useState<Subject<string>>(new BehaviorSubject("")); //private subscription: Subscription;
  const [, setSubscription] = useState<Subscription>(null); //private subscription: Subscription;

  const currentLanguage = useCurrentLanguage();

  useClickOutside(rootElement, (clickedOutside) => {
    setIsFocused(!clickedOutside);
  });

  useEffect(() => {
    subject$.next(searchTerm);
  }, [subject$, searchTerm])

  useEffect(() => {
    const successCallback = (places: ISearchResultSuggestion[]) => {
      // Once we received a response after the user search we update isLoading.
      setIsLoading(false);
      if (!places || places?.length <= 0) {
        /*
        * Once we received a response after the user search and it does not include
        * any suggestions update the info text accordingly.
        */
        setInfoText(!places ? 'Der Dienst steht aktuell nicht zur Verfügung. Versuchen sie es später erneut.' : 'Keine Ergebnisse.');
      } else {
        setSuggestions(places);
      }
    };

    const errorCallback = (error: any) => {
      setIsLoading(false);
      LogService.error('An error occured during `MapboxAddressService.fetchResponse`.', error);
      flashMessageService.addMessage({
        id: Date.now(),
        status: error.statusCode,
        text: 'Addressen konnten nicht abgefragt werden.'
      });
    }

    const subscription = subject$.pipe(
      // Distinct, Debounce and filter to prevent unnecessary Mapbox API requests.
      distinctUntilChanged(),
      debounceTime(250),
      filter((searchTerm: string) => searchTerm.length >= 3),
      // switchMap onto HTTP request for address autocompletions
      switchMap((searchTerm: string) => {
        //return from(googleMapsApiService.getPlacesForQuery(searchTerm))

        return from(MapboxglGeocodingService.getInstance()
          .findPublityPlacesForQuery(searchTerm, null, ['address'], currentLanguage, searchInEveryCountry))
      }),
    )
      .subscribe(successCallback, errorCallback);

    setSubscription(subscription);

    return () => {
      if (!subscription?.closed) {
        subscription?.unsubscribe();
      }
    };
  }, [subject$]);

  useEffect(() => {
    const suggestionItems: any = document.querySelectorAll('li.suggest-item > button');
    if (!suggestionItems?.length) {
      return;
    }

    suggestionItems.forEach(item => item?.setAttribute('tabindex', '-1'));
    suggestionItems?.[cursor]?.setAttribute('tabindex', '0');
    suggestionItems?.[cursor]?.focus();
  }, [cursor]);

  const moveCursorUp = () => {
    const suggestionCount = suggestions?.length ?? 0;
    let newCursor = cursor - 1;
    if (newCursor < 0) {
      newCursor = suggestionCount - 1;
    }
    setCursor(newCursor);
  }

  const moveCursorDown = () => {
    const suggestionCount = suggestions?.length ?? 0;
    let newCursor = cursor + 1;
    if (newCursor > suggestionCount - 1) {
      newCursor = 0;
    }
    setCursor(newCursor);
  }

  const handleArrowKeys = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (readonly) {
      return;
    }

    if (KeyCodeHelper.isArrowUp(event.keyCode) || KeyCodeHelper.isArrowDown(event.keyCode)) {
      event.preventDefault();
      event.stopPropagation();
    }

    if (KeyCodeHelper.isArrowUp(event.keyCode)) {
      moveCursorUp();
    } else if (KeyCodeHelper.isArrowDown(event.keyCode)) {
      moveCursorDown();
    }
  };

  const onValueChange = ({currentTarget}: React.FormEvent<HTMLInputElement>) => {
    if (readonly) {
      return;
    }

    const searchTerm = currentTarget?.value;
    const isValidValue = searchTerm.length >= SEARCH_TERM_MIN_LENGTH;
    setIsLoading(isValidValue);

    setCursor(-1);
    setShowSuggestions(true);

    if (!isValidValue) {
      setSearchTerm('');
      setInfoText(null);
      setSuggestions([]);
      return;
    }

    setSearchTerm(searchTerm);
    setInfoText('Suche nach Vorschlägen...');
  };

  const onSelect = (value: string) => {
    setTouched(true);
    setSearchTerm('');
    setIsLoading(false);
    setShowSuggestions(false);
    changeHandler?.({
      name,
      value,
      fallbackName: null,
      isFallback: false,
      validateForm
    });
  }

  const onFocus = () => {
    if (readonly) {
      return;
    }

    setIsFocused(true);
    setShowSuggestions(true)
  };

  return (
    <>
      <div className="mapbox-address-input" ref={rootElement}>
        <div className={`address-wrapper ${isFocused ? 'is-focused' : ''}`}>
          <BaseInputField
            isRequired={true}
            name={name}
            value={value}
            placeholderText={placeholderText}
            inputType={InputType.SEARCH}
            customCss={`address-input ${customCss}`}
            onInputChange={onValueChange}
            onFocus={onFocus}
            onKeyDown={handleArrowKeys}
            autoComplete='off'
            tabIndex={0}
            readonly={readonly}
            icon={showInputIcon ? getInputIcon(isLoading, label) : null}
          />

          {showSuggestions &&
          <ul className="search-suggestions">
            {
              suggestions?.length ? (
                suggestions.map((place: ISearchResultSuggestion, key) => {
                  const isValid = AddressValidationHelper.isValid(place.address);
                  return (
                    <MapboxSuggestionItem
                      key={key}
                      value={place?.address}
                      text={place?.address}
                      onSelect={onSelect}
                      tabIndex={key === 0 ? 0 : -1}
                      onKeyDown={handleArrowKeys}
                      isValid={isValid}
                    />
                  );
                })
              ) : (
                <li className='empty-result'>
                  {infoText ?? `Bitte ${label} eingeben.`}
                </li>
              )
            }
          </ul>
          }
        </div>
      </div>
    </>
  );
}
