import { useEffect, useReducer } from "react";
import { usePrevious } from "../../hooks/use-previous";
import { IAction } from "../../shared/interfaces/action.interface";
import { ImageEditorActionType, imageEditorDefaults, ImageEditorState, ImageEditorStatus } from "./image-editor.types";

function imageEditorStateReducer(state: ImageEditorState, action: IAction) {
  switch (action.type) {
    case ImageEditorActionType.INITIALIZE:
      return state.merge({
        status: ImageEditorStatus.Canvas,
        originalImage: action.payload
      });

    case ImageEditorActionType.USE_CANVAS:
      return state.merge({
        status: ImageEditorStatus.Canvas
      });

    case ImageEditorActionType.USE_CROPPER:
      return state.merge({
        status: ImageEditorStatus.Cropper
      });

    case ImageEditorActionType.SET_CURRENT_BASE64:
      return state.merge({
        currentBase64: action.payload
      });

    case ImageEditorActionType.SET_CURRENT_IMAGE:
      return state.merge({
        currentImage: action.payload
      });

    case ImageEditorActionType.SET_BRIGHTNESS:
      return state.merge({
        settings: {
          ...state.settings,
          brightness: action.payload
        }
      });

    case ImageEditorActionType.SET_CONTRAST:
      return state.merge({
        settings: {
          ...state.settings,
          contrast: action.payload
        }
      });

    case ImageEditorActionType.SET_ROTATION:
      return state.merge({
        settings: {
          ...state.settings,
          rotation: action.payload
        }
      });

    default:
      return state;
  }
}

export const loadImageBySrc = (src: string, callback: (...props) => void) => {
  const image = new Image();
  image.crossOrigin = "anonymous";
  image.src = src;
  image.onload = () => {
    callback(image);
  };
};

abstract class ImageEditorStateActions {
  static initialize(originalImage: HTMLImageElement) {
    return { type: ImageEditorActionType.INITIALIZE, payload: originalImage };
  }

  static SetIsCanvas() {
    return { type: ImageEditorActionType.USE_CANVAS };
  }

  static SetIsCropper() {
    return { type: ImageEditorActionType.USE_CROPPER };
  }

  static setBrightness(value: number) {
    return { type: ImageEditorActionType.SET_BRIGHTNESS, payload: value };
  }

  static setContrast(value: number) {
    return { type: ImageEditorActionType.SET_CONTRAST, payload: value };
  }

  static setRotation(value: number) {
    return { type: ImageEditorActionType.SET_ROTATION, payload: value };
  }

  static setCurrentBase64(src: string) {
    return { type: ImageEditorActionType.SET_CURRENT_BASE64, payload: src };
  }

  static setCurrentImage(image: HTMLImageElement) {
    return { type: ImageEditorActionType.SET_CURRENT_IMAGE, payload: image };
  }
}

const useImageEditorState = (originalImageSrc: string) => {
  const [state, dispatch] = useReducer(imageEditorStateReducer, new ImageEditorState(imageEditorDefaults));
  const previousOriginalImageSrc = usePrevious(originalImageSrc);

  const init = (originalImage: HTMLImageElement) => dispatch(ImageEditorStateActions.initialize(originalImage));
  const setIsCanvas = () => dispatch(ImageEditorStateActions.SetIsCanvas());
  const setIsCropper = () => dispatch(ImageEditorStateActions.SetIsCropper());

  const setBrightness = (value: number) => dispatch(ImageEditorStateActions.setBrightness(value));
  const setContrast = (value: number) => dispatch(ImageEditorStateActions.setContrast(value));
  const setRotation = (value: number) => dispatch(ImageEditorStateActions.setRotation(value));

  const setCurrentBase64 = (src: string) => dispatch(ImageEditorStateActions.setCurrentBase64(src));
  const setCurrentImage = (src: string): Promise<null> => {
    return new Promise<null>((resolve, reject) => {
      if (!src?.length) {
        dispatch(ImageEditorStateActions.setCurrentImage(null));
        reject();
        return;
      }

      const callback = (img: HTMLImageElement) => {
        dispatch(ImageEditorStateActions.setCurrentImage(img));
        resolve();
      };
      loadImageBySrc(src, callback);
    });
  };


  useEffect(() => {
    if (originalImageSrc === previousOriginalImageSrc)
      return;
    loadImageBySrc(originalImageSrc, init);
  }, [originalImageSrc, previousOriginalImageSrc]);


  const isInitial = state.status === ImageEditorStatus.Initial;
  const isCanvas = state.status === ImageEditorStatus.Canvas;
  const isCropping = state.status === ImageEditorStatus.Cropper;

  const {
    originalImage,
    currentImage,
    currentBase64,
    settings,
    imageInfo
  } = state;

  return {
    status: { isInitial, isCanvas, isCropping },

    settings,
    imageInfo,
    originalImage,
    currentImage,
    currentBase64,

    init,
    setIsCanvas,
    setIsCropper,

    setBrightness,
    setContrast,
    setRotation,

    setCurrentBase64,
    setCurrentImage
  };
};

export {
  useImageEditorState
};
