export const PropertiesActionTypes = {
  SET_LIST: '[PROPERTIES] Set list',
  SET_FILTER: '[PROPERTIES] Set filter',
  SET_OFFICE_AREA_TYPE_FILTER: '[PROPERTIES - SIDE EFFECT] Set explicit office area type filter',
  RESET_OFFICE_AREA_TYPE_FILTER: '[PROPERTIES - SIDE EFFECT] Reset explicit office area type filter',
  RESET_OFFICE_AREA_FILTER: '[PROPERTIES - SIDE EFFECT] Reset explicit office area filter',
  RESET_FILTER: '[PROPERTIES] Reset filter',
  RESET_ALL_FILTER: '[PROPERTIES] Reset all filter',
  FETCH_IN_PROGRESS: '[PROPERTIES] Fetch In Progress',
  FETCH_FINISHED: '[PROPERTIES] Fetch finished',
  SET_SORTING: '[PROPERTIES] Set sorting',
  SET_SORTED_LIST: '[PROPERTIES] Set sorted list',
  SET_OFFSET: '[PROPERTIES] Set offset',
  SET_LOADED_DATA: '[PROPERTIES] Set loaded data',
  RESET_DATA: '[PROPERTIES] Reset list',
  SET_SCROLL_TOP: '[PROPERTIES] Set scroll top',
};
