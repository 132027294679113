import React, { useEffect, useRef, useState } from "react";
import { useHistory, withRouter } from "react-router-dom";
import { IWithRouterProps } from "../../../../shared/interfaces/with-router-props.interface";
import { IFieldSearchSuggestion, IFieldSingle } from "../../interfaces/field-single.interface";
import { useField } from "formik";
import { useClickOutside } from "../../../../hooks/use-click-outside";
import { LoadingSpinnerBlueIcon } from "../../../icons/collection/loading-spinner-blue-icon";
import { SearchIcon } from "../../../icons/collection/search-icon";
import { FlashMessagesService } from "../../../../shared/services/flash-messages.service";
import { BaseInputField } from "../base-input-field";
import { InputType } from "../../enums/input-type.enum";
import { debounceTime, distinctUntilChanged, filter, switchMap } from "rxjs/operators";
import { BehaviorSubject, from, Subject, Subscription } from "rxjs";
import { LogService } from "../../../../shared/services/log.service";
import { css, StyleSheet } from "aphrodite/no-important";
import { CssSize } from "../../../../shared/helpers/styles.helper";
import { Button } from "../../../button/button";
import { ButtonSize } from "../../../button/button-size.const";
import { ButtonColor } from "../../../button/button-color.const";
import { LinkIcon } from "../../../icons/collection/link-icon";
import { SvgVariant } from "../../../icons/enum/svg-variant.enum";
import { FieldRelationSuggestions } from "./field-relation-suggestions/field-relation-suggestions";
import { KeyCodeHelper } from "../../../../shared/helpers/key-code.helper";
import { IState } from "../../../../shared/interfaces/state.interface";
import { connect } from "react-redux";

interface IFieldRelationConnectorProps extends IFieldSingle, IWithRouterProps {
  state: IState
}

const SEARCH_TERM_MIN_LENGTH = 1;
const flashMessageService = FlashMessagesService.getInstance();

const getInputIcon = (isLoading: boolean, label: string): JSX.Element => {
  return isLoading ? (
    <span className='icon-container'>
        <LoadingSpinnerBlueIcon titleAccess='Test' viewBox={"0 0 16 16"} className='loading'/>
      </span>
  ) : (
    <SearchIcon color={"secondary"} viewBox={"0 0 40 40"} titleAccess={label}/>
  );
};


const FieldRelationConnectorWithoutStore = React.memo(withRouter((props: IFieldRelationConnectorProps) => {
  const rootElement = useRef(null);
  const {
    state,
    value,
    name,
    label,
    placeholderText,
    customCss = "",
    changeHandler,
    onRelationSearch,
    relationUrlPath,
    readonly = false,
    selectableObject
  } = props;

  const history = useHistory();
  const [, , { setTouched }] = useField(name + "visible");
  const [isFocused, setIsFocused] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const [searchTerm, setSearchTerm] = useState(""); //readonly searchTerm$: BehaviorSubject<string> = new BehaviorSubject('');
  const [subject$] = useState<Subject<string>>(new BehaviorSubject("")); //private subscription: Subscription;
  const [, setSubscription] = useState<Subscription>(null); //private subscription: Subscription;

  const [showSuggestions, setShowSuggestions] = useState(false);
  const [suggestions, setSuggestions] = useState<any>([]);

  const [infoText, setInfoText] = useState(null);

  const [inputValue, setInputValue] = useState(null);

  useClickOutside(rootElement, (clickedOutside) => {
    setIsFocused(!clickedOutside);
  });

  useEffect(() => {
    subject$.next(searchTerm);
  }, [subject$, searchTerm]);

  useEffect(() => {
    const successCallback = (value: any) => {
      const suggestions: IFieldSearchSuggestion[] = value;
      // Once we received a response after the user search we update isLoading.
      setIsLoading(false);
      if (!suggestions || suggestions?.length <= 0) {
        /*
        * Once we received a response after the user search and it does not include
        * any suggestions update the info text accordingly.
        */
        setInfoText(!suggestions ? "Der Dienst steht aktuell nicht zur Verfügung. Versuchen sie es später erneut." : "Keine Ergebnisse.");
      } else {
        setSuggestions(suggestions);
      }
    };

    const errorCallback = (error: any) => {
      setIsLoading(false);
      LogService.error("An error occured during `GoogleAddressService.fetchResponse`.", error);
      flashMessageService.addMessage({
        id: Date.now(),
        status: error.statusCode,
        text: "Addressen konnten nicht abgefragt werden."
      });
    };

    const subscription = subject$.pipe(
      // Distinct, Debounce and filter to prevent unnecessary Google API requests.
      distinctUntilChanged(),
      debounceTime(1000),
      filter((searchTerm: string) => searchTerm.length >= 3),
      // switchMap onto HTTP request for address autocompletions
      switchMap((searchTerm: string) => {
        return from(onRelationSearch?.(searchTerm));
      })
    )
      .subscribe(successCallback, errorCallback);

    setSubscription(subscription);

    return () => {
      if (!subscription?.closed) {
        subscription?.unsubscribe();
      }
    };
  }, [subject$, onRelationSearch]);

  const inputValueChangeHandler = ({ currentTarget }: React.FormEvent<HTMLInputElement>) => {
    if(readonly) {
      return
    }

    const searchTerm = currentTarget?.value;
    const isValidValue = searchTerm.length >= SEARCH_TERM_MIN_LENGTH;

    setInputValue(searchTerm);

    setIsLoading(isValidValue);
    setShowSuggestions(true);

    if (!isValidValue) {
      setSearchTerm("");
      setInfoText(null);
      setSuggestions([]);
      return;
    }

    setSearchTerm(searchTerm);
    setInfoText("Suche nach Vorschlägen...");
  };

  const keyDownHandler = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if(readonly) {
      return;
    }

    if (KeyCodeHelper.isArrowUp(event.keyCode) || KeyCodeHelper.isArrowDown(event.keyCode)) {
      event.preventDefault();
      event.stopPropagation();

      //setShowSuggestions(true);
    }
  };

  const onFocus = () => {
    if(readonly) {
      return;
    }

    setIsFocused(true);
    setShowSuggestions(true);
  };

  const getLinkToObject = () => {
    const id = value;

    if (!relationUrlPath || !id) {
      return;
    }

    return `${relationUrlPath}/${id}`;
  };

  const suggestionsSelectHandler = (id: string | number, label: string) => {
    if(readonly) {
      return
    }

    setTouched(true);
    setSearchTerm("");
    setIsLoading(false);
    setShowSuggestions(false);
    setInputValue(label);
    changeHandler?.({
      name,
      value: id,
      fallbackName: null,
      isFallback: false
    });
  };

  useEffect(() => {
    if (!!value && inputValue === null) {
      selectableObject?.(state, value).then((result) => {
        if (result) {
          setInputValue(result?.label);
        }
      });
    }

  }, [state, value, inputValue, selectableObject]);

  return (
    <>
      <div className={css(!!label ? styles.fieldWrapper : styles.noLabelFieldWrapper, styles[customCss])}
           ref={rootElement}>
        {!!label && <label htmlFor={name + "visible"} dangerouslySetInnerHTML={{ __html: label }}/>}
        <div className={css(styles.searchWrapper)}>
          <Button type={"button"}
                  btnClass={`${css(styles.connectorButton)} no-padding`}
                  size={ButtonSize.Medium}
                  color={ButtonColor.LighterPrimary}
                  showLabel={false}
                  label={null}
                  disabled={!value || !getLinkToObject()}
                  onClick={() => history.push(getLinkToObject())}>
            <LinkIcon
              viewBox='0 0 40 40'
              color={SvgVariant.Secondary}
              titleAccess={"Zur Verlinkungen wechseln"}
            />
          </Button>

          <BaseInputField
            isRequired={true}
            value={inputValue}
            name={name + '_renderOnly'}
            placeholderText={placeholderText}
            inputType={InputType.SEARCH}
            customCss={`${css(styles.connectorInput)} ${customCss}`}
            onInputChange={inputValueChangeHandler}
            onFocus={onFocus}
            onKeyDown={keyDownHandler}
            autoComplete='off'
            tabIndex={0}
            readonly={readonly}
            icon={getInputIcon(isLoading, label)}
            rawValue={true}
          />

          <BaseInputField
            name={name}
            placeholderText={placeholderText}
            inputType={InputType.HIDDEN}
            withoutWrapper={true}
          />

          {showSuggestions &&
          <FieldRelationSuggestions suggestions={suggestions} isFocused={isFocused} readonly={readonly}
                                    onSelect={suggestionsSelectHandler} label={label} infoText={infoText}/>}
        </div>
      </div>
    </>
  );
}));

const mapStateToProps = (state: IState) => ({state});

export const FieldRelationConnector = connect(mapStateToProps)(FieldRelationConnectorWithoutStore)

export const styles = StyleSheet.create({
  fieldWrapper: {
    display: "grid",
    alignItems: "center",
    gridTemplateColumns: "var(--form-label) 1fr",
    gridGap: "var(--size-20)",
    marginBottom: "var(--size-10)"
  },
  noLabelFieldWrapper: {
    display: "grid",
    alignItems: "center",
    gridTemplateColumns: "100%",
    marginBottom: "var(--size-10)"
  },
  searchWrapper: {
    position: "relative",
    display: "grid",
    gridTemplateColumns: `${CssSize.s40} 1fr`
  },
  connectorInput: {
    borderTopLeftRadius: "0 !important",
    borderBottomLeftRadius: "0 !important",
    backgroundColor: "black"
  },
  connectorButton: {
    borderTopRightRadius: "0 !important",
    borderBottomRightRadius: "0 !important"
  }
});
