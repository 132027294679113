import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Dropdown } from '../dropdown/dropdown';
import { SelectList } from '../inputs/select-list/select-list';
import { PropertyStatusOptions } from '../../shared/mock/property-status.options';
import { PropertyImageOptions } from '../../shared/mock/property-image.options';
import { ISelectItem } from '../inputs/interfaces/select-item.interface';
import { IState } from '../../shared/interfaces/state.interface';
import {
  selectPropertyHasImages,
  selectPropertyId,
  selectPropertyStatus
} from '../../shared/redux/property/property.selectors';
import { performPutRequest } from '../../shared/redux/middleware/api.middleware';
import { PropertyActionTypes } from '../../shared/redux/property/property.action-types';
import { KeyValuePairs } from '../../shared/types/key-value-pairs.type';
import { ButtonSize } from '../button/button-size.const';
import { ApiEndpoint } from '../../shared/constants/api-endpoint.enum';
import { useUser } from "../../contexts/user.context";

interface IStatusListProps {
  propertyId: number;
  status: string;
  hasImages: boolean;
  updateProperty?: (data: KeyValuePairs) => void;
}

export const StatusList = React.memo((props: IStatusListProps) => {
  const {
    propertyId,
    status,
    hasImages,
    updateProperty
  } = props;

  const { getGroups } = useUser();

  const [ statusOptions, setStatusOptions ] = useState(PropertyStatusOptions);

  const [ statusColor, setStatusColor ] = useState('');
  const [ imageOptions, setImageOptions ] = useState(PropertyImageOptions);
  const [ imageStatusColor, setImageStatusColor ] = useState('');

  const [writePermissions, setWritePermissions] = useState(false)

  useEffect(() => {
    if(getGroups()?.includes('admin') || getGroups()?.includes('editor')) {
      setWritePermissions(true);
    }
  }, [getGroups]);


  useEffect(() => {
    if (!status) return;

    const syncedOptions = PropertyStatusOptions.map((option) => {
      if (option.value === status) {
        setStatusColor(option.color);
        return { ...option, isChecked: true };
      }
      return { ...option, isChecked: false };
    });
    setStatusOptions(syncedOptions);
  }, [status]);

  useEffect(() => {
    const hasImagesLabel = hasImages ? 'hasImages' : 'noImages';

    const syncedImages = PropertyImageOptions.map((option) => {
      if (option.value === hasImagesLabel) {
        setImageStatusColor(option.color);
        return { ...option, isChecked: true };
      }

      return { ...option, isChecked: false };
    });
    setImageOptions(syncedImages);
  }, [hasImages]);

  const handleStatusUpdate = (groupKey: string, list: ISelectItem[]) => {
    const checkedOption = list.find((option) => option.isChecked);

    if (groupKey === 'hasImages') {
      updateProperty({ propertyId, [groupKey]: getImageStatusByValue(checkedOption.value) });
      setImageStatusColor(checkedOption.color);
      setImageOptions(list);
      return;
    }

    updateProperty({ propertyId, [groupKey]: checkedOption.value });
    setStatusColor(checkedOption.color);
    setStatusOptions(list);
  };

  const getImageStatusByValue = (value: string) => {
    return value === 'hasImages';
  };

  const buttonLabel = `<span class="status-icon data-${statusColor} images-${imageStatusColor}"></span> Status`;

  return (
    <Dropdown buttonLabel={buttonLabel} dropdownClass={'medium-padding'}
              buttonSize={ButtonSize.Medium} btnClass={'small-font-size'}>
      <SelectList groupKey={'status'}
                  title={'Daten'}
                  list={statusOptions}
                  type={'radio'}
                  emitList={(list: ISelectItem[]) => handleStatusUpdate('status', list)}
                  readOnly={!writePermissions}/>
      <SelectList groupKey={'hasImages'}
                  title={'Bilder'}
                  list={imageOptions}
                  type={'radio'}
                  emitList={(list: ISelectItem[]) => handleStatusUpdate('hasImages', list)}
                  readOnly={!writePermissions}/>
    </Dropdown>
  );
});

const mapStateToProps = (state: IState) => {
  return {
    propertyId: selectPropertyId(state),
    status: selectPropertyStatus(state),
    hasImages: selectPropertyHasImages(state),
  };
};

const mapDispatchToProps = (dispatch: any) => ({
  updateProperty: (data: KeyValuePairs) => dispatch(
    performPutRequest(
      ApiEndpoint.PROPERTY,
      data,
      PropertyActionTypes.FETCH_IN_PROGRESS,
      PropertyActionTypes.SET_PROPERTY,
      PropertyActionTypes.FETCH_FINISHED
    )
  )
});

export const StatusListWithStore = connect(mapStateToProps, mapDispatchToProps)(StatusList);

