import { css, StyleSheet } from "aphrodite/no-important";
import { useFormik } from "formik";
import React, { useEffect, useRef } from "react";
import { useDispatch } from "react-redux";
import { CssColor, CssSize } from "../../shared/helpers/styles.helper";
import { IIntroTextsParams, IntroTextModel } from "../../shared/models/intro-text.model";
import { IntroTextActions } from "../../shared/redux/intro-texts/intro-texts.action";
import { IntroTextsDataService } from "../../shared/services/intro-texts-data.service";
import { PostalCodeDataService } from "../../shared/services/postal-code-data.service";
import { Badge } from "../badge/badge";
import { Button, ButtonColor, ButtonSize } from "../button";
import { CollapsableElements } from "../collapsable-elements/collapsable-elements";
import { BinIcon } from "../icons/collection/bin-icon";
import { SvgVariant } from "../icons/enum/svg-variant.enum";
import { SuggestionDropdown } from "../suggestion-dropdown/suggestion-dropdown";
import { IntroTextPostalCodes } from "./intro-text-postal-codes";
import { IntroTextTemplate } from "./intro-text-template";
import { EntryTitleInput } from "../form/fields/entry-title-input";
import { SuggestionDropdownContext } from "../../contexts/suggestion-dropdown.context";
import { defaultGridTemplateColumns } from "../../shared/constants/media-grid.const";
import { LogService } from "../../shared/services/log.service";
import { Translated } from "../translated/translated";
import { Language } from "../../shared/constants/language.enum";

interface IIntroTextRowProps {
  data: IntroTextModel;
  autoFocus?: boolean,
  onDelete: () => void;
  onUpdate: (introText: IIntroTextsParams) => void;
}

export const IntroTextRow = (props: IIntroTextRowProps) => {
  const {
    data = new IntroTextModel(),
    autoFocus = false,
    onDelete,
    onUpdate
  } = props;

  const titleInputRef = useRef<HTMLInputElement>();

  // Pre-Set the formik field values with empty strings.
  const initialFormikValues = useRef(data.merge({
    title_de: data?.title_de ?? '',
    title_en: data?.title_en ?? '',
    firstTemplate_de: data?.firstTemplate_de ?? '',
    firstTemplate_en: data?.firstTemplate_en ?? '',
    secondTemplate_de: data?.secondTemplate_de ?? '',
    secondTemplate_en: data?.secondTemplate_en ?? '',
    thirdTemplate_de: data?.thirdTemplate_de ?? '',
    thirdTemplate_en: data?.thirdTemplate_en ?? '',
  }).toJS());

  useEffect(() => {
    if (autoFocus) {
      titleInputRef.current.focus();
    }
  }, [autoFocus]);

  const formik = useFormik({
    initialValues: initialFormikValues.current,
    onSubmit: (values) => onUpdate(values),
  });

  const handleOnBlur = () => {
    const isDistinctValue = !data.equals(new IntroTextModel(formik.values));
    if (isDistinctValue) {
      formik.submitForm();
    }
  }

  const inputProps = {
    onChange: formik.handleChange,
    onBlur: handleOnBlur
  };

  const dispatch = useDispatch();

  const onSelect = async (postalCode: string) => {
    try {
      const res = await IntroTextsDataService.addPostalCode(data.id, postalCode);
      dispatch(IntroTextActions.addPostalCode(data.id, postalCode, res?.data?.updatedByTitle));
    } catch (error) {
      LogService.error('[IntroTextRow] onSelect: Failed adding postal-code', error);
    }
  }

  return (
    <div className={css(styles.container)}>

      <div>
        <Translated translations={[
          {
            language: Language.GERMAN,
            value: <EntryTitleInput
              name='title_de'
              ref={titleInputRef}
              value={formik.values.title_de}
              {...inputProps}
            />
          },
          {
            language: Language.ENGLISH,
            value: <EntryTitleInput
              name='title_en'
              ref={titleInputRef}
              value={formik.values.title_en}
              {...inputProps}
            />
          }
        ]}/>
      </div>

      <div className={css(styles.nestedGrid)}>
        <div className={css(styles.templateGrid)}>
          <Translated translations={[
            {
              language: Language.GERMAN,
              value: <IntroTextTemplate
                text={formik.values.firstTemplate_de}
                title='Vorlage 1'
                name={'firstTemplate_de'}
                {...inputProps}
              />
            },
            {
              language: Language.ENGLISH,
              value: <IntroTextTemplate
                text={formik.values.firstTemplate_en}
                title='Template 1'
                name={'firstTemplate_en'}
                {...inputProps}
              />
            }
            ]}/>

          <Translated translations={[
            {
              language: Language.GERMAN,
              value: <IntroTextTemplate
                text={formik.values.secondTemplate_de}
                title='Vorlage 2'
                name={'secondTemplate_de'}
                {...inputProps}
              />
            },
            {
              language: Language.ENGLISH,
              value: <IntroTextTemplate
                text={formik.values.secondTemplate_en}
                title='Template 2'
                name={'secondTemplate_en'}
                {...inputProps}
              />
            },
          ]}/>

          <Translated translations={[
            {
              language: Language.GERMAN,
              value: <IntroTextTemplate
                text={formik.values.thirdTemplate_de}
                title='Vorlage 3'
                name={'thirdTemplate_de'}
                {...inputProps}
              />
            },
            {
              language: Language.ENGLISH,
              value: <IntroTextTemplate
                text={formik.values.thirdTemplate_en}
                title='Template 3'
                name={'thirdTemplate_en'}
                {...inputProps}
              />
            },
          ]}/>

        </div>
        <CollapsableElements
          header={(
            <SuggestionDropdownContext.Provider value={{
              buttonLabel: 'Postleitzahl hinzufügen',
              placeholderText: 'Postleitzahl',
              noDataText: 'Bitte Postleitzahl eingeben',
              maxInputLength: 5,
              selectedSuggestions: data?.postalCodes,
              onSelect,
              getSuggestions: async (searchTerm) => await PostalCodeDataService.fetch(searchTerm)
            }}>
              <div className={css(styles.collapsableHeader)}>
                  <SuggestionDropdown/>
                  {data?.postalCodes?.length !== null && (
                    <Badge value={data?.postalCodes?.length || 0} />
                  )}
              </div>
            </SuggestionDropdownContext.Provider>
          )}
          content={(
            <IntroTextPostalCodes introTextId={data.id} />
          )}
        />
      </div>

      <div>
        <Button type={'button'}
          btnClass={'no-padding'}
          size={ButtonSize.Medium}
          color={ButtonColor.Basic}
          showLabel={false}
          label={'Introtext löschen'}
          onClick={() => onDelete()}>
          <BinIcon
            viewBox='0 0 40 40'
            color={SvgVariant.Secondary}
            titleAccess={'Eintrag löschen'}
          />
        </Button>
      </div>
    </div>
  );
};

const styles = StyleSheet.create({
  container: {
    width: '100%',
    display: 'grid',
    gridTemplateColumns: defaultGridTemplateColumns,
    gridGap: CssSize.s10
  },
  nestedGrid: {
    display: 'grid',
    gridTemplateRows: 'repeat(2, auto)',
    gridGap: CssSize.s10
  },
  templateGrid: {
    display: 'grid',
    gridTemplateColumns: 'repeat(3, 1fr)',
    gridGap: CssSize.s10
  },
  postalCodeContainer: {
    border: `2px solid ${CssColor.IcePrimary}`,
    borderRadius: CssSize.s05,
    padding: CssSize.s05,
    display: 'flex',
    flexDirection: 'column',
  },
  collapsableHeader: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  }
})
