import React, { ReactChild } from 'react'
import { Overlay } from '../overlay';
import { useEscapeKey } from '../../../hooks/use-escape-key';
import { CloseIcon } from '../../icons/collection/close-icon';
import { DialogControls } from './dialog-controls';
import { DialogMessage } from './dialog-message';
import { Button, ButtonSize, ButtonColor } from '../../button';
import { SvgVariant } from '../../icons/enum/svg-variant.enum';

import './dialog.scss';

interface IDialogProps {
  title: string;
  className?: string;
  children?: ReactChild[];
  onClose?: () => void;
  [rest: string]: any;
}

/**
 * This component renders a modal dialog inside the Overlay component.
 *
 * The compound components DialogMessage & DialogControls should be used as the Dialogs children.
 * Note that the children of Dialog are just rendered without any additional modifications.
 */
const Dialog = (props: IDialogProps) => {
  const {
    title,
    children,
    className,
    onClose,
    ...rest
  } = props;

  useEscapeKey(onClose);

  return (
    <Overlay tabIndex={0}>
      <div className={`dialog ${className ?? ''}`} {...rest}>
        <div className="dialog-wrapper">
          <div className="dialog-title">
            <h2>{title}</h2>
            <Button onClick={onClose}
              size={ButtonSize.Medium}
              color={ButtonColor.None}
              borderRadius={'large'}
              label=''
              btnClass={'no-padding close'}
              showLabel={false}>
              <CloseIcon color={SvgVariant.Black} viewBox={'0 0 40 40'} titleAccess='Modal schließen' />
            </Button>
          </div>

          {children}

        </div>
      </div>
    </Overlay>
  );
};

Dialog.Message = DialogMessage;
Dialog.Controls = DialogControls;

export { Dialog };
