import React, { useEffect, useState } from "react";
import { MediaElementModel } from "../../shared/models/media-element.model";
import { css, StyleSheet } from "aphrodite";
import { CssColor, CssSize } from "../../shared/helpers/styles.helper";
import { mediaGridTemplateColumns } from "../../shared/constants/media-grid.const";
import { FormattingHelper } from "../../shared/services/formatting.helper";
import { Badge } from "../badge/badge";
import { PropertyMediaRowControls } from "./property-media-row-controls";
import { FileType } from "../../shared/constants/file-type.enum";
import { S3ImageThumbnail } from "./S3ImageThumbnail/S3ImageThumbnail";
import { S3VideoPreview } from "./S3VideoPreview/S3VideoPreview";
import { EditImageModel } from "../overlay/modal/variants/edit-image-modal";
import { useUser } from "../../contexts/user.context";

interface IPropertyMediaRowProps {
  type: FileType,
  mediaElement: MediaElementModel;
  highlightId: string;
  mediaImagesSize: number;
  clearHighlighted: () => void;
  onMoveUp: (mediaId: string) => void;
  onMoveDown: (mediaId: string) => void;
  onDelete: (media: MediaElementModel) => void;
  onUpdate?: (media: MediaElementModel, base64: string) => Promise<Function>;
}

const VIDEOS_VISIBLE_IN_WATCHLIST = 1;
const IMAGES_VISIBLE_IN_WATCHLIST = 4;

export const PropertyMediaRow = React.memo((props: IPropertyMediaRowProps) => {
  const {
    mediaElement,
    highlightId,
    mediaImagesSize,
    clearHighlighted,
    onDelete,
    onMoveDown,
    onMoveUp,
    onUpdate
  } = props;

  const { getGroups } = useUser();

  const [writePermissions, setWritePermissions] = useState(false);

  useEffect(() => {
    if (getGroups()?.includes("admin") || getGroups()?.includes("editor")) {
      setWritePermissions(true);
    }
  }, [getGroups]);

  const type = props.type ?? FileType.Image;

  const [isHighlighted, setIsHighlighted] = useState(false);

  useEffect(() => {
    let timeout = null;
    if (highlightId === mediaElement.id) {
      setIsHighlighted(true);
      timeout = setTimeout(() => {
        setIsHighlighted(false);
        clearHighlighted();
      }, 500);
    }
    return () => clearTimeout(timeout);
  }, [highlightId, mediaElement.id, clearHighlighted]);

  const [fileSize, setFileSize] = useState<string>();
  const [fileType, setFileType] = useState<string>();
  const [positionBackgroundColor, setPositionBackgroundColor] = useState<CssColor>();

  useEffect(() => {
    setFileSize(FormattingHelper.formatBytes(mediaElement.fileSize));
    setFileType(FormattingHelper.formatFileType(mediaElement.fileType));

    const visibleMediaInWatchlist = (type === FileType.Image ? IMAGES_VISIBLE_IN_WATCHLIST : VIDEOS_VISIBLE_IN_WATCHLIST);
    const positionBackgroundColor = mediaElement.position <= visibleMediaInWatchlist ? CssColor.Secondary : CssColor.LightGrey;
    setPositionBackgroundColor(positionBackgroundColor);

  }, [type, mediaElement.fileSize, mediaElement.fileType, mediaElement.position]);


  const [isEditing, setIsEditing] = useState(false);
  const onEdit = () => {
    if(!writePermissions) {
      return;
    }

    setIsEditing(true);
  };

  const onImageEditorClose = () => {
    if(!writePermissions) {
      return;
    }

    setIsEditing(false)
  }

  return (
    <>
      <div key={mediaElement.id} className={css(styles.mediaRowContainer, isHighlighted && styles.highlight)}>
        {type === FileType.Image ? <S3ImageThumbnail key={mediaElement.thumbnailS3Key} s3ThumbnailKey={mediaElement.thumbnailS3Key} alt={mediaElement.title}/> :
          <S3VideoPreview mediaElement={mediaElement}/>}
        <div className={css(styles.column)}>{mediaElement.title}</div>
        <div className={css(styles.column, styles.fileSize)}>{fileSize}</div>
        <div className={css(styles.column, styles.fileType)}>{fileType}</div>
        <div className={css(styles.column, styles.position)}>
          <Badge backColor={positionBackgroundColor} value={mediaElement.position}/>
        </div>
        {writePermissions && <div className={css(styles.column, styles.controls)}>
          <PropertyMediaRowControls
            isEditable={type === FileType.Image}
            isFirst={mediaElement.position === 1 || mediaImagesSize === 1}
            isLast={mediaElement.position === mediaImagesSize || mediaImagesSize === 1}
            onMoveUp={() => onMoveUp(mediaElement.id)}
            onMoveDown={() => onMoveDown(mediaElement.id)}
            onDelete={() => onDelete(mediaElement)}
            onEdit={onEdit}
          />
        </div>}
      </div>

      {isEditing && <EditImageModel close={onImageEditorClose} imageElement={mediaElement} onUpdate={onUpdate}/>}
    </>
  );
});

const colorGreyStyle = {
  color: CssColor.Grey
};

const styles = StyleSheet.create({
  mediaRowContainer: {
    width: "100%",
    display: "grid",
    gridTemplateColumns: mediaGridTemplateColumns,
    gridGap: CssSize.s10,
    alignItems: "center",
    transition: "background-color .3s"
  },
  highlight: {
    backgroundColor: CssColor.LighterPrimary
  },
  column: {
    padding: `0 ${CssSize.s10}`
  },
  fileSize: {
    ...colorGreyStyle
  },
  fileType: {
    ...colorGreyStyle
  },
  position: {
    justifySelf: "center"
  },
  controls: {
    justifySelf: "flex-end",
    display: "grid",
    gridAutoFlow: "column",
    columnGap: CssSize.s10
  }
});
