import { useEffect, useRef } from "react";
import ReactDOM from "react-dom";

interface IPortalProps {
  children: any;
  rootId: string
}

export const Portal = (props: IPortalProps) => {
  const {
    children,
    rootId
  } = props;

  const el = useRef(document.createElement('div'));
  const root = useRef<HTMLElement>(document.getElementById(rootId));

  useEffect(() => {
    const rootCurrent = root.current;
    const elCurrent = el.current;

    if (rootCurrent) {
      rootCurrent.appendChild(elCurrent);
    }
    return () => {
      if (rootCurrent) {
        rootCurrent.removeChild(elCurrent);
      }
    };
  }, [root]);

  return ReactDOM.createPortal(children, root.current);
};
