import React from 'react';
import { ReactNode } from 'react';

import "./button.scss";
import { ButtonColor } from './button-color.const';
import { ButtonSize } from './button-size.const';
import { ButtonIconPosition } from './button-icon-position.const';

export interface IButtonProps {
  onClick?: (event: any) => void;
  label?: string;
  showLabel?: boolean;
  children?: ReactNode;
  size?: ButtonSize;
  color?: ButtonColor;
  borderRadius?: 'small' | 'large';
  isPressed?: boolean;
  iconPosition?: ButtonIconPosition;
  btnClass?: string;
  [rest: string]: any;
}

export const Button = React.memo(
  (props: IButtonProps) => {
    const {
      label,
      children,
      onClick,
      btnClass,
      showLabel = props.showLabel ?? true,
      isPressed = false,
      iconPosition = 'right',
      borderRadius = 'small',
      size = ButtonSize.Medium,
      color = ButtonColor.Primary,
      ...rest
    } = props;

    const positionClass = !!children && iconPosition === ButtonIconPosition.Left ? 'icon-left' : (iconPosition === ButtonIconPosition.Center ? 'icon-center' : 'icon-right');
    const activeClass = isPressed ? 'is-pressed' : '';

    return (
      <div className={`btn ${!!children ? positionClass : 'no-icon'} ${activeClass} size-${size} ${color} radius-${borderRadius} ${btnClass ?? ''}`}
        onClick={onClick}
        {...rest}
      >
        <span className={`btn-label ${showLabel ? 'show' : 'hide'}`} dangerouslySetInnerHTML={{ __html: label }} />
        {children}
      </div>
    )
  }
);
