import React, { Children, cloneElement, isValidElement, ReactNode, useState } from 'react';
import { WizardContext } from '../../contexts/wizard.context';
import { WizardStep } from './wizard-step';

interface IWizardProps {
  viewDependencies: { prev: number, next: number[] }[];
  children: ReactNode;
}

export const Wizard = (props: IWizardProps) => {
  const { viewDependencies, children } = props;
  const [ currentStep, setStep ] = useState<number>(0);

  return (
    <WizardContext.Provider value={{
      currentStep,
      setStep
    }}>
      {Children.map(children, (child, index) => {
        if (isValidElement(child)) {
          return (
            <WizardStep step={index}>
              {cloneElement(child, { context: WizardContext, view: { prev: viewDependencies?.[index]?.prev, next: viewDependencies?.[index]?.next } })}
            </WizardStep>
          )
        }

        return null;
      })}
    </WizardContext.Provider>
  )
}
