import React, { useEffect, useReducer, useState } from "react";
import { css, StyleSheet } from "aphrodite/no-important";
import { FieldArray as FormikFieldArray, FormikValues } from "formik";
import { withRouter } from "react-router-dom";
import { LogService } from "../../../../shared/services/log.service";
import { Button, ButtonColor, ButtonIconPosition, ButtonSize } from "../../../button";
import { SvgVariant } from "../../../icons/enum/svg-variant.enum";
import { BinIcon } from "../../../icons/collection/bin-icon";
import { AddIcon } from "../../../icons/collection/add-icon";
import { Dialog, DialogControl } from "../../../overlay/dialog";
import { IWithRouterProps } from "../../../../shared/interfaces/with-router-props.interface";
import { IFieldArray } from "../../interfaces/field-array.interface";
import { IFieldChangeEvent } from "../../interfaces/field-single.interface";
import { FieldArrayHelper } from "../../helpers/field-array.helper";
import { FormHelper } from "../../helpers/form.helper";
import { FieldArrayContext } from "../../context/field-array.context";
import { fieldArrayReducer, IFieldArrayState } from "./field-array.reducer";
import { setData, setIsDataAddAllowed, setPreviousData, setRegistry } from "./field-array.actions";

interface IFieldArrayProps extends IFieldArray, IWithRouterProps<{ propertyId: number }> {
  formValues: FormikValues;
  readonly?: boolean
}

const FIELD_ARRAY_DEFAULT_STATE: IFieldArrayState = {
  data: null,
  isDataAddAllowed: true,
  existingDataRegistry: null,
  previousData: null
};

export const FieldArray = React.memo(withRouter((props: IFieldArrayProps) => {
  const {
    name,
    formValues,
    newDataRef,
    addLabel,
    changeHandler,
    customCss,
    fieldHeaders,
    onDataDelete,
    onDataAdd,
    identifier,
    triggerDataRefetch,
    fieldArrayTitle,
    isDeleteAllowed,
    match,
    useRegistry,
    registryDataKey,
    maxDataLength,
    readonly = false
  } = props;

  const [state, dispatch] = useReducer(fieldArrayReducer, FIELD_ARRAY_DEFAULT_STATE);
  const {
    data,
    isDataAddAllowed,
    existingDataRegistry,
    previousData
  } = state;

  const [deleteTargetIdentifier, setDeleteTargetIdentifier] = useState(null);
  const [showDeleteDialog, setShowDeleteDialog] = useState(false);
  const [propertyId, setPropertyId] = useState<number>(null);

  useEffect(() => {
    setPropertyId(match.params.propertyId);
  }, [match]);

  const updateStateByFormChanges = () => {
    const fieldArrayValues = formValues?.[name];

    if (!previousData?.length || previousData?.length !== data?.length) {
      dispatch(setPreviousData(fieldArrayValues));
    }

    dispatch(setData(fieldArrayValues));

    if (useRegistry) {
      const registry = fieldArrayValues.map((item) => item?.[registryDataKey]).filter((value) => !!value);
      dispatch(setRegistry(registry));
    }
  };
  useEffect(updateStateByFormChanges, [formValues, name]);

  const updateIsDataAddAllowed = () => {
    dispatch(setIsDataAddAllowed(maxDataLength === data?.length));
  };
  useEffect(updateIsDataAddAllowed, [data]);

  const handleChange = (change: IFieldChangeEvent) => {
    if (readonly) {
      return;
    }

    const updatedData = FieldArrayHelper.onDataUpdate(change, data, previousData);

    if (!updatedData?.length) {
      return;
    }

    dispatch(setPreviousData(updatedData));

    changeHandler?.({
      name,
      value: updatedData,
      fallbackName: null,
      isFallback: null
    });
  };

  const onDialogControlClick = async (submitDelete: boolean) => {
    if (readonly) {
      return;
    }

    if (submitDelete) {
      try {
        await onDataDelete?.(propertyId, deleteTargetIdentifier);
        await triggerDataRefetch?.();
      } catch (error) {
        LogService.error("onDialogControlClick: Http request failed.", error);
      }
    }
    setDeleteTargetIdentifier(null);
    setShowDeleteDialog(false);
  };

  const renderDataRef = (fieldIdentifier: string, index: number) => {
    const dataRef = FieldArrayHelper.mapArrayDataToDataRef(name, data, index, newDataRef, { readonly });

    return FormHelper.renderFieldByTemplateType(
      dataRef,
      {
        key: fieldIdentifier || index,
        changeHandler: (change: IFieldChangeEvent) => handleChange(change),
        readonly
      }
    );
  };

  const renderDeleteButton = (deleteIdentifier: string) => {
    return isDeleteAllowed && !readonly && (
      <Button type={"button"}
              btnClass={"no-padding"}
              size={ButtonSize.Medium}
              color={ButtonColor.Basic}
              showLabel={false}
              label={null}
              onClick={() => {
                setDeleteTargetIdentifier(deleteIdentifier);
                setShowDeleteDialog(true);
              }}>
        <BinIcon
          viewBox='0 0 40 40'
          color={SvgVariant.Secondary}
          titleAccess={"Eintrag löschen"}
        />
      </Button>
    );
  };

  const renderHeader = (header, index) => <span key={header + index}
                                                className={css(styles.arrayHeader, styles[`position${index + 1}`])}>{header}</span>;

  const renderHeaders = () => {
    return fieldHeaders?.length &&
      <div
        className={css(isDeleteAllowed && !readonly ? styles.arrayHeaderWrapper : styles.arrayHeaderWrapperWithoutDelete)}>
        {fieldHeaders.map((header, index) => renderHeader(header, index))}
        {/* Add another column for the delete button*/}
        {(!readonly && isDeleteAllowed) && renderHeader("", fieldHeaders.length + 1)}
      </div>;
  };

  return (
    <>
      {
        showDeleteDialog && !readonly &&
        <Dialog
          title='Sind Sie sicher?'
          onClose={() => setShowDeleteDialog(false)}
        >
          <Dialog.Message>
            Wenn sie auf „Löschen“ klicken wird der Eintrag<br/>
            gelöscht und kann nicht wiederhergestellt werden.
          </Dialog.Message>
          <Dialog.Controls>
            <DialogControl label='Abbrechen' value={false} color={ButtonColor.Basic}
                           onClick={onDialogControlClick}></DialogControl>
            <DialogControl label='Löschen' value={true} color={ButtonColor.Danger}
                           onClick={onDialogControlClick}></DialogControl>
          </Dialog.Controls>
        </Dialog>
      }
      <FieldArrayContext.Provider value={{
        existingDataRegistry: existingDataRegistry
      }}>
        <FormikFieldArray name={name}
                          render={_ => (
                            <div className={css(styles[customCss])}>
                              <div
                                className={css(fieldArrayTitle ? styles.arrayTitleWrapper : styles.arrayWithoutTitleWrapper)}>
                                {fieldArrayTitle ? (
                                  <p className={css(styles.arrayTitle)}>
                                    {fieldArrayTitle} ({data?.length})
                                  </p>
                                ) : null}
                                <div className={css(styles.addButtonPosition)}>
                                  {!readonly && <Button type={"button"}
                                                        disabled={isDataAddAllowed}
                                                        btnClass={"medium-font-size bold margin-left-auto"}
                                                        size={ButtonSize.Small}
                                                        color={ButtonColor.Ghost}
                                                        iconPosition={ButtonIconPosition.Left}
                                                        label={addLabel}
                                                        onClick={async () => {
                                                          await onDataAdd?.(propertyId);
                                                          await triggerDataRefetch?.();
                                                        }}>
                                    <AddIcon
                                      viewBox='0 0 30 30'
                                      color={isDataAddAllowed ? SvgVariant.Grey : SvgVariant.Secondary}
                                      titleAccess='Eintrag hinzufügen'
                                    />
                                  </Button>}
                                </div>
                              </div>
                              {renderHeaders()}
                              {data?.length > 0 ? (
                                data.map((field, index) => (
                                  <div
                                    className={css(isDeleteAllowed && !readonly ? styles.arrayWrapper : styles.arrayWrapperWithoutDelete)}
                                    key={index}>
                                    {renderDataRef(field?.id, index)}
                                    {renderDeleteButton(field?.[identifier])}
                                  </div>
                                ))
                              ) : null}
                            </div>
                          )}/>
      </FieldArrayContext.Provider>
    </>
  );
}));

const styles = StyleSheet.create({
  arrayWrapper: {
    display: "grid",
    gridTemplateColumns: "1fr var(--size-40)",
    gridGap: "var(--size-10)",
    alignItems: "flex-start"
  },
  arrayWrapperWithoutDelete: {
    display: "grid",
    gridTemplateColumns: "1fr",
    gridGap: "var(--size-10)",
    alignItems: "flex-start"
  },
  arrayTitleWrapper: {
    display: "grid",
    gridTemplateColumns: "calc(var(--form-label) + var(--size-10)) repeat(2, 1fr)",
    gridGap: "var(--size-10)",
    marginBottom: "var(--size-15)"
  },
  arrayWithoutTitleWrapper: {
    display: "grid",
    gridTemplateColumns: "calc(var(--form-label) + var(--size-10)) 1fr",
    gridGap: "var(--size-10)",
    marginBottom: "var(--size-15)"
  },
  arrayTitle: {
    gridColumn: 2,
    fontSize: "var(--font-size-title)",
    fontWeight: "bold",
    marginBottom: 0
  },
  addButtonPosition: {
    gridColumn: 3,
    display: "inline-flex"
  },
  arrayHeaderWrapper: {
    display: "grid",
    gridTemplateColumns: "calc(var(--form-label) + var(--size-10)) repeat(2, 1fr) var(--size-40)",
    gridGap: "var(--size-10)",
    marginBottom: "var(--size-10)"
  },
  arrayHeaderWrapperWithoutDelete: {
    display: "grid",
    gridTemplateColumns: "calc(var(--form-label) + var(--size-10)) repeat(2, 1fr)",
    gridGap: "var(--size-10)",
    marginBottom: "var(--size-10)"
  },
  arrayHeader: {
    padding: "var(--size-10)",
    borderBottom: "var(--base-border) solid var(--lighter-grey)",
    textAlign: "left",
    fontSize: "14px",
    fontWeight: "bold"
  },
  position1: {
    gridColumn: "2"
  },
  position2: {
    gridColumn: "3"
  },
  position3: {
    gridColumn: "4"
  },
  marginTop: {
    marginTop: "var(--size-30)"
  }
});
