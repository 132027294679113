import { css, StyleSheet } from "aphrodite/no-important";
import React from "react";
import { IFieldSingle } from "../interfaces/field-single.interface";
import { BaseInputField } from "./base-input-field";
import { Translated } from "../../translated/translated";

export const FieldSingle = React.memo((props: IFieldSingle) => {
  const {
    children,
    label,
    name,
    customCss,
    condensed,
    i18n
  } = props;

  const dynStyles = StyleSheet.create({
    fieldWrapper: {
      display: "grid",
      alignItems: "center",
      gridTemplateColumns: "var(--form-label) 1fr",
      gridGap: "var(--size-20)",
      marginBottom: condensed ? 0 : "var(--size-10)"
    },
    noLabelFieldWrapper: {
      display: "grid",
      alignItems: "center",
      gridTemplateColumns: "100%",
      marginBottom: condensed ? 0 : "var(--size-10)"
    }
  });

  const field = () => {
    return <div className={css(!!label ? dynStyles.fieldWrapper : dynStyles.noLabelFieldWrapper, styles[customCss])}>
      {!!label && <label htmlFor={name} dangerouslySetInnerHTML={{ __html: label }}/>}
      {!!children ? children : <BaseInputField key={name} {...props} />}
    </div>;
  };

  return !!i18n ? <Translated translations={[{ language: i18n, value: field() }]}/> : field();
});

const styles = StyleSheet.create({
  marginTop: {
    marginTop: "var(--size-30)"
  }
});
