import { GroupSize } from '../enums/group-size.enum';
import { IFormFieldset } from '../interfaces/form-fieldset.interface';
import { PropertyInputDefinitions } from './property-input-definitions.const';
import { Language } from "../../../shared/constants/language.enum";

export const PropertyFormDefinition: IFormFieldset[] = [
  {...{
    name: 'basics',
    customCss: 'highlight',
    groups: [
      {
        title: 'Basics',
        size: GroupSize.Half,
        fields: [
          {
            ...PropertyInputDefinitions.PropertyName
          },
          {
            ...PropertyInputDefinitions.PropertyId
          }
        ]
      },

      {
        title: 'Kontaktperson',
        size: GroupSize.Half,
        fields: PropertyInputDefinitions.PropertyContactFields
      }
    ],
  }},
  {...{
    name: 'propertyLocation',
    customCss: 'separator',
    groups: [
      {
        title: 'Adressen',
        hideTitle: true,
        size: GroupSize.Half,
        fields: [
          {
            ...PropertyInputDefinitions.PropertyAddresses
          }
        ],
      },
      {
        title: 'Land & Region',
        hideTitle: true,
        size: GroupSize.Half,
        fields: [
          PropertyInputDefinitions.PropertyFederalState,
          PropertyInputDefinitions.getPropertyRegion(Language.GERMAN),
          PropertyInputDefinitions.getPropertyRegion(Language.ENGLISH),
          PropertyInputDefinitions.getPropertyCountry(Language.GERMAN),
          PropertyInputDefinitions.getPropertyCountry(Language.ENGLISH),
        ],
      },
    ]
  }},
  {...{
    name: 'propertyTypes',
    customCss: 'separator',
    groups: [
      {
        title: 'Objektart',
        size: GroupSize.Half,
        fields: [
          {
            ...PropertyInputDefinitions.PropertyCondition
          },
          {
            ...PropertyInputDefinitions.PropertyTenant
          },
          {
            ...PropertyInputDefinitions.PropertyFloorConstructionYear
          },
          {
            ...PropertyInputDefinitions.PropertyVacancyRate
          },
          {
            ...PropertyInputDefinitions.PropertyParkingSpace
          },
        ],
      },
      {
        title: 'Flächen',
        size: GroupSize.Half,
        fields: [
          {
            ...PropertyInputDefinitions.PropertyTotalArea
          },
          {
            ...PropertyInputDefinitions.PropertyArea
          },
          {
            ...PropertyInputDefinitions.PropertyAverageRentalCharge
          },
          {
            ...PropertyInputDefinitions.PropertyAreaTypes
          }
        ]
      },
    ],
  }},
  {...{
    name: 'facts',
    customCss: 'separator',
    groups: [
      {
        title: 'Allgemeine Zahlen',
        size: GroupSize.Half,
        fields: [
          {
            ...PropertyInputDefinitions.PropertyAnnualRent
          },
          {
            ...PropertyInputDefinitions.PropertyRenovationCost
          },
          {
            ...PropertyInputDefinitions.PropertyConstructionCost
          },
        ]
      },
      {
        title: '',
        size: GroupSize.Half,
        fields: [
          {
            ...PropertyInputDefinitions.PropertyPurchasePrices
          }
        ]
      }
    ],
  }},
  {...{
    name: 'owner',
    customCss: 'separator',
    groups: [
      {
        title: 'Eigentümer & Alteigentümer',
        size: GroupSize.Full,
        fields: [
          {
            ...PropertyInputDefinitions.PropertyOwners
          }
        ]
      }
    ]
  }},
  {...{
    name: 'tenancy',
    groups: [
      {
        title: 'Mieter & Altmieter',
        size: GroupSize.Full,
        fields: [
          {
            ...PropertyInputDefinitions.PropertyTenancies
          }
        ]
      }
    ]
  }}
];
