import { MonthNames } from '../constants/month-names.const';
import { Month, MonthDigits } from '../constants/month.enum';
import { LogService } from './log.service';
import { DatePickerType } from '../../components/form/enums/datepicker-type.enum';
import { ByteUnitAbbrevation } from '../constants/byte-unit.enum';

export enum DateTimeFormat {
  DD_MM_YYY,
  HH_MM
}

/**
 * This helper class provides various static methods to format strings to our needs.
 */
export abstract class FormattingHelper {

  static formatDateTime(
    input: string | Date,
    dateFormat: DateTimeFormat = DateTimeFormat.DD_MM_YYY,
    timeFormat: DateTimeFormat = DateTimeFormat.HH_MM,
  ) {
    return `${this.formatDate(input, dateFormat)}${!!timeFormat ? ', ' + this.formatTime(input, timeFormat) : ''}`;
  }

  static formatDate(input: string | Date, format: DateTimeFormat = DateTimeFormat.DD_MM_YYY): string {
    const date = new Date(input);
    const day = date.getDate();
    const month = date.getMonth() + 1;
    const year = date.getFullYear();

    switch (format) {
      case DateTimeFormat.DD_MM_YYY:
        const twoDigitDay = this.setTwoDigits(day);
        const twoDigitMonth = this.setTwoDigits(month);
        return `${twoDigitDay}.${twoDigitMonth}.${year}`;
      default:
        return new Date(input).toLocaleDateString();
    }
  }

  static formatTime(input: string | Date, format: DateTimeFormat = DateTimeFormat.HH_MM): string {
    const date = new Date(input);
    const hours = date.getHours();
    const minutes = date.getMinutes();

    switch (format) {
      case DateTimeFormat.HH_MM:
        const twoDigitHours = this.setTwoDigits(hours);
        const twoDigitMinutes = this.setTwoDigits(minutes);
        return `${twoDigitHours}:${twoDigitMinutes}`;
      default:
        return new Date(input).toLocaleTimeString();
    }
  }

  static formatEditorialMonthYearDate(input: string | Date): string {
    const date = new Date(input);
    const year = date.getFullYear();

    return `${MonthNames[date.getMonth()]}, ${year}`;
  }

  static formatEditorialDateWithMonthName(input: string | Date): string {
    const date = new Date(input);
    const day = date.getDate();
    const year = date.getFullYear();

    return `${day}. ${MonthNames[date.getMonth()]}, ${year}`;
  }

  private static setTwoDigits(value: number | string) {
    const stringValue = `${value ?? 0}`;
    return stringValue.length < 2 ?
      `0${stringValue}` :
      stringValue;
  }

  static formatDateInput(datePickerType: DatePickerType, value: string): string {
    switch (datePickerType) {
      case DatePickerType.Year: {
        return `${value}-01-01`;
      }
      case DatePickerType.Month: {
        const [monthName, year] = value.split(',');
        const monthDigits = FormattingHelper.getDigitsByMonthName(monthName);

        return `${year}-${monthDigits}-01`;
      }
      case DatePickerType.Full: {
        const [day, month, year] = value.split('.');;

        return `${year}-${month}-${day}`;
      }
      default:
        LogService.error(`[FormattingHelper] Invalid DatePickerType - ${datePickerType}.`);
        break;
    }
  }

  static getDigitsByMonthName(month: string) {
    switch (month) {
      case Month.January:
        return MonthDigits.January;
      case Month.February:
        return MonthDigits.February;
      case Month.March:
        return MonthDigits.March;
      case Month.April:
        return MonthDigits.April;
      case Month.May:
        return MonthDigits.May;
      case Month.June:
        return MonthDigits.June;
      case Month.July:
        return MonthDigits.July;
      case Month.August:
        return MonthDigits.August;
      case Month.September:
        return MonthDigits.September;
      case Month.October:
        return MonthDigits.October;
      case Month.November:
        return MonthDigits.November;
      case Month.December:
        return MonthDigits.December;
      default:
        LogService.error(`[FormattingHelper] Invalid month - ${month}.`);
        break;
    }
  }

  static formatNumber(value: number): string {
    const formatter = Intl.NumberFormat();
    return formatter.format(Math.floor(value));
  }

  static formatBytes(bytes, decimals = 2): string {
    if (bytes === 0) return '0 Bytes';

    const thousands = 1024;
    const sizes = [
      ByteUnitAbbrevation.Byte,
      ByteUnitAbbrevation.Kilobyte,
      ByteUnitAbbrevation.Megabyte,
      ByteUnitAbbrevation.Gigabyte
    ];

    const i = Math.floor(Math.log(bytes) / Math.log(thousands));
    const roundedByteValue = parseFloat((bytes / Math.pow(thousands, i)).toFixed(decimals));
    return `${roundedByteValue} ${sizes[i]}`;
  }

  static formatFileType(fileType: string): string {
    return fileType
      .replace(/^\w*\//, '')
      .toUpperCase();
  }
}
