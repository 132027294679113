import { IFieldChangeEvent } from "../interfaces/field-single.interface";
import { KeyValuePairs } from "../../../shared/types/key-value-pairs.type";
import { CountryService } from "../../../shared/services/country.service";
import { Language } from "../../../shared/constants/language.enum";

const countryService = CountryService.getInstance();

export abstract class FieldArrayHelper {
  static onDataUpdate(
    change: IFieldChangeEvent,
    currentValues: KeyValuePairs[],
    previousValues: KeyValuePairs[])
    : KeyValuePairs[] {
    const [, dataIndex, fieldName] = change.name.split(".");
    const changeValue = change.value;
    const previousValue = previousValues?.[dataIndex]?.[fieldName];

    if (!this.hasValueChanged(changeValue, previousValue)) {
      return null;
    }

    return currentValues.map((item, currentIndex) => {
      if (currentIndex === +dataIndex) {
        return {
          ...item,
          [fieldName]: change.value
        };
      }

      return item;
    });
  }

  static onAddressDataUpdate(index: number, data: any, currentValues: KeyValuePairs[], currentLanguage: Language) {
    return currentValues.map((item, currentIndex) => {
      if (currentIndex === +index) {
        const countryCode = countryService.getAlpha2Code(data.country, currentLanguage);
        const countryDe = countryService.getName(countryCode, "de");
        const countryEn = countryService.getName(countryCode, "en");

        const countryData = {
          country_de: countryDe,
          country_en: countryEn,
          country_alpha2Code: countryCode
        };

        return {
          ...item,
          ...data,
          ...countryData
        };
      }

      return item;
    });
  }

  static mapArrayDataToDataRef(dataName: string, data: KeyValuePairs[], itemIndex: number, dataRef: KeyValuePairs, overrideOptions = {}): any {
    let mappedFields = null;

    if (this.hasNestedFields(dataRef)) {
      mappedFields = dataRef.fields.map((field) => {
        return {
          ...field,
          name: this.generateNestedFieldName(dataName, itemIndex, field.name),
          label: !field.hideInputEnumeration ? this.generateNestedFieldLabel(field?.label, data.length, itemIndex) : field?.label,
          value: data?.[itemIndex]?.[field.name],
          ...overrideOptions
        };
      });
    }

    return {
      ...dataRef,
      label: !dataRef.hideInputEnumeration ? this.generateNestedFieldLabel(dataRef?.label, data.length, itemIndex) : dataRef?.label,
      fields: mappedFields ?? dataRef.fields
    };
  }

  //TODO must set values for all children!
  static mapTabArrayToDataRef(
    dataName: string,
    data: KeyValuePairs[],
    dataIndex: number,
    tabContentIndex: number,
    tabContentLength: number,
    dataRef: KeyValuePairs,
    overrideOptions = {}
  ): any {
    let mappedFields = null;

    const setField = (field, index?) => {
      const newField = { ...field };

      if (!!field?.fields) {
        newField.fields = mapFields(field.fields);
      }

      const name = this.generateNestedFieldName(dataName, dataIndex, field.name);
      const value = data?.[dataIndex]?.[field?.name];

      return {
        ...newField,
        label: !field.hideInputEnumeration ? this.generateNestedFieldLabel(field?.label, tabContentLength, index) : field?.label,
        name,
        value,
        ...overrideOptions
      };
    };

    const mapFields = (fields: KeyValuePairs) => fields.map((field, index) => setField(field, index));

    if (this.hasNestedFields(dataRef)) {
      mappedFields = mapFields(dataRef.fields);
    }

    return {
      ...dataRef,
      label: !dataRef.hideInputEnumeration ? this.generateNestedFieldLabel(dataRef?.label, tabContentLength, tabContentIndex) : dataRef?.label,
      fields: mappedFields ?? dataRef.fields
    };
  }

  static mapAddressArrayToDataRef(
    dataName: string,
    dataIndex: number,
    dataRef: KeyValuePairs,
    isPrimary: boolean
  ) {
    return {
      ...dataRef,
      name: this.generateNestedFieldName(dataName, dataIndex, dataRef.name),
      label: isPrimary ? "Primäradresse" : dataRef?.label
    };
  }

  private static generateNestedFieldName(dataName: string, dataIndex: number, fieldName: string): string {
    return `${dataName}.${dataIndex}.${fieldName}`;
  }

  private static generateNestedFieldLabel(fieldLabel: string, dataLength: number, dataIndex: number): string {
    return `${fieldLabel} (${dataLength - dataIndex})`;
  }

  private static hasNestedFields(dataRef: KeyValuePairs): boolean {
    return dataRef?.fields?.length;
  }

  private static hasNestedSets(dataRef: KeyValuePairs): boolean {
    return dataRef?.sets?.length;
  }

  private static hasValueChanged(fieldValue: any, previousValue: any): boolean {
    return fieldValue !== previousValue;
  }
}
