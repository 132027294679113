import { TemplateType } from '../enums/template-type.enum';
import { IFieldArray } from '../interfaces/field-array.interface';

export abstract class FieldArrayConfig {
  private static DEFAULT_CONFIG: IFieldArray = {
    templateType: TemplateType.FIELD_ARRAY,
    name: null,
    value: [],
    fieldHeaders: null,
    fieldArrayTitle: null,
    addLabel: null,
    readonly: false,
    newDataRef: null,
    identifier: null,
    fields: null,
    hideInputEnumeration: false,
    isDeleteAllowed: true,
    useRegistry: false,
    maxDataLength: null
  };

  static getConfig(options: Partial<IFieldArray>) {
    return {
      ...FieldArrayConfig.DEFAULT_CONFIG,
      ...options
    }
  }
}
