import React from 'react'

export const FieldsetContent = React.memo(
  (props) => {
    const {
      children
    } = props;

    return (
      <>
        {children}
      </>
    );
  }
);
