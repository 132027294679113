import React from 'react';
import { css, StyleSheet } from 'aphrodite/no-important';
import { CssSize } from '../../shared/helpers/styles.helper';
import { List } from 'immutable';

interface IGridTableBodyProps {
  rows: List<JSX.Element> | Array<JSX.Element>;
}

export const GridTableBody = React.memo((props: IGridTableBodyProps) => {
  const {
    rows
  } = props;

  return (
    <div className={css(styles.gridTableBody)}>
      {(rows as Array<JSX.Element>)?.map((row) => row)}
    </div>
  );
});

const styles = StyleSheet.create({
  gridTableBody: {
    width: '100%',
    display: 'grid',
    gridGap: CssSize.s10,
    paddingTop: CssSize.s10,
  }
});
