import { IState } from '../../shared/interfaces/state.interface';
import { DatasetHistory } from './dataset-history';
import { connect } from 'react-redux';
import { selectPersonGroupsUpdatedAt, selectPersonGroupsUpdatedBy } from '../../shared/redux/person-groups/person-groups.selectors';

const mapStateToProps = (state: IState) => {
  return {
    updatedAt: selectPersonGroupsUpdatedAt(state),
    updatedBy: selectPersonGroupsUpdatedBy(state)
  };
};

export const PersonGroupsDatasetHistory = connect(mapStateToProps, null)(DatasetHistory);