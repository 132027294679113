import React, { SyntheticEvent, useContext, useState } from "react";
import { DownloadLink } from "../../links/download-link";
import { TableContext } from "../../../contexts/table.context";
import { API } from "aws-amplify";
import { LogService } from "../../../shared/services/log.service";

const formatter = Intl.NumberFormat();

export const TablePanel = React.forwardRef((_props: any, ref: any) => {
  const { tableTitle, metaData, affixRef, csvExportFileName, csvExportUrl, csvExportVisible = true } = useContext(TableContext);
  const [href] = useState(csvExportUrl ?? "");
  const [visible] = useState(csvExportVisible);
  const [isDownloadLoading, setIsDownloadLoading] = useState(false);

  const downloadCsv = (event$: SyntheticEvent) => {
    // persist event for custom click handling on download.
    event$.persist();

    event$.preventDefault();
    event$.stopPropagation();
    setIsDownloadLoading(true);

    API
      .get("api", csvExportUrl, { "responseType": "text" })
      .then(response => {
        // Add your code here
        downloadFile(csvExportFileName, response);
        // once we received the CSV string we can trigger the download.
      })
      .catch(error => {
        LogService.error(error);
      }).finally(() => {
      setIsDownloadLoading(false);
    });
  };

  const renderMetaData = () => {
    return metaData?.data.map((metaDataItem) => {
      if (!!metaDataItem?.label) {
        return <li key={metaDataItem.label}>
          <strong>{formatter.format(metaDataItem?.value || 0)}</strong> {metaDataItem.label} </li>;
      }

      return null;
    });
  };

  return (
    <ul ref={ref}
        className={"table-panel"}
        style={{ "top": `${affixRef?.current?.clientHeight}px` }}>
      <li className={"table-title"}>
        {tableTitle}
      </li>
      {renderMetaData()}
      {!!visible && <li className='right'>
        <DownloadLink
          onClick={downloadCsv}
          href={!!href ? href : ""}
          fileName={csvExportFileName}
          label='Tabelle als CSV runterladen'
          isLoading={isDownloadLoading}
          disabled={isDownloadLoading}
        />
      </li>}
    </ul>
  );

  function downloadFile(filename, content) {
    const element = document.createElement('a');
    element.setAttribute('href', 'data:text/comma-separated-values;charset=utf-8,' + encodeURIComponent(content));
    element.setAttribute('download', filename);

    element.style.display = 'none';
    document.body.appendChild(element);

    element.click();

    document.body.removeChild(element);
  }
});
