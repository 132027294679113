import React from 'react'
import { css, StyleSheet } from 'aphrodite';
import { useSelector, useDispatch } from 'react-redux';
import { CssSize } from '../../shared/helpers/styles.helper';
import { SvgVariant } from '../icons/enum/svg-variant.enum';
import { DeleteIcon } from '../icons/collection/delete-icon';
import { Button, ButtonSize, ButtonColor } from '../button';
import { selectPersonGroupById } from '../../shared/redux/person-groups/person-groups.selectors';
import { PersonGroupsDataService } from '../../shared/services/person-groups-data.service';
import { LogService } from '../../shared/services/log.service';
import { PersonGroupActions } from '../../shared/redux/person-groups/person-groups.action';

interface IPersonGroupPropertiesProps {
  personGroupId: string;
}

export const PersonGroupProperties = React.memo((props: IPersonGroupPropertiesProps) => {
  const {
    personGroupId,
  } = props;

  const { properties } = useSelector(selectPersonGroupById(personGroupId));
  const dispatch = useDispatch();

  const onDeselect = async (propertyId: string) => {
    try {
      const res = await PersonGroupsDataService.removeProperty(personGroupId, propertyId);
      dispatch(PersonGroupActions.removeProperty(personGroupId, propertyId, res?.data?.updatedByTitle));
    } catch (error) {
      LogService.error(error);
    }
  };

  return (
    !properties?.length ? null : (
      <ul className={css(styles.list)}>
        {properties?.map(({ title, propertyId, id }) => {
          return (
            <li key={id} className={css(styles.listItem)}>
              <Button onClick={() => onDeselect(id)}
                size={ButtonSize.Small}
                color={ButtonColor.Secondary}
                borderRadius={'large'}
                label={`${title} (Objekt-ID: ${propertyId})`}>
                <DeleteIcon color={SvgVariant.White} viewBox={'0 0 30 30'} titleAccess={`${title} (Objekt-ID: ${propertyId}) abwählen`} />
              </Button>
            </li>
          );
        })}
      </ul>
    )
  );
});

const styles = StyleSheet.create({
  list: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    margin: `0 0 ${CssSize.s10} ${CssSize.s10}`,
    padding: 0,
  },
  listItem: {
    marginRight: CssSize.s10,
    marginBottom: CssSize.s10,
  }
});
