import React from 'react';
import { css, StyleSheet } from 'aphrodite';
import { NavigationItem } from './navigation-item/navigation-item';
import { RealEstateIcon } from '../icons/collection/real-estate-icon';
import { AffiliateIcon } from '../icons/collection/affiliate-icon';
import { SvgVariant } from '../icons/enum/svg-variant.enum';
import { CogIcon } from '../icons/collection/cog-icon';
import { CssSize } from '../../shared/helpers/styles.helper';
import { useUser } from "../../contexts/user.context";

interface INavigationProps {
  isExpanded: boolean;
}

export const Navigation = React.memo((props: INavigationProps) => {
  const { isExpanded } = props;

  const { getGroups } = useUser();

  return (
    <nav className={css(styles.nav)}>

      <NavigationItem label='Objekte'
        isExpanded={isExpanded}
        href='/properties'
        className={css(styles.navItemMargin)}>
        <RealEstateIcon className={css(styles.svg)}
          viewBox='0 0 140 140'
          color={SvgVariant.White}
          titleAccess='Objekte' />
      </NavigationItem>

      <NavigationItem label='Mietflächenliste'
        isExpanded={isExpanded}
        href='/tenancies'
        className={css(styles.navItemMargin)}>
        <AffiliateIcon className={css(styles.svg)}
          viewBox='0 0 140 140'
          color={SvgVariant.White}
          titleAccess='Mietflächenliste' />
      </NavigationItem>

      {getGroups()?.includes('admin') && <NavigationItem label='Admin'
        isExpanded={isExpanded}
        href='/admin'
        className={css(styles.navItemNoMargin)}>
        <CogIcon className={css(styles.svg)}
          viewBox='0 0 60 60'
          color={SvgVariant.White}
          titleAccess='Admin' />
      </NavigationItem>}

    </nav>
  )
});

const styles = StyleSheet.create({
  nav: {
    position: 'relative',
    padding: CssSize.s20,
  },
  navItemMargin: {
    marginBottom: CssSize.s05
  },
  navItemNoMargin: {
    marginBottom: 0
  },
  svg: {
    width: CssSize.s60,
    height: CssSize.s60
  }
});
