import React, { ReactNode, useEffect, useState } from "react";
import { Form as FormikForm, Formik, FormikValues } from "formik";
import { IFormFieldset } from "./interfaces/form-fieldset.interface";
import { Button, ButtonColor } from "../button";
import { IFieldChangeEvent } from "./interfaces/field-single.interface";
import { ObjectSchema } from "yup";
import { FormFieldSets } from "./form-field-sets";

interface IFormProps {
  formDefinition: IFormFieldset[];
  showButtonControls: boolean;
  submitButtonLabel?: string;
  cancelButtonLabel?: string;
  validationSchema?: ObjectSchema;
  submitHandler?: (values: FormikValues) => Promise<boolean>;
  cancelHandler?: () => void;
  changeHandler?: (change: IFieldChangeEvent) => void;
  refetchSourceData?: () => void;
  submitDisabled?: boolean;
}

export const Form = React.memo((props: IFormProps) => {
  const {
    showButtonControls,
    submitButtonLabel,
    cancelButtonLabel,
    validationSchema,
    submitHandler,
    cancelHandler,
    changeHandler,
    refetchSourceData,
    formDefinition,
    submitDisabled = false
  } = props;

  const [initialValues, setInitialValues] = useState({});

  useEffect(() => {
    const formValues = {};

    if (!formDefinition?.length) {
      return;
    }

    const generateFormValuesForFieldSet = (fieldSets: IFormFieldset[] = []) => {
      for (const fieldset of fieldSets) {
        for (const group of fieldset?.groups) {
          for (const field of group?.fields) {
            if (!!field && "sets" in field) {
              generateFormValuesForFieldSet(field?.sets);
            } else if (!!field) {
              if ("fields" in field && field?.fields?.length) {
                for (const nestedInput of field?.fields) {
                  formValues[nestedInput?.name] = nestedInput?.value || "";
                }

                continue;
              }

              if ("name" in field && "value" in field) {
                formValues[field?.name] = field?.value;
              }
            }
          }
        }
      }
    };

    generateFormValuesForFieldSet(formDefinition);

    setInitialValues(formValues);
  }, [formDefinition]);

  const renderButtonControls = (
    options: Partial<{
      resetForm,
      values
    }>
  ): ReactNode => {
    const onCancel = () => {
      options.resetForm();
      cancelHandler?.();
    };

    return (
      <div className='button-container'>
        <Button type={"reset"}
                btnClass={"form-btn form-btn-reset"}
                onClick={onCancel}
                color={ButtonColor.Ghost}
                label={cancelButtonLabel}/>
        <Button
          type={"submit"}
          disabled={submitDisabled || (!!validationSchema && !validationSchema.isValidSync(options.values))}
          btnClass={`form-btn`}
          onClick={() => onSubmit(options.values)}
          color={ButtonColor.Secondary}
          label={submitButtonLabel}/>
      </div>
    );
  };

  const onSubmit = async (values) => {
    const submitReturn = await submitHandler?.(values);

    console.log("submitReturn", submitReturn);

    if(submitReturn) {
      cancelHandler?.();
    }
  };

  const renderForm = () => {
    return <Formik
      initialValues={initialValues}
      enableReinitialize={true}
      validateOnMount={true}
      validationSchema={validationSchema}
      onSubmit={onSubmit}>
      {({ values, resetForm }) => (
        <FormikForm>
          <FormFieldSets refetchSourceData={refetchSourceData} changeHandler={changeHandler} sets={formDefinition}
                         formValues={values}/>
          {showButtonControls && renderButtonControls({ resetForm, values })}
        </FormikForm>
      )}
    </Formik>;
  };

  return !!Object.keys(initialValues).length && renderForm();
});
