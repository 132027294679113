import { IState } from '../../../interfaces/state.interface';
import { fromJS, List } from 'immutable';
import { IReducer } from '../../../interfaces/reducer.interface';
import { IAction } from '../../../interfaces/action.interface';
import { RemarkModel } from '../../../models/remark.model';
import { PropertyIntroTextModel, PropertyIntroTextTemplate } from '../../../models/property-intro-text.model';
import { PropertyTextsActionTypes } from './property-texts.action-types';
import { mergeState } from '../../../utils/reducer';

const initialState: IState = fromJS({
  remarks: List<RemarkModel>(),
  introTexts: List<PropertyIntroTextModel>(),
  showIntroTextInWatchlist: true,
  activeIntroTextTemplate: null
});

export const propertyTextsReducer: IReducer = (state = initialState, action: IAction) => {
  const stateRemarks = state.get('remarks');
  const stateIntroTexts = state.get('introTexts');

  switch (action.type) {
    case PropertyTextsActionTypes.SET_REMARKS:
      return mergeState(state, {
        remarks: List(action.payload.map((remark) => new RemarkModel(remark))),
        loadingStatus: action.loadingStatus
      });

    case PropertyTextsActionTypes.ADD_REMARK:
      const newRemark = new RemarkModel(action.payload);

      return mergeState(state, {
        remarks: [ newRemark, ...stateRemarks ]
      });

    case PropertyTextsActionTypes.UPDATE_REMARK:
      const updateIndex = stateRemarks.findIndex((remark) => remark.id === action.payload.id);
      const updatedRemark = new RemarkModel(action.payload);

      return mergeState(state, {
        remarks: stateRemarks.splice(updateIndex, 1, updatedRemark)
      });

    case PropertyTextsActionTypes.DELETE_REMARK:
      const deleteIndex = stateRemarks.findIndex((remark) => remark.id === action.payload);

      return mergeState(state, {
          remarks: stateRemarks.remove(deleteIndex)
      });

    case PropertyTextsActionTypes.SET_INTROTEXTS:
      return mergeState(state, {
        introTexts: List(action.payload?.introTexts.map((introText) => new PropertyIntroTextModel(introText))),
        showIntroTextInWatchlist: action.payload?.showIntroTextInWatchlist,
        activeIntroTextTemplate: action.payload?.activeIntroTextTemplate
      });

    case PropertyTextsActionTypes.TOGGLE_SHOW_INTROTEXT_IN_WATCHLIST:
      return mergeState(state, {
        showIntroTextInWatchlist: action.payload
      });

    case PropertyTextsActionTypes.SET_ACTIVE_INTRO_TEXT_TEMPLATE:
      return mergeState(state, {
        activeIntroTextTemplate: action.payload
      });

    case PropertyTextsActionTypes.UPDATE_OWN_INTROTEXT:
      const editableIntroTextIndex = stateIntroTexts.findIndex((introText) => introText.introTextTemplate === PropertyIntroTextTemplate.OWN);
      const updatedEditableIntroText = new PropertyIntroTextModel(action.payload);

      return mergeState(state, {
        introTexts: stateIntroTexts.splice(editableIntroTextIndex, 1, updatedEditableIntroText)
      });

    default:
      return state;
  }
}
