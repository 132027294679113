import { TemplateType } from '../enums/template-type.enum';
import { IFieldCollapsable } from '../interfaces/field-collapsable.interface';

export abstract class FieldCollapsableConfig {
  private static DEFAULT_CONFIG: IFieldCollapsable = {
    templateType: TemplateType.FIELD_COLLAPSABLE,
    label: null,
    name: null,
    value: null,
    hideInputEnumeration: true,
    titleBar: null,
    fields: null,
    showAdditionalButton: false,
    initialExpand: false
  };

  static getConfig(options: Partial<IFieldCollapsable>) {
    return {
      ...FieldCollapsableConfig.DEFAULT_CONFIG,
      ...options
    }
  }
}
