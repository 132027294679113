import { IState } from '../../interfaces/state.interface';
import { fromJS, List } from 'immutable';
import { IReducer } from '../../interfaces/reducer.interface';
import { IAction } from '../../interfaces/action.interface';
import { mergeState } from '../../utils/reducer';
import { PersonGroupModel } from '../../models/person-group.model';
import { PersonGroupActionTypes } from './person-groups.action-types';
import { getLatestUpdatedPersonGroup } from './person-groups-state-helper';

// TODO: Replace all new Date()'s accordingly with https://webartig.atlassian.net/browse/PUB-115
const initialState: IState = fromJS({
  data: List<PersonGroupModel>(),
  updatedAt: null,
  updatedByTitle: null
});

export const personGroupReducer: IReducer = (state = initialState, action: IAction) => {
  switch (action.type) {
    case PersonGroupActionTypes.SET_PERSON_GROUPS:
      const latestUpdated = getLatestUpdatedPersonGroup(action.payload);
      return mergeState(state, {
        data: List<PersonGroupModel>(action.payload?.map(data => new PersonGroupModel(data))),
        updatedAt: latestUpdated?.updatedAt,
        updatedByTitle: latestUpdated?.updatedByTitle,
      })

    case PersonGroupActionTypes.DELETE_PERSON_GROUP:
      const deleteIndex = state.get('data').findIndex((personGroup: PersonGroupModel) => personGroup.id === action.payload);
      return mergeState(state, {
        data: state.get('data').remove(deleteIndex)
      })

    case PersonGroupActionTypes.UPDATE_PERSON_GROUP:
      const updatePersonGroups = state.get('data') as List<PersonGroupModel>;
      const updateIndex = updatePersonGroups.findIndex((personGroup: PersonGroupModel) => personGroup.id === action.payload.id);
      const updateData = new PersonGroupModel(action.payload);
      return mergeState(state, {
        data: updatePersonGroups.splice(updateIndex, 1, updateData),
        updatedAt: updateData.updatedAt,
        updatedByTitle: updateData.updatedByTitle,
      })

    case PersonGroupActionTypes.CREATE_PERSON_GROUP:
      const newPersonGroup = new PersonGroupModel({
        id: action.payload.id,
        title: action.payload.title
      });

      const statePersonGroups = state.get('data') as List<PersonGroupModel>;

      return mergeState(state, {
        data: List([newPersonGroup, ...statePersonGroups]),
        updatedAt: action.payload.updatedAt,
        updatedByTitle: action.payload.updatedByTitle,
      });

    case PersonGroupActionTypes.ADD_PROPERTY:
      const updatedState = ((state: IState) => {
        const currentPersonGroups = state.get('data') as PersonGroupModel[];
        const selectedPersonGroup = currentPersonGroups.find((personGroup) => personGroup.id === action.payload.personGroupId);
        const properties = List(selectedPersonGroup.properties);
        const updatedProperties = properties.push({
          id: action.payload.id,
          propertyId: action.payload.propertyId,
          title: action.payload.title
        });
        return {
          data: currentPersonGroups.map(personGroup => {
            if (personGroup.id !== action.payload.personGroupId) {
              return personGroup;
            }

            return personGroup.set('properties', updatedProperties.toJSON());
          }),
          updatedAt: new Date().toISOString(),
          updatedByTitle: action.payload.updatedByTitle
        };
      })(state);
      return mergeState(state, updatedState);

    case PersonGroupActionTypes.REMOVE_PROPERTY:
      const currentPersonGroups = state.get('data') as PersonGroupModel[];
      const selectedPersonGroup = currentPersonGroups.find((personGroup) => personGroup.id === action.payload.personGroupId);
      const propertyIndex = selectedPersonGroup.properties.findIndex((property) => property.id === action.payload.propertyId);
      const updatedProperties = List(selectedPersonGroup.properties).remove(propertyIndex);

      return mergeState(state, {
        data: currentPersonGroups.map(personGroup => {
          if (personGroup.id !== action.payload.personGroupId) {
            return personGroup;
          }
          return personGroup.set('properties', updatedProperties.toJSON());
        }),
        updatedAt: new Date().toISOString(),
        updatedByTitle: action.payload.updatedByTitle
      });

    default:
      return state;
  }
}
