import { connect } from "react-redux";
import { IState } from "../../shared/interfaces/state.interface";
import { InfoBadge } from "./info-badge";
import { selectPropertyId, selectPropertyTitle } from "../../shared/redux/property/property.selectors";

const mapStateToProps = (state: IState) => {
  return {
    title: selectPropertyId(state)?.toString(),
    subTitle: selectPropertyTitle(state)
  };
};

export const PropertyInfoBadgeWithStore = connect(mapStateToProps, null)(InfoBadge);
