import { IState } from '../../interfaces/state.interface';
import { createSelector } from 'reselect';

const baseSettingsSelector = ['settings'];

const selectBase = (state: IState): IState => state.getIn([...baseSettingsSelector]);

export const selectLanguage = createSelector(
  [selectBase],
  (settingsState) => {
    return settingsState.get('language')
  }
)
