import { IAction } from '../../interfaces/action.interface';
import { PropertyMediaImageActionTypes } from './property-media-image.action-types';
import { IPropertyMedia } from '../../interfaces/property-media.interface';
import { MoveMediaPosition } from '../../services/media-data.service';

export abstract class PropertyMediaImageActions {
  static updateImage(image: IPropertyMedia): any {
    return {
      type: PropertyMediaImageActionTypes.UPDATE_IMAGE,
      payload: image
    }
  }

  static deleteImage(imageId: string): any {
    return {
      type: PropertyMediaImageActionTypes.DELETE_IMAGE,
      payload: imageId
    }
  }

  static setImages(payload: IPropertyMedia[]): IAction {
    return {
      type: PropertyMediaImageActionTypes.SET_MEDIA_IMAGE_LIST,
      payload
    }
  }

  static saveImages(payload: IPropertyMedia[]): IAction {
    return {
      type: PropertyMediaImageActionTypes.SAVE_MEDIA_IMAGES,
      payload
    }
  }

  static updatePosition(imageId: string, move: MoveMediaPosition): IAction {
    return {
      type: PropertyMediaImageActionTypes.UPDATE_POSITION,
      payload: {
        mediaId: imageId,
        move
      }
    };
  }

  static clearHighlightId(): IAction {
    return { type: PropertyMediaImageActionTypes.CLEAR_HIGHLIGHTED };
  }
}
