import { FieldSingleConfig } from "../config/field-single.config";
import { TemplateType } from "../enums/template-type.enum";
import { AffiliateDataService } from "../../../shared/services/affiliate-data.service";
import { ISelectableObject } from "../../../shared/interfaces/selectable.interface";
import { IState } from "../../../shared/interfaces/state.interface";

//TODO move to better place
export abstract class AgentInputDefinitions {

  static SelectorInputField(label: string = "Makler", name:string = "_agentId", selectableObject: (state: IState, id: number) => Promise<ISelectableObject>) {
    return {
      ...FieldSingleConfig.getConfig({
        templateType: TemplateType.FIELD_RELATION_CONNECTOR,
        name,
        label,
        relationUrlPath: "/affiliates",
        onRelationSearch: async (searchTerm: string) => await AffiliateDataService.searchAffiliate(searchTerm),
        selectableObject
      })
    };
  }
}
