import React from 'react';
import './select-item.scss';
import { CheckIcon } from '../../icons/collection/check-icon';
import { RadioIcon } from '../../icons/collection/radio-icon';
import {SvgVariant} from '../../icons/enum/svg-variant.enum';

interface ISelectItemProps {
  id: string;
  value: string;
  isChecked: boolean;
  label: string;
  onChange: (event: any) => void;
  type?: 'checkbox' | 'radio';
  color?: string;
  autotoggle?: boolean;
  readOnly?: boolean;
  [rest: string]: any;
}

export const SelectItem = (props: ISelectItemProps) => {
  const {
    id,
    isChecked,
    value,
    onChange,
    label,
    color,
    type = 'checkbox',
    readOnly = false,
    ...rest
  } = props;

  return (
    <>
      <input className={`${type}-select`}
        id={id}
        type={type}
        value={value}
        onChange={onChange}
        checked={isChecked}
        {...rest} />
      <label className={`${type}-select-label` + (readOnly ? ' readonly' : '')}
        htmlFor={id}>
        <span className={`${type}-select-icon-wrapper ${color}`}>
          {
            type === 'checkbox' ?
              <CheckIcon color={SvgVariant.Transparent} viewBox={'0 0 14 11'} titleAccess={label} />
              :
              <RadioIcon color={SvgVariant.Transparent} viewBox={'0 0 14 14'} titleAccess={label} />
          }
        </span>
        {label}
      </label>
    </>
  )
};
