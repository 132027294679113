import { ApiEndpoint } from '../constants/api-endpoint.enum';
import { HttpService } from './http.service';
import { IRegionsParams } from '../models/region.model';

/*
This service is a plain copy of the IntroTextDataService - please consider generalizing this implementation into something like "ApiDataService", "CrudDataService", ...
*/

export class RegionsDataService {

  static async fetch(): Promise<any> {
    return HttpService.performGetRequest(`${ApiEndpoint.REGION}`);
  }

  static async add(): Promise<any> {
    return HttpService.performPostRequest(`${ApiEndpoint.REGION}`, null);
  }

  static async update(data: IRegionsParams): Promise<any> {
    return HttpService.performPutRequest(`${ApiEndpoint.REGION}`, data);
  }

  static async delete(id: string): Promise<any> {
    return HttpService.performDeleteRequest(`${ApiEndpoint.REGION}/${id}`)
  }

  static async removePostalCode(regionId: string, postalCode: string): Promise<any> {
    return HttpService.performDeleteRequest(`${ApiEndpoint.REGION}/${regionId}/postalcodes/${postalCode}`)
  }

  static async addPostalCode(regionId: string, postalCode: string): Promise<any> {
    return HttpService.performPostRequest(`${ApiEndpoint.REGION}/${regionId}/postalcodes`, { postalCode });
  }
}
