import {css, StyleSheet} from "aphrodite";
import {GracefulImage} from "../../graceful-image/graceful-image";
import React, {useEffect, useState} from "react";
import {FileService} from "../../../shared/services/file-service";

interface S3ImageThumbnailProps {
  s3ThumbnailKey: string,
  alt: string
}

export const S3ImageThumbnail = React.memo((props: S3ImageThumbnailProps) => {
  const {
    s3ThumbnailKey,
    alt
  } = props;

  const [s3ImageThumbnail, setS3ImageThumbnail] = useState<string>(null);

  useEffect(() => {
    FileService.get(s3ThumbnailKey)
      .then(result => {
        if (typeof result === "string") {
          setS3ImageThumbnail(result);
        }
      })
      .catch(err => console.error(err));
  }, [s3ThumbnailKey]);

  return <div className={css(styles.container)}>
    {s3ImageThumbnail && <GracefulImage
      noLazyLoad={true}
      src={s3ImageThumbnail}
      className={css(styles.thumbnail)}
      alt={`${alt} thumbnail`}
    />}
  </div>;
});

const styles = StyleSheet.create({
  container: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    overflow: "hidden",
    height: 120,
    width: 190,
  },
  thumbnail: {
    width: "100%",
    objectFit: "contain"
  }
});
