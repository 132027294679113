import { IFlashMessage } from '../../shared/interfaces/flash-message.interface';
import { FlashMessagesActionTypes } from './flash-messages.action-types';

interface IFlashMessagesState {
  messages: IFlashMessage[];
}

export function flashMessagesReducer(state: IFlashMessagesState, action: any) {
  switch (action.type) {
    case FlashMessagesActionTypes.ADD_MESSAGE:
      return {
        ...state,
        messages: [ ...state.messages, action.message ]
      }
    case FlashMessagesActionTypes.REMOVE_MESSAGE:
      return {
        ...state,
        messages: state.messages.filter((message) =>  message.id !== action.id)
      }
  }
}
