import { ITableHeaderAndFooterConfig } from '../interfaces/table-header-config.interface';

export const UserHeadersConfig: ITableHeaderAndFooterConfig[] = [
  {
    label: "User / E-Mail",
    width: "25%",
    columnKey: "email",
  },
  {
    label: "Vorname",
    width: "15%",
    columnKey: "firstName",
  },
  {
    label: "Nachname",
    width: "15%",
    columnKey: "lastName",
  },
  {
    label: "Rechte",
    columnKey: "role",
  },
  {
    label: "Status",
    columnKey: "status",
  },
  {
    columnKey: "actions",
    sortable: false,
    width: 90,
    colSpan: 2
  },
];
