export class CssCustomPropertyService {
  private static document: HTMLElement;
  private static instance: CssCustomPropertyService;

  private constructor() {
    CssCustomPropertyService.document = document.documentElement;
  }

  static getInstance(): CssCustomPropertyService {
    if (!CssCustomPropertyService.instance) {
      CssCustomPropertyService.instance = new CssCustomPropertyService();
    }

    return CssCustomPropertyService.instance;
  }

  addCustomProperty(propertyName: string, value: string): void {
    CssCustomPropertyService.document.style.setProperty(propertyName, value);
  }
}
