import { KeyValuePairs } from '../../../../shared/types/key-value-pairs.type';
import { FieldArrayActionTypes } from './field-array.action-types';

export interface IFieldArrayState {
  data: any[];
  previousData: any[];
  existingDataRegistry: string[];
  isDataAddAllowed: boolean;
}

export function fieldArrayReducer(state: IFieldArrayState, action: KeyValuePairs) {
  switch (action.type) {
    case FieldArrayActionTypes.SET_DATA:
      return {
        ...state,
        data: action.data
      };
    case FieldArrayActionTypes.SET_PREVIOUS_DATA:
      return {
        ...state,
        previousData: action.previousData
      };
    case FieldArrayActionTypes.SET_IS_DATA_ADD_ALLOWED:
      return {
        ...state,
        isDataAddAllowed: action.isDataAddAllowed
      };
    case FieldArrayActionTypes.SET_EXISTING_DATA_REGISTRY:
      return {
        ...state,
        existingDataRegistry: action.existingDataRegistry
      };
    default:
      return state;
  }
}
