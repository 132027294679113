import { IState } from "../../../shared/interfaces/state.interface";
import { connect } from "react-redux";
import { selectActiveFilter } from "../../../shared/redux/tenancies/tenancies.selector";
import { resetAllFilterAction, resetFilterAction } from "../../../shared/redux/tenancies/tenancies.action";
import { FilterSelectionWithoutStore } from "../filter-selection-without-store";

const mapStateToProps = (state: IState) => ({
  activeFilter: selectActiveFilter(state)
});

const mapDispatchToProps = (dispatch: any) => ({
  resetFilter: (groupKey: string) => dispatch(resetFilterAction(groupKey)),
  resetAllFilter: () => dispatch(resetAllFilterAction())
});

export const TenanciesFilterSelection = connect(
  mapStateToProps,
  mapDispatchToProps,
  null,
  { forwardRef: true }
)(FilterSelectionWithoutStore);
