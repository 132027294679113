import { ISelectItem } from '../../components/inputs/interfaces/select-item.interface';

export const PropertyStatusOptions: ISelectItem[] = [
  { label: 'Neu', isChecked: false, value: 'new', color: 'red' },
  { label: 'In Bearbeitung', isChecked: false, value: 'inProgress', color: 'orange' },
  { label: 'Fertig', isChecked: false, value: 'done', color: 'green' }
].map((item, index) => {
  return {
    id: `property-data-option-${index+1}`,
    ...item
  }
});
