import React from 'react';
import Container from 'react-bootstrap/esm/Container';
import Row from 'react-bootstrap/esm/Row';
import Col from 'react-bootstrap/esm/Col';
import {RemarksListWithStore} from './remarks/remarks-list';
import {IntroTextsListWithStore} from './introtexts/intro-texts-list';
import {css, StyleSheet} from 'aphrodite';
import {CssSize} from '../../../../shared/helpers/styles.helper';
import {SpotterRemarkWithStore} from "./spotter-remark";

export const PropertyDetailTextView = () => {
  return (
    <Container fluid>
      <Row>
        <Col xs={12} md={6}>
          <div className={css(styles.extraPaddingRight)}>
            <IntroTextsListWithStore/>
          </div>
          <div className={css(styles.extraPaddingRight)}>
            <SpotterRemarkWithStore/>
          </div>
        </Col>
        <Col xs={12} md={6}>
          <div className={css(styles.extraPaddingLeft)}>
            <RemarksListWithStore/>
          </div>
        </Col>
      </Row>
    </Container>
  );
}

const styles = StyleSheet.create({
  extraPaddingLeft: {
    padding: `0 ${CssSize.s20} 0 ${CssSize.s10}`
  },
  extraPaddingRight: {
    padding: `0 ${CssSize.s10} 0 ${CssSize.s20}`
  }
})
