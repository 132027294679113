import { GroupSize } from '../../../enums/group-size.enum';
import { IFormFieldset } from '../../../interfaces/form-fieldset.interface';
import { AffiliateOwnerAgentObjectsFormDefinitions } from "./affiliate-owner-agent-objects.input-definitions.const";
import { AffiliateTenancyAgentObjectsFormDefinitions } from "./affiliate-tenancy-agent-objects.input-definitions.const";
import {
  selectAffiliateOwnerAgentObjectsAffiliateSelectorObject,
  selectAffiliateOwnerAgentObjectsPropertySelectorObject,
  selectAffiliateTenancyAgentObjectsAffiliateSelectorObject,
  selectAffiliateTenancyAgentObjectsPropertySelectorObject
} from "../../../../../shared/redux/affiliate/affiliate.selectors";

export const AffiliateOwnerAgentObjectsFormDefinition: IFormFieldset[] = [
  {
    name: 'agentObjects',
    groups: [
      {
        title: 'Makler-Objekte (mit Eigentümern)',
        size: GroupSize.Full,
        fields: [
          AffiliateOwnerAgentObjectsFormDefinitions.AffiliateAgentObjects(selectAffiliateOwnerAgentObjectsPropertySelectorObject, selectAffiliateOwnerAgentObjectsAffiliateSelectorObject, 'owneragentobjects')
        ]
      }
    ]
  },
];

export const AffiliateTenancyAgentObjectsFormDefinition: IFormFieldset[] = [
  {
    name: 'agentObjects',
    groups: [
      {
        title: 'Makler-Objekte (mit Mietern)',
        size: GroupSize.Full,
        fields: [
          AffiliateTenancyAgentObjectsFormDefinitions.AffiliateAgentObjects(selectAffiliateTenancyAgentObjectsPropertySelectorObject, selectAffiliateTenancyAgentObjectsAffiliateSelectorObject, 'tenancyagentobjects')
        ]
      }
    ]
  },
];
