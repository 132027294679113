import { css, StyleSheet } from "aphrodite";
import { List } from "immutable";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { IState } from "../../../../shared/interfaces/state.interface";
import { IIntroTextsParams, IntroTextModel } from "../../../../shared/models/intro-text.model";
import { IntroTextActions } from "../../../../shared/redux/intro-texts/intro-texts.action";
import { selectIntroTextTemplates } from "../../../../shared/redux/intro-texts/intro-texts.selectors";
import { IntroTextsDataService } from "../../../../shared/services/intro-texts-data.service";
import { Button, ButtonColor, ButtonIconPosition, ButtonSize } from "../../../button";
import { IntroTextsDatasetHistory } from "../../../dataset-history/intro-texts-dataset-history";
import { Fieldset } from "../../../fieldset/fieldset";
import { AddIcon } from "../../../icons/collection/add-icon";
import { SvgVariant } from "../../../icons/enum/svg-variant.enum";
import { IntroTextRow } from "../../../intro-text-row/intro-text-row";
import { GridTable } from "../../../grid-table/grid-table";
import { useIntroTextsState } from "./use-intro-texts-state";
import { usePrevious } from "../../../../hooks/use-previous";
import { DeleteEntryDialog } from "../../../overlay/dialog/variants/delete-entry-dialog";

interface IIntroTextsViewProps {
  templates: List<IntroTextModel>;
  addTemplate: () => void;
  fetchTemplates: () => void;
  deleteTemplate: (id: string) => void;
  updateTemplate: (template: IIntroTextsParams) => void;
}

export const IntroTextsView = (props: IIntroTextsViewProps) => {
  const {
    templates = List(),
    addTemplate,
    fetchTemplates,
    deleteTemplate,
    updateTemplate
  } = props;

  const {
    state,
    setCreateNewState,
    setUpdateState
  } = useIntroTextsState();

  const previousTemplatesSize = usePrevious(templates?.size ?? 0);

  const [showDeleteDialog, setShowDeleteDialog] = useState(false);
  const [deleteIndex, setDeleteIndex] = useState(null);


  useEffect(() => {
    if (!templates?.size) {
      fetchTemplates();
    }
  }, [templates, fetchTemplates, previousTemplatesSize, setUpdateState]);


  const onAddTemplate = () => {
    setCreateNewState();
    addTemplate();
  };

  const onDelete = (templateId: string) => {
    setShowDeleteDialog(true);
    setDeleteIndex(templateId);
  };

  const onUpdate = (template: IIntroTextsParams) => {
    updateTemplate(template);
    setUpdateState();
  };

  const onDialogClick = (isDeleteClick: boolean) => {
    setShowDeleteDialog(false);

    if (isDeleteClick) {
      deleteTemplate(deleteIndex);
      setUpdateState();
    }

  };

  return (
    <>
      <IntroTextsDatasetHistory/>

      <Fieldset>

        <Fieldset.Legend>
          <div className={css(styles.legendTitle)}>Introtext Vorlagen</div>

          <Button
            onClick={onAddTemplate}
            btnClass={"medium-font-size bold margin-left-auto"}
            size={ButtonSize.Small}
            color={ButtonColor.Ghost}
            label={"Neue Introtext Vorlage anlegen"}
            iconPosition={ButtonIconPosition.Left}>
            <AddIcon
              viewBox='0 0 30 30'
              color={SvgVariant.Secondary}
              titleAccess='Eintrag hinzufügen'
            />
          </Button>

        </Fieldset.Legend>

        <Fieldset.Content>
          {
            !templates?.size
              ?
              <div>Keine Introtexte vorhanden.</div>
              :
              <GridTable>
                <GridTable.Head headers={["Lage", "Vorlage & Verknüpfungen"]}/>
                <GridTable.Body rows={templates?.map((template, index) =>
                  <IntroTextRow
                    key={template.id}
                    autoFocus={state.isNew && index === 0}
                    data={template}
                    onDelete={() => onDelete(template.id)}
                    onUpdate={(template) => onUpdate(template)}/>
                )}
                />
              </GridTable>
          }
        </Fieldset.Content>

      </Fieldset>

      <DeleteEntryDialog
        onControlClick={onDialogClick}
        showDialog={showDeleteDialog}
        onClose={() => setShowDeleteDialog(false)}
      />
    </>
  );
};

const styles = StyleSheet.create({
  legendTitle: {
    fontSize: "var(--font-size-title)",
    fontWeight: "bold"
  }
});

const mapStateToProps = (state: IState) => {
  return {
    templates: selectIntroTextTemplates(state)
  };
};

const mapDispatchToProps = (dispatch: any) => ({
  fetchTemplates: async () => {
    const res = await IntroTextsDataService.fetch();
    dispatch(IntroTextActions.set(res.data));
  },
  deleteTemplate: (id: string) => IntroTextsDataService.delete(id).then(() => dispatch(IntroTextActions.delete(id))),
  updateTemplate: async (template: IIntroTextsParams) => {
    const res = await IntroTextsDataService.update(template);
    dispatch(IntroTextActions.update(res?.data));
  },
  addTemplate: async () => {
    const res = await IntroTextsDataService.add();
    dispatch(IntroTextActions.create(res?.data));
  }
});

export const IntroTextsViewWithStore = connect(mapStateToProps, mapDispatchToProps)(IntroTextsView);
