import { canvasDimensions } from "./image-editor.defaults";
import { IImageEditorSettings } from "./image-editor.types";

export abstract class CanvasHelper {

  static applySettings(canvas: HTMLCanvasElement, ctx: CanvasRenderingContext2D, { contrast, brightness, rotation }: IImageEditorSettings) {
    let filterValue = "";

    if (contrast !== 0) {
      filterValue += `contrast(${contrast}%)`;
    }

    if (brightness !== 0) {
      filterValue += ` brightness(${brightness}%)`;
    }

    // ctx.filter = `brightness(${brightness ? brightness + '%' : 'none'})`; // brightness(${brightness ? brightness + '%' : 'none'})`;
    ctx.filter = filterValue;
    ctx.translate(canvas.width / 2, canvas.height / 2);
    ctx.rotate(rotation * Math.PI / 180);
  }

  static clearCanvas(ctx: CanvasRenderingContext2D) {
    ctx.filter = "";
    ctx.setTransform(1, 0, 0, 1, 0, 0);
    ctx.clearRect(0, 0, canvasDimensions.width, canvasDimensions.height);
  }

  static drawImage(ctx: CanvasRenderingContext2D, img: HTMLImageElement, canvas: HTMLCanvasElement, settings: IImageEditorSettings) {
    this.clearCanvas(ctx);

    const isRotated = this.getIsRotated(settings.rotation);
    const rotationImageWidth = !isRotated ? img.naturalWidth : img.naturalHeight;
    const rotationImageHeight = !isRotated ? img.naturalHeight : img.naturalWidth;
    const rotationCanvasWidth = !isRotated ? canvasDimensions.width : canvasDimensions.height;
    const rotationCanvasHeight = !isRotated ? canvasDimensions.height : canvasDimensions.width;

    let { scaleX, imageWidth } = CanvasHelper.getXAxisScaling(rotationImageWidth, rotationCanvasWidth);
    let { scaleY, imageHeight } = CanvasHelper.getYAxisScaling(rotationImageHeight, rotationCanvasHeight);

    const { scaledHeight, scaledWidth } = CanvasHelper.getScaledDimensions(scaleY, scaleX, imageHeight, imageWidth);
    CanvasHelper.updateCanvasDimensions(canvas, scaledHeight, scaledWidth);
    this.applySettings(canvas, ctx, settings);

    const startX = -(!isRotated ? canvas.width : canvas.height) / 2;
    const startY = -(!isRotated ? canvas.height : canvas.width) / 2;
    ctx.drawImage(img, startX, startY, !isRotated ? scaledWidth : scaledHeight, !isRotated ? scaledHeight : scaledWidth);
  }

  private static getIsRotated(rotation: number) {
    return rotation % 180 !== 0;
  }

  private static getXAxisScaling(width: number, canvasWidth: number) {
    let imageWidth = width;
    let scaleX = 1;
    if (imageWidth > canvasWidth) {
      scaleX = canvasWidth / imageWidth;
    }

    return { scaleX, imageWidth };
  }

  private static getYAxisScaling(height: number, canvasHeight: number) {
    let imageHeight = height;
    let screenHeight = canvasHeight;
    let scaleY = 1;
    if (imageHeight > screenHeight)
      scaleY = screenHeight / imageHeight;
    return { scaleY, imageHeight };
  }

  private static getScaledDimensions(scaleY: number, scaleX: number, imageHeight: number, imageWidth: number) {
    let scale = scaleY;

    if (scaleX < scaleY) {
      scale = scaleX;
    }

    if (scale < 1) {
      imageHeight = imageHeight * scale;
      imageWidth = imageWidth * scale;
    }

    return {
      scaledHeight: imageHeight,
      scaledWidth: imageWidth
    };
  }

  private static updateCanvasDimensions(canvas: HTMLCanvasElement, scaledHeight: number, scaledWidth: number) {
    canvas.height = scaledHeight;
    canvas.width = scaledWidth;
  }
}
