export const UserActionTypes = {
  SET_USERS: '[USERS] Set users',
  SET_USERS_META: '[USERS] Set users meta',
  SORT_USERS: '[USERS] Sort users',
  ADD_EMPTY_USER: '[USERS] Add empty user',
  CONFIRM_USER: '[USERS] Confirm user',
  UPDATE_USER: '[USERS] Update user',
  DELETE_USER: '[USERS] Delete user',
  ENABLE_USER: '[USERS] Enable user',
  DISABLE_USER: '[USERS] Disable user',
  FETCH_IN_PROGRESS: '[USERS] Fetch in progress',
  FETCH_FINISHED: '[USERS] Fetch finished',
}
