import React, { ReactNode, useEffect, useReducer, useRef, useState } from "react";
import { SearchService } from "../../../shared/services/search.service";
import { searchReducer } from "./state/search.reducer";
import { SearchIcon } from "../../icons/collection/search-icon";
import { LoadingSpinnerBlueIcon } from "../../icons";
import { resetSearch, setSuggestions, setValue } from "./state/search.action";
import { useEndOfViewport } from "../../../hooks/use-end-of-viewport";
import { useClickOutside } from "../../../hooks/use-click-outside";
import { ActiveFilterModel } from "../../../shared/models/active-filter.model";
import { IFilterParam } from "../../../shared/interfaces/filter-param.interface";
import { SvgVariant } from "../../icons/enum/svg-variant.enum";
import { KeyCodeHelper } from "../../../shared/helpers/key-code.helper";
import { filter, map } from "rxjs/operators";
import "./search-input.scss";
import { SuggestionItem } from "./suggestion-item/suggetions-item";
import { useCurrentLanguage } from "../../../contexts/current-language.context";
import { useI18nLabelFromObject } from "../../../hooks/use-i18n-label-from-object";

interface ISearchInputProps {
  storedValue: ActiveFilterModel | undefined;
  groupKey: string;
  setFilter: (filter: IFilterParam) => void;
  resetFilter: (groupKey: string) => void;
  placeHolder: string;
  searchService: SearchService;
  valueKey?: string;
  labelKey?: string;
  i18n?: boolean;
}

export const SearchInput = (props: ISearchInputProps) => {
  const searchRef = useRef(null);
  const suggestionContainerRef = useRef(null);

  const [cursor, setCursor] = useState<number>(0);
  const [isFocused, setIsFocused] = useState<boolean>(false);
  const [suggestionsAlignment, setSuggestionsAlignment] = useState("left");

  const { storedValue, groupKey, setFilter, resetFilter, placeHolder, searchService, labelKey, valueKey, i18n = false } = props;

  const currentLanguage = useCurrentLanguage();

  const [state, dispatch] = useReducer(searchReducer, {
    value: storedValue?.value ?? "",
    valueObject: storedValue?.valueObject ?? null,
    suggestions: [],
    isLoading: false,
    hasSelected: false
  });

  const { value, valueObject, isLoading, suggestions, hasSelected } = state;

  const WINDOW_SECTIONS = 5;

  const onActiveFilterChange = () => {
    if (!storedValue) {
      dispatch(resetSearch());
      setCursor(0);
    }
  };

  useEffect(onActiveFilterChange, [storedValue]);

  useEffect(() => {
    if (!hasSelected) {
      setCursor(0);

      searchService.search(value);

      const suggestionsSubscription = searchService.getSuggestions()
        .pipe(
          filter(( data) => !!data?.data),
          map(({ data }) => {
            return data?.map((suggestion) => {
              const firstKey = Object.keys(suggestion)[0];

              const searchServiceObjectLabelKeyI18n = !!i18n ? labelKey + '_' + currentLanguage : labelKey;
              const suggestionLabelKey = !!labelKey ? searchServiceObjectLabelKeyI18n : firstKey;
              const suggestionLabel = suggestion[suggestionLabelKey];

              const suggestionValueKey = !!valueKey ? valueKey : firstKey;
              const suggestionValue = suggestion[suggestionValueKey];

              return Object.keys(suggestion).length <= 1 ? suggestionValue : {
                label: suggestionLabel,
                value: suggestionValue,
                valueObject: suggestion
              };
            });
          })
        )
        .subscribe((data: []) => {
          dispatch(setSuggestions(data));
        });

      return () => {
        suggestionsSubscription.unsubscribe();
      };
    }
  }, [searchService, hasSelected, value, i18n, currentLanguage, labelKey, valueKey]);

  useEndOfViewport(searchRef, WINDOW_SECTIONS, (isEndOfViewport: boolean) => {
    setSuggestionsAlignment(isEndOfViewport ? "right" : "left");
  });

  useClickOutside(searchRef, (clickedOutside) => {
    setIsFocused(!clickedOutside);
  });

  const handleFilterUpdate = () => {
    if (hasSelected) {
      setIsFocused(false);
      setCursor(0);

      if (!value) {
        resetFilter(groupKey);
        return;
      }

      const filterObject = {
        groupKey: groupKey,
        groupLabel: placeHolder,
        value,
        valueObject
      };

      if(!!labelKey || !!valueKey) {
        const meta = {};

        if(!!labelKey) {
          meta['labelKey'] = labelKey;
        }

        if(!!valueKey) {
          meta['valueKey'] = valueKey;
        }

        meta['i18n'] = i18n;

        filterObject['meta'] = meta;
      }

      setFilter(filterObject);
    }
  };

  useEffect(handleFilterUpdate, [groupKey, placeHolder, setFilter, resetFilter, hasSelected, value]);

  const handleChange = (event: any) => {
    dispatch(setValue(event.target.value, null, event.target.value?.length >= 3, false));
  };

  const handleSelect = (event: any, value: string, valueObject = null) => {
    event?.preventDefault();
    event?.stopPropagation();

    dispatch(setValue(value, valueObject, false, true));
  };

  const handleBlur = (event: any) => {
    if (!event.target.value && !hasSelected) {
      resetFilter(groupKey);
    }
  };

  /**
   * Listen to key press of "enter" and only allow selection
   * if the current input value is not equal the selected or the user either hasn't selected
   * @param event
   */
  const handleKeyPress = (event: any) => {
    const selectedValue = getSelectedValueByCursor();

    if (event.key === "Enter" && (event.target.value !== selectedValue || !hasSelected)) {
      handleSelect(event, selectedValue);
      event.target.blur();
    }
  };

  const getSelectedValueByCursor = () => {
    return suggestions?.[cursor]?.[groupKey] || "";
  };

  /**
   * From here on we handle the navigation by arrows
   * through the list of suggestions
   * @param event
   */
  const handleKeyDown = (event: any) => {
    if (KeyCodeHelper.isArrowUp(event.keyCode) && cursor > 0) {
      const newCursor = moveUp();
      setCursor(newCursor);
    } else if (KeyCodeHelper.isArrowDown(event.keyCode) && cursor < suggestions?.length - 1) {
      const newCursor = moveDown();
      setCursor(newCursor);
    } else if (KeyCodeHelper.isArrowDown(event.keyCode) && cursor === suggestions?.length - 1) {
      suggestionContainerRef.current.scrollTop = 0;

      setCursor(0);
    }
  };

  const moveUp = (): number => {
    const suggestionItem = suggestions[cursor - 1];

    const suggestionNode: HTMLElement | null = document.querySelector(`[data-suggestion="${suggestionItem.propertyId}"]`);
    const nodeOffsetHeight = suggestionNode?.offsetHeight;
    const nodeOffsetTop = suggestionNode?.offsetTop;

    if (!!nodeOffsetTop && nodeOffsetTop <= (suggestionContainerRef?.current as any)?.scrollTop) {
      suggestionContainerRef.current.scrollTop = suggestionContainerRef.current.scrollTop - nodeOffsetHeight;
    }

    return cursor - 1;
  };

  const moveDown = (): number => {
    const suggestionItem = suggestions[cursor + 1];

    const suggestionNode: HTMLElement | null = document.querySelector(`[data-suggestion="${suggestionItem.propertyId}"]`);
    const nodeOffsetHeight = suggestionNode?.offsetHeight;
    const nodeOffsetTop = suggestionNode?.offsetTop;

    if (!!nodeOffsetTop && nodeOffsetTop >= (suggestionContainerRef?.current as any)?.scrollTop + (suggestionContainerRef?.current as any)?.clientHeight) {
      suggestionContainerRef.current.scrollTop = suggestionContainerRef.current.scrollTop + nodeOffsetHeight;
    }

    return cursor + 1;
  };

  const getPlaceholderByStatus = () => {
    if (value?.length >= 3 && isLoading) {
      return "Suche nach Vorschlägen...";
    } else if (value?.length >= 3 && !suggestions?.length && !isLoading) {
      return "Keine Ergebnisse.";
    } else {
      return `Bitte ${placeHolder} eingeben.`;
    }
  };

  const i18nLabel = useI18nLabelFromObject({i18n, valueKey: valueKey, labelKey: labelKey, valueObject, value});

  const renderSuggestionsNodes = (): ReactNode => {
    if (!suggestions?.length) {
      return <li className='empty-result'>{getPlaceholderByStatus()}</li>;
    }

    return <>{suggestions?.map((suggestion: string | object, index: number) =>
      <SuggestionItem key={index} suggestion={suggestion} index={index} handleSelect={handleSelect}
                      cursor={cursor}/>)}</>;
  };

  const loadingSpinnerIcon = <span className='icon-container'><LoadingSpinnerBlueIcon titleAccess='Test' viewBox={"0 0 16 16"} className='loading'/></span>;
  const searchIcon = <SearchIcon color={SvgVariant.Secondary} viewBox={"0 0 40 40"} titleAccess={placeHolder}/>;

  return (
    <label className={`search-input-wrapper ${isFocused ? "is-focused" : ""}`}
           ref={searchRef}
           htmlFor={groupKey}>
      <input type="text"
             id={groupKey}
             name={groupKey}
             autoComplete={"none"}
             value={i18nLabel()}
             placeholder={placeHolder}
             onBlur={handleBlur}
             onChange={handleChange}
             onKeyDown={handleKeyDown}
             onKeyPress={handleKeyPress}
             onFocus={() => setIsFocused(true)}
      />
      <button onClick={handleFilterUpdate}>
        {isLoading ? loadingSpinnerIcon : searchIcon}
      </button>
      <ul className={`suggestions-container ${suggestionsAlignment}`}
          ref={suggestionContainerRef}>
        {renderSuggestionsNodes()}
      </ul>
    </label>
  );
};
