import { TemplateType } from '../enums/template-type.enum';
import { IFieldCompose } from '../interfaces/field-double.interface';

export abstract class FieldComposeConfig {
  private static DEFAULT_CONFIG: IFieldCompose = {
    name: null,
    templateType: TemplateType.FIELD_COMPOSE,
    value: null,
    customCss: null,
    headers: null,
    fields: null,
    repeat: 2,
    condensed: false
  };

  static getConfig(options: Partial<IFieldCompose>) {
    return {
      ...FieldComposeConfig.DEFAULT_CONFIG,
      ...options
    }
  }
}
