export const TenanciesActionTypes = {
    SET_DATA: '[Tenancies] Set tenancy list',
    SET_FILTER: '[Tenancies] Set tenancy filter',
    RESET_FILTER: '[Tenancies] Reset filter',
    RESET_ALL_FILTER: '[Tenancies] Reset all filter',
    FETCH_IN_PROGRESS: '[Tenancies] Fetch In Progress',
    FETCH_FINISHED: '[Tenancies] Fetch finished',
    SET_SORTING: '[Tenancies] Set sorting',
    SET_SORTED_LIST: '[Tenancies] Set sorted list',
    SET_OFFSET: '[Tenancies] Set offset',
    SET_MORE_DATA: '[Tenancies] Set more tenancy list data',
    RESET_DATA: '[Tenancies] Reset tenancy list',
  SET_SCROLL_TOP: '[Tenancies] Set scroll top',
}
