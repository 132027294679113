import { KeyValuePairs } from '../../../../shared/types/key-value-pairs.type';
import { FieldAddressArrayActionTypes } from './field-address-array.action-types';

export interface IFieldAddressArrayState {
  addresses: KeyValuePairs[];
  previousAddresses: KeyValuePairs[];
}

export function fieldAddressArrayReducer(state: IFieldAddressArrayState, action: KeyValuePairs) {
  switch (action.type) {
    case FieldAddressArrayActionTypes.SET_ADDRESSES:
      return {
        ...state,
        addresses: action.addresses
      };
    case FieldAddressArrayActionTypes.SET_PREVIOUS_ADDRESSES:
      return {
        ...state,
        previousAddresses: action.previousAddresses
      }
    default:
      return state;
  }
}
