import "../filter.scss";
import React from "react";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import { Dropdown } from "../../dropdown/dropdown";
import { NumericSeparator } from "../../inputs/constants/numeric-separators.enum";
import { RangeInput, RangeInputType } from "../../inputs/numeric-range-input/range-input";
import { SearchInput } from "../../inputs/search-input/search-input";
import { PropertyFilterKey } from "../../../shared/constants/property-filter-key.enum";
import { CollapsableContainer } from "../../collapsable-container/collapsable-container";
import { SearchService } from "../../../shared/services/search.service";
import { ActiveFilterModel } from "../../../shared/models/active-filter.model";
import { IFilterParam } from "../../../shared/interfaces/filter-param.interface";
import { ApiEndpoint } from "../../../shared/constants/api-endpoint.enum";
import { connect } from "react-redux";
import { resetFilterAction, setFilterAction } from "../../../shared/redux/tenancies/tenancies.action";
import { TenanciesFilterKey } from "../../../shared/redux/tenancies/tenancies-filter-key.enum";
import {
  selectActiveFilter,
  selectAffiliateNameActiveFilter,
  selectCountryActiveFilter,
  selectLocationActiveFilter,
  selectOfficeAreaActiveFilter,
  selectPostalCodeActiveFilter,
  selectRegionActiveFilter,
  selectRentalEndActiveFilter,
  selectRentalStartActiveFilter,
  selectRentPriceActiveFilter,
  selectStreetActiveFilter,
  selectTotalAreaActiveFilter
} from "../../../shared/redux/tenancies/tenancies.selector";
import { useCurrentLanguage } from "../../../contexts/current-language.context";
import {UnitSymbol} from "../../form/enums/unit-symbol.enum";

interface ITenanciesFilterProps {
  affiliateNameActiveFilter: ActiveFilterModel,
  streetActiveFilter: ActiveFilterModel,
  postalCodeActiveFilter: ActiveFilterModel,
  locationActiveFilter: ActiveFilterModel,
  countryActiveFilter: ActiveFilterModel,
  regionActiveFilter: ActiveFilterModel,
  rentalStartActiveFilter: ActiveFilterModel,
  totalAreaActiveFilter: ActiveFilterModel,
  rentalEndActiveFilter: ActiveFilterModel,
  officeAreaActiveFilter: ActiveFilterModel,
  rentPriceActiveFilter: ActiveFilterModel,

  setFilter: (filter: IFilterParam) => void;
  resetFilter: (groupKey: string) => void;
}

const TenanciesFilterWithoutStore = (props: ITenanciesFilterProps) => {
  const {
    affiliateNameActiveFilter,
    streetActiveFilter,
    postalCodeActiveFilter,
    locationActiveFilter,
    //countryActiveFilter,
    regionActiveFilter,
    rentalStartActiveFilter,
    totalAreaActiveFilter,
    rentalEndActiveFilter,
    officeAreaActiveFilter,
    rentPriceActiveFilter,

    setFilter,
    resetFilter
  } = props;

  const currentLanguage = useCurrentLanguage();

  return (
    <>
      <CollapsableContainer>
        {{
          baseFilter: (
            <Row>
              <Col xs={6} lg={4} xl={3}>
                <SearchInput storedValue={affiliateNameActiveFilter}
                             searchService={new SearchService(`${ApiEndpoint.TENANCY_SUGGESTIONS}?${TenanciesFilterKey.AFFILIATE_NAME}=`)}
                             groupKey={TenanciesFilterKey.AFFILIATE_NAME}
                             placeHolder={"Mieter"}
                             setFilter={setFilter}
                             resetFilter={resetFilter}/>
              </Col>
              <Col xs={6} lg={4} xl={3}>
                <SearchInput storedValue={streetActiveFilter}
                             searchService={new SearchService(`${ApiEndpoint.TENANCY_SUGGESTIONS}?${TenanciesFilterKey.STREET}=`)}
                             groupKey={PropertyFilterKey.STREET}
                             placeHolder={"Straße"}
                             setFilter={setFilter}
                             resetFilter={resetFilter}/>
              </Col>
              <Col xs={6} lg={4} xl={3}>
                <SearchInput storedValue={locationActiveFilter}
                             searchService={new SearchService(`${ApiEndpoint.TENANCY_SUGGESTIONS}?${TenanciesFilterKey.LOCATION}=`)}
                             groupKey={TenanciesFilterKey.LOCATION}
                             placeHolder={"Stadt"}
                             setFilter={setFilter}
                             resetFilter={resetFilter}/>
              </Col>
            </Row>
          ),
          wrappedFilter: (
            <>
              <Row>
                <Col xs={6} lg={4} xl={3}>
                  <Dropdown buttonLabel={"Postleitzahl"} btnClass={"fullwidth"}>
                    <RangeInput storedSelection={postalCodeActiveFilter}
                                setFilter={setFilter}
                                resetFilter={resetFilter}
                                size={"large"}
                                showLabel={false}
                                label={"Postleitzahl"}
                                max={99999}
                                allowDecimal={false}
                                allowLeadingZeros={true}
                                thousandSeparator={NumericSeparator.None}
                                groupKey={TenanciesFilterKey.POSTAL_CODE}/>
                  </Dropdown>
                </Col>
                {/*<Col xs={6} lg={4} xl={3}>
                  <SearchInput storedValue={countryActiveFilter}
                               searchService={new SearchService(`${ApiEndpoint.TENANCY_SUGGESTIONS}?${TenanciesFilterKey.COUNTRY}=`)}
                               groupKey={TenanciesFilterKey.COUNTRY}
                               placeHolder={"Land"}
                               setFilter={setFilter}
                               resetFilter={resetFilter}/>
                </Col>*/}
                <Col xs={6} lg={4} xl={3}>
                  <SearchInput storedValue={regionActiveFilter}
                               searchService={new SearchService(`${ApiEndpoint.TENANCY_SUGGESTIONS}?language=${currentLanguage}&${TenanciesFilterKey.REGION}=`)}
                               groupKey={TenanciesFilterKey.REGION}
                               valueKey={'id'}
                               labelKey={'title'}
                               i18n={true}
                               placeHolder={"Region"}
                               setFilter={setFilter}
                               resetFilter={resetFilter}/>
                </Col>
              </Row>
              <Row className='filter'>
                <Col xs={6} lg={4} xl={3}>
                  <RangeInput storedSelection={rentalStartActiveFilter}
                              setFilter={setFilter}
                              resetFilter={resetFilter}
                              type={RangeInputType.DATE}
                              groupKey={TenanciesFilterKey.RENTAL_START}
                              label={"Mietbeginn"}
                  />
                  <RangeInput storedSelection={totalAreaActiveFilter}
                              setFilter={setFilter}
                              resetFilter={resetFilter}
                              allowDecimal={false}
                              groupKey={TenanciesFilterKey.TOTAL_AREA}
                              label={"Gesamtfläche in m<sup>2</sup>"}
                  />
                </Col>
                <Col xs={6} lg={4} xl={3}>
                  <RangeInput storedSelection={rentalEndActiveFilter}
                              setFilter={setFilter}
                              resetFilter={resetFilter}
                              type={RangeInputType.DATE}
                              groupKey={TenanciesFilterKey.RENTAL_END}
                              label={"Mietende"}
                  />
                  <RangeInput storedSelection={officeAreaActiveFilter}
                              setFilter={setFilter}
                              resetFilter={resetFilter}
                              allowDecimal={false}
                              groupKey={TenanciesFilterKey.OFFICE_AREA}
                              label={"Bürofläche in m<sup>2</sup>"}
                  />
                </Col>
                <Col xs={6} lg={4} xl={3}>
                  <RangeInput storedSelection={rentPriceActiveFilter}
                              setFilter={setFilter}
                              resetFilter={resetFilter}
                              groupKey={TenanciesFilterKey.RENT_PRICE}
                              label={"Ø Mietpreis pro " + UnitSymbol.SquareMeter}
                  />
                </Col>
              </Row>
            </>
          )
        }}
      </CollapsableContainer>
    </>
  );
};

const mapStateToProps = (state: any) => {
  return {
    activeFilter: selectActiveFilter(state),
    affiliateNameActiveFilter: selectAffiliateNameActiveFilter(state),
    streetActiveFilter: selectStreetActiveFilter(state),
    postalCodeActiveFilter: selectPostalCodeActiveFilter(state),
    locationActiveFilter: selectLocationActiveFilter(state),
    countryActiveFilter: selectCountryActiveFilter(state),
    regionActiveFilter: selectRegionActiveFilter(state),
    rentalStartActiveFilter: selectRentalStartActiveFilter(state),
    totalAreaActiveFilter: selectTotalAreaActiveFilter(state),
    rentalEndActiveFilter: selectRentalEndActiveFilter(state),
    officeAreaActiveFilter: selectOfficeAreaActiveFilter(state),
    rentPriceActiveFilter: selectRentPriceActiveFilter(state)
  };
};

const mapDispatchToProps = (dispatch: any) => ({
  setFilter: (filter: IFilterParam) => dispatch(setFilterAction(filter)),
  resetFilter: (groupKey: string) => dispatch(resetFilterAction(groupKey))
});

export const TenanciesFilter = connect(
  mapStateToProps,
  mapDispatchToProps
)(TenanciesFilterWithoutStore);
