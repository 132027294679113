import React from "react";
import { Language } from "../shared/constants/language.enum";

export const CurrentLanguageContext = React.createContext(Language.GERMAN);

export const useCurrentLanguage = () => {
  const context = React.useContext(CurrentLanguageContext);

  if(context === undefined) {
    throw new Error('`useCurrentLanguage` hook must be used within a `LanguageProvider` component');
  }

  return context;
};
