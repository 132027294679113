import {TemplateType} from "../enums/template-type.enum";
import {InputType} from "../enums/input-type.enum";
import {AddressAutofill} from "../enums/autofill-address.enum";
import {FieldSingleConfig} from "../config/field-single.config";
import {FieldComposeConfig} from "../config/field-compose.config";
import {UnitSymbol} from "../enums/unit-symbol.enum";
import {FieldArrayConfig} from "../config/field-array.config";
import {FieldCollapsableConfig} from "../config/field-collapsable.config";
import {FieldTabArrayConfig} from "../config/field-tab-array.config";
import {selectCurrent, selectFormer} from "../fields/field-tab-array/field-tab-array.selector";
import {IFieldTabArrayState} from "../fields/field-tab-array/field-tab-array.reducer";
import {FieldAddressArrayConfig} from "../config/field-address-array.config";
import {FieldTableConfig} from "../config/field-table.config";
import {DatePickerType} from "../enums/datepicker-type.enum";
import {AffiliateDataService} from "../../../shared/services/affiliate-data.service";
import {IState} from "../../../shared/interfaces/state.interface";
import {TenancyInputDefinitions} from "./tenancy.input-definitions.const";
import {
  selectPropertyOwnersAffiliateSelectorObject,
  selectPropertyOwnersAgentSelectorObject,
  selectPropertyTenanciesAffiliateSelectorObject,
  selectPropertyTenanciesAgentSelectorObject
} from "../../../shared/redux/property/property.selectors";
import {ISelectableObject} from "../../../shared/interfaces/selectable.interface";
import {Language} from "../../../shared/constants/language.enum";
import {PropertyService} from "../../../shared/services/property.service";
import {TitleBarNumberType} from "../interfaces/field-collapsable.interface";

export abstract class PropertyInputDefinitions {
  static get PropertyName() {
    return FieldSingleConfig.getConfig({
      name: "title",
      fallbackName: "street",
      label: "Objektname"
    });
  }

  static get PropertyId() {
    return FieldSingleConfig.getConfig({
      name: "propertyId",
      label: "Objekt-ID",
      readonly: true
    });
  }

  static get PropertyContactFields() {
    return [
      {
        ...PropertyInputDefinitions.PropertyContactNameTel
      },
      {
        ...PropertyInputDefinitions.PropertyContactMailWebsite
      }
    ];
  }

  private static get PropertyContactNameTel() {
    return FieldComposeConfig.getConfig({
      fields: [
        {
          ...FieldSingleConfig.getConfig({
            name: "name",
            label: "Name"
          })
        },
        {
          ...FieldSingleConfig.getConfig({
            name: "phone",
            label: "Tel."
          })
        }
      ]
    });
  }

  private static get PropertyContactMailWebsite() {
    return FieldComposeConfig.getConfig({
      fields: [
        {
          ...FieldSingleConfig.getConfig({
            name: "email",
            label: "E-Mail"
          })
        },
        {
          ...FieldSingleConfig.getConfig({
            name: "website",
            label: "Website"
          })
        }
      ]
    });
  }

  static Address(name: string = AddressAutofill.address, readonly: boolean = false, showInputIcon = true) {
    return FieldSingleConfig.getConfig({
      templateType: TemplateType.MAPBOX_ADDRESS,
      name,
      label: "Adresse",
      placeholderText: !readonly ? "Adresse eingeben… z.B. Frankenallee 12, 12345" : "",
      readonly,
      showInputIcon
    });
  };

  static get MapboxAddress() {
    return FieldSingleConfig.getConfig({
      templateType: TemplateType.MAPBOX_ADDRESS,
      name: AddressAutofill.address,
      label: "Primäradresse *",
      placeholderText: "Adresse eingeben… z.B. Frankenallee 12, 12345"
    });
  };

  static get PropertyFederalState() {
    return FieldSingleConfig.getConfig({
      name: AddressAutofill.federalState,
      label: "Bundesland",
      placeholderText: "Wird durch Postleitzahl befüllt",
      isAutofill: true
    });
  }

  static getPropertyRegion(language: Language) {
    return FieldSingleConfig.getConfig({
      name: AddressAutofill.region + '_' + language,
      label: "Region",
      placeholderText: "Wird durch Regionenraster befüllt",
      isAutofill: true,
      i18n: language
    });
  }

  static getPropertyCountry(language: Language) {
    return FieldSingleConfig.getConfig({
      name: 'country_' + language,
      label: "Land",
      placeholderText: "Wird durch Postleitzahl befüllt",
      isAutofill: true,
      i18n: language
    });
  }

  static get PropertyCondition() {
    return FieldSingleConfig.getConfig({
      inputType: InputType.SELECT,
      name: "condition",
      label: "Zustand",
      options: [
        {
          label: "Neubau",
          value: "newBuilding"
        },
        {
          label: "Neuwertig",
          value: "asNew"
        },
        {
          label: "Gepflegt",
          value: "wellKept"
        },
        {
          label: "Saniert",
          value: "renovated"
        },
        {
          label: "Sanierungsbedürftig",
          value: "unrenovated"
        }
      ]
    });
  }

  static get PropertyTenant() {
    return FieldSingleConfig.getConfig({
      inputType: InputType.SELECT,
      name: "isMultiTenant",
      label: "Tenant",
      value: null,
      options: [
        {
          label: "Single",
          value: false
        },
        {
          label: "Multi",
          value: true
        }
      ]
    });
  }

  static get PropertyFloorConstructionYear() {
    return FieldComposeConfig.getConfig({
      fields: [
        {
          ...FieldSingleConfig.getConfig({
            inputType: InputType.NUMBER,
            name: "floor",
            label: "Geschosse",
            attributes: {
              allowDecimal: false,
              max: 999
            }
          })
        },
        {
          ...FieldSingleConfig.getConfig({
            inputType: InputType.DATE,
            name: "constructionYear",
            label: "Baujahr",
            attributes: {
              datePickerType: DatePickerType.Year
            }
          })
        }
      ]
    });
  }

  static get PropertyVacancyRate() {
    return FieldSingleConfig.getConfig({
      inputType: InputType.NUMBER,
      name: "vacancyRate",
      label: "Leerstand",
      attributes: {
        abbreviation: UnitSymbol.Percentage,
        allowDecimal: false,
        max: 100
      }
    });
  }

  static get PropertyParkingSpace() {
    return FieldComposeConfig.getConfig({
      customCss: "marginTop",
      headers: ["Tiefgarage", "Außenfläche"],
      fields: [
        {
          ...FieldSingleConfig.getConfig({
            inputType: InputType.NUMBER,
            name: "undergroundParkingSpace",
            label: "Parkplätze",
            attributes: {
              abbreviation: UnitSymbol.Pieces,
              allowDecimal: false,
              max: 99999
            }
          })
        },
        {
          ...FieldSingleConfig.getConfig({
            inputType: InputType.NUMBER,
            name: "outdoorParkingSpace",
            attributes: {
              abbreviation: UnitSymbol.Pieces,
              allowDecimal: false,
              max: 99999
            }
          })
        }
      ]
    });
  }

  static get PropertyTotalArea() {
    return FieldSingleConfig.getConfig({
      inputType: InputType.NUMBER,
      name: "totalArea",
      label: "Gesamtfläche (BGF)",
      attributes: {
        abbreviation: UnitSymbol.SquareMeter,
        max: 999999999999
      }
    });
  }

  static get PropertyArea() {
    return FieldSingleConfig.getConfig({
      inputType: InputType.NUMBER,
      name: "propertyArea",
      label: "Grundstücksgröße",
      attributes: {
        abbreviation: UnitSymbol.SquareMeter,
        max: 999999999999
      }
    });
  }

  static get PropertyAverageRentalCharge() {
    return FieldSingleConfig.getConfig({
      inputType: InputType.NUMBER,
      name: "averageRentalCharge",
      label: "Ø Mietpreis pro " + UnitSymbol.SquareMeter,
      attributes: {
        abbreviation: UnitSymbol.Euro,
        max: 999999999999,
        allowDecimal: true,
      }
    });
  }

  static get PropertyAreaTypes() {
    return FieldArrayConfig.getConfig({
      name: "areaTypes",
      customCss: "marginTop",
      fieldHeaders: ["Flächenart", "Größe"],
      addLabel: "Fläche hinzufügen",
      useRegistry: true,
      registryDataKey: "type",
      maxDataLength: 7,
      newDataRef: {
        ...FieldComposeConfig.getConfig({
          fields: [
            {
              ...FieldSingleConfig.getConfig({
                inputType: InputType.SELECT,
                name: "type",
                label: "Fläche",
                hideInputEnumeration: false,
                options: [
                  {
                    label: "Bitte auswählen",
                    value: null
                  },
                  {
                    label: "Wohnen",
                    value: "housing"
                  },
                  {
                    label: "Gastro",
                    value: "gastronomy"
                  },
                  {
                    label: "Hotel",
                    value: "hotel"
                  },
                  {
                    label: "Sonstiges",
                    value: "other"
                  },
                  {
                    label: "Büro",
                    value: "office"
                  },
                  {
                    label: "Lager",
                    value: "warehouse"
                  },
                  {
                    label: "Einzelhandel",
                    value: "retail"
                  },
                ]
              })
            },
            {
              ...FieldSingleConfig.getConfig({
                inputType: InputType.NUMBER,
                name: "size",
                attributes: {
                  abbreviation: UnitSymbol.SquareMeter,
                  max: 999999999999
                }
              })
            }
          ]
        })
      },
      identifier: "id",
      onDataAdd: async (routeParam: string | number) =>
        await PropertyService.addData(routeParam, "areatypes"),
      onDataDelete: async (routeParam: string | number, identifier: string) =>
        await PropertyService.deleteData(routeParam, `areatypes/${identifier}`)
    });
  }

  static get PropertyAnnualRent() {
    return FieldComposeConfig.getConfig({
      headers: ["Summe", "Stand"],
      fields: [
        {
          ...FieldSingleConfig.getConfig({
            inputType: InputType.NUMBER,
            name: "annualNetRent",
            label: "Jahresnettokaltmiete",
            attributes: {
              abbreviation: UnitSymbol.Euro,
              allowDecimal: false,
              max: 999999999999
            }
          })
        },
        {
          ...FieldSingleConfig.getConfig({
            inputType: InputType.DATE,
            name: "annualNetRentDate",
            attributes: {
              datePickerType: DatePickerType.Month
            }
          })
        }
      ]
    });
  }

  static get PropertyRenovationCost() {
    return FieldComposeConfig.getConfig({
      fields: [
        {
          ...FieldSingleConfig.getConfig({
            inputType: InputType.NUMBER,
            name: "renovationCost",
            label: "Sanierungskosten",
            attributes: {
              abbreviation: UnitSymbol.Euro,
              allowDecimal: false,
              max: 999999999999
            }
          })
        },
        {
          ...FieldSingleConfig.getConfig({
            inputType: InputType.DATE,
            name: "renovationCostDate",
            attributes: {
              datePickerType: DatePickerType.Year
            }
          })
        }
      ]
    });
  }

  static get PropertyConstructionCost() {
    return FieldComposeConfig.getConfig({
      fields: [
        {
          ...FieldSingleConfig.getConfig({
            inputType: InputType.NUMBER,
            name: "constructionCost",
            label: "Baukosten",
            attributes: {
              abbreviation: UnitSymbol.Euro,
              allowDecimal: false,
              max: 999999999999
            }
          })
        },
        {
          ...FieldSingleConfig.getConfig({
            inputType: InputType.DATE,
            name: "constructionCostDate",
            attributes: {
              datePickerType: DatePickerType.Year
            }
          })
        }
      ]
    });
  }

  static get PropertyPurchasePrices() {
    return FieldArrayConfig.getConfig({
      fieldHeaders: ["Summe", "Stand"],
      name: "purchasePrices",
      readonly: true,
      fieldArrayTitle: "Kaufpreise",
      addLabel: "Kaufpreis hinzufügen",
      newDataRef: {
        ...FieldComposeConfig.getConfig({
          fields: [
            {
              ...FieldSingleConfig.getConfig({
                inputType: InputType.NUMBER,
                name: "total",
                label: "Preis",
                hideInputEnumeration: true,
                attributes: {
                  abbreviation: UnitSymbol.Euro,
                  allowDecimal: false,
                  max: 999999999999
                }
              })
            },
            {
              ...FieldSingleConfig.getConfig({
                inputType: InputType.DATE,
                name: "date",
                attributes: {
                  datePickerType: DatePickerType.Month
                }
              })
            }
          ]
        })
      },
      identifier: "id",
      isDeleteAllowed: false,
      onDataAdd: async (routeParam: string | number) =>
        await PropertyService.addData(routeParam, "purchaseprices")
    });
  }

  static get PropertyOwners() {
    const titleBar = [
      [
        {
          replaceValue: "_affiliateId",
          title: async (state, id) => (selectPropertyOwnersAffiliateSelectorObject(state, id)?.label)
        }
      ],
      [
        {
          replaceValue: "total",
          title: `<strong>Kaufpreis (${UnitSymbol.Euro})</strong><br>$0`
        },
        {
          replaceValue: "purchaseDate",
          title: `<strong>Kaufdatum</strong><br>$0`
        },
        {
          replaceValue: "agentId",
          title: async (state, id) => "<strong>Makler</strong><br/>" + (selectPropertyOwnersAgentSelectorObject(state, id)?.label)
        },
        {
          replaceValue: "agentId2",
          title: async (state, id) => "<strong>Makler 2</strong><br/>" + (selectPropertyOwnersAgentSelectorObject(state, id, '2')?.label)
        },
        {
          replaceValue: "agentId3",
          title: async (state, id) => "<strong>Makler 3</strong><br/>" + (selectPropertyOwnersAgentSelectorObject(state, id, '3')?.label)
        },
        {
          replaceValue: "agentId4",
          title: async (state, id) => "<strong>Makler 4</strong><br/>" + (selectPropertyOwnersAgentSelectorObject(state, id, '4')?.label)
        }
      ]
    ];
    const fields = [
      {
        ...FieldSingleConfig.getConfig({
          templateType: TemplateType.FIELD_RELATION_CONNECTOR,
          name: "_affiliateId",
          label: "Name",
          relationUrlPath: "/affiliates",
          onRelationSearch: async (searchTerm: string) => await AffiliateDataService.searchAffiliate(searchTerm),
          selectableObject: async (state, id: number) => selectPropertyOwnersAffiliateSelectorObject(state, id)
        })
      },
      {
        ...FieldSingleConfig.getConfig({
          inputType: InputType.NUMBER,
          name: "total",
          label: "Preis",
          hideInputEnumeration: true,
          attributes: {
            abbreviation: UnitSymbol.Euro,
            allowDecimal: false,
            max: 999999999999
          }
        })
      },
      {
        ...FieldSingleConfig.getConfig({
          inputType: InputType.DATE,
          name: "purchaseDate",
          label: "Kaufdatum",
          attributes: {
            datePickerType: DatePickerType.Month
          }
        })
      },
      {
        ...FieldComposeConfig.getConfig({
          fullWidth: true,
          repeat: "auto",
          name: "agentsComposition",
          headers: [
            `Makler 1`,
            "Makler 2",
            `Makler 3`,
            `Makler 4`,
          ],
          attachedTop: true,
          fields: [
            {
              ...FieldSingleConfig.getConfig({
                templateType: TemplateType.FIELD_RELATION_CONNECTOR,
                name: "_agentId",
                label: "",
                relationUrlPath: "/affiliates",
                onRelationSearch: async (searchTerm: string) => await AffiliateDataService.searchAffiliate(searchTerm),
                selectableObject: async (state, id: number) => selectPropertyOwnersAgentSelectorObject(state, id)
              })
            },
            {
              ...FieldSingleConfig.getConfig({
                templateType: TemplateType.FIELD_RELATION_CONNECTOR,
                name: "_agentId2",
                label: "",
                relationUrlPath: "/affiliates",
                onRelationSearch: async (searchTerm: string) => await AffiliateDataService.searchAffiliate(searchTerm),
                selectableObject: async (state, id: number) => selectPropertyOwnersAgentSelectorObject(state, id, '2')
              })
            },
            {
              ...FieldSingleConfig.getConfig({
                templateType: TemplateType.FIELD_RELATION_CONNECTOR,
                name: "_agentId3",
                label: "",
                relationUrlPath: "/affiliates",
                onRelationSearch: async (searchTerm: string) => await AffiliateDataService.searchAffiliate(searchTerm),
                selectableObject: async (state, id: number) => selectPropertyOwnersAgentSelectorObject(state, id, '3')
              })
            },
            {
              ...FieldSingleConfig.getConfig({
                templateType: TemplateType.FIELD_RELATION_CONNECTOR,
                name: "_agentId4",
                label: "",
                relationUrlPath: "/affiliates",
                onRelationSearch: async (searchTerm: string) => await AffiliateDataService.searchAffiliate(searchTerm),
                selectableObject: async (state, id: number) => selectPropertyOwnersAgentSelectorObject(state, id, '4')
              })
            },
          ]
        })
      },
    ];

    return FieldTabArrayConfig.getConfig({
      name: "owners",
      addLabel: "Eigentümer hinzufügen",
      transformMessage: "Wenn sie auf „Umwandeln“ klicken wird der „Eigentümer“ zum „Alteigentümer“ umgewandelt.",
      tabs: [
        {
          key: "owner",
          label: "Eigentümer",
          selector: (state: IFieldTabArrayState) => selectCurrent(state),
          newDataRef: {
            ...FieldCollapsableConfig.getConfig({
              label: "Eigentümer",
              hideInputEnumeration: false,
              showAdditionalButton: true,
              titleBar,
              fields
            })
          }
        },
        {
          key: "formerOwner",
          label: "Alteigentümer",
          selector: (state: IFieldTabArrayState) => selectFormer(state),
          newDataRef: {
            ...FieldCollapsableConfig.getConfig({
              label: "Alteigentümer",
              hideInputEnumeration: false,
              showAdditionalButton: true,
              titleBar,
              fields
            })
          }
        }
      ],
      identifier: "id",
      onDataAdd: async (routeParam: string | number) =>
        await PropertyService.addData(routeParam, "owners"),
      onDataTransform: async (routeParam: string | number, identifier: string) =>
        await PropertyService.updateData(routeParam, "owners", identifier, {id: identifier, isFormer: true}),
      onDataDelete: async (routeParam: string | number, identifier: string) =>
        await PropertyService.deleteData(routeParam, "owners", identifier, {id: identifier})
    });
  }

  static get PropertyAddresses() {
    return FieldAddressArrayConfig.getConfig(
      {
        name: "addresses",
        fieldArrayTitle: "Adressen",
        addLabel: "Hinzufügen",
        newDataRef: {
          ...PropertyInputDefinitions.MapboxAddress,
          name: "address",
          label: "Sekundäradresse"
        },
        onDataAdd: async (routeParam: string | number) =>
          await PropertyService.addData(routeParam, "addresses", {isPrimary: false}),
        onDataDelete: async (routeParam: string | number, identifier: string) =>
          await PropertyService.deleteData(routeParam, `addresses/${identifier}`)
      }
    );
  }

  static get PropertyTenancies() {
    const titleBar = [
      [
        {
          replaceValue: "_affiliateId",
          title: async (state: IState, value) => (selectPropertyTenanciesAffiliateSelectorObject(state, value)?.label)
        }
      ],
      [
        {
          replaceValue: "totalArea",
          title: `<strong>Gesamtmietfläche (${UnitSymbol.SquareMeter})</strong><br>$0`
        },
        {
          replaceValue: "officeArea",
          title: `<strong>Bürofläche (${UnitSymbol.SquareMeter})</strong><br>$0`
        },
        {
          replaceValue: "totalMonthlyRent",
          title: `<strong>Mtl. Gesamtmiete (${UnitSymbol.Euro})</strong><br>$0`
        },
        {
          replaceValue: "averageRent",
          title: `<strong>Ø Mietpreis pro ${UnitSymbol.SquareMeter}</strong><br>$0`,
          numberType: TitleBarNumberType.DECIMAL,
        },
        {
          replaceValue: "rentalStart",
          title: "<strong>Mietbeginn</strong><br>$0"
        },
        {
          replaceValue: "rentalEnd",
          title: "<strong>Mietende</strong><br>$0"
        },
        {
          replaceValue: "agentId",
          title: async (state, id) => "<strong>Makler</strong><br/>" + (selectPropertyTenanciesAgentSelectorObject(state, id)?.label)
        },
        {
          replaceValue: "agentId2",
          title: async (state, id) => "<strong>Makler 2</strong><br/>" + (selectPropertyTenanciesAgentSelectorObject(state, id, '2')?.label)
        },
        {
          replaceValue: "agentId3",
          title: async (state, id) => "<strong>Makler 3</strong><br/>" + (selectPropertyTenanciesAgentSelectorObject(state, id, '3')?.label)
        },
        {
          replaceValue: "agentId4",
          title: async (state, id) => "<strong>Makler 4</strong><br/>" + (selectPropertyTenanciesAgentSelectorObject(state, id, '4')?.label)
        },
      ]
    ];
    const fields = [
      TenancyInputDefinitions.AffiliateSelectorInputField(async (state: IState, id) => (selectPropertyTenanciesAffiliateSelectorObject(state, id))),
      {
        ...FieldSingleConfig.getConfig({
          inputType: InputType.DATE,
          name: "rentalStart",
          label: "Mietbeginn",
          attributes: {
            datePickerType: DatePickerType.Full
          }
        })
      },
      {
        ...FieldSingleConfig.getConfig({
          inputType: InputType.NUMBER,
          name: "totalArea",
          label: "Gesamtmietfläche",
          attributes: {
            abbreviation: UnitSymbol.SquareMeter,
            max: 999999999
          }
        })
      },
      {
        ...FieldSingleConfig.getConfig({
          inputType: InputType.DATE,
          name: "rentalEnd",
          label: "Mietende",
          attributes: {
            datePickerType: DatePickerType.Full
          }
        })
      },
      {
        ...FieldSingleConfig.getConfig({
          inputType: InputType.NUMBER,
          name: "officeArea",
          label: "Bürofläche",
          attributes: {
            abbreviation: UnitSymbol.SquareMeter,
            max: 999999999
          }
        })
      },
      {
        ...FieldSingleConfig.getConfig({
          inputType: InputType.NUMBER,
          name: "totalMonthlyRent",
          label: "Mtl. Gesamtmiete",
          attributes: {
            abbreviation: UnitSymbol.Euro,
            max: 999999999
          }
        })
      },
      {
        ...FieldSingleConfig.getConfig({
          inputType: InputType.NUMBER,
          name: "averageRent",
          label: `${UnitSymbol.Average} Mietpreis in ${UnitSymbol.SquareMeter}`,
          attributes: {
            abbreviation: UnitSymbol.Euro,
            max: 99.99,
            allowDecimal: true
          }
        })
      },
      {
        ...FieldComposeConfig.getConfig({
          fullWidth: true,
          repeat: "auto",
          name: "agentsComposition",
          headers: [
            `Makler 1`,
            "Makler 2",
            `Makler 3`,
            `Makler 4`,
          ],
          attachedTop: true,
          fields: [
            {
              ...FieldSingleConfig.getConfig({
                templateType: TemplateType.FIELD_RELATION_CONNECTOR,
                name: "_agentId",
                label: "Makler (ID)",
                relationUrlPath: "/affiliates",
                onRelationSearch: async (searchTerm: string) => await AffiliateDataService.searchAffiliate(searchTerm),
                selectableObject: async (state: IState, id) => selectPropertyTenanciesAgentSelectorObject(state, id)
              })
            },
            {
              ...FieldSingleConfig.getConfig({
                templateType: TemplateType.FIELD_RELATION_CONNECTOR,
                name: "_agentId2",
                label: "",
                relationUrlPath: "/affiliates",
                onRelationSearch: async (searchTerm: string) => await AffiliateDataService.searchAffiliate(searchTerm),
                selectableObject: async (state: IState, id) => selectPropertyTenanciesAgentSelectorObject(state, id, '2')
              })
            },
            {
              ...FieldSingleConfig.getConfig({
                templateType: TemplateType.FIELD_RELATION_CONNECTOR,
                name: "_agentId3",
                label: "",
                relationUrlPath: "/affiliates",
                onRelationSearch: async (searchTerm: string) => await AffiliateDataService.searchAffiliate(searchTerm),
                selectableObject: async (state: IState, id) => selectPropertyTenanciesAgentSelectorObject(state, id, '3')
              })
            },
            {
              ...FieldSingleConfig.getConfig({
                templateType: TemplateType.FIELD_RELATION_CONNECTOR,
                name: "_agentId4",
                label: "",
                relationUrlPath: "/affiliates",
                onRelationSearch: async (searchTerm: string) => await AffiliateDataService.searchAffiliate(searchTerm),
                selectableObject: async (state: IState, id) => selectPropertyTenanciesAgentSelectorObject(state, id, '4')
              })
            },
          ]
        })
      },
      {
        ...FieldSingleConfig.getConfig({
          inputType: InputType.TEXTAREA,
          name: "additionalInformation",
          label: "Zusatzinfo",
          placeholderText: "Zusatzinfo eingeben",
          fullWidth: true,
          attachedTop: true
        })
      },
      {
        ...FieldTableConfig.getConfig({
          name: "rentalAreas",
          addLabel: "Fläche hinzufügen",
          rowLabel: "Fläche",
          tableStructure: [
            {
              key: "type",
              header: "Flächenart",
              dataRef: {
                ...FieldSingleConfig.getConfig({
                  inputType: InputType.SELECT,
                  name: "type",
                  options: [
                    {
                      label: "Bitte auswählen",
                      value: null
                    },
                    {
                      label: "Wohnen",
                      value: "housing"
                    },
                    {
                      label: "Gastro",
                      value: "gastronomy"
                    },
                    {
                      label: "Hotel",
                      value: "hotel"
                    },
                    {
                      label: "Sonstiges",
                      value: "other"
                    },
                    {
                      label: "Büro",
                      value: "office"
                    },
                    {
                      label: "Lager",
                      value: "warehouse"
                    },
                    {
                      label: "Einzelhandel",
                      value: "retail"
                    },
                    {
                      label: "TG-Stellplatz",
                      value: "undergroundParkingSpace"
                    },
                    {
                      label: "Stellplatz außen",
                      value: "outdoorParkingSpace"
                    }
                  ]
                })
              }
            },
            {
              key: "rentalArea",
              header: `Mietfläche (${UnitSymbol.SquareMeter})`,
              dataRef: {
                ...FieldSingleConfig.getConfig({
                  inputType: InputType.NUMBER,
                  name: "rentalArea",
                  attributes: {
                    abbreviation: UnitSymbol.SquareMeter,
                    max: 999999999
                  }
                })
              }
            },
            {
              key: "price",
              header: `Preis (${UnitSymbol.SquareMeter})`,
              dataRef: {
                ...FieldSingleConfig.getConfig({
                  inputType: InputType.NUMBER,
                  name: "price",
                  attributes: {
                    abbreviation: UnitSymbol.Euro,
                    max: 999.99,
                    allowDecimal: true
                  }
                })
              }
            },
            {
              key: "additionalCosts",
              header: `Nebenkosten (${UnitSymbol.SquareMeter})`,
              dataRef: {
                ...FieldSingleConfig.getConfig({
                  inputType: InputType.NUMBER,
                  name: "additionalCosts",
                  attributes: {
                    abbreviation: UnitSymbol.Euro,
                    max: 99.99,
                    allowDecimal: true
                  }
                })
              }
            },
            {
              key: "rentalStart",
              header: "Mietbeginn",
              dataRef: {
                ...FieldSingleConfig.getConfig({
                  inputType: InputType.DATE,
                  name: "rentalStart",
                  attributes: {
                    datePickerType: DatePickerType.Full
                  }
                })
              }
            },
            {
              key: "rentalEnd",
              header: "Mietende",
              dataRef: {
                ...FieldSingleConfig.getConfig({
                  inputType: InputType.DATE,
                  name: "rentalEnd",
                  attributes: {
                    datePickerType: DatePickerType.Full
                  }
                })
              }
            },
            {
              key: "additionalInformation",
              header: "",
              dataRef: {
                ...FieldSingleConfig.getConfig({
                  inputType: InputType.TEXTAREA,
                  name: "additionalInformation",
                  placeholderText: "Zusatzinfo eingeben"
                })
              }
            }
          ],
          onDataAdd: async (routeParam: string | number, parentRelationId) => {
            await PropertyService.addData(routeParam, `tenancies/${parentRelationId}/rentalareas`);
          },
          onDataDelete: async (routeParam: string | number, parentRelationId, deleteId) => {
            await PropertyService.deleteData(routeParam, `tenancies/${parentRelationId}/rentalareas/${deleteId}`);
          }
        })
      }
    ];

    return FieldTabArrayConfig.getConfig({
      name: "tenancies",
      addLabel: "Mieter hinzufügen",
      transformMessage: "Wenn sie auf „Umwandeln“ klicken wird der „Mieter“ zum „Altmieter“ umgewandelt.",
      tabs: [
        {
          key: "tenancy",
          label: "Mieter",
          selector: (state: IFieldTabArrayState) => selectCurrent(state),
          newDataRef: {
            ...FieldCollapsableConfig.getConfig({
              label: "Mieter",
              hideInputEnumeration: false,
              showAdditionalButton: true,
              titleBar,
              fields
            })
          }
        },
        {
          key: "formerTenancy",
          label: "Altmieter",
          selector: (state: IFieldTabArrayState) => selectFormer(state),
          newDataRef: {
            ...FieldCollapsableConfig.getConfig({
              label: "Altmieter",
              hideInputEnumeration: false,
              showAdditionalButton: true,
              titleBar,
              fields
            })
          }
        }
      ],
      identifier: "id",
      onDataAdd: async (routeParam: string | number) =>
        await PropertyService.addData(routeParam, "tenancies"),
      onDataTransform: async (routeParam: string | number, identifier: string) =>
        await PropertyService.updateData(routeParam, "tenancies", identifier, {id: identifier, isFormer: true}),
      onDataDelete: async (routeParam: string | number, identifier: string) =>
        await PropertyService.deleteData(routeParam, "tenancies", identifier, {id: identifier}),

    });
  }

  static PropertySelectorInputField(selectableObject: (state: IState, id: number) => Promise<ISelectableObject>) {
    return {
      ...FieldSingleConfig.getConfig({
        templateType: TemplateType.FIELD_RELATION_CONNECTOR,
        name: "_propertyId",
        label: "Objekt",
        relationUrlPath: "/properties",
        onRelationSearch: async (searchTerm: string) => await PropertyService.searchProperty(searchTerm),
        selectableObject
      })
    };
  }
}
