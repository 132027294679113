import { Record } from 'immutable';

interface ITenanciesMetaDataParams {
  total?: number;
  data?: IMetaDataItem[]
}

export interface IMetaDataItem {
  label: string;
  value: number;
}

export class TenanciesMetaDataModel extends Record<ITenanciesMetaDataParams>({
  total: undefined,
  data: []
}) {
  constructor(params?: ITenanciesMetaDataParams) {
    params ? super(params) : super();
  }

  with(values: ITenanciesMetaDataParams): this {
    return this.merge(values) as this;
  }
}
