import { IState } from '../../interfaces/state.interface';
import { PropertyModel } from '../../models/property.model';
import { List } from 'immutable';
import { PropertyMetaDataModel } from '../../models/property-meta-data.model';
import { ActiveFilterModel } from '../../models/active-filter.model';
import { createSelector } from '../../utils/reducer';
import { PropertyFilterKey } from '../../constants/property-filter-key.enum';
import { LoadingStatus } from '../../constants/loading-status.enum';
import { SortOrder } from '../../constants/sort-order.enum';

const basePropertiesSelector = ['properties'];

export const selectPropertyList = (state: IState): List<PropertyModel> => state.getIn([...basePropertiesSelector, 'list']);

export const selectPropertyMetaData = (state: IState): PropertyMetaDataModel => state.getIn([...basePropertiesSelector, 'meta']);

export const selectIsPropertiesLoading = (state: IState): LoadingStatus => state.getIn([...basePropertiesSelector, 'loadingStatus']);

export const selectPropertiesOffset = (state: IState): number => state.getIn([...basePropertiesSelector, 'offset']);

export const selectPropertiesSortOrder = (state: IState): SortOrder => state.getIn([...basePropertiesSelector, 'sortOrder']);

export const selectPropertiesSortByColumn = (state: IState): string => state.getIn([...basePropertiesSelector, 'sortByColumn']);

export const selectActiveFilter = (state: IState): List<ActiveFilterModel> => state.getIn([...basePropertiesSelector, 'activeFilter']);

export const selectScrollTop = (state: IState): number => state.getIn([...basePropertiesSelector, 'scrollTop']);

export const selectPropertyListWithFallback = createSelector(
  [selectPropertyList],
  (propertyList) => {
    return propertyList.map((property: PropertyModel) => {
      return property.merge({
        street: `${property.street} ${property.streetNumber ?? ''}`
      });
    });
  }
)

export const selectPropertyIdActiveFilter = createSelector(
  [selectActiveFilter],
  (activeFilter) => {
    return activeFilter.find((filter) => filter.groupKey === PropertyFilterKey.PROPERTY_ID);
  }
)

export const selectTitleActiveFilter = createSelector(
  [selectActiveFilter],
  (activeFilter) => {
    return activeFilter.find((filter) => filter.groupKey === PropertyFilterKey.TITLE);
  }
)

export const selectStreetActiveFilter = createSelector(
  [selectActiveFilter],
  (activeFilter) => {
    return activeFilter.find((filter) => filter.groupKey === PropertyFilterKey.STREET);
  }
)

export const selectPostalCodeActiveFilter = createSelector(
  [selectActiveFilter],
  (activeFilter) => {
    return activeFilter.find((filter) => filter.groupKey === PropertyFilterKey.POSTAL_CODE);
  }
)

export const selectLocationActiveFilter = createSelector(
  [selectActiveFilter],
  (activeFilter) => {
    return activeFilter.find((filter) => filter.groupKey === PropertyFilterKey.LOCATION);
  }
)

export const selectRegionActiveFilter = createSelector(
  [selectActiveFilter],
  (activeFilter) => {
    return activeFilter.find((filter) => filter.groupKey === PropertyFilterKey.REGION);
  }
)

export const selectOfficeAreaActiveFilter = createSelector(
  [selectActiveFilter],
  (activeFilter) => {
    return activeFilter.find((filter) => filter.groupKey === PropertyFilterKey.OFFICE_AREA);
  }
)

export const selectConstructionYearActiveFilter = createSelector(
  [selectActiveFilter],
  (activeFilter) => {
    return activeFilter.find((filter) => filter.groupKey === PropertyFilterKey.CONSTRUCTION_YEAR);
  }
)

export const selectAverageRentalChargeActiveFilter = createSelector(
  [selectActiveFilter],
  (activeFilter) => {
    return activeFilter.find((filter) => filter.groupKey === PropertyFilterKey.AVERAGE_RENTAL_CHARGE);
  }
)

export const selectTotalAreaActiveFilter = createSelector(
  [selectActiveFilter],
  (activeFilter) => {
    return activeFilter.find((filter) => filter.groupKey === PropertyFilterKey.TOTAL_AREA);
  }
)

export const selectPurchasePriceActiveFilter = createSelector(
  [selectActiveFilter],
  (activeFilter) => {
    return activeFilter.find((filter) => filter.groupKey === PropertyFilterKey.PURCHASE_PRICE);
  }
)

export const selectVacancyRateActiveFilter = createSelector(
  [selectActiveFilter],
  (activeFilter) => {
    return activeFilter.find((filter) => filter.groupKey === PropertyFilterKey.VACANCY_RATE);
  }
)

export const selectAreaTypeActiveFilter = createSelector(
  [selectActiveFilter],
  (activeFilter) => {
    return activeFilter.find((filter) => filter.groupKey === PropertyFilterKey.AREA_TYPE);
  }
)

export const selectOfficeAreaTypeActiveFilter = createSelector(
  [selectAreaTypeActiveFilter],
  (activeAreaTypeFilter) => {
    return activeAreaTypeFilter?.get('list').find((entry) => entry.get('value') === 'office');
  }
)

export const selectTenantTypeActiveFilter = createSelector(
  [selectActiveFilter],
  (activeFilter) => {
    return activeFilter.find((filter) => filter.groupKey === PropertyFilterKey.TENANT_TYPE);
  }
)

export const selectConditionActiveFilter = createSelector(
  [selectActiveFilter],
  (activeFilter) => {
    return activeFilter.find((filter) => filter.groupKey === PropertyFilterKey.CONDITION);
  }
)

export const selectStatusActiveFilter = createSelector(
  [selectActiveFilter],
  (activeFilter) => {
    return activeFilter.find((filter) => filter.groupKey === PropertyFilterKey.STATUS);
  }
)

export const selectHasImagesActiveFilter = createSelector(
  [selectActiveFilter],
  (activeFilter) => {
    return activeFilter.find((filter) => filter.groupKey === PropertyFilterKey.HAS_IMAGES);
  }
)

export const selectOwnerActiveFilter = createSelector(
  [selectActiveFilter],
  (activeFilter) => {
    return activeFilter.find((filter) => filter.groupKey === PropertyFilterKey.OWNER);
  }
)

export const selectTenantActiveFilter = createSelector(
  [selectActiveFilter],
  (activeFilter) => {
    return activeFilter.find((filter) => filter.groupKey === PropertyFilterKey.TENANT);
  }
)

export const selectAgentActiveFilter = createSelector(
  [selectActiveFilter],
  (activeFilter) => {
    return activeFilter.find((filter) => filter.groupKey === PropertyFilterKey.AGENT);
  }
)
export const selectPurchaseDateActiveFilter = createSelector(
  [selectActiveFilter],
  (activeFilter) => {
    return activeFilter.find((filter) => filter.groupKey === PropertyFilterKey.PURCHASE_DATE);
  }
)
