import React from "react";

export const GoogleMapApiContext = React.createContext(null);

export const useGoogleMapsApi = () => {
  const context = React.useContext(GoogleMapApiContext);

  if(context === undefined) {
    throw new Error('`useGoogleMapsApi` hook must be used within a `GoogleMapApiProvider` component');
  }
  return context;
};
