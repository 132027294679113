import { BehaviorSubject, from, Observable, of } from 'rxjs';
import {catchError, debounceTime, distinctUntilChanged, switchMap, tap} from 'rxjs/operators';
import { LogService } from './log.service';
import { HttpService } from './http.service';

export class SearchService {
  private searchTerm$: BehaviorSubject<string>;

  constructor(
    private searchPath: string,
    private termMinLength: number = 3
  ) {
    this.searchTerm$ = new BehaviorSubject<string>('');
  }

  search(term: string): void {
    this.searchTerm$.next(term);
  }

  getSuggestions(): Observable<any> {
    return this.searchTerm$
      .pipe(
        debounceTime(300),
        distinctUntilChanged(),
        switchMap((term: string) => {
          return !!term && term.length >= this.termMinLength ?
            from(HttpService.performGetRequest(`${this.searchPath}${encodeURIComponent(term)}`)) :
            of([]);
        }),
        catchError((error: any) => {
          LogService.error(error);
          return of([]);
        })
      )
  }
}
