import { Record } from 'immutable';
import { AffiliateModel } from "./affiliate.model";
import { PropertyModel } from "./property.model";

export interface ITenancyParams {
  id: number;
  isFormer: boolean;

  _affiliateId: number;
  affiliate: AffiliateModel,

  _propertyId: number;
  property: PropertyModel,

  undergroundParkingSpace: number;
  outdoorParkingSpace: number;
  totalArea: number;
  officeArea: number;
  totalMonthlyRent: number;
  averageRent: number;
  rentalStart: string;
  rentalEnd: string;
  _agentId: number;
  agent: AffiliateModel,
  _agentId2: number;
  agent2: AffiliateModel,
  _agentId3: number;
  agent3: AffiliateModel,
  _agentId4: number;
  agent4: AffiliateModel,
  additionalInformation: string;

  rentalAreas: [];
}

export class TenancyModel extends Record<ITenancyParams>({
  id: undefined,
  isFormer: undefined,

  _affiliateId: undefined,
  affiliate: undefined,

  _propertyId: undefined,
  property: undefined,

  undergroundParkingSpace: undefined,
  outdoorParkingSpace: undefined,
  totalArea: undefined,
  officeArea: undefined,
  totalMonthlyRent: undefined,
  averageRent: undefined,
  rentalStart: undefined,
  rentalEnd: undefined,
  _agentId: undefined,
  agent: undefined,
  _agentId2: undefined,
  agent2: undefined,
  _agentId3: undefined,
  agent3: undefined,
  _agentId4: undefined,
  agent4: undefined,
  additionalInformation: undefined,

  rentalAreas: undefined,
}) {
  constructor(params?: any) {
    params ? super(params) : super();
  }

  with(values: ITenancyParams): this {
    return this.merge(values) as this;
  }
}
