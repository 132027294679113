import { IAction } from '../../interfaces/action.interface';
import { IState } from '../../interfaces/state.interface';
import { mergeState } from '../../utils/reducer';
import { ActiveFilterModel } from '../../models/active-filter.model';
import { fromJS } from 'immutable';
import { PropertyFilterKey } from '../../constants/property-filter-key.enum';

export abstract class PropertiesSideEffectActions {

  public static setOfficeAreaTypeFilter(state: IState, action: IAction): IState {
    const currentActiveFilter = state.get('activeFilter');
    const areaTypeFilter = currentActiveFilter.find((activeFilter) => activeFilter.groupKey === action.payload.groupKey);

    if (!areaTypeFilter) {
      return mergeState(state, {
        activeFilter: [new ActiveFilterModel(fromJS(action.payload)), ...currentActiveFilter]
      });
    }

    const areaTypeFilterList = areaTypeFilter.get('list');
    const officeAreaTypeFilter = areaTypeFilterList.find((filter) => filter.get('value') === 'office');

    if (!!officeAreaTypeFilter) {
      return state;
    }

    const mergedAreaTypeFilterList = [ ...fromJS(action.payload.list) ,...areaTypeFilterList ];
    const updatedActiveFilter = currentActiveFilter.map((activeFilter) => {
      if (activeFilter.groupKey === action.payload.groupKey) {
        return mergeState(activeFilter, {
          list: mergedAreaTypeFilterList
        });
      }

      return activeFilter;
    })

    return mergeState(state, {
      activeFilter: updatedActiveFilter
    });
  }

  public static resetOfficeAreaTypeFilter(state: IState): IState {
    const currentActiveFilter = state.get('activeFilter');
    const areaTypeActiveFilter = currentActiveFilter.find((activeFilter) => activeFilter.groupKey === PropertyFilterKey.AREA_TYPE);
    const areaTypeActiveFilterIndex = currentActiveFilter.findIndex((activeFilter) => activeFilter.groupKey === PropertyFilterKey.AREA_TYPE);
    const officeAreaTypeFilterIndex = areaTypeActiveFilter?.get('list').findIndex((item) => item.get('value') === 'office');

    if (officeAreaTypeFilterIndex === -1) {
      return state;
    }

    const updatedAreaTypeFilterList = areaTypeActiveFilter?.list.remove(officeAreaTypeFilterIndex);
    const updateActiveFilterState = currentActiveFilter.map((activeFilter) => {
      if (activeFilter.groupKey === PropertyFilterKey.AREA_TYPE) {
        return mergeState(activeFilter, {
          list: updatedAreaTypeFilterList
        });
      }

      return activeFilter;
    });

    return mergeState(state, {
      activeFilter: updatedAreaTypeFilterList?.size > 0 ? updateActiveFilterState : currentActiveFilter.remove(areaTypeActiveFilterIndex)
    });
  }

  public static resetOfficeAreaFilter(state: IState): IState {
    const currentActiveFilter = state.get('activeFilter');
    const officeAreaFilterIndex = currentActiveFilter.findIndex((activeFilter) => activeFilter.groupKey === PropertyFilterKey.OFFICE_AREA);

    if (officeAreaFilterIndex === -1) {
      return state;
    }

    const areaTypeFilter = currentActiveFilter.find((activeFilter) => activeFilter.groupKey === PropertyFilterKey.AREA_TYPE);
    const officeAreaTypeFilter = areaTypeFilter?.get('list').find((item) => item.get('value') === 'office');  

    if (!!officeAreaTypeFilter) {
      return state;
    }

    return mergeState(state, {
      activeFilter: currentActiveFilter.remove(officeAreaFilterIndex)
    });
  }
}
