import {IAction} from '../../interfaces/action.interface';
import {IFilterParam} from '../../interfaces/filter-param.interface';
import {TenanciesActionTypes} from "./tenancies.action-types";

export const setFilterAction = (filter: IFilterParam): IAction => {
  return {
    type: TenanciesActionTypes.SET_FILTER,
    payload: filter
  };
};

export const resetFilterAction = (groupKey: string): IAction => {
  return {
    type: TenanciesActionTypes.RESET_FILTER,
    payload: groupKey
  }
};

export const resetAllFilterAction = (): IAction => {
  return {
    type: TenanciesActionTypes.RESET_ALL_FILTER
  }
};

export const setScrollTopAction = (yPosition: number): IAction => {
  return {
    type: TenanciesActionTypes.SET_SCROLL_TOP,
    payload: yPosition
  }
};

