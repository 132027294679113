import React from 'react'

interface ITabControlProps {
  label: string;
  activeTab: string;
  onClick: (activeTab: string) => void;
  className?: string;
}

/**
 * This component is beeing rendered as the tab controls of a tab container.
 *
 * If you want to specify Tabs inside a TabContainer please use the Tab Components as the TabContainers children.
 */
export const TabControl = (props: ITabControlProps) => {
  const {
    label,
    activeTab,
    onClick,
    className = ''
  } = props;

  const activeClassName = activeTab === label ? 'active' : '';

  return (
    <li
      className={`tab ${activeClassName} ${className}`}
      onClick={() => onClick(label)}
    >
      {label}
    </li>
  )
}
