import React, { ReactNodeArray } from 'react'
import { css, StyleSheet } from 'aphrodite';
import { CssSize } from '../../shared/helpers/styles.helper';
import { GridTableHead } from './grid-table-head';
import { GridTableBody } from './grid-table-body';

interface IGridTableProps {
  children: ReactNodeArray;
  className?: string; 
}

export const GridTable = (props: IGridTableProps) => {
  const {
    children,
    className = ''
  } = props;

  return (
    <div className={`${css(styles.gridTable)} ${className}`}>
      {children}
    </div>
  );
};

GridTable.Head = GridTableHead;
GridTable.Body = GridTableBody;

const styles = StyleSheet.create({
  gridTable: {
    width: '100%',
    paddingTop: CssSize.s30
  }
});
