import { ApiEndpoint } from '../constants/api-endpoint.enum';
import { HttpService } from './http.service';
import { IIntroTextsParams } from '../models/intro-text.model';

export class IntroTextsDataService {

  static async fetch(): Promise<any> {
    return HttpService.performGetRequest(`${ApiEndpoint.INTRO_TEXTS}`);
  }

  static async add(): Promise<any> {
    return HttpService.performPostRequest(`${ApiEndpoint.INTRO_TEXTS}`, null);
  }

  static async update(data: IIntroTextsParams): Promise<any> {
    return HttpService.performPutRequest(`${ApiEndpoint.INTRO_TEXTS}`, data);
  }

  static async delete(id: string): Promise<any> {
    return HttpService.performDeleteRequest(`${ApiEndpoint.INTRO_TEXTS}/${id}`)
  }

  static async removePostalCode(introTextId: string, postalCode: string): Promise<any> {
    return HttpService.performDeleteRequest(`${ApiEndpoint.INTRO_TEXTS}/${introTextId}/postalcodes/${postalCode}`)
  }

  static async addPostalCode(introTextId: string, postalCode: string): Promise<any> {
    return HttpService.performPostRequest(`${ApiEndpoint.INTRO_TEXTS}/${introTextId}/postalcodes`, { postalCode });
  }
}
