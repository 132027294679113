import React, { useState, useRef, useEffect, useContext } from 'react';
import { css, StyleSheet } from 'aphrodite';
import { CssSize, CssColor } from '../../shared/helpers/styles.helper';
import { useGetSuggestions } from './use-get-sugestions';
import { SearchIcon } from '../icons/collection/search-icon';
import { SuggestionDropdownList } from './suggestion-dropdown-list';
import { SuggestionDropdownContext, OnSelectFn } from '../../contexts/suggestion-dropdown.context';

interface ISuggestionOverlayProps {
    isOpen?: boolean;
    onSelect?: OnSelectFn
}

export const SuggestionOverlay = React.forwardRef(
    (props: ISuggestionOverlayProps, ref: React.Ref<HTMLDivElement>) => {
        const {
            onSelect,
            isOpen = false
        } = props;

        const {
            getSuggestions,
            maxInputLength,
            placeholderText,
            noDataText
        } = useContext(SuggestionDropdownContext);

        const [searchInputValue, setSearchInputValue] = useState('');
        const { suggestions } = useGetSuggestions(searchInputValue, getSuggestions);
        const onSearchInputChange = (event) => setSearchInputValue(event?.target?.value);
        const searchInputRef = useRef<HTMLInputElement>(null);

        useEffect(() => {
            if (!isOpen) {
                setSearchInputValue('');
                return;
            }
            searchInputRef.current.focus();
        }, [isOpen]);

        const handleOnSelect = (selectedValue: string) => {
            setSearchInputValue('');
            onSelect(selectedValue);
        }

        return (
            !isOpen ? null : (
                <div ref={ref} className={css(styles.overlay)}>
                    <div className={css(styles.container)}>
                        <div className={css(styles.searchInputContainer)}>
                            <div className="base-input">
                                <input
                                    maxLength={maxInputLength}
                                    className='with-icon'
                                    onChange={onSearchInputChange}
                                    placeholder={placeholderText}
                                    autoComplete='off'
                                    type='search'
                                    ref={searchInputRef}
                                />
                                <SearchIcon color={'secondary'} viewBox={'0 0 40 40'} titleAccess={'Postleitzahl-Suche'} />
                            </div>
                        </div>
                        {!suggestions ? (
                            <div className={css(styles.noData)}>
                                {noDataText}
                            </div>
                        ) : (
                                <SuggestionDropdownList
                                    suggestions={suggestions}
                                    onSelect={handleOnSelect}
                                />
                            )
                        }
                    </div>
                </div>
            )
        );
    }
);

const styles = StyleSheet.create({
    overlay: {
        zIndex: 10,
        position: 'absolute',
        left: CssSize.s05,
        top: 'calc(100% + 2px)',
        borderRadius: CssSize.s05,
        backgroundColor: CssColor.White,
        width: '320px',
        minHeight: '130px',
        padding: `${CssSize.s20} 0 ${CssSize.s30}`,
        filter: `drop-shadow(0px ${CssSize.s20} ${CssSize.s80} rgba(0, 0, 0, 0.15))`
    },
    container: {
        display: 'grid',
        gridTemplateRows: 'repeat(2, auto)',
        gridGap: CssSize.s30,
    },
    searchInputContainer: {
        margin: `0 ${CssSize.s20}`
    },
    noData: {
        textAlign: 'center',
        fontStyle: 'italic',
        color: CssColor.Grey,
    }
});
