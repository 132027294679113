import React, { useEffect, useState } from "react";
import { SelectItem } from "../select-item/select-item";
import { ISelectItem } from "../interfaces/select-item.interface";
import "./select-list.scss";

interface ISelectListProps {
  groupKey: string;
  title: string;
  list: ISelectItem[];
  storedList?: any;
  type?: "checkbox" | "radio";
  emitList?: (list: ISelectItem[]) => void;
  readOnly?: boolean;
}

/**
 * This functional component receives an Array of Items which will be displayed
 * in a List of Checkboxes.
 * @param props
 * @constructor
 */
export const SelectList = React.memo((props: ISelectListProps) => {
  const {
    groupKey,
    title,
    list,
    type,
    emitList,
    storedList,
    readOnly = false
  } = props;
  const [selectItems, setSelectItems] = useState(list);

  useEffect(() => {
    const activeList = storedList?.get("list").toJS();

    const mergedList = list?.map((item) => {
      const isActiveItem = activeList?.find((activeItem) => activeItem.value === item.value);

      return {
        ...item,
        isChecked: activeList?.length ? !!isActiveItem : item.isChecked
      };
    });

    setSelectItems(mergedList);
  }, [list, storedList]);

  const handleToggleEvent = (event: any) => {
    const targetValue = event.target.value;
    const targetCheckState = event.target.checked;

    const updatedSelection = selectItems?.map((item) => {
      if (item.value === targetValue) {
        return { ...item, isChecked: targetCheckState };
      }

      if (type === "radio") {
        return { ...item, isChecked: false };
      }

      return item;
    });

    emitList(updatedSelection);
  };

  return (
    <>
      <p className='select-list-title'>
        {title}
      </p>
      <ul className='select-list-list'>
        {selectItems?.map((item: ISelectItem) =>
          (
            <li key={item.id}>
              <SelectItem id={item.id}
                          name={type === "checkbox" ? item.id : groupKey}
                          color={item.color}
                          value={item.value}
                          label={item.label}
                          isChecked={item.isChecked}
                          onChange={handleToggleEvent}
                          type={type ?? "checkbox"}
                          readOnly={readOnly}
                          disabled={readOnly}/>
            </li>
          ))
        }
      </ul>
    </>
  );
});
