import React, { useState, useEffect } from 'react';
import { css, StyleSheet } from 'aphrodite';
import { Button, ButtonSize, ButtonColor, ButtonIconPosition } from '../button';
import { ChevronDownIcon } from '../icons/collection/chevron-down-icon';
import { SvgVariant } from '../icons/enum/svg-variant.enum';
import { CssSize, CssColor } from '../../shared/helpers/styles.helper';
interface ICollapsableElementsProps {
    header: JSX.Element,
    content: JSX.Element,
    chevronDownPosition?: ButtonIconPosition;
}

export const CollapsableElements = (props: ICollapsableElementsProps) => {
    const {
        header,
        content,
        chevronDownPosition = ButtonIconPosition.Right
    } = props;

    const [isExpanded, setIsExpanded] = useState(false);
    const [containerClassName, setContainerClassName] = useState([styles.collapsableContainer]);

    useEffect(() => {
        if (isExpanded) {
            setContainerClassName([styles.collapsableContainer, styles.isExpandedBorder]);
            return;
        }
        setContainerClassName([styles.collapsableContainer]);
    }, [isExpanded]);

    return (
        <div className={css(containerClassName)}>
            <header className={css(chevronDownPosition === ButtonIconPosition.Left ? styles.headerReverse : styles.header)}>
                {header}
                <Button type={'button'}
                    btnClass={`no-padding ${css(chevronDownPosition === ButtonIconPosition.Left && styles.iconLeft)}`}
                    size={ButtonSize.Medium}
                    color={ButtonColor.Ghost}
                    showLabel={false}
                    label={null}
                    onClick={() => {
                        setIsExpanded(!isExpanded);
                    }}>
                    <ChevronDownIcon
                        viewBox='0 0 40 40'
                        color={SvgVariant.Secondary}
                        className={css(isExpanded ? styles.isExpandedIcon : styles.isCollapsedIcon)}
                    />
                </Button>
            </header>
            {isExpanded && (
                <section>
                    {content}
                </section>
            )}
        </div>
    );
};

const styles = StyleSheet.create({
    collapsableContainer: {
        display: 'grid',
        gridAutoFlow: 'row',
        rowGap: CssSize.s15,
        border: `2px solid ${CssColor.IcePrimary}`,
        borderRadius: CssSize.s05,
        padding: CssSize.s05,
        transition: '.1s',
    },
    header: {
        display: 'grid',
        gridTemplateColumns: '1fr auto',
        columnGap: CssSize.s05,
        alignItems: 'center',
    },
    headerReverse: {
        display: 'grid',
        gridTemplateColumns: 'auto 1fr',
        columnGap: CssSize.s05,
        alignItems: 'center',
    },
    isExpandedBorder: {
        borderColor: CssColor.Secondary,
    },
    isExpandedIcon: {
        transition: '.3s',
        transform: 'rotate(180deg)',
    },
    isCollapsedIcon: {
        transition: '.3s',
        transform: 'rotate(0)',
    },
    iconLeft: {
        order: -1
    }
});
