import { ApiEndpoint } from "../constants/api-endpoint.enum";
import { HttpService } from "./http.service";
import { IRemarkParams } from "../models/remark.model";
import { IPropertyIntroTextsParams } from "../models/property-intro-text.model";

export class PropertyTextsDataService {

  static async fetchRemarks(propertyId: number): Promise<any> {
    return HttpService.performGetRequest(`${ApiEndpoint.PROPERTY}/${propertyId}/remarks`);
  }

  static async addRemark(propertyId: number): Promise<any> {
    return HttpService.performPostRequest(`${ApiEndpoint.PROPERTY}/${propertyId}/remarks`, { _propertyId: propertyId });
  }

  static async updateRemark(propertyId: number, data: Partial<IRemarkParams>): Promise<any> {
    return HttpService.performPutRequest(`${ApiEndpoint.PROPERTY}/${propertyId}/remarks`, data);
  }

  static async deleteRemark(propertyId: number, id: string): Promise<any> {
    return HttpService.performDeleteRequest(`${ApiEndpoint.PROPERTY}/${propertyId}/remarks/${id}`);
  }

  static async fetchPropertyIntroTexts(propertyId: number): Promise<any> {
    return HttpService.performGetRequest(`${ApiEndpoint.INTRO_TEXTS}/${propertyId}`);
  }

  static async updateShowIntroTextInWatchlist(propertyId: number, value: boolean): Promise<any> {
    return HttpService.performPutRequest(`${ApiEndpoint.PROPERTY}`, { propertyId, showIntroTextInWatchlist: value });
  }

  static async updateActiveIntroTextTemplate(propertyId: number, value: string): Promise<any> {
    const [introTextId, activeIntroTextTemplate] = value.split("_");
    const introTextForeignKey = introTextId === "null" ? null : introTextId;

    return HttpService.performPutRequest(`${ApiEndpoint.PROPERTY}`, {
      propertyId,
      _introTextId: introTextForeignKey,
      activeIntroTextTemplate
    });
  }

  static async updateOwnIntroText(propertyId: number, data: IPropertyIntroTextsParams): Promise<any> {
    const changeObject = {};

    if (!!data.template_de) {
      changeObject["customIntroText_de"] = data.template_de;
    }

    if (!!data.template_en) {
      changeObject["customIntroText_en"] = data.template_en;
    }

    return HttpService.performPutRequest(`${ApiEndpoint.PROPERTY}`, { propertyId, ...changeObject });
  }
}
