import { IState } from "../../interfaces/state.interface";
import { fromJS } from "immutable";
import { IReducer } from "../../interfaces/reducer.interface";
import { IAction } from "../../interfaces/action.interface";
import { mergeState } from "../../utils/reducer";
import { SettingsActionTypes } from "./settings.action-types";

const initialState: IState = fromJS({
  language: null
});

export const settingsReducer: IReducer = (state = initialState, action: IAction) => {
  switch (action.type) {
    case SettingsActionTypes.SET_LANGUAGE: {
      return mergeState(state, action.payload);
    }

    default:
      return state;
  }
};
