import React, { Component } from 'react';
import { NoRenderingError } from '../../shared/constants/no-rendering.error';

interface ITabProps {
  label: string;
  children?: any;
}

export class Tab extends Component<ITabProps> {
  render() {
    throw new NoRenderingError('Tab');
    // eslint-disable-next-line no-unreachable
    return <></>;
  }
}
