import {List} from "immutable";
import React, {useEffect, useRef, useState} from "react";
import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import {connect} from "react-redux";
import {TableContext} from "../../../contexts/table.context";
import {SortOrder} from "../../../shared/constants/sort-order.enum";
import {IState} from "../../../shared/interfaces/state.interface";
import {PropertyMetaDataModel} from "../../../shared/models/property-meta-data.model";
import {PropertyModel} from "../../../shared/models/property.model";
import {
  performGetRequest,
  performOffsetRequest,
  performSortRequest
} from "../../../shared/redux/middleware/api.middleware";
import {PropertiesActionTypes} from "../../../shared/redux/properties/properties.action-types";
import {
  selectActiveFilter,
  selectIsPropertiesLoading,
  selectPropertiesOffset,
  selectPropertiesSortByColumn,
  selectPropertiesSortOrder,
  selectPropertyListWithFallback,
  selectPropertyMetaData, selectScrollTop
} from "../../../shared/redux/properties/properties.selectors";
import {LogService} from "../../../shared/services/log.service";
import {buildFilterQueryParams} from "../../../shared/utils/filter-utils";
import {PropertiesFilterSelection} from "../../filter/properties-filter/properties-filter-selection";
import {Table} from "../../table/table";
import {ActiveFilterModel} from "../../../shared/models/active-filter.model";
import {LoadingStatus} from "../../../shared/constants/loading-status.enum";
import {ApiEndpoint} from "../../../shared/constants/api-endpoint.enum";
import {TableRow} from "../../table/table-row/table-row";
import {SortService} from "../../../shared/services/sort.service";
import {WindowService} from "../../../shared/services/window.service";
import {PropertyHeadersConfig} from "../../../shared/table-headers/property-headers.config";
import {PropertiesFilter} from "../../filter/properties-filter/properties-filter";
import {useCurrentLanguage} from "../../../contexts/current-language.context";
import {useUser} from "../../../contexts/user.context";
import {useLoadMoreIntersectionObserver} from "../../../hooks/use-load-more-intersection-observer";
import useResizeObserver from "../../../hooks/use-resize-observer";
import {setScrollTopAction} from "../../../shared/redux/properties/properties.action";

interface IPropertListViewProps {
  propertyList: List<PropertyModel>,
  activeFilter: List<ActiveFilterModel>,
  metaData: PropertyMetaDataModel,
  loadingStatus: LoadingStatus,
  offset: number,
  sortOrder: SortOrder,
  sortByColumn: string,
  fetchPropertiesData: (path: string) => Promise<any>,
  loadMorePropertiesData: (offset: number, path: string) => void,
  sortPropertiesData: (sortOrder: SortOrder, sortByColumn: string, path: string) => void
  resetPropertiesData: (path: string) => void;
  scrollTop: number,
  setScrollTop: (yPosition: number) => void;
}

const defaultLoadMoreOffset = 150;

export const PropertyListView = (props: IPropertListViewProps) => {
  const {
    propertyList,
    activeFilter,
    metaData,
    loadingStatus,
    offset,
    fetchPropertiesData,
    sortOrder,
    sortByColumn,
    sortPropertiesData,
    loadMorePropertiesData,
    //resetPropertiesData
    scrollTop,
    setScrollTop,
  } = props;

  const {getGroups} = useUser();

  const [ready, setReady] = useState(false);
  const filterSelectionRef = useRef({current: null});
  const currentLanguage = useCurrentLanguage();

  useEffect(() => {
    if (!ready) {
      setReady(true);
    }

    return () => {
      const tableScrollY = document.getElementsByClassName("properties-table")?.[0]?.scrollTop;

      setScrollTop(tableScrollY);
    }
  }, [ready]);

  const onLoadMore = () => {
    if (loadingStatus === LoadingStatus.READY) {
      const newOffset = offset + (+process.env.REACT_APP_LOAD_MORE_OFFSET || defaultLoadMoreOffset);
      const queryParams = getQueryParams(newOffset, sortOrder, sortByColumn);

      loadMorePropertiesData(newOffset, `${ApiEndpoint.PROPERTY}${queryParams}`);
    }
  };

  const {loadMoreRef, inView} = useLoadMoreIntersectionObserver(
    document.getElementsByClassName("properties-table")?.[0],
    propertyList?.size,
    metaData.get("total"),
    ready && loadingStatus === LoadingStatus.READY,
    onLoadMore
  );

  const getQueryParams = (paramsOffset: number, paramsSortOrder: SortOrder, paramsSortByColumn: string): string => {
    const defaultQueryParam = `?offset=${paramsOffset}&sortByColumn=${paramsSortByColumn}&sortOrder=${paramsSortOrder}&language=${currentLanguage}`;
    return buildFilterQueryParams(activeFilter, defaultQueryParam);
  };

  const getFetchUrlWithParams = (offset, sortOrder, sortByColumn) => {
    const queryParams = getQueryParams(offset, sortOrder, sortByColumn);
    return `${ApiEndpoint.PROPERTY}${queryParams}`;
  };

  const fetchInitialData = (internOffset?: number) => {
    try {
      fetchPropertiesData(getFetchUrlWithParams(internOffset ?? offset, sortOrder, sortByColumn)).then();
      WindowService.scrollTo(document.getElementsByClassName("properties-table")?.[0]);

    } catch (error) {
      LogService.error("An error occured while trying to perform a GET Request", error);
    }
  };

  useEffect(() => {
    if (ready) {
      if (propertyList.size <= 0) {
        fetchInitialData(0);
      }

      WindowService.scrollTo(document.getElementsByClassName("properties-table")?.[0], scrollTop, 'auto')
    }
  }, [ready]);

  useEffect(() => {
    if (ready) {
      fetchInitialData();
    }
  }, [activeFilter]);

  const onSortChange = (sortColumn: string): void => {
    const resetOffset = 0;
    const {newSortOrder, column} = SortService.handleSortChange(sortOrder, sortByColumn, sortColumn);
    const queryParams = getQueryParams(resetOffset, newSortOrder, column);

    WindowService.scrollTo(document.getElementsByClassName("properties-table")?.[0]);

    sortPropertiesData(newSortOrder, column, `${ApiEndpoint.PROPERTY}${queryParams}`);
  };

  //TODO i18n region
  const csvExportUrl = () => {
    const fields = [
      "title",
      "propertyId",
      "primaryAddress.fullStreet:street",
      "primaryAddress.postalCode:postalCode",
      "primaryAddress.location:location",
      "secondaryFullStreets:secondaryStreets",
      "federalState",
      "region_" + currentLanguage + ':region',
      "country_" + currentLanguage + ':country',
      'condition',
      'isMultiTenant',
      'floor:floors',
      "constructionYear",
      "vacancyRate",
      "totalArea",
      'propertyArea',
      "averageRentalCharge",
      "undergroundParkingSpace",
      "outdoorParkingSpace",
      'housingSpace',
      'gastronomySpace',
      'hotelSpace',
      'officeSpace',
      'warehouseSpace',
      'retailSpace',
      'otherSpace',
      "annualNetRent",
      "renovationCost",
      "constructionCost",
      "allCurrentOwners",
      'purchasePrice',
      'purchasePriceDate',
      "allCurrentOwnerAgents"
    ];
    return "/" + getFetchUrlWithParams(0, sortOrder, sortByColumn) + "&contentType=text/csv&fields=" + JSON.stringify(fields);
  };

  const upperRef = useRef();

  const setFilterAreaHeight = (el?) => {
    const filterAreaHeight = (el ?? upperRef.current).contentRect?.height;
    if (!!filterAreaHeight || filterAreaHeight === 0) {
      //console.log("height is", filterAreaHeight)
      document.documentElement.style.setProperty('--filter-area-height', (filterAreaHeight) + "px");
    }
  }

  const resetFilterAreaHeight = () => {
    document.documentElement.style.removeProperty('--filter-area-height');
  }

  useEffect(() => {
    setFilterAreaHeight();
    return () => resetFilterAreaHeight();
  }, [ready])

  useResizeObserver(upperRef.current, (entries) => {
    const first = entries[0];

    if (first) {
      setFilterAreaHeight(first);
    }
  });

  return (
    <Container fluid>
      <div ref={upperRef}>
        <PropertiesFilter/>
        <PropertiesFilterSelection ref={filterSelectionRef}/>
      </div>
      <Row>
        <Col>
          <TableContext.Provider value={{
            tableTitle: "Objekte",
            headers: PropertyHeadersConfig,
            rows: propertyList,
            metaData: metaData,
            setSortByColumn: onSortChange,
            sortByColumn: sortByColumn,
            sortOrder: sortOrder,
            affixRef: filterSelectionRef,
            loadingStatus,
            csvExportFileName: "properties.csv",
            csvExportUrl: csvExportUrl(),
            csvExportVisible: getGroups()?.includes("admin") || getGroups()?.includes("editor")
          }}>
            <Table showTablePanel={true}
                   striped={true}
                   tableClassName='properties-table'
                   ref={loadMoreRef}>
              <TableRow
                urlPath='properties'
                urlPathDetailValue='propertyId'/>
            </Table>
          </TableContext.Provider>
        </Col>
      </Row>
    </Container>
  );
};

const mapStateToProps = (state: IState) => {
  return {
    propertyList: selectPropertyListWithFallback(state),
    metaData: selectPropertyMetaData(state),
    activeFilter: selectActiveFilter(state),
    loadingStatus: selectIsPropertiesLoading(state),
    offset: selectPropertiesOffset(state),
    sortByColumn: selectPropertiesSortByColumn(state),
    sortOrder: selectPropertiesSortOrder(state),
    scrollTop: selectScrollTop(state),
  };
};

const mapDispatchToProps = (dispatch: any) => ({
  fetchPropertiesData: (path: string) => dispatch(
    performGetRequest(path, PropertiesActionTypes.FETCH_IN_PROGRESS, PropertiesActionTypes.SET_LIST, PropertiesActionTypes.FETCH_FINISHED, true)
  ),
  sortPropertiesData: (sortOrder: SortOrder, sortByColumn: string, path: string) => dispatch(
    performSortRequest(path, sortOrder, sortByColumn, PropertiesActionTypes.SET_SORTING, PropertiesActionTypes.SET_SORTED_LIST, PropertiesActionTypes.FETCH_FINISHED)
  ),
  resetPropertiesData: (path: string) => dispatch(
    performOffsetRequest(path, 0, PropertiesActionTypes.SET_OFFSET, PropertiesActionTypes.RESET_DATA, PropertiesActionTypes.FETCH_FINISHED)
  ),
  loadMorePropertiesData: (offset: number, path: string) => dispatch(
    performOffsetRequest(path, offset, PropertiesActionTypes.SET_OFFSET, PropertiesActionTypes.SET_LOADED_DATA, PropertiesActionTypes.FETCH_FINISHED)
  ),
  setScrollTop: (yPosition) => dispatch(
    setScrollTopAction(yPosition)
  )
});

export const PropertyListViewWithStore = connect(mapStateToProps, mapDispatchToProps)(PropertyListView);
