import React, { Children, ReactElement } from 'react';
import { IDialogControlProps } from './dialog-control';
import { Button, ButtonSize, ButtonColor } from '../../button';

interface IDialogControlsProps {
  children: ReactElement<IDialogControlProps> | ReactElement<IDialogControlProps>[];
}

const renderButton = ({ color, label, value, onClick }: IDialogControlProps) => {
  return (
    <Button
      size={ButtonSize.Large}
      color={(color as ButtonColor) ?? ButtonColor.Basic}
      label={label ?? 'undefined'}
      onClick={() => onClick?.(value ?? label)}
    />
  );
}

export const DialogControls = ({ children }: IDialogControlsProps) => {
  return (
    <div className="dialog-controls">
      {
        Children.map(children, (control) => renderButton(control.props))
      }
    </div>
  );
};