import { Record } from 'immutable';
import { AffiliateModel } from "./affiliate.model";
import { PropertyModel } from "./property.model";

export interface IAgentParams {
  id: number;
  isFormer: boolean;

  _agentId: number;
  agent: AffiliateModel,

  _agentId2: number;
  agent2: AffiliateModel,

  _agentId3: number;
  agent3: AffiliateModel,

  _agentId4: number;
  agent4: AffiliateModel,

  _affiliateId: number;
  affiliate: AffiliateModel,

  _propertyId: number;
  property: PropertyModel,
  propertyAddress: string,
}

export class AgentModel extends Record<IAgentParams>({
  id: undefined,
  isFormer: undefined,

  _agentId: undefined,
  agent: undefined,

  _agentId2: undefined,
  agent2: undefined,

  _agentId3: undefined,
  agent3: undefined,

  _agentId4: undefined,
  agent4: undefined,

  _affiliateId: undefined,
  affiliate: undefined,

  _propertyId: undefined,
  property: undefined,
  propertyAddress: undefined,

}) {
  constructor(params?: any) {
    params ? super(params) : super();
  }

  with(values: IAgentParams): this {
    return this.merge(values) as this;
  }
}
