export enum AddressAutofill {
  address = 'address',
  street = 'street',
  streetNumber = 'streetNumber',
  location = 'location',
  federalState = 'federalState',
  region = 'region',
  country_de = 'country_de',
  country_en = 'country_en',
  countryAlpha2Code = 'country_alpha2Code',
  postalCode = 'postalCode',
  geometry = 'geometry'
}
