import { Dispatch } from "redux";
import { HttpService } from "../../services/http.service";
import { KeyValuePairs } from "../../types/key-value-pairs.type";
import { LoadingStatus } from "../../constants/loading-status.enum";
import { SortOrder } from "../../constants/sort-order.enum";

/**
 * This function can be used to asynchronously fetch data through redux.
 * Pass in a start, set and finishType.
 * This function will execute them, when the function starts, has a response and is finished.
 * @param path
 * @param startType
 * @param setType
 * @param finishType
 */
export const performGetRequest = (
  path: string,
  startType: string,
  setType: string,
  finishType: string,
  rawResponse = false) => {

  return function(dispatch: Dispatch) {
    dispatch({
      type: startType,
      loadingStatus: LoadingStatus.IN_PROGRESS
    });

    return HttpService.performGetRequest(path)
      .then((res) => {
        dispatch({
          type: setType,
          payload: rawResponse ? res : res?.data
        });
      })
      .finally(() => {
        dispatch({
          type: finishType,
          loadingStatus: LoadingStatus.READY
        });
      });
  };
};

export const performPostRequest = (
  path: string,
  data: KeyValuePairs,
  startType: string,
  setType: string,
  finishType: string) => {

  return function(dispatch: Dispatch) {
    dispatch({
      type: startType,
      loadingStatus: LoadingStatus.IN_PROGRESS
    });

    return HttpService.performPostRequest(path, data)
      .then((res) => {
        dispatch({
          type: setType,
          payload: res?.data
        });
      })
      .finally(() => {
        dispatch({
          type: finishType,
          loadingStatus: LoadingStatus.READY
        });
      });
  };
};

export const performPutRequest = (
  path: string,
  data: KeyValuePairs,
  startType: string,
  setType: string,
  finishType: string) => {

  return function(dispatch: Dispatch) {
    dispatch({
      type: startType,
      loadingStatus: LoadingStatus.IN_PROGRESS
    });

    return HttpService.performPutRequest(path, data)
      .then((res) => {
        dispatch({
          type: setType,
          payload: res?.data
        });
      })
      .finally(() => {
        dispatch({
          type: finishType,
          loadingStatus: LoadingStatus.READY
        });
      });
  };
};

export const performSortRequest = (
  path: string,
  sortOrder: SortOrder,
  sortByColumn: string,
  startType: string,
  setType: string,
  finishType: string
) => {
  return function(dispatch: Dispatch) {
    dispatch({
      type: startType,
      loadingStatus: LoadingStatus.IN_PROGRESS,
      payload: { sortOrder, sortByColumn }
    });

    return HttpService.performGetRequest(path)
      .then((res) => {
        dispatch({
          type: setType,
          payload: res?.data
        });
      })
      .finally(() => {
        dispatch({
          type: finishType,
          loadingStatus: LoadingStatus.READY
        });
      });
  };
};

export const performOffsetRequest = (
  path: string,
  offset: number,
  startType: string,
  setType: string,
  finishType: string
) => {
  return function(dispatch: Dispatch) {
    dispatch({
      type: startType,
      loadingStatus: LoadingStatus.IN_PROGRESS,
      payload: offset
    });

    return HttpService.performGetRequest(path)
      .then((res) => {
        dispatch({
          type: setType,
          payload: res?.data
        });
      })
      .finally(() => {
        dispatch({
          type: finishType,
          loadingStatus: LoadingStatus.READY
        });
      });
  };
};
