/* eslint-disable react-hooks/exhaustive-deps */

import "./image-editor-slider.scss";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { useImageEditorState } from "./image-editor-state.reducer";
import { css, StyleSheet } from "aphrodite";
import { CssColor, CssSize } from "../../shared/helpers/styles.helper";
import { canvasDimensions } from "./image-editor.defaults";
import { CanvasHelper } from "./canvas.helper";
import { ResetIcon } from "../icons/collection/reset-icon";
import { SvgVariant } from "../icons/enum/svg-variant.enum";
import { Button, ButtonColor, ButtonIconPosition, ButtonSize } from "../button";
import { ArrowRightIcon } from "../icons/collection/arrow-right-icon";
import { ImageCropper } from "./crop-image/image-cropper";
import { RotateImageControl } from "./rotate-image-control/rotate-image-control";
import { ImageContrastControl } from "./image-contrast-control/image-contrast-control";
import { CropImageControl } from "./crop-image/crop-image-control";
import { ImageBrightnessControl } from "./image-brightness-control/image-brightness-control";
import { CloseImageDialog } from "./close-image-dialog";
import { ResetImageDialog } from "./reset-image-dialog";
import { LoadingSpinnerBlueIcon } from "../icons";

interface INewImageEditorProps {
  src: string;
  fileName: string;
  onSave?: (base64: string) => Promise<Function>;
  close?: () => void;
}

export const ImageEditor = React.memo((props: INewImageEditorProps) => {
  const {
    src,
    fileName = "File-Name.jpeg",
    onSave,
    close
  } = props;

  const {
    status,
    settings,
    currentBase64,
    currentImage,

    setCurrentBase64,
    setCurrentImage,
    setRotation,
    setContrast,
    setBrightness,

    setIsCanvas,
    setIsCropper
  } = useImageEditorState(src);

  const canvasRef = useRef<HTMLCanvasElement>();
  const imageRef = useRef<HTMLImageElement>();
  const contextRef = useRef<CanvasRenderingContext2D>();

  const [newBase64, setNewBase64] = useState<string>();
  const [isReady, setIsReady] = useState(false);
  const [isSaving, setIsSaving] = useState<boolean>(null);
  const [isTouched, setIsTouched] = useState(false);
  const [showResetImageDialog, setResetImageDialog] = useState(false);
  const [showCloseDialog, setShowCloseDialog] = useState(false);

  useEffect(() => {
    setIsReady(!status.isInitial);
  }, [status]);

  const drawImage = useCallback(
    () => {
      contextRef.current = canvasRef.current.getContext("2d");
      const imageToDraw = currentImage ?? imageRef.current;
      CanvasHelper.drawImage(contextRef.current, imageToDraw, canvasRef.current, settings);
      const base64 = canvasRef.current.toDataURL();
      setCurrentBase64(base64);
    }, [settings, canvasRef, contextRef, currentImage, imageRef, setCurrentBase64]
  );

  useEffect(() => {
    if (isReady) {
      drawImage();
    }
  }, [settings.contrast, settings.brightness, settings.rotation]);

  useEffect(() => {
    if (isReady) {
      //reset all settings since we have a new base image
      resetSettings();

      drawImage();
    }
  }, [currentImage]);

  const handleOriginalImageLoad = () => {
    drawImage();
  };

  const resetSettings = () => {
    setRotation(0);
    setBrightness(0);
    setContrast(0);
  };

  const resetImageControlHandler = (value: boolean) => {
    if (value) {
      setCurrentBase64(null);
      setCurrentImage(null);
      setIsCanvas();
      resetSettings();

      setIsTouched(false);
      drawImage();
    }

    setResetImageDialog(false);
  };

  const handleOnSave = () => {
    if(onSave !== null) {
      setIsSaving(true);
    }

    onSave?.(currentBase64).then((response) => {
      if (response) {
        close?.();
        response?.();
      } else {
        console.warn("Save was not successful");
        setIsSaving(false);
      }
    }).catch((error) => {
      console.error(error);
      setIsSaving(false);
    });
  };

  const goBackHandler = () => {
    if (isTouched) {
      setShowCloseDialog(true);
    } else {
      close?.();
    }
  };

  const goBackControlHandler = (value) => {
    if (value) {
      close?.();
    }

    setShowCloseDialog(false);
  };

  return (<>
      <div className={css(styles.imageEditorContainer)}>
        <header className={css(styles.headerContainer)}>
          <Button
            onClick={goBackHandler}
            btnClass='modal-back-button'
            color={ButtonColor.Transparent}
            label={"Zurück"}
            iconPosition={ButtonIconPosition.Left}
            disabled={isSaving}
            tabIndex={0}
          >
            <ArrowRightIcon
              color={SvgVariant.White}
              viewBox='0 0 40 40'
              style={{ transform: "rotate(180deg)" }}
              titleAccess='Zurück'
            />
          </Button>
          <div>
            {fileName}
          </div>
          <div className={css(styles.headerContainerRight)}>
            <div className={css(styles.reverseButton)}>
              <Button
                id='reset'
                onClick={() => setResetImageDialog(true)}
                btnClass={"no-padding"}
                showLabel={false}
                label={"Zurücksetzen"}
                color={ButtonColor.Transparent}
                iconPosition={ButtonIconPosition.Right}
                size={ButtonSize.Medium}
                disabled={!isReady || !isTouched || isSaving}
                tabIndex={0}
              >
                <ResetIcon className={css(styles.reverseIcon)} color={SvgVariant.White} viewBox={"0 0 30 30"}
                           titleAccess={"Bearbeitung zurücksetzen"}/>
              </Button>
            </div>
            <div className={css(styles.saveButton)}>
              <Button
                onClick={handleOnSave}
                disabled={!isReady || !isTouched || isSaving}
                iconPosition={ButtonIconPosition.Left}
                color={ButtonColor.Secondary}
                label={"Speichern"}
                tabIndex={0}
              >
                {isSaving && <div className={css(styles.saveButtonIcon)}>
                  <span className='icon-container'><LoadingSpinnerBlueIcon
                    titleAccess='Test' viewBox={"0 0 16 16"} className={"loading"}/></span>
                </div>}
              </Button>
              {isReady && isTouched && !isSaving && <span className={css(styles.saveButtonIndicator)}/>}
            </div>
          </div>
        </header>
        <div className={css(styles.imageWrapper)}>
          {!isReady &&
          <div className={css(styles.loadingSpinner)}><span className='icon-container'><LoadingSpinnerBlueIcon
            titleAccess='Test' viewBox={"0 0 16 16"} className={"loading"}/></span></div>}
          {isReady && <>
          <img onLoad={handleOriginalImageLoad} className={css(styles.imageElement)} ref={imageRef} src={src}
               alt='currently editing' crossOrigin='anonymous'/>
          <canvas className={css(styles.canvasElement, status.isCropping && styles.hide)} width={canvasDimensions.width}
                  height={canvasDimensions.height} ref={canvasRef}/>
          <ImageCropper width={canvasRef?.current?.width}
                        height={canvasRef?.current?.height}
                        status={status}
                        currentBase64={currentBase64}
                        setNewBase64={setNewBase64}
          /></>}
        </div>
        <div className={css(styles.imageEditorControls, !isReady && styles.disableControls)}>
          {!status.isCropping && <>
            <div className={css(styles.controlContainer)}>
              <RotateImageControl rotation={settings.rotation}
                                  setRotation={setRotation}
                                  disabled={!isReady || isSaving}
                                  setIsTouched={setIsTouched}
              />
            </div>
            <div className={css(styles.controlContainer)}>
              <ImageBrightnessControl value={settings.brightness}
                                      setValue={setBrightness}
                                      disabled={!isReady || isSaving}
                                      setIsTouched={setIsTouched}
              />
            </div>
            <div className={css(styles.controlContainer)}>
              <ImageContrastControl value={settings.contrast}
                                    setValue={setContrast}
                                    disabled={!isReady || isSaving}
                                    setIsTouched={setIsTouched}
              />
            </div>
          </>}
          <div className={css(styles.controlContainer)}>
            <CropImageControl status={status}
                              newBase64={newBase64}
                              setIsTouched={setIsTouched}
                              disabled={!isReady || isSaving}
                              setIsCanvas={setIsCanvas}
                              setIsCropper={setIsCropper}
                              setCurrentImage={setCurrentImage}

            />
          </div>
        </div>
      </div>
      <ResetImageDialog onControlClick={resetImageControlHandler} showDialog={showResetImageDialog}/>
      <CloseImageDialog onControlClick={goBackControlHandler} showDialog={showCloseDialog}/>
    </>
  );
});

const styles = StyleSheet.create({
  imageEditorContainer: {
    display: "grid",
    gridTemplateRows: `${CssSize.s80} 1fr ${CssSize.s100}`,
    color: "white",
    justifyItems: "center",
    alignItems: "center",
    width: "100%",
    height: "100%"
  },
  headerContainer: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    width: "100%"
  },
  headerContainerRight: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center"
  },
  reverseButton: {
    marginRight: CssSize.s10
  },
  reverseIcon: {
    transform: "scaleX(-1)"
  },
  saveButton: {
    position: "relative"
  },
  saveButtonIcon: {
    transform: "scale(0.5)"
  },
  saveButtonIndicator: {
    borderRadius: "50%",
    backgroundColor: CssColor.Red,
    border: "2px solid " + CssColor.Black,
    position: "absolute",
    right: "-7px",
    top: "50%",
    width: "14px",
    height: "14px",
    transform: "translateY(-50%)"
  },
  loadingSpinner: {
    position: "absolute",
    left: "50%",
    top: "50%",
    transform: "translateX(-50%) translateY(-50%)",
    width: "16px",
    height: "16px"
  },
  imageWrapper: {
    height: "100%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    width: "100%"
  },
  canvasElement: {
    maxHeight: "50vh",

    '@media (min-height: 550px)': {
      maxHeight: "60vh",
    }
  },
  imageElement: {
    display: "none"
  },
  imageEditorControls: {
    display: "grid",
    gridAutoFlow: "column",
    gridGap: CssSize.s20,
    paddingBottom: CssSize.s20,
  },
  disableControls: {
    pointerEvents: "none",
    userSelect: "none"
  },
  hide: {
    display: "none",
    zIndex: -1,
    visibility: "hidden"
  },
  controlContainer: {
    color: CssColor.Grey,
    display: "grid",
    gridAutoFlow: "row",
    rowGap: CssSize.s10,
    justifyItems: "center",
    alignItems: "flex-start"
  }
});
