import { KeyCode } from '../constants/key-code.enum';

export abstract class KeyCodeHelper {

  static isEnter(keyCode: number) {
    return keyCode === KeyCode.ENTER;
  }

  static isBackspace(keyCode: number) {
    return keyCode === KeyCode.BACKSPACE;
  }

  static isDelete(keyCode: number) {
    return keyCode === KeyCode.DELETE;
  }

  static isEscape(keyCode: number) {
    return keyCode === KeyCode.ESCAPE;
  }

  static isArrowDown(keyCode: number) {
    return keyCode === KeyCode.ARROW_KEY_DOWN;
  }

  static isArrowUp(keyCode: number) {
    return keyCode === KeyCode.ARROW_KEY_UP;
  }
}
