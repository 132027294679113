import { List } from "immutable";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { FileType } from "../../shared/constants/file-type.enum";
import { IPropertyMedia } from "../../shared/interfaces/property-media.interface";
import { IState } from "../../shared/interfaces/state.interface";
import { MediaElementModel } from "../../shared/models/media-element.model";
import { selectPropertyId } from "../../shared/redux/property/property.selectors";
import { LogService } from "../../shared/services/log.service";
import { MediaDataService, MoveMediaPosition } from "../../shared/services/media-data.service";
import { Fieldset } from "../fieldset/fieldset";
import { S3FileUpload } from "../file-upload/s3-file-upload";
import { PropertyMediaGridTable } from "../grid-table/media-grid-table";
import { PropertyMediaHeadline } from "./property-media-headline";
import { DeleteEntryDialog } from "../overlay/dialog/variants/delete-entry-dialog";
import {
  selectPropertyMediaVideos,
  selectPropertyMediaVideoUpdatedAt
} from "../../shared/redux/property-media-video/property-media-video.selectors";
import { PropertyMediaVideoActions } from "../../shared/redux/property-media-video/property-media-video.action";
import { FileService } from "../../shared/services/file-service";
import { PropertyActions } from "../../shared/redux/property/property.actions";
import { useUser } from "../../contexts/user.context";

interface IPropertyMediaVideosProps {
  propertyId: number;
  mediaList: List<MediaElementModel>;
  updatedAt?: Date | string;
  fetchPropertyMediaList: (propertyId: number) => void;
  savePropertyVideos: (videoS: IPropertyMedia[], propertyId) => void;
  deleteVideo: (media: MediaElementModel, propertyId: number) => void;
  updatePosition: (move: MoveMediaPosition, videoId: string, propertyId: number) => void;
}

export const PropertyMediaVideos = (props: IPropertyMediaVideosProps) => {
  const {
    propertyId,
    mediaList,
    updatedAt,
    fetchPropertyMediaList,
    savePropertyVideos,
    deleteVideo,
    updatePosition
  } = props;

  const { getGroups } = useUser();

  const [writePermissions, setWritePermissions] = useState(false);

  useEffect(() => {
    if (getGroups()?.includes("admin") || getGroups()?.includes("editor")) {
      setWritePermissions(true);
    }
  }, [getGroups]);

  useEffect(() => {
    fetchPropertyMediaList(propertyId);
  }, [fetchPropertyMediaList, propertyId]);

  const [showDeleteDialog, setShowDeleteDialog] = useState(false);
  const [deleteMedia, setDeleteMedia] = useState(null);

  const saveVideos = async (videos: IPropertyMedia[]) => {
    if(!writePermissions) {
      return;
    }

    try {
      savePropertyVideos(videos, propertyId);
    } catch (error) {
      LogService.error("Unable to save videos", error);
    }
  };

  const onDelete = async (media: MediaElementModel) => {
    if(!writePermissions) {
      return;
    }

    setShowDeleteDialog(true);
    setDeleteMedia(media);
  };

  const onMoveUp = async (videoId: string) => {
    if(!writePermissions) {
      return;
    }

    updatePosition(MoveMediaPosition.Up, videoId, propertyId);
  };

  const onMoveDown = async (videoId: string) => {
    if(!writePermissions) {
      return;
    }

    updatePosition(MoveMediaPosition.Down, videoId, propertyId);
  };

  const onDialogClick = async (isDeleteClick: boolean) => {
    if(!writePermissions) {
      return;
    }

    setShowDeleteDialog(false);

    if (!isDeleteClick) return;

    try {
      await deleteVideo(deleteMedia, propertyId);
    } catch (error) {
      LogService.error("Unable to delete video", error);
    }
  };

  return (
    <>
      <Fieldset>
        <Fieldset.Legend>
          <PropertyMediaHeadline title='Videos' lastEditedAt={updatedAt}/>
          {writePermissions && <S3FileUpload
            onChange={saveVideos}
            type={FileType.Video}
            label='Video hochladen'
          />}
        </Fieldset.Legend>

        <Fieldset.Content>
          <PropertyMediaGridTable
            tableType={FileType.Video}
            mediaList={mediaList}
            onDelete={onDelete}
            onMoveDown={onMoveDown}
            onMoveUp={onMoveUp}
          />
        </Fieldset.Content>
      </Fieldset>

      <DeleteEntryDialog
        onControlClick={onDialogClick}
        showDialog={showDeleteDialog}
        onClose={() => setShowDeleteDialog(false)}
      >
        Wenn sie auf „Löschen“ klicken wird das Video<br/>
        gelöscht und kann nicht wiederhergestellt werden.
      </DeleteEntryDialog>
    </>
  );
};

const mapStateToProps = (state: IState) => {
  return {
    propertyId: selectPropertyId(state),
    mediaList: selectPropertyMediaVideos(state),
    updatedAt: selectPropertyMediaVideoUpdatedAt(state)
  };
};

const mapDispatchToProps = (dispatch: any) => ({
  fetchPropertyMediaList: async (propertyId: number) => {
    const res = await MediaDataService.fetchVideosByPropertyId(propertyId);
    dispatch(PropertyMediaVideoActions.setVideos(res?.data));
  },

  savePropertyVideos: async (videos: IPropertyMedia[], propertyId) => {
    const res = await MediaDataService.saveVideosByPropertyId(videos, propertyId);

    dispatch(PropertyMediaVideoActions.saveVideos(res?.data));
    dispatch(PropertyActions.touch(res?.meta?.updatedByTitle));
  },

  deleteVideo: async (video: MediaElementModel, propertyId: number) => {
    const deletedImage = FileService.remove(video.s3Key);
    const deletedThumbnail = FileService.remove(video.thumbnailS3Key);

    await Promise.all([deletedImage, deletedThumbnail]);
    const deletedMediaObject = await MediaDataService.deleteVideo(video, propertyId);

    dispatch(PropertyMediaVideoActions.deleteVideo(video.id));
    dispatch(PropertyActions.touch(deletedMediaObject?.meta?.updatedByTitle));
  },

  updatePosition: async (move: MoveMediaPosition, videoId: string, propertyId: number) => {
    const res = await MediaDataService.updateVideoPosition(move, videoId, propertyId)
    dispatch(PropertyMediaVideoActions.updatePosition(videoId, move));
    dispatch(PropertyActions.touch(res?.meta?.updatedByTitle));
  }
});

export const PropertyMediaVideosWithStore = connect(mapStateToProps, mapDispatchToProps)(PropertyMediaVideos);
