import { KeyValuePairs } from '../../../../shared/types/key-value-pairs.type';
import { FieldTabArrayActionTypes } from './field-tab-array.action-types';

export function setData(data: KeyValuePairs[]) {
  return {
    type: FieldTabArrayActionTypes.SET_DATA,
    data
  }
}

export function setPreviousData(previousData: KeyValuePairs[]) {
  return {
    type: FieldTabArrayActionTypes.SET_PREVIOUS_DATA,
    previousData
  }
}

export function setActiveTab(activeTab: number) {
  return {
    type: FieldTabArrayActionTypes.SET_ACTIVE_TAB,
    activeTab
  }
}

export function setActiveTabContent(content: KeyValuePairs) {
  return {
    type: FieldTabArrayActionTypes.SET_ACTIVE_TAB_CONTENT,
    activeTabContent: content
  }
}

export function setLastAddedData(data: KeyValuePairs) {
  return {
    type: FieldTabArrayActionTypes.SET_LAST_ADDED_DATA,
    lastAddedData: data
  }
}
