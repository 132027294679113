import { ITableHeaderAndFooterConfig } from '../interfaces/table-header-config.interface';
import {UnitSymbol} from "../../components/form/enums/unit-symbol.enum";

export const AffiliateHeadersConfig: ITableHeaderAndFooterConfig[] = [
  {
    label: "ID",
    width: 120,
    columnKey: "_affiliateId",
  },
  {
    label: "Mieter",
    width: 350,
    columnKey: "affiliate.affiliateName",
  },
  {
    label: "Straße / Nr.",
    width: 250,
    columnKey: "property.primaryAddress.fullStreet",
  },
  {
    label: "PLZ",
    width: 120,
    columnKey: "property.primaryAddress.postalCode",
  },
  {
    label: "Ort",
    width: 150,
    columnKey: "property.primaryAddress.location",
  },
  /*{
    label: "Land",
    columnKey: "property.country"
  },*/
  {
    label: "Gesamtfl. (m<sup>2</sup>)",
    align: 'right',
    width: 150,
    columnKey: "totalArea",
    formatNumber: true
  },
  {
    label: "Bürofl. (m<sup>2</sup>)",
    align: 'right',
    width: 150,
    columnKey: "officeArea",
    formatNumber: true
  },
  {
    label: "Mietbeginn",
    align: 'right',
    width: 200,
    columnKey: "rentalStart",
    formatDate: true,
    hideTime: true
  },
  {
    label: "Mietende",
    width: 200,
    align: 'right',
    columnKey: "rentalEnd",
    formatDate: true,
    hideTime: true
  },
  {
    label: "Ø Mietpreis pro " + UnitSymbol.SquareMeter,
    width: 200,
    align: 'right',
    columnKey: "averageRent",
  },
];
