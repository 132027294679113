import { GroupSize } from '../../../enums/group-size.enum';
import { IFormFieldset } from '../../../interfaces/form-fieldset.interface';
import { AffiliateOwnersFormDefinitions } from "./affiliate-owners.input-definitions.const";

export const AffiliateOwnersFormDefinition: IFormFieldset[] = [
  {
    name: 'owner',
    groups: [
      {
        title: 'Eigentümer & Alteigentümer',
        size: GroupSize.Full,
        fields: [
          AffiliateOwnersFormDefinitions.AffiliateOwners
        ]
      }
    ]
  },
];
