import React from 'react';
import { Language } from "../../shared/constants/language.enum";
import { useCurrentLanguage } from "../../contexts/current-language.context";

interface Translation {
  language: Language;
  value: any;
}

interface ITranslatedProps {
  translations: Translation[];
}

export const Translated = (props: ITranslatedProps) => {
  const currentLanguage = useCurrentLanguage();

  return <>{props.translations.map((translation) => {
    return translation?.language === currentLanguage ? <React.Fragment key={translation?.language}>{translation.value}</React.Fragment> : null
  })}</>;
}
