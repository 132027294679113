import { ISelectItem } from '../../components/inputs/interfaces/select-item.interface';

export const PropertyImageOptions: ISelectItem[] = [
  { label: 'Unbebildert', isChecked: false, value: 'noImages', color: 'orange' },
  { label: 'Bebildert', isChecked: false, value: 'hasImages', color: 'green' }
].map((item, index) => {
  return {
    id: `property-image-option-${index+1}`,
    ...item
  }
});
