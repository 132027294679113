import React, { Ref, useRef } from 'react'
import { KeyCodeHelper } from '../../../shared/helpers/key-code.helper';

interface IEntryTitleInputProps {
    name: string;
    value: string;
    onChange: (evt: any) => void;
    onBlur: () => void;
}

export const EntryTitleInput = React.memo(
    React.forwardRef((props: IEntryTitleInputProps, ref: Ref<HTMLInputElement>) => {
        const {
            value,
            onBlur
        } = props;

        const previousValue = useRef(value);

        const distinctOnBlur = () => {
            if (previousValue.current !== value) {
                onBlur();
                previousValue.current = value;
            }
        }

        const handleKeyDown = (event) => {
            if (KeyCodeHelper.isEnter(event.keyCode)) {
                event.target.blur();
            }
        }

        return (
            <input
                {...props}
                type="text"
                onKeyDown={handleKeyDown}
                onBlur={distinctOnBlur}
                ref={ref}
            />
        )
    })
)
