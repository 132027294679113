import React, {useEffect, useReducer, useState} from 'react';
import { IWithRouterProps} from '../../../../shared/interfaces/with-router-props.interface';
import { FormikValues, FormikErrors, useField } from 'formik';
import { IFieldAddressArray } from '../../interfaces/field-address-array.interface';
import { withRouter } from 'react-router-dom';
import { fieldAddressArrayReducer, IFieldAddressArrayState } from './field-address-array.reducer';
import {setAddresses, setPreviousAddresses} from './field-address-array.actions';
import { css, StyleSheet } from 'aphrodite/no-important';
import { Button, ButtonColor, ButtonIconPosition, ButtonSize } from '../../../button';
import { AddIcon } from '../../../icons/collection/add-icon';
import { SvgVariant } from '../../../icons/enum/svg-variant.enum';
import { selectPrimaryAddress, selectSecondaryAddresses } from './field-address-array.selector';
import { KeyValuePairs } from '../../../../shared/types/key-value-pairs.type';
import { FieldArrayHelper } from '../../helpers/field-array.helper';
import { FormHelper } from '../../helpers/form.helper';
import { IFieldChangeEvent } from '../../interfaces/field-single.interface';
import { BinIcon } from '../../../icons/collection/bin-icon';
import { LogService } from '../../../../shared/services/log.service';
import { Dialog, DialogControl } from '../../../overlay/dialog';
import { AddressValidationHelper } from '../../../../shared/helpers/address-validation.helper';
import { FieldAddresArrayHelper } from './field-address-array.helper';
import {FlashMessagesService} from "../../../../shared/services/flash-messages.service";
import { useCurrentLanguage } from "../../../../contexts/current-language.context";
import {MapboxglGeocodingService} from "../../../../shared/services/mapboxglGeocodingService";

interface IFieldAddressArrayProps extends IFieldAddressArray, IWithRouterProps<{ propertyId: number }> {
  formValues: FormikValues;
  validateForm?: (values: any) => Promise<FormikErrors<any>>,
  readonly?: boolean;
}

const ADDRESSES_DEFAULT_STATE: IFieldAddressArrayState = {
  addresses: [],
  previousAddresses: []
};

const flashMessageService = FlashMessagesService.getInstance();

export const FieldAddressArray = React.memo((props: IFieldAddressArrayProps) => {
  const {
    match,
    name,
    formValues,
    fieldArrayTitle,
    addLabel,
    onDataAdd,
    onDataDelete,
    triggerDataRefetch,
    newDataRef,
    changeHandler,
    validateForm,
    readonly = false
  } = props;

  const [ state, dispatch ] = useReducer(fieldAddressArrayReducer, ADDRESSES_DEFAULT_STATE);

  const [ deleteTargetIdentifier, setDeleteTargetIdentifier ] = useState(null);
  const [ showDeleteDialog, setShowDeleteDialog ] = useState(false);

  const [ showChangeDialog, setShowChangeDialog ] = useState(false);
  const [ changeData, setChangeData ] = useState(null);

  const [ propertyId, setPropertyId ] = useState<number>(null);
  const { addresses, previousAddresses } = state;
  const [,, {setError, setTouched}] = useField(name);

  const currentLanguage = useCurrentLanguage();

  useEffect(() => {
    setPropertyId(match.params.propertyId);
  }, [match]);

  const updateStateByFormChanges = () => {
    if (!previousAddresses?.length) {
      dispatch(setPreviousAddresses(formValues[name]));
    }

    dispatch(setAddresses(formValues[name]));
  }
  useEffect(updateStateByFormChanges, [formValues, name]);

  const handleChange = async (change: IFieldChangeEvent) => {
    setTouched(true);
    const changedAddressIndex = FieldAddresArrayHelper.getIndexFromName(change.name);

    MapboxglGeocodingService.getInstance().findPublityPlacesForQuery(change.value, null, ['address'], currentLanguage).then((places) => {
      const place = places?.[0];

      const updatedValues = FieldArrayHelper.onAddressDataUpdate(changedAddressIndex, place, addresses, currentLanguage);
      const { address, postalCode, isPrimary } = updatedValues[changedAddressIndex];

      if (!AddressValidationHelper.isValid(address)) {
        const error = FieldAddresArrayHelper.createValidationError(updatedValues.length, changedAddressIndex);
        setError(error);
        return;
      }

      const currentPrimaryAddress = selectPrimaryAddress(state);

      if (isPrimary && postalCode !== currentPrimaryAddress.postalCode) {
        setShowChangeDialog(true);
        setChangeData(updatedValues);
        return;
      }

      dispatch(setPreviousAddresses(updatedValues));
      changeHandler?.({
        name,
        value: updatedValues,
        fallbackName: null,
        isFallback: null,
        validateForm
      });

    }).catch((error) => {
      LogService.error(
        'An error occurred during `field-address-array.handleChange`.',
        error
      );
      flashMessageService.addMessage({
        id: Date.now(),
        status: error.statusCode,
        text: 'Autofill anhand der Adresse fehlgeschalgen.',
      });
    });

  }

  const renderDataRef = (address: KeyValuePairs, isPrimaryAddress: boolean) => {
    const dataIndex = findAddressIndex(address);
    const dataRef = FieldArrayHelper.mapAddressArrayToDataRef(name, dataIndex, newDataRef, isPrimaryAddress);

    return FormHelper.renderFieldByTemplateType(
      dataRef,
      {
        key: address?.id,
        changeHandler: async (change: IFieldChangeEvent) => {
          return await handleChange({
            ...change
          });
        },
        readonly
      }
    )
  }

  const findAddressIndex = (address: KeyValuePairs) => {
    return addresses?.indexOf(address);
  }

  const onDialogControlClick = async (submitDelete: boolean) => {
    if(readonly) {
      return;
    }

    if (submitDelete) {
      try {
        await onDataDelete?.(propertyId, deleteTargetIdentifier);
        await triggerDataRefetch?.();
      } catch (error) {
        LogService.error('onDialogControlClick: Http request failed.', error);
      }
    }
    setDeleteTargetIdentifier(null);
    setShowDeleteDialog(false);
  }

  const onDialogChangeControlClick = (submitChange: boolean) => {
    if(readonly) {
      return;
    }

    if (submitChange && !!changeData) {
      dispatch(setPreviousAddresses(changeData));
      changeHandler?.({
        name,
        value: changeData,
        fallbackName: null,
        isFallback: null,
        validateForm
      });
    }

    setShowChangeDialog(false);
    setChangeData(null);
  }

  return (
    <>
      {
        showDeleteDialog && !readonly &&
          <Dialog
            title='Sind Sie sicher?'
            onClose={() => setShowDeleteDialog(false)}
          >
            <Dialog.Message>
              Wenn sie auf „Löschen“ klicken wird der Eintrag<br />
              gelöscht und kann nicht wiederhergestellt werden.
            </Dialog.Message>
            <Dialog.Controls>
              <DialogControl label='Abbrechen' value={false} color={ButtonColor.Basic} onClick={onDialogControlClick}></DialogControl>
              <DialogControl label='Löschen' value={true} color={ButtonColor.Danger} onClick={onDialogControlClick}></DialogControl>
            </Dialog.Controls>
          </Dialog>
      }
      {
        showChangeDialog && !readonly &&
          <Dialog
            title='Sind Sie sicher?'
            onClose={() => setShowChangeDialog(false)}
          >
            <Dialog.Message>
              Wenn sie auf „Bestätigen“ klicken werden alle Postleitzahlabhängigen Konfigurationen<br />
              verworfen und können nicht wiederhergestellt werden.
            </Dialog.Message>
            <Dialog.Controls>
              <DialogControl label='Abbrechen' value={false} color={ButtonColor.Basic} onClick={onDialogChangeControlClick}></DialogControl>
              <DialogControl label='Bestätigen' value={true} color={ButtonColor.Danger} onClick={onDialogChangeControlClick}></DialogControl>
            </Dialog.Controls>
          </Dialog>
      }
      <div className={css(styles.titleWrapper)}>
        {fieldArrayTitle ? (
          <p className={css(styles.arrayTitle)}>
            {fieldArrayTitle} ({addresses?.length})
          </p>
        ) : null}
        {!readonly && <Button type={'button'}
                btnClass={'medium-font-size bold margin-left-auto'}
                size={ButtonSize.Small}
                color={ButtonColor.Ghost}
                iconPosition={ButtonIconPosition.Left}
                label={addLabel}
                onClick={async () => {
                  await onDataAdd?.(propertyId);
                  await triggerDataRefetch?.();
                }}>
          <AddIcon
            viewBox='0 0 30 30'
            color={SvgVariant.Secondary}
            titleAccess='Eintrag hinzufügen'
          />
        </Button>}
      </div>
      {renderDataRef(selectPrimaryAddress(state), true)}
      {selectSecondaryAddresses(state)?.map((address) => {
        return (
          <div key={address?.id}
               className={css(styles.addressWrapper)}>
            {renderDataRef(address, false)}
            {!readonly && <Button type={'button'}
                    btnClass={'no-padding'}
                    size={ButtonSize.Medium}
                    color={ButtonColor.Basic}
                    showLabel={false}
                    label={null}
                    onClick={() => {
                      setDeleteTargetIdentifier(address?.id);
                      setShowDeleteDialog(true);
                    }}>
              <BinIcon
                viewBox='0 0 40 40'
                color={SvgVariant.Secondary}
                titleAccess={'Eintrag löschen'}
              />
            </Button>}
          </div>
        )
      })}
    </>
  )
});

const styles = StyleSheet.create({
  titleWrapper: {
    display: 'grid',
    gridTemplateColumns: 'calc(var(--form-label) + var(--size-10)) repeat(2, 1fr)',
    gridGap: 'var(--size-10)',
    marginBottom: 'var(--size-15)',
  },
  addressWrapper: {
    display: 'grid',
    gridTemplateColumns: '1fr var(--size-40)',
    gridGap: 'var(--size-10)',
    alignItems: 'flex-start'
  },
  arrayTitle: {
    gridColumn: 2,
    fontSize: 'var(--font-size-title)',
    fontWeight: 'bold',
    marginBottom: 0
  },
  addButtonPosition: {
    gridColumn: 3
  }
})

export const FieldAddressArrayWithRouter = withRouter(FieldAddressArray);
