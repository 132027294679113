import React, { ReactNode } from "react";
import { CurrentLanguageContext } from "../contexts/current-language.context";
import { connect } from "react-redux";
import { IState } from "../shared/interfaces/state.interface";
import { selectLanguage } from "../shared/redux/settings/settings.selector";
import { Language } from "../shared/constants/language.enum";

interface ILanguageContainerProps {
  currentLanguage: Language,
  children?: ReactNode;
}

export const LanguageContainerWithoutStore = React.memo((props: ILanguageContainerProps) => {
  return (<>
    <CurrentLanguageContext.Provider value={props.currentLanguage}>
      {props.children}
    </CurrentLanguageContext.Provider>
  </>);
});

const mapStateToProps = (state: IState) => {
  return {
    currentLanguage: selectLanguage(state)
  };
};

export const LanguageContainer = connect(mapStateToProps)(LanguageContainerWithoutStore);

export default LanguageContainer;
