import { GroupSize } from '../../../enums/group-size.enum';
import { IFormFieldset } from '../../../interfaces/form-fieldset.interface';
import { AffiliateTenanciesInputDefinitions } from "./affiliate-tenancies.input-definitions.const";

export const AffiliateTenanciesFormDefinition: IFormFieldset[] = [
  {
    name: 'tenancies',
    groups: [
      {
        title: 'Mieter & Alt-Mieter',
        size: GroupSize.Full,
        fields: [
          {
            ...AffiliateTenanciesInputDefinitions.AffiliateTenancies
          }
        ]
      }
    ]
  },
];
