import {css, StyleSheet} from 'aphrodite';
import React from 'react';
import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import {connect} from 'react-redux';
import {AdminTab} from '../../../shared/constants/admin-tab.enum';
import {setAdminTabsActiveTab} from '../../../shared/redux/admin-tabs/admin-tabs.action';
import {selectActiveAdminTab} from '../../../shared/redux/admin-tabs/admin-tabs.selectors';
import {ControlsHeader} from '../../controls-header/controls-header';
import {Tab, Tabs} from '../../tabs';
import {AdminTabViews} from './admin-tab-views';
import {useUser} from "../../../contexts/user.context";

const adminTabs = [
  AdminTab.User,
  AdminTab.IntroTexts,
  AdminTab.Regions,
  AdminTab.PersonGroups,
];

interface IAdminMainViewProps {
  setActiveTab: (selectedTab: string) => void,
  activeTab: string,
}

export const AdminMainView = (props: IAdminMainViewProps) => {
  const {
    setActiveTab,
    activeTab = AdminTab.User,
  } = props;

  const user = useUser();

  const isSystemAdmin = process.env.NODE_ENV !== 'production' && user?.user?.attributes?.email?.includes('@webartig.com') | user?.user?.attributes?.email?.includes('@danielmeisl.com')

  let tabs = adminTabs;

  if (isSystemAdmin) {
    tabs = [...tabs, AdminTab.DevTools]
  }

  return (
    <Container fluid className={css(styles.container)}>
      <Row>
        <Col>
          <ControlsHeader>
            <Tabs
              controlsOnly={true}
              onSelect={setActiveTab}
              activeTab={activeTab}
            >
              {tabs.map((tabLabel, index) => (<Tab key={index} label={tabLabel}></Tab>))}
            </Tabs>
          </ControlsHeader>
        </Col>
      </Row>
      <Row>
        <Col>
          <AdminTabViews activeTab={activeTab}/>
        </Col>
      </Row>
    </Container>
  )
}

const styles = StyleSheet.create({
  container: {
    padding: 'var(--size-30) var(--size-30) 0 var(--size-30)' // TODO: Update CssSize once https://webartig.atlassian.net/browse/PUB-114 is merged
  },
  legendTitle: {
    fontSize: 'var(--font-size-title)',
    fontWeight: 'bold',
  }
});

const mapStateToProps = (state: any) => {
  return {
    activeTab: selectActiveAdminTab(state)
  };
};

const mapDispatchToProps = (dispatch: any) => ({
  setActiveTab: (selectedTab: string) => dispatch(
    setAdminTabsActiveTab(selectedTab)
  )
});

export const AdminMainViewWithStore = connect(mapStateToProps, mapDispatchToProps)(AdminMainView);
