export abstract class FieldAddresArrayHelper {

  static createValidationError(totalAddressAmount: number, ...errorIndexList: number[]) {
    const errorArray = new Array(totalAddressAmount);
    const errorObject = {address: 'invalid'};

    for (const errorIndex of errorIndexList) {
      errorArray[errorIndex] = errorObject;
    }

    return errorArray;
  }

  static getIndexFromName(fieldName: string): number {
    const [, indexMatch] = fieldName.match(/^addresses\.(\d{1,2})/i);
    return +indexMatch;
  }
}
