import { css, StyleSheet } from 'aphrodite/no-important';
import { useFormik } from 'formik';
import React, { useEffect, useRef, useState } from 'react';
import { CssSize } from '../../shared/helpers/styles.helper';
import { Button, ButtonColor, ButtonSize } from '../button';
import { BinIcon } from '../icons/collection/bin-icon';
import { SvgVariant } from '../icons/enum/svg-variant.enum';
import { PersonGroupModel, IPersonGroupParams } from '../../shared/models/person-group.model';
import { EntryTitleInput } from '../form/fields/entry-title-input';
import { CollapsableElements } from '../collapsable-elements/collapsable-elements';
import { SuggestionDropdown } from '../suggestion-dropdown/suggestion-dropdown';
import { useDispatch } from 'react-redux';
import { Badge } from '../badge/badge';
import { PersonGroupPropertyDataService } from '../../shared/services/person-group-property-data.service';
import { PersonGroupsDataService } from '../../shared/services/person-groups-data.service';
import { PersonGroupProperties } from './person-group-properties';
import { PersonGroupActions } from '../../shared/redux/person-groups/person-groups.action';
import { SuggestionDropdownContext } from '../../contexts/suggestion-dropdown.context';
import { defaultGridTemplateColumns } from '../../shared/constants/media-grid.const';

interface IPersonGroupRow {
  data: PersonGroupModel;
  autoFocus?: boolean,
  onDelete: () => void;
  onUpdate: (personGroup: IPersonGroupParams) => void;
}
const disableDeleteList = [process.env.REACT_APP_PERSONGROUPS_PIPELINE, process.env.REACT_APP_PERSONGROUPS_PUBLITY, process.env.REACT_APP_PERSONGROUPS_GORE, process.env.REACT_APP_PERSONGROUPS_PREOS];

export const PersonGroupRow = (props: IPersonGroupRow) => {
  const {
    data = new PersonGroupModel(),
    autoFocus = false,
    onDelete,
    onUpdate
  } = props;

  const titleInputRef = useRef<HTMLInputElement>();
  const [ selectedSuggestions, setSelectedSuggestions ] = useState<string[]>(null);

  // Pre-Set the formik field values with empty strings.
  const initialFormikValues = useRef(data.merge({
    title: data?.title ?? ''
  }).toJS());

  useEffect(() => {
    if (autoFocus) {
      titleInputRef.current.focus();
    }
  }, [autoFocus]);

  useEffect(() => {
    const existingValues = data.properties?.map(({ title, propertyId }) => {
      return `${title?.trim()} (Objekt-ID: ${propertyId})`;
    });

    setSelectedSuggestions(existingValues);
  }, [data.properties]);

  const formik = useFormik({
    initialValues: initialFormikValues.current,
    onSubmit: (values) => onUpdate(values),
  });

  const handleOnBlur = () => {
    const isDistinctValue = !data.equals(new PersonGroupModel(formik.values));
    if (isDistinctValue) {
      formik.submitForm();
    }
  }

  const inputProps = {
    onChange: formik.handleChange,
    onBlur: handleOnBlur
  };

  const dispatch = useDispatch();

  // Earn Title and Property ID of received suggestions with structure
  // [title] (Objekt-ID: [propertyID])
  const resolveSuggestion = (suggestion: string) => {
    const idPartRegex = /\((.+)\)/g;
    const idPart = suggestion.match(idPartRegex)[0];
    const idRegex = /[0-9]+/g;
    const propertyId = idPart.match(idRegex)[0];

    const regex = /^[^(]+/g;
    const title = suggestion.match(regex)[0];

    return {
      propertyId,
      title
    };
  }

  const onSelect = async (suggestion: string) => {
    try {
      const { propertyId, title } = resolveSuggestion(suggestion);
      const { data: { _propertyId } } = await PersonGroupsDataService.addProperty(data.id, propertyId);

      dispatch(PersonGroupActions.addProperty(data.id, { id: _propertyId, propertyId, title }, data.updatedByTitle));
    } catch (error) {
      console.error('[PersonGroupRow] onSelect: Failed adding property', error);
    }
  }

  return (
    <div className={css(styles.container)}>

      <div>
        <EntryTitleInput
            name='title'
            ref={titleInputRef}
            value={formik.values.title}
            {...inputProps}
          />
      </div>

      <div>
        <CollapsableElements
            header={(
              <SuggestionDropdownContext.Provider value={{
                  buttonLabel: 'Objekt hinzufügen',
                  placeholderText: 'Objektname oder Straße',
                  noDataText: 'Bitte Objektname oder Straße eingeben',
                  selectedSuggestions,
                  onSelect,
                  getSuggestions: async (searchTerm) => await PersonGroupPropertyDataService.fetch(searchTerm)
                }}>
                  <div className={css(styles.collapsableHeader)}>
                      <SuggestionDropdown/>
                      {data?.properties?.length !== null && (
                        <Badge value={data?.properties?.length || 0} />
                      )}
                  </div>
                </SuggestionDropdownContext.Provider>
            )}
            content={(
              <PersonGroupProperties personGroupId={data.id}/>
            )}
          />
      </div>

      <div>
        <Button type={'button'}
          btnClass={'no-padding'}
          size={ButtonSize.Medium}
          color={ButtonColor.Basic}
          showLabel={false}
          disabled={disableDeleteList.includes(data.id)}
          label={'Eintrag löschen'}
          onClick={() => {
            if(!disableDeleteList.includes(data.id)) {
              onDelete();
            }
          }}>
          <BinIcon
            viewBox='0 0 40 40'
            color={SvgVariant.Secondary}
            titleAccess={'Eintrag löschen'}
          />
        </Button>
      </div>

    </div>
  );
};

const styles = StyleSheet.create({
  container: {
    width: '100%',
    display: 'grid',
    gridTemplateColumns: defaultGridTemplateColumns,
    gridGap: CssSize.s10
  },
  collapsableHeader: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  }
});
