export abstract class WindowService {

  static scrollTo(context: any = window, top: number = 0, behavior: 'smooth' | 'auto' = 'smooth'): void {
    context.scrollTo({
      top,
      behavior
    });
  }

}
