import {IState} from '../../interfaces/state.interface';
import {fromJS, List} from 'immutable';
import {IReducer} from '../../interfaces/reducer.interface';
import {IAction} from '../../interfaces/action.interface';
import {UserActionTypes} from './users.action-types';
import {mergeState} from '../../utils/reducer';
import {IUserModel, UserModel} from '../../models/user.model';
import {UserStatus} from "../../constants/user-status.enum";
import {SortOrder} from "../../constants/sort-order.enum";
import {LoadingStatus} from "../../constants/loading-status.enum";

const initialState: IState = fromJS({
  data: List(),
  updatedAt: null,
  updatedByTitle: null,
  loadingStatus: LoadingStatus.READY,
});

const sortBy = (list: List<any>, sortByColumn: string, sortOrder: SortOrder) => {
  return list.sort((a: IUserModel, b: IUserModel) => {
    if (sortOrder === SortOrder.ASC) {
      return a[sortByColumn] > b[sortByColumn] ? 1 : -1
    } else {
      return a[sortByColumn] > b[sortByColumn] ? -1 : 1
    }
  });
}

export const userReducer: IReducer = (state = initialState, action: IAction) => {
  switch (action.type) {
    case UserActionTypes.SET_USERS:
      const mapped: List<UserModel> = action.payload.map((user) => new UserModel(user))
      return mergeState(state, {
        data: sortBy(mapped, 'email', SortOrder.ASC),
        sortByColumn: 'email',
        sortOrder: SortOrder.ASC,
        loadingStatus: LoadingStatus.READY
      })
    case UserActionTypes.SET_USERS_META:
      return mergeState(state, {
        updatedAt: action.payload?.updatedAt,
        updatedByTitle: action.payload?.updatedByTitle,
      })
    case UserActionTypes.SORT_USERS:
      const {sortByColumn, sortOrder} = action.payload;
      return mergeState(state, {
        ...action.payload,
        data: sortBy((state.get('data') as List<UserModel>), sortByColumn, sortOrder),
      })
    case UserActionTypes.ADD_EMPTY_USER:
      const newUser = new UserModel({});
      const currentUsers = state.get('data') as List<UserModel>;

      return mergeState(state, {
        data: List([newUser, ...currentUsers])
      })
    case UserActionTypes.CONFIRM_USER:
      const updatedUsers = state.get('data').map((user, listIndex) => {
        if (listIndex === action.payload.index) {
          return user.merge({
            ...action.payload.user,
            status: UserStatus.Active
          });
        }

        return user;
      });

      return mergeState(state, {
        data: updatedUsers,
        updatedAt: new Date()
      });
    case UserActionTypes.UPDATE_USER:
      const updatedUsers2 = state.get('data').map((user, listIndex) => {
        if (listIndex === action.payload?.index) {
          return user.merge({
            ...action.payload.user
          });
        }

        return user;
      });

      return mergeState(state, {
        data: updatedUsers2,
        updatedAt: new Date()
      });
    case UserActionTypes.DELETE_USER:
      const deleteIndex = state.get('data').findIndex((_, index) => index === action.payload);

      return mergeState(state, {
        data: state.get('data').remove(deleteIndex)
      })
    case UserActionTypes.ENABLE_USER:
      const enabledUserState = state.get('data').map((user, userIndex) => {
        if (userIndex === action.payload) {
          return user.merge({
            status: UserStatus.Active
          });
        }

        return user;
      });

      return mergeState(state, {
        data: enabledUserState,
        updatedAt: new Date()
      })
    case UserActionTypes.DISABLE_USER:
      const disabledUserState = state.get('data').map((user, userIndex) => {
        if (userIndex === action.payload) {
          return user.merge({
            status: UserStatus.Locked
          });
        }

        return user;
      });

      return mergeState(state, {
        data: disabledUserState,
        updatedAt: new Date()
      })

    case UserActionTypes.FETCH_IN_PROGRESS:
      return mergeState(state, {
        loadingStatus: LoadingStatus.IN_PROGRESS
      });

    default:
      return state;
  }
}
