export enum Month {
    January = 'Januar',
    February = 'Februar',
    March = 'März',
    April = 'April',
    May = 'Mai',
    June = 'Juni',
    July = 'Juli',
    August = 'August',
    September = 'September',
    October = 'Oktober',
    November = 'November',
    December = 'Dezember'
}

export enum MonthDigits {
    January = '01',
    February = '02',
    March = '03',
    April = '04',
    May = '05',
    June = '06',
    July = '07',
    August = '08',
    September = '09',
    October = '10',
    November = '11',
    December = '12'
}