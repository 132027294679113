import { css, StyleSheet } from "aphrodite";
import { List } from "immutable";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { IState } from "../../../../shared/interfaces/state.interface";
import { PersonGroupActions } from "../../../../shared/redux/person-groups/person-groups.action";
import { selectPersonGroups } from "../../../../shared/redux/person-groups/person-groups.selectors";
import { PersonGroupsDataService } from "../../../../shared/services/person-groups-data.service";
import { Button, ButtonColor, ButtonIconPosition, ButtonSize } from "../../../button";
import { PersonGroupsDatasetHistory } from "../../../dataset-history/person-groups-dataset-history";
import { Fieldset } from "../../../fieldset/fieldset";
import { AddIcon } from "../../../icons/collection/add-icon";
import { SvgVariant } from "../../../icons/enum/svg-variant.enum";
import { GridTable } from "../../../grid-table/grid-table";
import { usePrevious } from "../../../../hooks/use-previous";
import { IPersonGroupParams, PersonGroupModel } from "../../../../shared/models/person-group.model";
import { PersonGroupRow } from "../../../person-group-row/person-group-row";
import { DeleteEntryDialog } from "../../../overlay/dialog/variants/delete-entry-dialog";
import { usePersonGroupState } from "./use-person-groups-state";

interface IPersonGroupsViewProps {
  personGroups: List<PersonGroupModel>;
  addPersonGroup: () => void;
  fetchPersonGroups: () => void;
  deletePersonGroups: (id: string) => void;
  updatePersonGroups: (personGroup: IPersonGroupParams) => void;
}

export const PersonGroupsView = (props: IPersonGroupsViewProps) => {
  const {
    personGroups = List(),
    addPersonGroup,
    fetchPersonGroups,
    deletePersonGroups,
    updatePersonGroups
  } = props;

  const {
    state,
    setCreateNewState,
    setUpdateState
  } = usePersonGroupState();

  const previousPersonGroupSize = usePrevious(personGroups?.size ?? 0);

  const [showDeleteDialog, setShowDeleteDialog] = useState(false);
  const [deleteIndex, setDeleteIndex] = useState(null);


  useEffect(() => {
    if (!personGroups?.size) {
      fetchPersonGroups();
    }
  }, [personGroups, fetchPersonGroups, previousPersonGroupSize]);

  const onAddPersonGroup = () => {
    setCreateNewState();
    addPersonGroup();
  };

  const onDelete = (personGroupId: string) => {
    setShowDeleteDialog(true);
    setDeleteIndex(personGroupId);
  };

  const onUpdate = (personGroup: IPersonGroupParams) => {
    updatePersonGroups(personGroup);
    setUpdateState();
  };

  const onDialogClick = (isDeleteClick: boolean) => {
    setShowDeleteDialog(false);

    if (isDeleteClick) {
      deletePersonGroups(deleteIndex);
      setUpdateState();
    }

  };

  return (
    <>
      <PersonGroupsDatasetHistory/>

      <Fieldset>

        <Fieldset.Legend>
          <div className={css(styles.legendTitle)}>Personengruppen</div>

          <Button
            onClick={onAddPersonGroup}
            btnClass={"medium-font-size bold margin-left-auto"}
            size={ButtonSize.Small}
            color={ButtonColor.Ghost}
            label={"Neue Personengruppe anlegen"}
            iconPosition={ButtonIconPosition.Left}>
            <AddIcon
              viewBox='0 0 30 30'
              color={SvgVariant.Secondary}
              titleAccess='Eintrag hinzufügen'
            />
          </Button>

        </Fieldset.Legend>

        <Fieldset.Content>
          {
            !personGroups?.size
              ?
              <div>Keine Personengruppen vorhanden.</div>
              :
              <GridTable>
                <GridTable.Head headers={["Personengruppe", "Objekte"]}/>
                <GridTable.Body rows={
                  personGroups?.map((personGroup, index) =>
                    <PersonGroupRow
                      key={personGroup.id}
                      autoFocus={state.isNew && index === 0}
                      data={personGroup}
                      onDelete={() => onDelete(personGroup.id)}
                      onUpdate={(personGroup) => onUpdate(personGroup)}/>
                  )
                }/>
              </GridTable>
          }
        </Fieldset.Content>

      </Fieldset>

      <DeleteEntryDialog
        onControlClick={onDialogClick}
        showDialog={showDeleteDialog}
        onClose={() => setShowDeleteDialog(false)}
      />
    </>
  );
};

const styles = StyleSheet.create({
  legendTitle: {
    fontSize: "var(--font-size-title)",
    fontWeight: "bold"
  }
});

const mapStateToProps = (state: IState) => {
  return {
    personGroups: selectPersonGroups(state)
  };
};

const mapDispatchToProps = (dispatch: any) => ({
  fetchPersonGroups: async () => {
    const res = await PersonGroupsDataService.fetch();
    dispatch(PersonGroupActions.set(res?.data));
  },
  deletePersonGroups: (id: string) => PersonGroupsDataService.delete(id).then(() => dispatch(PersonGroupActions.delete(id))),
  updatePersonGroups: async (personGroup: IPersonGroupParams) => {
    const res = await PersonGroupsDataService.update(personGroup);
    dispatch(PersonGroupActions.update(res?.data));
  },
  addPersonGroup: async () => {
    const res = await PersonGroupsDataService.add();
    dispatch(PersonGroupActions.create(res?.data));
  }
});

export const PersonGroupsViewWithStore = connect(mapStateToProps, mapDispatchToProps)(PersonGroupsView);
