import { Record } from 'immutable';

export interface IIntroTextsParams {
  id: string;
  title_de: string;
  title_en: string;
  firstTemplate_de: string;
  firstTemplate_en: string;
  secondTemplate_de: string;
  secondTemplate_en: string;
  thirdTemplate_de: string;
  thirdTemplate_en: string;

  postalCodes?: string[]

  updatedAt?: Date;
  updatedByTitle?: string;
}

const defaults: IIntroTextsParams = {
  id: '',
  title_de: '',
  title_en: '',
  firstTemplate_de: '',
  firstTemplate_en: '',
  secondTemplate_de: '',
  secondTemplate_en: '',
  thirdTemplate_de: '',
  thirdTemplate_en: '',
  postalCodes: null,
  updatedAt: null,
  updatedByTitle: null,
};

export class IntroTextModel extends Record<IIntroTextsParams>(defaults) {
  constructor(params?: IIntroTextsParams) {
    params ? super(params) : super();
  }

  with(values: IIntroTextsParams): this {
    return this.merge(values) as this;
  }
}
