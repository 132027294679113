import { Record } from 'immutable';

export interface IRegionsParams {
  id: string;
  title_de: string;
  title_en: string;
  updatedAt?: Date;
  updatedByTitle?: string;
  postalCodes?: string[]
}

const defaults: IRegionsParams = {
  id: '',
  title_de: '',
  title_en: '',
  updatedAt: null,
  updatedByTitle: null,
  postalCodes: null
};

export class RegionModel extends Record<IRegionsParams>(defaults) {
  constructor(params?: IRegionsParams) {
    params ? super(params) : super();
  }

  with(values: IRegionsParams): this {
    return this.merge(values) as this;
  }
}
