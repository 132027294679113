import React, { useEffect, useState } from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import { connect } from "react-redux";
import { IWithRouterProps } from "../../../../shared/interfaces/with-router-props.interface";
import { withRouter } from "react-router-dom";
import { performGetRequest } from "../../../../shared/redux/middleware/api.middleware";
import { ApiEndpoint } from "../../../../shared/constants/api-endpoint.enum";
import { AffiliateActionTypes } from "../../../../shared/redux/affiliate/affiliate.action-types";
import { IState } from "../../../../shared/interfaces/state.interface";
import { FormService } from "../../../../shared/services/form.service";
import { Form } from "../../../form/form";
import { IFieldChangeEvent } from "../../../form/interfaces/field-single.interface";
import { AffiliateTenanciesFormDefinition } from "../../../form/definitions/affiliate/tenancies/affiliate-tenancies.form-definition";
import { selectAffiliateTenancies } from "../../../../shared/redux/affiliate/affiliate.selectors";
import { useUser } from "../../../../contexts/user.context";
import { LoadingStatus } from "../../../../shared/constants/loading-status.enum";
import { HttpService } from "../../../../shared/services/http.service";
import { AffiliateActions } from "../../../../shared/redux/affiliate/affiliate.actions";

interface IAffiliateDetailTenancyViewProps extends IWithRouterProps<{ affiliateId: number }> {
  tenancies: any;
  fetchTenancies: (affiliateId: number) => Promise<any>;
  updateTenancy: (data) => Promise<any>;
}

export const AffiliateDetailTenancyView = React.memo(withRouter((props: IAffiliateDetailTenancyViewProps) => {
  const {
    tenancies,
    fetchTenancies,
    updateTenancy,
    match
  } = props;

  const { getGroups } = useUser();

  const [writePermissions, setWritePermissions] = useState(false);

  useEffect(() => {
    if (getGroups()?.includes("admin") || getGroups()?.includes("editor")) {
      setWritePermissions(true);
    }
  }, [getGroups]);

  const [affiliateId, setAffiliateId] = useState<number>(null);
  const [formDefinition, setFormDefinition] = useState(null);

  useEffect(() => {
    setAffiliateId(match.params.affiliateId);
  }, [match]);

  useEffect(() => {
    if (affiliateId !== null) {
      fetchTenancies(affiliateId).then();
    }
  }, [affiliateId, fetchTenancies]);

  useEffect(() => {
    const formService = new FormService();
    const updatedFormDefinition = formService.updateFormSets(AffiliateTenanciesFormDefinition, { tenancies: tenancies.toJS() }, !writePermissions ? { readonly: true } : {});

    setFormDefinition(updatedFormDefinition);
  }, [tenancies, writePermissions]);

  const handleInputChange = async (changeEvent: IFieldChangeEvent) => {
    if (!writePermissions) {
      return;
    }

    const updateData = changeEvent.value;

    updateTenancy({
      affiliateId,
      [changeEvent.name]: updateData
    });
  };

  return (
    <Container fluid>
      <Row>
        <Form showButtonControls={false}
              refetchSourceData={async () => await fetchTenancies(affiliateId)}
              changeHandler={handleInputChange}
              formDefinition={formDefinition}/>
      </Row>
    </Container>
  );
}));

const mapStateToProps = (state: IState) => {
  return {
    tenancies: selectAffiliateTenancies(state)
  };
};

const mapDispatchToProps = (dispatch: any) => ({
  fetchTenancies: (affiliateId: number) => dispatch(
    performGetRequest(
      `${ApiEndpoint.AFFILIATE}/${affiliateId}/tenancies`,
      AffiliateActionTypes.FETCH_IN_PROGRESS,
      AffiliateActionTypes.SET_AFFILIATE_TENANCIES,
      AffiliateActionTypes.FETCH_FINISHED
    )
  ),
  updateTenancy: async ({ affiliateId, ...data }) => {
    await dispatch({
      type: AffiliateActionTypes.FETCH_IN_PROGRESS,
      loadingStatus: LoadingStatus.IN_PROGRESS
    });

    const res = await HttpService.performPutRequest(`${ApiEndpoint.AFFILIATE}/${affiliateId}/tenancies`, data);

    await dispatch({
      type: AffiliateActionTypes.UPDATE_AFFILIATE_TENANCIES,
      payload: res?.data
    });

    await dispatch({
      type: AffiliateActionTypes.FETCH_FINISHED,
      loadingStatus: LoadingStatus.READY
    });

    dispatch(AffiliateActions.touch(res?.meta?.updatedByTitle));
  }
});

export const AffiliateDetailTenanciesViewWithStore = connect(mapStateToProps, mapDispatchToProps)(AffiliateDetailTenancyView);
