import { useLayoutEffect, useRef } from "react";
import ResizeObserver from "resize-observer-polyfill";

export default function useResizeObserver(el: Element, callback: Function) {
  const observer = useRef(new ResizeObserver(entries => {
    callback(entries);
  }));

  useLayoutEffect(() => {
    if (el instanceof Element) {
      observer?.current.observe(el);
    }

    return () => {
      if (el instanceof Element) {
        observer?.current?.unobserve(el);
      }
    };
  }, [el, observer]);
}
