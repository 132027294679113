import { connect } from 'react-redux';
import { IState } from '../../shared/interfaces/state.interface';
import { PropertyMediaImageActions } from '../../shared/redux/property-media-image/property-media-image.action';
import { selectMediaImageHightlight, selectMediaImageSize } from '../../shared/redux/property-media-image/property-media-image.selectors';
import { PropertyMediaVideoActions } from '../../shared/redux/property-media-video/property-media-video.action';
import { selectMediaVideoHightlight, selectMediaVideoSize } from '../../shared/redux/property-media-video/property-media-video.selectors';
import { PropertyMediaRow } from './property-media-row';

const mapVideoStateToProps = (state: IState) => {
    return {
        highlightId: selectMediaVideoHightlight(state),
        mediaImagesSize: selectMediaVideoSize(state),
    }
};

const mapVideoDispatchToProps = (dispatch: any) => ({
    clearHighlighted: () => dispatch(PropertyMediaVideoActions.clearHighlightId()),
});

export const PropertyMediaRowForVideos = connect(mapVideoStateToProps, mapVideoDispatchToProps)(PropertyMediaRow);

const mapImageStateToProps = (state: IState) => {
    return {
        highlightId: selectMediaImageHightlight(state),
        mediaImagesSize: selectMediaImageSize(state),
    }
};

const mapImageDispatchToProps = (dispatch: any) => ({
    clearHighlighted: () => dispatch(PropertyMediaImageActions.clearHighlightId()),
});

export const PropertyMediaRowForImages = connect(mapImageStateToProps, mapImageDispatchToProps)(PropertyMediaRow);
