import { API } from "aws-amplify";
import { UserRole } from "../constants/user-role.enum";
import { LogService } from "./log.service";
import { FlashMessagesService } from "./flash-messages.service";
import { IUserModel } from "../models/user.model";
import { HttpService } from "./http.service";
import { ApiEndpoint } from "../constants/api-endpoint.enum";

export class UserService {

  static async create({ email, firstName, lastName, role }: IUserModel) {
    const onReject = (error, reject) => {
      FlashMessagesService.sendResponseMessage(400, `Fehler! Etwas hat nicht geklappt.`);
      LogService.error(
        "UserService: The Server responded with an error",
        error
      );
      reject(error);
    };

    return new Promise<any>(async (resolve, reject) => {
      API.post("admin", "/createUser", {
        body: {
          email,
          firstName,
          lastName,
          role
        }
      }).then(() => {
        FlashMessagesService.sendResponseMessage(200, `User ${email} wurde erfolgreich angelegt und eine E-Mail zur Passwortänderung wurde versand.`);
        resolve(true);

        /*
        UserService.setRole(email, role, false).then(() => {
          FlashMessagesService.sendResponseMessage(200, `User ${email} wurde erfolgreich angelegt und eine E-Mail zur Passwortänderung wurde versand.`);

          resolve(true);
        }).catch((error) => {
          onReject(error, reject);
        })*/
      }).catch((error) => {
        onReject(error, reject);
      });
    });
  }

  static async update({ email, firstName, lastName, role }: Partial<IUserModel>) {
    const onReject = (error, reject) => {
      FlashMessagesService.sendResponseMessage(400, `Fehler! Etwas hat nicht geklappt.`);
      LogService.error(
        "UserService: The Server responded with an error",
        error
      );
      reject(error);
    };

    return new Promise<any>(async (resolve, reject) => {
      API.post("admin", "/updateUser", {
        body: {
          email,
          firstName,
          lastName,
          role
        }
      }).then(() => {
        FlashMessagesService.sendResponseMessage(200, `User ${email} wurde erfolgreich bearbeitet.`);
        resolve(true);

        /*
        UserService.setRole(email, role, false).then(() => {
          FlashMessagesService.sendResponseMessage(200, `User ${email} wurde erfolgreich bearbeitet.`);

          resolve(true);
        }).catch((error) => {
          onReject(error, reject);
        })*/
      }).catch((error) => {
        onReject(error, reject);
      });
    });
  }

  static async enable(email: string) {
    return new Promise<any>(async (resolve, reject) => {
      API.post("admin", "/enableUser", {
        body: {
          username: email
        }
      }).then(() => {
        FlashMessagesService.sendResponseMessage(200, `User ${email} wurde erfolgreich freigeschaltet.`);

        resolve(true);
      }).catch((error) => {
        FlashMessagesService.sendResponseMessage(400, `Fehler! Etwas hat nicht geklappt.`);
        LogService.error(
          "UserService: The Server responded with an error",
          error
        );
        reject(error);
      });
    });
  }

  static async disable(email: string) {
    return new Promise<any>(async (resolve, reject) => {
      API.post("admin", "/disableUser", {
        body: {
          email
        }
      }).then(() => {
        FlashMessagesService.sendResponseMessage(200, `User ${email} wurde erfolgreich gesperrt.`);

        resolve(true);
      }).catch((error) => {
        FlashMessagesService.sendResponseMessage(400, `Fehler! Etwas hat nicht geklappt.`);
        LogService.error(
          "UserService: The Server responded with an error",
          error
        );
        reject(error);
      });
    });
  }

  /*
    Sets the role by removing all AWS Cognito groups and adding the group based on the role name.
   */
  static async setRole(email: string, role: UserRole, verbose = true) {
    return new Promise<any>(async (resolve, reject) => {
      UserService.removeAllGroups(email).finally(() => {
        UserService.addGroup(email, role).then(() => {
          if (verbose) {
            FlashMessagesService.sendResponseMessage(200, `Die Role ${role} für User ${email} wurde erfolgreich gesetzt.`);
          }

          resolve(true);
        }).catch((error) => {
          if (verbose) {
            FlashMessagesService.sendResponseMessage(400, `Fehler! Etwas hat nicht geklappt.`);
          }

          LogService.error(
            "UserService: The Server responded with an error",
            error
          );
          reject(error);
        });
      }).catch((error) => {
        if (verbose) {
          FlashMessagesService.sendResponseMessage(400, `Fehler! Etwas hat nicht geklappt.`);
        }

        LogService.error(
          "UserService: The Server responded with an error",
          error
        );
        reject(error);
      });
    });
  }

  static async fetchMeta(): Promise<any> {
    return HttpService.performGetRequest(`${ApiEndpoint.USERS_META}`);
  }

  static async updateMeta(): Promise<any> {
    return HttpService.performPutRequest(`${ApiEndpoint.USERS_META}`, {}, false);
  }

  private static async addGroup(email: string, group: UserRole, verbose = false) {
    return new Promise<any>(async (resolve, reject) => {

      API.post("admin", "/addUserToGroup", {
        body: {
          username: email,
          groupname: group
        }
      }).then(() => {
        if (verbose) {
          FlashMessagesService.sendResponseMessage(200, `Die Gruppe ${group} für User ${email} wurde erfolgreich gesetzt.`);
        }

        resolve(true);
      }).catch((error) => {
        if (verbose) {
          FlashMessagesService.sendResponseMessage(400, `Fehler! Etwas hat nicht geklappt.`);
        }

        LogService.error(
          "UserService: The Server responded with an error",
          error
        );
        reject(error);
      });
    });
  }

  private static async removeAllGroups(email: string, verbose = false) {
    const promises = [];
    UserService.getGroups(email).then((data) => {
      data?.Groups.forEach((group) => {
        promises.push(new Promise((resolve, reject) => {
          UserService.removeGroup(email, group.GroupName, verbose)
            .then(() => resolve(true))
            .catch((error) => reject(error));
        }));

      });
    });
    return Promise.all(promises);
  }

  private static async removeGroup(email: string, group: UserRole, verbose = false) {
    return new Promise<any>(async (resolve, reject) => {

      API.post("admin", "/removeUserFromGroup", {
        body: {
          username: email,
          groupname: group
        }
      }).then(() => {
        if (verbose) {
          FlashMessagesService.sendResponseMessage(200, `Die Role ${group} für User ${email} wurde erfolgreich gesetzt.`);
        }

        resolve(true);
      }).catch((error) => {
        if (verbose) {
          FlashMessagesService.sendResponseMessage(400, `Fehler! Etwas hat nicht geklappt.`);
        }

        LogService.error(
          "UserService: The Server responded with an error",
          error
        );
        reject(error);
      });
    });
  }

  static async delete(email: string) {
    return new Promise<any>(async (resolve, reject) => {
      API.post("admin", "/deleteUser", {
        body: {
          email
        }
      }).then(() => {
        FlashMessagesService.sendResponseMessage(200, `User ${email} wurde erfolgreich gelöscht.`);

        resolve(true);
      }).catch((error) => {
        FlashMessagesService.sendResponseMessage(400, `Fehler! Etwas hat nicht geklappt.`);
        LogService.error(
          "UserService: The Server responded with an error",
          error
        );
        reject(error);
      });
    });
  }

  //todo implement next token and limit
  //todo why static
  static async getList(_limit: number = null, _token: string = null) {
    return API.get("admin", "/listUsers", {});
  }

  static async getGroups(email: string) {
    return API.get("admin", "/listGroupsForUser", {
      queryStringParameters: {
        username: email
      }
    });
  }
}
