import React from "react";
import { GridTable } from "./grid-table";
import { css, StyleSheet } from "aphrodite";
import { CssSize } from "../../shared/helpers/styles.helper";
import { FileType } from "../../shared/constants/file-type.enum";
import { MediaElementModel } from "../../shared/models/media-element.model";
import { List } from "immutable";
import { mediaGridTemplateColumns } from "../../shared/constants/media-grid.const";
import { PropertyMediaRowForImages, PropertyMediaRowForVideos } from "../property-media/property-media-row-variations";

interface IPropertyMediaGridTableProps {
  tableType: FileType;
  mediaList: List<MediaElementModel>;
  onDelete: (media: MediaElementModel) => void;
  onMoveUp: (imageId: string) => void;
  onMoveDown: (imageId: string) => void;
  onUpdate?: (media: MediaElementModel, base64: string) => Promise<Function>;
}

const getHeadersByTableType = (tableType: FileType) => {
  if (tableType === FileType.Image) {
    return ["Bild", "Name", "Größe", "Typ", "Position"];
  }
  return ["Video", "Name", "Größe", "Typ", "Position"];
};

export const PropertyMediaGridTable = React.memo((props: IPropertyMediaGridTableProps) => {
  const {
    tableType,
    mediaList,
    onDelete,
    onMoveDown,
    onMoveUp,
    onUpdate,
  } = props;

  return (
    <GridTable className={css(styles.grid)}>
      <GridTable.Head headers={getHeadersByTableType(tableType)} cssTemplateColumns={mediaGridTemplateColumns}/>
      <GridTable.Body rows={mediaList?.map((mediaElement) => {
        if (tableType === FileType.Image) {
          return (
            <PropertyMediaRowForImages
              type={tableType}
              mediaElement={mediaElement}
              key={mediaElement.id}
              onDelete={onDelete}
              onMoveDown={onMoveDown}
              onMoveUp={onMoveUp}
              onUpdate={onUpdate}
            />
          );
        }

        if (tableType === FileType.Video) {
          return (
            <PropertyMediaRowForVideos
              type={tableType}
              mediaElement={mediaElement}
              key={mediaElement.id}
              onDelete={onDelete}
              onMoveDown={onMoveDown}
              onMoveUp={onMoveUp}
            />
          );
        }

        return (<span>Invalid File Type.</span>);
      })}
      />
    </GridTable>
  );
});

const styles = StyleSheet.create({
  grid: {
    marginBottom: CssSize.s50
  }
});
