import { TemplateType } from '../enums/template-type.enum';
import { IFieldTable } from '../interfaces/field-table.interface';

export abstract class FieldTableConfig {
  private static DEFAULT_CONFIG: IFieldTable = {
    templateType: TemplateType.FIELD_TABLE,
    name: null,
    value: [],
    addLabel: null,
    rowLabel: null,
    tableStructure: null,
    identifier: null,
    hideInputEnumeration: false
  };

  static getConfig(options: Partial<IFieldTable>) {
    return {
      ...FieldTableConfig.DEFAULT_CONFIG,
      ...options
    }
  }
}
