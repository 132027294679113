import { ISelectItem } from '../../components/inputs/interfaces/select-item.interface';

export const PropertyTenantCollectionFilter: ISelectItem[] = [
  { label: 'Alle', isChecked: true, value: '' },
  { label: 'Single', isChecked: false, value: 'single' },
  { label: 'Multi', isChecked: false, value: 'multi' },
].map((item, index) => {
  return {
    id: `property-tenant-${index+1}`,
    ...item
  }
});
