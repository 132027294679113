import { Dispatch } from 'redux';
import { IFilterParam } from '../../interfaces/filter-param.interface';
import {
  resetOfficeAreaFilterAction,
  resetOfficeAreaTypeFilterAction,
  resetPropertiesFilterAction,
  setOfficeAreaTypeFilterAction,
  setPropertiesFilterAction
} from '../properties/properties.action';
import { PropertyFilterKey } from '../../constants/property-filter-key.enum';

export const setPropertiesFilter = (filter: IFilterParam) => {
  return (dispatch: Dispatch) => {
    dispatch(setPropertiesFilterAction(filter));

    if (isAreaTypeFilter(filter.groupKey)) {
      dispatch(resetOfficeAreaFilterAction());
    }

    if (isOfficeAreaFilter(filter.groupKey)) {
      dispatch(setOfficeAreaTypeFilterAction());
    }
  }
}

export const resetPropertiesFilter = (groupKey: string) => {
  return (dispatch: Dispatch) => {
    dispatch(resetPropertiesFilterAction(groupKey));

    if (isAreaTypeFilter(groupKey)) {
      dispatch(resetOfficeAreaFilterAction());
    }

    if (isOfficeAreaFilter(groupKey)) {
      dispatch(resetOfficeAreaTypeFilterAction());
    }
  }
}

const isOfficeAreaFilter = (groupKey: string) => {
  return groupKey === PropertyFilterKey.OFFICE_AREA;
}

const isAreaTypeFilter = (groupKey: string) => {
  return groupKey === PropertyFilterKey.AREA_TYPE;
}
