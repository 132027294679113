import { Record } from 'immutable';


export interface IImageEditorState {
    status: ImageEditorStatus;
    originalImage: HTMLImageElement;
    currentBase64: string;
    currentImage: HTMLImageElement;
    settings: IImageEditorSettings;
    imageInfo: IImageInfo;
}

export enum ImageEditorStatus {
    Initial = 'initial',
    Canvas = 'canvas',
    Cropper = 'cropper',
}

export interface IImageEditorSettings {
    rotation: number;
    contrast: number;
    brightness: number;
}

export enum ImageEditorActionType {
    INITIALIZE = 'INITIALIZE',
    USE_CANVAS = 'USE_CANVAS',
    USE_CROPPER = 'USE_CROPPER',
    SET_CURRENT_BASE64 = 'SET_CURRENT_BASE64',
    SET_CURRENT_IMAGE = 'SET_CURRENT_IMAGE',
    SET_ROTATION = 'SET_ROTATION',
    SET_BRIGHTNESS = 'SET_BRIGHTNESS',
    SET_CONTRAST = 'SET_CONTRAST',
}

export const imageEditorDefaults: IImageEditorState = {
    status: ImageEditorStatus.Initial,
    originalImage: null,
    currentBase64: null,
    currentImage: null,
    settings: {
        rotation: 0,
        brightness: 0, // none => default value of canvas.context.filter
        contrast: 0
    },
    imageInfo: {
        aspectRatio: 0,
        height: 0,
        width: 0,
        isPortaitFormat: false
    },
};

export interface IImageInfo {
    height: number;
    width: number;
    aspectRatio: number;
    isPortaitFormat: boolean;
}

export class ImageEditorState extends Record<IImageEditorState>(imageEditorDefaults) {
    constructor(params?: IImageEditorState) {
        params ? super(params) : super();
    }

    with(values: IImageEditorState): this {
        return this.merge(values) as this;
    }
}
