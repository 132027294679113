import { KeyValuePairs } from '../../../../shared/types/key-value-pairs.type';
import { FieldAddressArrayActionTypes } from './field-address-array.action-types';

export function setAddresses(addresses: KeyValuePairs[]) {
  return {
    type: FieldAddressArrayActionTypes.SET_ADDRESSES,
    addresses
  }
}

export function setPreviousAddresses(previousAddresses: KeyValuePairs[]) {
  return {
    type: FieldAddressArrayActionTypes.SET_PREVIOUS_ADDRESSES,
    previousAddresses
  }
}
