import "cropperjs/dist/cropper.css";
import React, { useCallback, useRef } from "react";
import { default as ReactCropper } from "react-cropper";
import _ from "lodash";

interface ICropperProps {
  src: string;
  height: number;
  width: number;
  onCropHandler?: (base64: string) => void;

  [rest: string]: any;
}

export const Cropper = (props: ICropperProps) => {
  const {
    src,
    height,
    width,
    onCropHandler,
    ...rest
  } = props;

  const base64 = useRef<any>();
  const cropperRef = useRef<any>();

  const onInit = (cropperInstance: Cropper) => {
    cropperRef.current = cropperInstance;
  };

  const onCrop = useCallback(() => {
    _.throttle(() => {
      base64.current = cropperRef.current?.getCroppedCanvas().toDataURL();
      onCropHandler?.(base64.current)
    }, 250)(); //TODO tweak!
  }, [cropperRef, onCropHandler, base64]);

  return (
    <ReactCropper
      src={src}
      style={{ width, height }}
      initialAspectRatio={1}
      viewMode={1}
      guides={true}
      crossOrigin='anonymous'
      background={false}
      responsive={false}
      crop={onCrop}
      onInitialized={onInit}
      {...rest}
    />
  );
};
