import React, {useEffect, useState} from 'react'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import {UserService} from "../../../shared/services/user.service";
import {Button, ButtonColor, ButtonIconPosition, ButtonSize} from "../../button";
import {SvgVariant} from "../../icons/enum/svg-variant.enum";
import {Fieldset} from '../../fieldset/fieldset';
import {css, StyleSheet} from 'aphrodite';
import {AddIcon} from '../../icons/collection/add-icon';
import {IState} from '../../../shared/interfaces/state.interface';
import {connect} from 'react-redux';
import {UsersActions} from '../../../shared/redux/users/users.actions';
import {selectIsUsersLoading, selectUsers} from '../../../shared/redux/users/users.selector';
import {List} from 'immutable';
import {IUserModel, UserModel} from '../../../shared/models/user.model';
import {UserTableRowWithStore} from '../../users/user-table-row';
import {UserDatasetHistory} from '../../dataset-history/user-dataset-history';
import {Dispatch} from "redux";
import {SortOrder} from "../../../shared/constants/sort-order.enum";
import {TableContext} from "../../../contexts/table.context";
import {Table} from "../../table/table";
import {CssSize} from "../../../shared/helpers/styles.helper";
import {LoadingStatus} from "../../../shared/constants/loading-status.enum";
import {UserHeadersConfig} from "../../../shared/table-headers/user-headers.config";

interface IUserListViewProps {
  users: List<UserModel>;
  fetchUsers: () => void;
  fetchMeta: () => void;
  sortUsers: (sortByColumn: string, sortOrder: SortOrder) => void;
  addEmptyUser: () => void;
  loadingStatus: LoadingStatus;
}

export const UserListView = (props: IUserListViewProps) => {
  const {
    users,
    fetchUsers,
    fetchMeta,
    sortUsers,
    addEmptyUser,
    loadingStatus
  } = props;

  const [sortByColumn, setSortByColumn] = useState('email');
  const [sortOrder, setSortOrder] = useState(SortOrder.DESC);

  useEffect(() => {
    if (!users?.size) {
      fetchUsers();
      sortUsers(sortByColumn, sortOrder);
      fetchMeta();
    }
  }, [users, fetchUsers, sortUsers, fetchMeta, sortByColumn, sortOrder]);

  useEffect(() => {
    sortUsers(sortByColumn, sortOrder);
  }, [sortUsers, sortByColumn, sortOrder]);

  const hasSortByColumnChanged = (column: string): boolean => {
    return sortByColumn !== column;
  }

  const resetSortOrder = (): void => {
    setSortOrder(SortOrder.DESC);
  }

  const toggleSortOrder = (): void => {
    setSortOrder(sortOrder === SortOrder.ASC ? SortOrder.DESC : SortOrder.ASC);
  }

  const onSortChange = (column: string): void => {
    hasSortByColumnChanged(column) ? resetSortOrder() : toggleSortOrder();
    setSortByColumn(column);
  }

  return (
    <>
      <Row>
        <Col>
          <UserDatasetHistory/>
        </Col>
      </Row>
      <Row>
        <Col>
          <Fieldset>

            <Fieldset.Legend>
              <div className={css(styles.legendTitle)}>Users & Logins</div>
              <Button
                onClick={() => addEmptyUser()}
                btnClass={'medium-font-size bold margin-left-auto'}
                size={ButtonSize.Small}
                color={ButtonColor.Ghost}
                label={'Neuen User anlegen'}
                iconPosition={ButtonIconPosition.Left}>
                <AddIcon
                  viewBox='0 0 30 30'
                  color={SvgVariant.Secondary}
                  titleAccess='Eintrag hinzufügen'
                />
              </Button>
            </Fieldset.Legend>
            <Fieldset.Content>
              <TableContext.Provider value={{
                tableTitle: 'Users & Logins',
                headers: UserHeadersConfig,
                setSortByColumn: onSortChange,
                sortByColumn: sortByColumn,
                sortOrder: sortOrder,
                rows: users,
                loadingStatus,
                xScrollable: false
              }}>
                <Table
                  striped={false}
                  tableClassName={css(styles.table)}
                  tbodyClassName={css(styles.tbody)}>
                  {users?.map((user, index) =>
                    <UserTableRowWithStore key={user?.email || index}
                                           index={index}
                                           autoFocus={!user?.email}
                                           userModel={user}/>
                  )}</Table>
              </TableContext.Provider>
            </Fieldset.Content>
          </Fieldset>
        </Col>
      </Row>
    </>
  );
}

const styles: any = StyleSheet.create({
  legendTitle: {
    fontSize: 'var(--font-size-title)',
    fontWeight: 'bold',
  },
  table: {
    width: '100%',
    userSelect: 'none',
    margin: `${CssSize.s40} -10px 0`,
  },
});

const mapStateToProps = (state: IState) => {
  return {
    users: selectUsers(state),
    loadingStatus: selectIsUsersLoading(state),
  }
};

const mapDispatchToProps = (dispatch: Dispatch) => ({
  fetchMeta: async () => {
    const res = await UserService.fetchMeta();
    dispatch(UsersActions.setUsersMeta(res?.data?.updatedAt, res?.data?.updatedByTitle));
  },
  fetchUsers: async () => {
    dispatch(UsersActions.startFetching());
    const data = await UserService.getList();
    dispatch(UsersActions.setUsers(data as IUserModel[]))
  },
  sortUsers: (sortByColumn: string, sortOrder: SortOrder) => dispatch(UsersActions.sortUsers(sortByColumn, sortOrder)),
  addEmptyUser: () => dispatch(UsersActions.addEmpty())
});

export const UserListViewWithStore = connect(mapStateToProps, mapDispatchToProps)(UserListView);
