import { useState, useEffect } from 'react';
import { FileService } from "../shared/services/file-service";

export function useFile(s3Key: string) {
  const [file, setFile] = useState<string>();

  useEffect(() => {
    FileService.get(s3Key)
      .then(result => {
        if (typeof result === "string") {
          setFile(result);
        }
      })
      .catch(err => console.error(err));

  }, [s3Key]);

  return file;
}
