import React, { useEffect, useState } from "react";
import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import { connect } from "react-redux";
import { useHistory, withRouter } from "react-router-dom";
import { IWithRouterProps } from "../../../shared/interfaces/with-router-props.interface";
import { performGetRequest } from "../../../shared/redux/middleware/api.middleware";
import { PropertyActionTypes } from "../../../shared/redux/property/property.action-types";
import { HttpService } from "../../../shared/services/http.service";
import { LogService } from "../../../shared/services/log.service";
import { ControlsHeader } from "../../controls-header/controls-header";
import { BinIcon } from "../../icons/collection/bin-icon";
import { SkyscraperIcon } from "../../icons/collection/skyscraper-icon";
import { PropertyInfoBadgeWithStore } from "../../info-badge/property-info-badge";
import { StatusListWithStore } from "../../status-list/status-list";
import { Tab, Tabs } from "../../tabs";
import { PropertyDetailInfoViewWithStore } from "./property-detail-info/property-detail-info.view";
import { Button, ButtonColor, ButtonIconPosition } from "../../button";
import { Dialog, DialogControl } from "../../overlay/dialog";
import { SvgVariant } from "../../icons/enum/svg-variant.enum";
import { PropertyDatasetHistory } from "../../dataset-history/property-dataset-history";
import { PropertyDetailTextView } from "./property-detail-text/property-detail-text.view";
import { PropertyDetailMediaView } from "./property-detail-media/property-detail-media.view";
import { ApiEndpoint } from "../../../shared/constants/api-endpoint.enum";
import { css, StyleSheet } from "aphrodite/no-important";
import { TableIcon } from "../../icons/collection/table-icon";
import { useUser } from "../../../contexts/user.context";
import { IState } from "../../../shared/interfaces/state.interface";
import { selectIsLoading, selectPropertyWithAddress } from "../../../shared/redux/property/property.selectors";
import { PropertyModel } from "../../../shared/models/property.model";
import { Loader } from "../../loader/loader";

interface IPropertyDetailViewProps extends IWithRouterProps<{ propertyId: number }> {
  fetchPropertyById: (path: string) => any;
  property: PropertyModel;
  isLoading: any;
}

export const PropertyDetailView = React.memo(withRouter((props: IPropertyDetailViewProps) => {
  const {
    match,
    fetchPropertyById,
    property,
    isLoading,
  } = props;
  const [propertyId, setPropertyId] = useState<number>(null);
  const [showDeleteDialog, setShowDeleteDialog] = useState(false);
  const [activeTab, setActiveTab] = useState("Info");
  const history = useHistory();
  const { getGroups } = useUser();

  const [writePermissions, setWritePermissions] = useState(false);

  useEffect(() => {
    if (getGroups()?.includes("admin") || getGroups()?.includes("editor")) {
      setWritePermissions(true);
    }
  }, [getGroups]);

  useEffect(() => {
    setPropertyId(match.params.propertyId);
  }, [match]);

  useEffect(() => {
    if (propertyId !== null) {
      fetchPropertyById(`${ApiEndpoint.PROPERTY}/${propertyId}`).then();
    }

    return
  }, [propertyId, fetchPropertyById]);

  const onDialogControlClick = async (submitDelete: boolean) => {
    if (submitDelete && writePermissions) {
      try {
        await HttpService.performDeleteRequest(`${ApiEndpoint.PROPERTY}/${propertyId}`);
        history.push("/properties");
      } catch (error) {
        LogService.error("onDialogControlClick: Http request failed.", error);
      }
    }
    setShowDeleteDialog(false);
  };


  const getContentByTab = (tab: string) => {
    if (tab === "Info") {
      return <PropertyDetailInfoViewWithStore/>;
    }

    if (tab === "Texte") {
      return <PropertyDetailTextView/>;
    }

    if (tab === "Medien") {
      return <PropertyDetailMediaView/>;
    }

    return (<h2>No Content for: {tab}</h2>);
  };

  return (
    <>
      {
        showDeleteDialog && writePermissions &&
        <Dialog
          title='Sind Sie sicher?'
          onClose={() => setShowDeleteDialog(false)}
        >
          <Dialog.Message>
            Wenn sie auf „Ja“ klicken wird der vollständige Datensatz<br/>
            gelöscht und kann nicht wiederhergestellt werden.
          </Dialog.Message>
          <Dialog.Controls>
            <DialogControl label='Abbrechen' value={false} color={ButtonColor.Basic}
                           onClick={onDialogControlClick}></DialogControl>
            <DialogControl label='Löschen' value={true} color={ButtonColor.Danger}
                           onClick={onDialogControlClick}></DialogControl>
          </Dialog.Controls>
        </Dialog>
      }
      <>{!!property?.id && <Container fluid className='property-detail-view' style={{ paddingTop: "30px" }}>
        <Row>
          <Col className={css(styles.overflowWrap)}>
            <ControlsHeader>
              <div className={css(styles.badgeWrapper)}>
                <Button
                  btnClass={css(styles.marginRight)}
                  label='Übersicht'
                  color={ButtonColor.Default}
                  iconPosition={ButtonIconPosition.Left}
                  onClick={() => history.push("/properties")}>
                  <TableIcon
                    viewBox='0 0 40 40'
                    color={SvgVariant.Secondary}
                    titleAccess={"Zur Übersicht"}
                  />
                </Button>
                <PropertyInfoBadgeWithStore>
                  <SkyscraperIcon viewBox={"0 0 40 40"} titleAccess={"Objekt Info Badge"}></SkyscraperIcon>
                </PropertyInfoBadgeWithStore>
              </div>
              <Tabs
                controlsOnly={true}
                onSelect={(tab) => setActiveTab(tab)}
                activeTab={activeTab}
              >
                <Tab label='Info'></Tab>
                <Tab label='Texte'></Tab>
                <Tab label='Medien'></Tab>
              </Tabs>

              <>
                <StatusListWithStore/>
                {writePermissions && <Button
                  label='Datensatz löschen'
                  color={ButtonColor.Default}
                  iconPosition={ButtonIconPosition.Left}
                  onClick={() => setShowDeleteDialog(true)}
                >
                  <BinIcon
                    viewBox='0 0 40 40'
                    color={SvgVariant.Secondary}
                    titleAccess={"Datensatz löschen"}
                  />
                </Button>}
              </>
            </ControlsHeader>
          </Col>
        </Row>
        <Row>
          <Col className={css(styles.overflowWrap)}>
            <PropertyDatasetHistory/>
          </Col>
        </Row>
        <Row>
          <Col className={css(styles.overflowWrap)}>
            {getContentByTab(activeTab)}
          </Col>
        </Row>
      </Container>}
        {(!!property?.id && !!isLoading) && <Loader/>}
        {!property?.id && !isLoading && <h3>404 Page not found</h3>}
      </>
    </>
  );
}));

const styles = StyleSheet.create({
  overflowWrap: {
    overflowWrap: "anywhere"
  },
  badgeWrapper: {
    display: "flex",
    alignItems: "center"
  },
  marginRight: {
    marginRight: 10
  }
});

const mapStateToProps = (state: IState) => {
  return {
    property: selectPropertyWithAddress(state),
    isLoading: selectIsLoading(state)
  };
};

const mapDispatchToProps = (dispatch: any) => ({
  fetchPropertyById: (path: string) => dispatch(
    performGetRequest(
      path,
      PropertyActionTypes.FETCH_IN_PROGRESS,
      PropertyActionTypes.SET_PROPERTY,
      PropertyActionTypes.FETCH_FINISHED
    )
  )
});

export const PropertyDetailViewWithStore = connect(mapStateToProps, mapDispatchToProps)(PropertyDetailView);
