import { Storage } from "@aws-amplify/storage";
import { basename, dirname } from "../utils/path";

export class FileService {

  static async get(key: string) {
    return Storage.get(key);
  }

  static async put(key: string, object, fileConfig?) {
    return Storage.put(key, object, fileConfig);
  }

  static async remove(key: string) {
    return Storage.remove(key)
  }

  static getUniqueName(name: string) {
    return Math.random().toString(36).substr(2, 9) + "_" + name;
  }

  static getThumbnailsKey(key) {
    const dirName = dirname(key) ?? "";
    const baseName = basename(key);

    return dirName + "thumbnail/" + baseName
  }

}
