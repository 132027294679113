import React, { useEffect, useState } from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import { connect } from "react-redux";
import { IWithRouterProps } from "../../../../shared/interfaces/with-router-props.interface";
import { withRouter } from "react-router-dom";
import { performGetRequest } from "../../../../shared/redux/middleware/api.middleware";
import { ApiEndpoint } from "../../../../shared/constants/api-endpoint.enum";
import { AffiliateActionTypes } from "../../../../shared/redux/affiliate/affiliate.action-types";
import { IState } from "../../../../shared/interfaces/state.interface";
import { selectAffiliateOwners } from "../../../../shared/redux/affiliate/affiliate.selectors";
import { FormService } from "../../../../shared/services/form.service";
import { AffiliateOwnersFormDefinition } from "../../../form/definitions/affiliate/owners/affiliate-owners.form-definition";
import { Form } from "../../../form/form";
import { IFieldChangeEvent } from "../../../form/interfaces/field-single.interface";
import { useUser } from "../../../../contexts/user.context";
import { AffiliateActions } from "../../../../shared/redux/affiliate/affiliate.actions";
import { LoadingStatus } from "../../../../shared/constants/loading-status.enum";
import { HttpService } from "../../../../shared/services/http.service";

interface IAffiliateDetailOwnerViewProps extends IWithRouterProps<{ affiliateId: number }> {
  owners: any;
  fetchOwners: (affiliateId: number) => Promise<any>;
  updateOwner: (data) => Promise<any>;
}

export const AffiliateDetailOwnerView = React.memo(withRouter((props: IAffiliateDetailOwnerViewProps) => {
  const {
    owners,
    fetchOwners,
    updateOwner,
    match
  } = props;

  const { getGroups } = useUser();

  const [writePermissions, setWritePermissions] = useState(false);

  useEffect(() => {
    if (getGroups()?.includes("admin") || getGroups()?.includes("editor")) {
      setWritePermissions(true);
    }
  }, [getGroups]);

  const [affiliateId, setAffiliateId] = useState<number>(null);
  const [formDefinition, setFormDefinition] = useState(null);

  useEffect(() => {
    setAffiliateId(match.params.affiliateId);
  }, [match]);

  useEffect(() => {
    if (affiliateId !== null) {
      fetchOwners(affiliateId).then();
    }
  }, [affiliateId, fetchOwners]);

  useEffect(() => {
    const formService = new FormService();
    const updatedFormDefinition = formService.updateFormSets(AffiliateOwnersFormDefinition, { owners: owners.toJS() }, !writePermissions ? { readonly: true } : {});

    setFormDefinition(updatedFormDefinition);
  }, [owners, writePermissions]);

  const handleInputChange = async (changeEvent: IFieldChangeEvent) => {
    if (!writePermissions) {
      return;
    }

    const updateData = changeEvent.value;

    updateOwner({
      affiliateId,
      [changeEvent.name]: updateData
    });
  };

  return (
    <Container fluid>
      <Row>
        <Form showButtonControls={false}
              refetchSourceData={async () => await fetchOwners(affiliateId)}
              changeHandler={handleInputChange}
              formDefinition={formDefinition}/>
      </Row>
    </Container>
  );
}));

const mapStateToProps = (state: IState) => {
  return {
    owners: selectAffiliateOwners(state)
  };
};

const mapDispatchToProps = (dispatch: any) => ({
  fetchOwners: (affiliateId: number) => dispatch(
    performGetRequest(
      `${ApiEndpoint.AFFILIATE}/${affiliateId}/owners`,
      AffiliateActionTypes.FETCH_IN_PROGRESS,
      AffiliateActionTypes.SET_AFFILIATE_OWNERS,
      AffiliateActionTypes.FETCH_FINISHED
    )
  ),
  updateOwner: async ({ affiliateId, ...data }) => {

    await dispatch({
      type: AffiliateActionTypes.FETCH_IN_PROGRESS,
      loadingStatus: LoadingStatus.IN_PROGRESS
    });

    const res = await HttpService.performPutRequest(`${ApiEndpoint.AFFILIATE}/${affiliateId}/owners`, data);

    await dispatch({
      type: AffiliateActionTypes.UPDATE_AFFILIATE_OWNERS,
      payload: res?.data
    });

    await dispatch({
      type: AffiliateActionTypes.FETCH_FINISHED,
      loadingStatus: LoadingStatus.READY
    });

    dispatch(AffiliateActions.touch(res?.meta?.updatedByTitle));
  }

});

export const AffiliateDetailOwnerViewWithStore = connect(mapStateToProps, mapDispatchToProps)(AffiliateDetailOwnerView);
