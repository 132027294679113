import { useLayoutEffect } from 'react';
import { fromEvent } from 'rxjs';
import { debounceTime, switchMap } from 'rxjs/operators';

/**
 * Custom Hook to listen to window resize
 * inspired by @MarcoTraspel
 * @param callback
 */
export function useWindowResize(callback: (...props) => void) {

  useLayoutEffect(() => {
    const windowSub = fromEvent(window, 'pageshow')
      .pipe(
        switchMap(() => {
          callback?.();

          return fromEvent(window, 'resize');
        }),
        debounceTime(300)
      ).subscribe(() => callback?.());

    return () => {
      windowSub.unsubscribe();
    };
  }, [callback])

}
