import React, { Props } from 'react'
import { FieldsetLegend } from './fieldset-legend';
import { FieldsetContent } from './fieldset-content';
import {css, StyleSheet} from "aphrodite";

const Fieldset = (props: Props<any>) => {
  const {
      children
    } = props;

  return (
    <fieldset className={css(styles.fieldset)}>
      {children}
    </fieldset>
  );
}

const styles = StyleSheet.create({
  fieldset: {
    marginBottom: 'var(--size-30)',
  },
});

Fieldset.Legend = FieldsetLegend;
Fieldset.Content = FieldsetContent;

export { Fieldset };
