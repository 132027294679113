import { KeyValuePairs } from '../../../../shared/types/key-value-pairs.type';
import { FieldTabArrayActionTypes } from './field-tab-array.action-types';

export interface IFieldTabArrayState {
  activeTab: number;
  activeTabContent: KeyValuePairs;
  data: KeyValuePairs[];
  previousData: KeyValuePairs[];
  lastAddedData: KeyValuePairs;
}

export function fieldTabArrayReducer(state: IFieldTabArrayState, action: KeyValuePairs) {
  switch (action.type) {
    case FieldTabArrayActionTypes.SET_ACTIVE_TAB:
      return {
        ...state,
        activeTab: action.activeTab
      }
    case FieldTabArrayActionTypes.SET_ACTIVE_TAB_CONTENT:
      return {
        ...state,
        activeTabContent: action.activeTabContent
      }
    case FieldTabArrayActionTypes.SET_DATA:
      return {
        ...state,
        data: action.data
      }
    case FieldTabArrayActionTypes.SET_PREVIOUS_DATA:
      return {
        ...state,
        previousData: action.previousData
      }
    case FieldTabArrayActionTypes.SET_LAST_ADDED_DATA:
      return {
        ...state,
        lastAddedData: action.lastAddedData
      }
    default:
      return state;
  }
}
