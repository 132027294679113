export enum UserRole {
  Unknown = '',
  Admin = 'admin',
  Editor = 'editor',
  Spotter = 'spotter',
  ProspectiveCustomer = 'prospectiveCustomer'
}

export const UserRoles = [
  UserRole.Unknown,
  UserRole.Admin,
  UserRole.Editor,
  UserRole.Spotter,
  UserRole.ProspectiveCustomer
]

export const UserRolesOptions = [
  {
    label: 'Bitte Rechte vergeben',
    value: UserRole.Unknown
  },
  {
    label: 'Admin',
    value: UserRole.Admin
  },
  {
    label: 'Redakteur',
    value: UserRole.Editor
  },
  {
    label: 'Interessent',
    value: UserRole.ProspectiveCustomer
  },
  {
    label: 'Spotter',
    value: UserRole.Spotter
  }
];

