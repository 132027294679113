import { Auth } from "aws-amplify";

export const awsconfig = {
  "aws_project_region": "eu-central-1",
  "aws_cognito_identity_pool_id": process.env.REACT_APP_AWS_COGNITO_IDENTITY_POOL_ID,
  "aws_cognito_region": "eu-central-1",
  "aws_user_pools_id": process.env.REACT_APP_AWS_USER_POOLS_ID,
  "aws_user_pools_web_client_id": process.env.REACT_APP_AWS_USER_POOLS_WEB_CLIENT_ID,
  Storage: {
    AWSS3: {
      bucket: process.env.REACT_APP_AWS_S3_BUCKET,
      region: "eu-central-1",
    }
  },
  "aws_cloud_logic_custom": [
    {
      "name": "api",
      "endpoint": process.env.REACT_APP_API_HOST,
      "region": "eu-central-1",
      "custom_header": async () => {
        return { Authorization: `Bearer ${(await Auth.currentSession()).getIdToken().getJwtToken()}` };
      }
    },
    {
      "name": "admin",
      "endpoint": process.env.REACT_APP_ADMIN_HOST,
      "region": "eu-central-1",
      "custom_header": async () => {
        return { Authorization: `Bearer ${(await Auth.currentSession()).getIdToken().getJwtToken()}` };
      }
    }
  ]
};

export default awsconfig;
