import { IState } from '../../interfaces/state.interface';
import { fromJS, List } from 'immutable';
import { IReducer } from '../../interfaces/reducer.interface';
import { IAction } from '../../interfaces/action.interface';
import { PropertyMediaVideoActionTypes } from './property-media-video.action-types';
import { mergeState } from '../../utils/reducer';
import { MediaElementModel } from '../../models/media-element.model';
import { getLatestUpdatedEntry } from '../../helpers/lastest-updated-at.helper';
import { IPropertyMedia } from '../../interfaces/property-media.interface';
import { PropertyMediaStoreHelper } from '../property-media-image/property-media-image-store.helper';

export enum PropertyMediaStatus {
  Initial = 'initial',
  Completed = 'completed',
}

const jsonToMediaElementModels = (videos: IPropertyMedia[]): MediaElementModel[] => {
  return videos?.map(data => new MediaElementModel(data));
}

const initialState: IState = fromJS({
  videos: List<MediaElementModel>(),
  updatedAt: null,
  status: PropertyMediaStatus.Initial,
  highlightId: null,
});

export const propertyMediaVideoReducer: IReducer = (state = initialState, action: IAction) => {
  switch (action.type) {
    case PropertyMediaVideoActionTypes.SET_MEDIA_VIDEO_LIST:
      const latestUpdatedVideo = getLatestUpdatedEntry(action.payload?.slice()); // clean copy via slice, as sort modifies the original array
      return mergeState(state, {
        updatedAt: latestUpdatedVideo?.updatedAt ?? null,
        videos: List<MediaElementModel>(jsonToMediaElementModels(action.payload)),
        status: PropertyMediaStatus.Completed,
      });

    case PropertyMediaVideoActionTypes.SAVE_MEDIA_VIDEOS:
      const savedVideos = List<MediaElementModel>([...jsonToMediaElementModels(action.payload), ...state.get('videos')]);
      return mergeState(state, {
        updatedAt: new Date(),
        videos: PropertyMediaStoreHelper.recalculatePositions(savedVideos),
        status: PropertyMediaStatus.Completed,
      });

    case PropertyMediaVideoActionTypes.DELETE_VIDEO:
      const videoList = state.get('videos') as List<MediaElementModel>;
      const deleteIndex = videoList.findIndex(element => element.id === action.payload);
      const updatedVideos = state.get('videos').delete(deleteIndex);
      return mergeState(state, {
        updatedAt: new Date(),
        videos: PropertyMediaStoreHelper.recalculatePositions(updatedVideos),
        status: PropertyMediaStatus.Completed,
      });

    case PropertyMediaVideoActionTypes.UPDATE_POSITION:
      return mergeState(state, {
        updatedAt: new Date(),
        videos: PropertyMediaStoreHelper.updatePosition(action.payload, state.get('videos') as List<MediaElementModel>),
        status: PropertyMediaStatus.Completed,
        highlightId: action.payload.mediaId
      });

    case PropertyMediaVideoActionTypes.CLEAR_HIGHLIGHTED:
      return mergeState(state, { highlightId: null });

    default:
      return state;
  }
}
