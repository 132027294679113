import { IState } from '../../shared/interfaces/state.interface';
import { selectPropertyUpdatedAt, selectPropertyUpdatedBy } from '../../shared/redux/property/property.selectors';
import { DatasetHistory } from './dataset-history';
import { connect } from 'react-redux';

const mapStateToProps = (state: IState) => {
  return {
    updatedAt: selectPropertyUpdatedAt(state),
    updatedBy: selectPropertyUpdatedBy(state)
  };
};

export const PropertyDatasetHistory = connect(mapStateToProps, null)(DatasetHistory);