import { ApiEndpoint } from '../constants/api-endpoint.enum';
import { HttpService } from './http.service';
import { IPersonGroupParams } from '../models/person-group.model';

export class PersonGroupsDataService {

  static async fetch(): Promise<any> {
    return HttpService.performGetRequest(`${ApiEndpoint.PERSON_GROUPS}`);
  }

  static async add(): Promise<any> {
    return HttpService.performPostRequest(`${ApiEndpoint.PERSON_GROUPS}`, null);
  }

  static async update(data: IPersonGroupParams): Promise<any> {
    return HttpService.performPutRequest(`${ApiEndpoint.PERSON_GROUPS}`, data);
  }

  static async delete(id: string): Promise<any> {
    return HttpService.performDeleteRequest(`${ApiEndpoint.PERSON_GROUPS}/${id}`)
  }

  static async addProperty(personGroupId: string, propertyId: string): Promise<any> {
    return HttpService.performPostRequest(`${ApiEndpoint.PERSON_GROUPS}/${personGroupId}/properties/`, { propertyId });
  }

  static async removeProperty(personGroupId: string, propertyId: string): Promise<any> {
    return HttpService.performDeleteRequest(`${ApiEndpoint.PERSON_GROUPS}/${personGroupId}/properties/${propertyId}`)
  }
}
