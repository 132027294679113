import { Record } from 'immutable';
import { IAction } from '../../../../shared/interfaces/action.interface';
import { useReducer } from 'react';

/*
This custom hook is a plain copy of the useIntroTextsState. Please consider generalizing the implementation and re-use properly.
*/


enum PersonGroupsStatus {
    initial = 'initial',
    createNew = 'createNew',
    ready = 'ready',
}

interface IPersonGroupStateParams {
    status?: PersonGroupsStatus;
}

enum PersonGroupsStateActionTypes {
    Set = 'Set',
    CreateNew = 'CreateNew',
}

const defaults: IPersonGroupStateParams = {
    status: PersonGroupsStatus.initial,
};

class PersonGroupState extends Record<IPersonGroupStateParams>(defaults) {
    constructor(params?: IPersonGroupStateParams) {
        params ? super(params) : super();
    }

    with(values: IPersonGroupStateParams): this {
        return this.merge(values) as this;
    }
}

function reducer(state: PersonGroupState, action: IAction): PersonGroupState {
    switch (action.type) {
        case PersonGroupsStateActionTypes.Set:
            return new PersonGroupState({
                ...state,
                status: PersonGroupsStatus.ready
            });


        case PersonGroupsStateActionTypes.CreateNew:
            return new PersonGroupState({
                ...state,
                status: PersonGroupsStatus.createNew
            });

        default: return state;
    }
}

abstract class PersonGroupsStateActions {
    static get set() { return { type: PersonGroupsStateActionTypes.Set }; }
    static get add() { return { type: PersonGroupsStateActionTypes.CreateNew }; }
}

const initialPersonGroupState: PersonGroupState = new PersonGroupState(defaults);

export function usePersonGroupState() {
    const [state, dispatch] = useReducer(reducer, initialPersonGroupState);

    const isInitial = state.status === PersonGroupsStatus.initial;
    const isNew = state.status === PersonGroupsStatus.createNew;
    const isReady = state.status === PersonGroupsStatus.ready;

    return {
        state: { isInitial, isNew, isReady, ...state },
        setCreateNewState: () => dispatch(PersonGroupsStateActions.add),
        setUpdateState: () => dispatch(PersonGroupsStateActions.set)
    };
}