import { List } from "immutable";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { FileType } from "../../shared/constants/file-type.enum";
import { IPropertyMedia } from "../../shared/interfaces/property-media.interface";
import { IState } from "../../shared/interfaces/state.interface";
import { MediaElementModel } from "../../shared/models/media-element.model";
import { PropertyMediaImageActions } from "../../shared/redux/property-media-image/property-media-image.action";
import {
  selectPropertyMediaImages,
  selectPropertyMediaImageUpdatedAt
} from "../../shared/redux/property-media-image/property-media-image.selectors";
import { selectPropertyId } from "../../shared/redux/property/property.selectors";
import { LogService } from "../../shared/services/log.service";
import { MediaDataService, MoveMediaPosition } from "../../shared/services/media-data.service";
import { Fieldset } from "../fieldset/fieldset";
import { S3FileUpload } from "../file-upload/s3-file-upload";
import { PropertyMediaGridTable } from "../grid-table/media-grid-table";
import { PropertyMediaHeadline } from "./property-media-headline";
import { DeleteEntryDialog } from "../overlay/dialog/variants/delete-entry-dialog";
import { FileService } from "../../shared/services/file-service";
import { sizeOfBase64 } from "../../shared/utils/file";
import { PropertyActions } from "../../shared/redux/property/property.actions";
import { useUser } from "../../contexts/user.context";

interface IPropertyMediaImagesProps {
  propertyId: number;
  mediaList: List<MediaElementModel>;
  updatedAt?: Date | string;
  fetchPropertyMediaList: (propertyId: number) => void;
  savePropertyImages: (images: IPropertyMedia[], propertyId) => void;
  updateImage: (image: IPropertyMedia, base64: string) => Promise<Function>;
  deleteImage: (image: IPropertyMedia, propertyId: number) => void; //TODO remove propertyId from action
  updatePosition: (move: MoveMediaPosition, imageId: string, propertyId: number) => void;
}

export const PropertyMediaImages = (props: IPropertyMediaImagesProps) => {
  const {
    propertyId,
    mediaList,
    updatedAt,
    fetchPropertyMediaList,
    savePropertyImages,
    updateImage,
    deleteImage,
    updatePosition
  } = props;

  const { getGroups } = useUser();

  const [writePermissions, setWritePermissions] = useState(false);

  useEffect(() => {
    if (getGroups()?.includes("admin") || getGroups()?.includes("editor")) {
      setWritePermissions(true);
    }
  }, [getGroups]);

  useEffect(() => {
    fetchPropertyMediaList(propertyId);
  }, [fetchPropertyMediaList, propertyId]);

  const [showDeleteDialog, setShowDeleteDialog] = useState(false);
  const [deleteMedia, setDeleteMedia] = useState(null);

  const saveImages = async (images: IPropertyMedia[]) => {
    if(!writePermissions) {
      return;
    }

    try {
      savePropertyImages(images, propertyId);
    } catch (error) {
      LogService.error("Unable to save images", error);
    }
  };

  const onUpdate = async (media: MediaElementModel, base64: string): Promise<Function> => {
    if(!writePermissions) {
      return;
    }

    return updateImage(media, base64);
  };

  const onDelete = async (media: MediaElementModel) => {
    if(!writePermissions) {
      return;
    }

    setShowDeleteDialog(true);
    setDeleteMedia(media);
  };

  const onMoveUp = async (imageId: string) => {
    if(!writePermissions) {
      return;
    }

    updatePosition(MoveMediaPosition.Up, imageId, propertyId);
  };

  const onMoveDown = async (imageId: string) => {
    if(!writePermissions) {
      return;
    }

    updatePosition(MoveMediaPosition.Down, imageId, propertyId);
  };

  const onDialogClick = async (isDeleteClick: boolean) => {
    if(!writePermissions) {
      return;
    }

    setShowDeleteDialog(false);

    if (!isDeleteClick) return;

    try {
      await deleteImage(deleteMedia, propertyId);
    } catch (error) {
      LogService.error("Unable to delete image", error);
    }
  };

  return (
    <>
      <Fieldset>
        <Fieldset.Legend>
          <PropertyMediaHeadline title='Bilder' lastEditedAt={updatedAt}/>
          {writePermissions && <S3FileUpload
            onChange={saveImages}
            type={FileType.Image}
            label='Bild hochladen'
          />}
        </Fieldset.Legend>

        <Fieldset.Content>
          <PropertyMediaGridTable
            tableType={FileType.Image}
            mediaList={mediaList}
            onDelete={onDelete}
            onMoveDown={onMoveDown}
            onMoveUp={onMoveUp}
            onUpdate={onUpdate}
          />
        </Fieldset.Content>
      </Fieldset>


      <DeleteEntryDialog
        onControlClick={onDialogClick}
        showDialog={showDeleteDialog}
        onClose={() => setShowDeleteDialog(false)}
      >
        Wenn sie auf „Löschen“ klicken wird das Bild<br/>
        gelöscht und kann nicht wiederhergestellt werden.
      </DeleteEntryDialog>
    </>
  );
};

const mapStateToProps = (state: IState) => {
  return {
    propertyId: selectPropertyId(state),
    mediaList: selectPropertyMediaImages(state),
    updatedAt: selectPropertyMediaImageUpdatedAt(state)
  };
};

const mapDispatchToProps = (dispatch: any) => ({
  fetchPropertyMediaList: async (propertyId: number) => {
    const res = await MediaDataService.fetchImagesByPropertyId(propertyId);
    dispatch(PropertyMediaImageActions.setImages(res?.data));
  },

  savePropertyImages: async (images: IPropertyMedia[], propertyId) => {
    const res = await MediaDataService.saveImagesByPropertyId(images, propertyId);

    dispatch(PropertyMediaImageActions.saveImages(res.data));
    dispatch(PropertyActions.touch(res?.meta?.updatedByTitle));
  },

  deleteImage: async (image: IPropertyMedia, propertyId: number) => {
    const deletedImage = FileService.remove(image.s3Key);
    const deletedThumbnail = FileService.remove(image.thumbnailS3Key);

    await Promise.all([deletedImage, deletedThumbnail]);
    const deletedMediaObject = await MediaDataService.deleteImage(image, propertyId);

    dispatch(PropertyMediaImageActions.deleteImage(image.id));
    dispatch(PropertyActions.touch(deletedMediaObject?.meta?.updatedByTitle));
  },

  updateImage: async (image: IPropertyMedia, base64: string): Promise<Function> => {
    try {
      const deletedImage = FileService.remove(image.s3Key);
      const deletedThumbnail = FileService.remove(image.thumbnailS3Key);

      await Promise.all([deletedImage, deletedThumbnail]);

      const newS3Key = FileService.getUniqueName(image.title);
      const newThumbnailsS3Key = FileService.getThumbnailsKey(newS3Key);

      const fileSize = sizeOfBase64(base64);
      const buffer = Buffer.from(base64.replace(/^data:image\/\w+;base64,/, ""), "base64");

      await FileService.put(newS3Key, buffer);

      const newImage = (image as MediaElementModel).merge({
        s3Key: newS3Key,
        thumbnailS3Key: newThumbnailsS3Key,
        fileSize: fileSize
      });

      const res = await MediaDataService.updateImage(newImage).catch((error) => {
        return Promise.reject(error);
      });

      dispatch(PropertyActions.touch(res?.meta?.updatedByTitle));

      const callback = () => dispatch(PropertyMediaImageActions.updateImage(newImage));
      return Promise.resolve(callback);
    } catch (error) {
      return Promise.reject(error);
    }
  },

  updatePosition: async (move: MoveMediaPosition, imageId: string, propertyId: number) => {
    const res = await MediaDataService.updateImagePosition(move, imageId, propertyId)
    dispatch(PropertyMediaImageActions.updatePosition(imageId, move));
    dispatch(PropertyActions.touch(res?.meta?.updatedByTitle));
  }
});

export const PropertyMediaImagesWithStore = connect(mapStateToProps, mapDispatchToProps)(PropertyMediaImages);
