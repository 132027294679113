import React, { useContext } from 'react';
import { Button, ButtonSize, ButtonColor, ButtonIconPosition } from '../button';
import { AddIcon } from '../icons/collection/add-icon';
import { SvgVariant } from '../icons/enum/svg-variant.enum';
import { SuggestionDropdownContext } from '../../contexts/suggestion-dropdown.context';

interface IAddSuggestionButtonProps {
    a11yLabel?: string;
    onClick?: (event: any) => void;
    isPressed?: boolean;
}

export const AddSuggestionButton = (props: IAddSuggestionButtonProps) => {
    const {
        buttonLabel
    } = useContext(SuggestionDropdownContext);
    const {
        a11yLabel = buttonLabel,
        onClick,
        isPressed
    } = props;

    return (
        <Button
            onClick={onClick}
            btnClass={'medium-font-size bold'}
            size={ButtonSize.Small}
            color={ButtonColor.Ghost}
            label={buttonLabel}
            isPressed={isPressed}
            iconPosition={ButtonIconPosition.Left}>
            <AddIcon
                viewBox='0 0 30 30'
                color={SvgVariant.Secondary}
                titleAccess={a11yLabel}
            />
        </Button>
    );
}
