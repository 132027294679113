import { IFormFieldset } from "../../components/form/interfaces/form-fieldset.interface";
import { KeyValuePairs } from "../types/key-value-pairs.type";
import { IFieldSingle } from "../../components/form/interfaces/field-single.interface";
import { FieldTypeUnion } from "../../components/form/types/field-type.union";

export class FormService {
  private fallbackClass = "mark-as-fallback";

  updateFormSets(sets: IFormFieldset[], sourceData: KeyValuePairs, overrideOptions = {}): IFormFieldset[] {
    const updateFormDefinition = (sets) => {

      return sets.map((fieldset) => {
        const updatedGroups = fieldset?.groups.map((group) => {
          const updatedFields = group?.fields.map((field: FieldTypeUnion) => {
            if (!!field) {
              if ("fields" in field && field?.fields?.length) {
                return {
                  ...field,
                  fields: this.updateFields(field.fields, sourceData, overrideOptions)
                };
              } else if ("name" in field) {
                return this.updateField(field, sourceData, overrideOptions);
              }
            } else if (!!field && "sets" in field) {
              return updateFormDefinition(field?.sets);
            }
          });

          return {
            ...group,
            fields: updatedFields
          };
        });

        return {
          ...fieldset,
          groups: updatedGroups
        };
      });
    };

    return updateFormDefinition(sets);
  }

  private updateFields(fields: IFieldSingle[], sourceData: KeyValuePairs, overrideOptions = {}): IFieldSingle[] {
    return fields?.map((nestedField) => this.updateField(nestedField, sourceData, overrideOptions));
  }

  private updateField(field: IFieldSingle, sourceData: KeyValuePairs, overrideOptions = {}): IFieldSingle {

    const hasFallbackName = this.hasFallbackName(field);
    const isFallbackValue = this.isFallbackValue(field, sourceData);
    const value = sourceData[field?.name] ?? field?.value;

    return {
      ...field,
      value,
      fallbackValue: hasFallbackName ? sourceData[field?.fallbackName] : null,
      isFallback: isFallbackValue,
      customCss: hasFallbackName && isFallbackValue ? this.fallbackClass : field.customCss,
      ...overrideOptions
    };
  }

  private isFallbackValue(field: IFieldSingle, sourceData: KeyValuePairs): boolean {
    return sourceData[field?.name] === sourceData[field?.fallbackName];
  }

  private hasFallbackName(field: IFieldSingle): boolean {
    return !!field?.fallbackName;
  }

  static hasValueChanged(
    changeValue: string,
    distinctValue: string) {
    let currentValue = changeValue;

    if (!currentValue && !distinctValue) {
      return false;
    }

    if (typeof currentValue === "string") {
      currentValue = changeValue.slice().trim();
    }

    return currentValue !== distinctValue;
  }
}
