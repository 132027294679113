export enum PropertyTextsActionTypes {
    SET_REMARKS = '[Property Texts] Set Remarks',
    ADD_REMARK = '[Property Texts] Add Remark',
    UPDATE_REMARK = '[Property Texts] Update Remark',
    DELETE_REMARK = '[Property Texts] Delete Remark',
    SET_INTROTEXTS = '[Property Texts] Set Introtexts',
    TOGGLE_SHOW_INTROTEXT_IN_WATCHLIST = '[Property Texts] Toggle show Intro Texts in Watchlist',
    SET_ACTIVE_INTRO_TEXT_TEMPLATE = '[Property Texts] Set Active Intro Text Template',
    UPDATE_OWN_INTROTEXT = '[Property Texts] Update Own Intro Text',
    FETCH_IN_PROGRESS = '[Property Texts] Fetch In Progress',
    FETCH_FINISHED = '[Property Texts] Fetch Finish'
}
