export enum ButtonColor {
  None = 'none',
  Basic = 'basic',
  Dark = 'dark',
  Transparent = 'transparent',
  Warning = 'warning',
  Primary = 'primary',
  Secondary = 'secondary',
  Default = 'default',
  Danger = 'danger',
  Ghost = 'ghost',
  GhostDark = 'ghost-dark',
  GhostSelect = 'ghost-select',
  LighterPrimary = 'lighter-primary'
};
