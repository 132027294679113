import { IAction } from "../../interfaces/action.interface";
import { PropertyActionTypes } from "./property.action-types";

export abstract class PropertyActions {

  static touch(updatedBy: string): IAction {
    return {
      type: PropertyActionTypes.TOUCH_PROPERTY,
      payload: {updatedBy}
    };
  }

}
