import {css, StyleSheet} from "aphrodite";
import React from "react";
import {connect} from "react-redux";
import {IState} from "../../../../shared/interfaces/state.interface";
import {Fieldset} from "../../../fieldset/fieldset";
import * as Yup from 'yup';
import {Field, Formik} from "formik";
import {ApiEndpoint} from "../../../../shared/constants/api-endpoint.enum";
import {Auth} from "aws-amplify";
import axios from "axios";
import {ApiEndpointHelper} from "../../../../shared/helpers/api-endpoint.helper";

export const DevToolsView = () => {

  const fileUpload = async (uploadPath: string, file: any) => {
    const formData = new FormData();
    formData.append('file', file);
    const session = await Auth.currentSession();
    const token = session.getIdToken().getJwtToken();
    const url = process.env.REACT_APP_API_HOST + '/' + uploadPath;
    return await axios.post(url, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
        Authorization: `Bearer ${token}`,
      },
    }).catch(error => {
      console.error(error.response);
      return {data: false};
    });
  };

  const triggerPropertyScript = async (script: string) => {
    const formData = new FormData();
    formData.append('trigger-script', script);
    const session = await Auth.currentSession();
    const token = session.getIdToken().getJwtToken();
    const url = process.env.REACT_APP_API_HOST + '/' + ApiEndpoint.PROPERTY;
    return await axios.post(url, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
        Authorization: `Bearer ${token}`,
      },
    }).catch(error => {
      console.error(error.response);
      return {data: false};
    });
  };

  const onUploadSubmit = async (values) => {
    console.log(values);
    let result;

    if(values.resource === 'affiliate') {
      console.log("upload to affiliate")
      result = await fileUpload(ApiEndpoint.AFFILIATE, values.file);
    }
    else if(values.resource === 'property') {
      console.log("upload to properties")
      result = await fileUpload(ApiEndpoint.PROPERTY, values.file);
    }
    else if(values.resource === 'property-image') {
      console.log("upload to properties images")
      result = await fileUpload(ApiEndpointHelper.getMediaImagePath(0), values.file);
    }
    else if(values.resource === 'tenancy') {
      console.log("upload to properties")
      result = await fileUpload(ApiEndpoint.TENANCY, values.file);
    }
    else if(values.resource === 'owner') {
      console.log("upload to owners")
      result = await fileUpload(ApiEndpoint.OWNERS, values.file);
    }

    if(result?.data === true) {
      alert("Successfully started importer.")
    }
    else {
      alert("Error. Something went wrong. Please try again.")
    }
  }

  const onScriptsSubmit = async (values) => {
    console.log(values);
    let result;

    if(values.script === 'latlng') {
      console.log("re-calc. lat/lng values for empty columns")
      result = await triggerPropertyScript(values.script);
    }

    if(values.script === 'imageftpsync') {
      console.log("download missing legacy media files from ftp server")
      result = await triggerPropertyScript(values.script);
    }

    if(result?.data === true) {
      alert("Successfully started scripts.")
    }
    else {
      alert("Error. Something went wrong. Please try again.")
    }
  }

  return (
    <>
      <Fieldset>

        <Fieldset.Legend>
          <div className={css(styles.legendTitle)}>Bulk-Import</div>
        </Fieldset.Legend>

        <Fieldset.Content>
          <Formik
            initialValues={{file: null, resource: 'affiliate'}}
            onSubmit={onUploadSubmit}
            validationSchema={Yup.object().shape({
              file: Yup.mixed().required(),
            })}>
            {({handleSubmit, setFieldValue}) => {
              return (
                <form onSubmit={handleSubmit}>
                  <div className="form-group">
                    <div>
                      <label htmlFor="resource-select">Ressource:</label>

                      <Field as="select" name="resource" id="resource-select">
                        <option value="affiliate">Affiliate</option>
                        <option value="property">Property</option>
                        <option value="property-image">Property image</option>
                        <option value="owner">Owner</option>
                        <option value="tenancy">Tenancy</option>
                      </Field>
                    </div>

                    <div>
                      <label htmlFor="file-input">File upload</label>
                      <input id="file-input" name="file" type="file" accept=".csv" onChange={(event) => {
                        setFieldValue("file", event.currentTarget.files[0]);
                      }} className="form-control"/>
                    </div>
                  </div>
                  <button type="submit" className="btn btn-primary">Upload</button>
                </form>
              );
            }}</Formik>
        </Fieldset.Content>
      </Fieldset>
      <Fieldset>

        <Fieldset.Legend>
          <div className={css(styles.legendTitle)}>Scripts</div>
        </Fieldset.Legend>

        <Fieldset.Content>
          <Formik
            initialValues={{script: 'latlng'}}
            onSubmit={onScriptsSubmit}
            validationSchema={Yup.object().shape({
              script: Yup.mixed().required(),
            })}>
            {({handleSubmit}) => {
              return (
                <form onSubmit={handleSubmit}>
                  <div className="form-group">
                    <div>
                      <label htmlFor="script-select">Script:</label>

                      <Field as="select" name="script" id="script-select">
                        <option value="latlng">Re-calc empty lat/lng</option>
                        <option value="imageftpsync">Download missing legacy media files from ftp</option>
                      </Field>
                    </div>

                  </div>
                  <button type="submit" className="btn btn-primary">Start</button>
                </form>
              );
            }}</Formik>
        </Fieldset.Content>
      </Fieldset>
    </>
  );
};

const styles = StyleSheet.create({
  legendTitle: {
    fontSize: "var(--font-size-title)",
    fontWeight: "bold"
  }
});

const mapStateToProps = (_state: IState) => {
  return {};
};

const mapDispatchToProps = (_dispatch: any) => ({});

export const DevToolsViewWithStore = connect(mapStateToProps, mapDispatchToProps)(DevToolsView);
