export enum TenanciesFilterKey {
  AFFILIATE_NAME = 'affiliateName',
  STREET = 'street',
  POSTAL_CODE = 'postalCode',
  LOCATION = 'location',
  COUNTRY = 'country',
  REGION = 'region',
  RENTAL_START = 'rentalStart',
  TOTAL_AREA = 'totalArea',
  RENTAL_END = 'rentalEnd',
  OFFICE_AREA = 'officeArea',
  RENT_PRICE = 'rentPrice',
}
