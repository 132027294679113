import { ApiEndpoint } from "../constants/api-endpoint.enum";

export abstract class ApiEndpointHelper {

  static getMediaImagePath(propertyId): string {
    return ApiEndpoint.MEDIA_IMAGE.replace(ApiEndpoint.PropertyIdPlaceholder, propertyId);
  }


  static getMediaVideoPath(propertyId): string {
    return ApiEndpoint.MEDIA_VIDEO.replace(ApiEndpoint.PropertyIdPlaceholder, propertyId);
  }
}

