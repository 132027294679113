/* eslint-disable react-hooks/exhaustive-deps */

import React  from "react";
import { defaultRotation } from "../image-editor.defaults";
import { Button, ButtonColor, ButtonIconPosition, ButtonSize } from "../../button";
import { RotateIcon } from "../../icons/collection/rotate-icon";
import { CssColor } from "../../../shared/helpers/styles.helper";
import { SvgVariant } from "../../icons/enum/svg-variant.enum";

interface IRotateImageProps {
  rotation: number,
  setRotation: (number) => void,
  setIsTouched: (boolean) => void,
  onReset?: () => void; //TODO
  disabled?: boolean;
}

export const RotateImageControl = React.memo((props: IRotateImageProps) => {
  const {
    rotation,
    setRotation,
    setIsTouched,
    disabled = false,
  } = props;

  const rotationHandler = () => {
    setIsTouched(true);
    setRotation(rotation + defaultRotation);
  }

  return <>
          <label>Drehen</label>
          <Button
            id='rotate'
            onClick={rotationHandler}
            btnClass={"no-padding"}
            showLabel={false}
            label={"Bild drehen"}
            color={ButtonColor.Transparent}
            iconPosition={ButtonIconPosition.Right}
            size={ButtonSize.Medium}
            disabled={disabled}
            tabIndex={0}
          >
            <RotateIcon stroke={CssColor.White} color={SvgVariant.White} viewBox={"0 0 30 30"}
                        titleAccess={"Bild drehen"}/>
          </Button>
        </>
});
