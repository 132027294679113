import { Record } from 'immutable';

export enum PropertyIntroTextTemplate {
  FIRST = 'first',
  SECOND = 'second',
  THIRD = 'third',
  OWN = 'own'
}

export interface IPropertyIntroTextsParams {
  introTextId: string;
  title_de: string;
  title_en: string;
  template_de: string;
  template_en: string;
  isActive: boolean;
  isEditable: boolean;
  introTextTemplate: PropertyIntroTextTemplate;
}

const defaults: IPropertyIntroTextsParams = {
  introTextId: '',
  title_de: '',
  title_en: '',
  template_de: '',
  template_en: '',
  isActive: undefined,
  isEditable: undefined,
  introTextTemplate: undefined
};

export class PropertyIntroTextModel extends Record<IPropertyIntroTextsParams>(defaults) {
  constructor(params?: IPropertyIntroTextsParams) {
    params ? super(params) : super();
  }

  with(values: IPropertyIntroTextsParams): this {
    return this.merge(values) as this;
  }
}
