import React from "react";

interface ISuggestionItemProps {
  suggestion: string | object;
  index: number;
  cursor?: number;
  handleSelect: (event, value, valueObject?) => void
}

const MAX_SUGGESTION_LENGTH = 100;

export const SuggestionItem = (props: ISuggestionItemProps) => {
  const { suggestion, index, cursor, handleSelect } = props;

  const limitSuggestionLength = (str: string) => {
    const ellipsis = "...";
    return str.slice(0, MAX_SUGGESTION_LENGTH - ellipsis.length).concat(ellipsis);
  };

  const label = typeof suggestion === "object" && ("label" in suggestion) ? suggestion["label"] : suggestion;
  const fixedLabel = label?.length > MAX_SUGGESTION_LENGTH ? limitSuggestionLength(label) : label;
  const value = typeof suggestion === "object" && ("value" in suggestion) ? suggestion["value"] : suggestion;
  const valueObject = typeof suggestion === "object" && ("valueObject" in suggestion) ? suggestion["valueObject"] : null;

  return <>{value && <li className={`is-result ${cursor === index ? "active" : ""}`} data-suggestion={value}>
    <button type={"button"} onClick={(event: any) => handleSelect(event, value, valueObject)}>
      {fixedLabel}
    </button>
  </li>}</>;
};
