import {
  selectAffiliateOwnerAgentObjectsAffiliateSelectorObject
} from "../../../../../shared/redux/affiliate/affiliate.selectors";
import { AffiliateDataService } from "../../../../../shared/services/affiliate-data.service";
import { FieldSingleConfig } from "../../../config/field-single.config";
import { TemplateType } from "../../../enums/template-type.enum";
import { FieldCollapsableConfig } from "../../../config/field-collapsable.config";
import { RelationshipInputFieldFactory } from "../../relationship-input-field-factory";
import { IFieldTabArrayState } from "../../../fields/field-tab-array/field-tab-array.reducer";
import { selectFormer, selectCurrent } from "../../../fields/field-tab-array/field-tab-array.selector";
import { ISelectableObject } from "../../../../../shared/interfaces/selectable.interface";
import { PropertyService } from "../../../../../shared/services/property.service";

export abstract class AffiliateOwnerAgentObjectsFormDefinitions {
  static AffiliateAgentObjects(propertySelector: (state, id) => ISelectableObject, _affiliateSelector: (state, id) => ISelectableObject, apiPath: string) {
    const titleBar = [
      [
        {
          replaceValue: "_propertyId",
          title: async (state, id) => (propertySelector(state, id)?.label)
        }
      ],
      [
        {
          replaceValue: "_affiliateId",
          title: async (state, id) => "Kunde: " + (selectAffiliateOwnerAgentObjectsAffiliateSelectorObject(state, id)?.label)
        }
      ]
    ];

    const fields = [
      {
        ...FieldSingleConfig.getConfig({
          templateType: TemplateType.FIELD_RELATION_CONNECTOR,
          name: "_propertyId",
          label: "Objekt",
          relationUrlPath: "/properties",
          onRelationSearch: async (searchTerm: string) => await PropertyService.searchProperty(searchTerm),
          selectableObject: async (state, id: number) => (propertySelector(state, id))
        })
      },
      {
        ...FieldSingleConfig.getConfig({
          templateType: TemplateType.FIELD_RELATION_CONNECTOR,
          name: "_affiliateId",
          label: "Kunde",
          relationUrlPath: "/affiliates",
          onRelationSearch: async (searchTerm: string) => await AffiliateDataService.searchAffiliate(searchTerm),
          selectableObject: async (state, id: number) => (selectAffiliateOwnerAgentObjectsAffiliateSelectorObject(state, id))
        })
      },

      {
        ...FieldSingleConfig.getConfig({
          name: "property.primaryAddress.fullAddress",
          label: "Adresse",
          readonly: true
        })
      }
    ];

    const tabFactory = (key, label, selector, showCreateButton) => {
      return {
        key: key + "_item",
        label: label,
        selector: selector,
        newDataRef: {
          ...FieldCollapsableConfig.getConfig({
            label: label,
            hideInputEnumeration: false,
            showAdditionalButton: showCreateButton,
            titleBar,
            fields,
          })
        }
      };
    };

    return RelationshipInputFieldFactory.TwoTabsWithFormerCollapsibleFactory(
      "agentObjects",
      "Makler-Objekt",
      "Makler-Objekte",
      (state: IFieldTabArrayState) => selectCurrent(state),
      (state: IFieldTabArrayState) => selectFormer(state),
      tabFactory,
      apiPath
    );
  }
}
