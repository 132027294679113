import {List} from "immutable";
import React, {useEffect, useRef, useState} from "react";
import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import {connect} from "react-redux";
import {TableContext} from "../../../contexts/table.context";
import {ApiEndpoint} from "../../../shared/constants/api-endpoint.enum";
import {SortOrder} from "../../../shared/constants/sort-order.enum";
import {IState} from "../../../shared/interfaces/state.interface";
import {
  performGetRequest,
  performOffsetRequest,
  performSortRequest
} from "../../../shared/redux/middleware/api.middleware";
import {LogService} from "../../../shared/services/log.service";
import {Table} from "../../table/table";
import {LoadingStatus} from "../../../shared/constants/loading-status.enum";
import {TableRow} from "../../table/table-row/table-row";
import {SortService} from "../../../shared/services/sort.service";
import {WindowService} from "../../../shared/services/window.service";
import {
  selectActiveFilter,
  selectData,
  selectIsLoading,
  selectMetaData,
  selectOffset,
  selectScrollTop,
  selectSortByColumn,
  selectSortOrder
} from "../../../shared/redux/tenancies/tenancies.selector";
import {TenancyModel} from "../../../shared/models/tenancy.model";
import {TenanciesActionTypes} from "../../../shared/redux/tenancies/tenancies.action-types";
import {TenanciesMetaDataModel} from "../../../shared/models/tenancies-meta-data.model";
import {AffiliateHeadersConfig} from "../../../shared/table-headers/affiliate-headers.config";
import {TenanciesFilter} from "../../filter/tenancies-filter/tenancies-filter";
import {TenanciesFilterSelection} from "../../filter/tenancies-filter/tenancies-filter-selection";
import {buildFilterQueryParams} from "../../../shared/utils/filter-utils";
import {ActiveFilterModel} from "../../../shared/models/active-filter.model";
import {useCurrentLanguage} from "../../../contexts/current-language.context";
import {useUser} from "../../../contexts/user.context";
import {useLoadMoreIntersectionObserver} from "../../../hooks/use-load-more-intersection-observer";
import useResizeObserver from "../../../hooks/use-resize-observer";
import {setScrollTopAction} from "../../../shared/redux/tenancies/tenancies.action";

interface IAffiliateListView {
  tenancyList: List<TenancyModel>,
  loadingStatus: LoadingStatus,
  offset: number,
  activeFilter: List<ActiveFilterModel>,
  sortOrder: SortOrder,
  sortByColumn: string,
  metaData: TenanciesMetaDataModel,
  fetchData: (path: string) => Promise<any>,
  fetchMoreData: (offset: number, path: string) => void,
  sortData: (sortOrder: SortOrder, sortByColumn: string, path: string) => void;
  resetData: (path: string) => void;
  scrollTop: number,
  setScrollTop: (yPosition: number) => void;
}

const defaultLoadMoreOffset = 150;

export const TenanciesListViewWithoutStore = (props: IAffiliateListView) => {
  const {
    tenancyList,
    loadingStatus,
    offset,
    activeFilter,
    metaData,
    fetchData,
    fetchMoreData,
    sortOrder,
    sortByColumn,
    sortData,
    resetData,
    scrollTop,
    setScrollTop,
  } = props;

  const {getGroups} = useUser();

  const [ready, setReady] = useState(false);
  const currentLanguage = useCurrentLanguage();

  useEffect(() => {
    if (!ready) {
      setReady(true);
    }

    return () => {
      const tableScrollY = document.getElementsByClassName("tenancies-table")?.[0]?.scrollTop;

      setScrollTop(tableScrollY);
    }

  }, [ready]);

  const getQueryParams = (paramsOffset: number, paramsSortOrder: SortOrder, paramsSortByColumn: string): string => {
    const defaultQueryParam = `?offset=${paramsOffset}&sortByColumn=${paramsSortByColumn}&sortOrder=${paramsSortOrder}&language=${currentLanguage}`;
    return buildFilterQueryParams(activeFilter, defaultQueryParam);
  };

  const getFetchUrlWithParams = (offset, sortOrder, sortByColumn) => {
    const queryParams = getQueryParams(offset, sortOrder, sortByColumn);
    return `${ApiEndpoint.TENANCY}${queryParams}`;
  };

  const fetchInitialData = (internOffset?: number) => {
    try {
      fetchData(getFetchUrlWithParams(internOffset ?? offset, sortOrder, sortByColumn)).then();
      WindowService.scrollTo(document.getElementsByClassName("tenancies-table")?.[0]);

    } catch (error) {
      LogService.error("An error occured while trying to perform a GET Request", error);
    }
  };

  useEffect(() => {
    if (ready) {
      if(tenancyList.size <= 0) {
        fetchInitialData(0);
      }

      WindowService.scrollTo(document.getElementsByClassName("tenancies-table")?.[0], scrollTop, 'auto')
    }
  }, [ready]);

  useEffect(() => {
    if (ready) {
      fetchInitialData();
    }
  }, [activeFilter]);

  const onSortChange = (sortColumn: string): void => {
    const resetOffset = 0;
    const {newSortOrder, column} = SortService.handleSortChange(sortOrder, sortByColumn, sortColumn);
    const queryParams = getQueryParams(resetOffset, newSortOrder, column);

    WindowService.scrollTo(document.getElementsByClassName("tenancies-table")?.[0]);

    sortData(newSortOrder, column, `${ApiEndpoint.TENANCY}${queryParams}`);
  };

  const filterSelectionRef = useRef({current: null});

  const loadMore = () => {
    if (loadingStatus === LoadingStatus.READY) {
      const newOffset = offset + (+process.env.REACT_APP_LOAD_MORE_OFFSET || defaultLoadMoreOffset);
      const queryParams = getQueryParams(newOffset, sortOrder, sortByColumn);

      fetchMoreData(newOffset, `${ApiEndpoint.TENANCY}${queryParams}`);
    }
  };

  const {loadMoreRef} = useLoadMoreIntersectionObserver(
    document.getElementsByClassName("tenancies-table")?.[0],
    tenancyList?.size,
    metaData.get("total"),
    ready && loadingStatus === LoadingStatus.READY,
    loadMore
  );

  //TODO i18n region
  const csvExportUrl = () => {
    const fields = [
      "_affiliateId:affiliateId",
      "affiliate.affiliateName:affiliateName",
      "property.primaryAddress.fullStreet:fullStreet",
      "property.primaryAddress.postalCode:postalCode",
      "property.primaryAddress.location:location",
      "totalArea",
      "officeArea",
      "totalMonthlyRent",
      "averageRent",
      "rentalStart",
      "rentalEnd",
    ];
    return "/" + getFetchUrlWithParams(0, sortOrder, sortByColumn) + "&contentType=text/csv&fields=" + JSON.stringify(fields);
  };

  const upperRef = useRef();

  const setFilterAreaHeight = (el?) => {
    const filterAreaHeight = (el ?? upperRef.current).contentRect?.height;
    if (!!filterAreaHeight || filterAreaHeight === 0) {
      //console.log("height is", filterAreaHeight)
      document.documentElement.style.setProperty('--filter-area-height', (filterAreaHeight) + "px");
    }
  }

  useEffect(() => {
    setFilterAreaHeight();
  }, [ready])

  useResizeObserver(upperRef.current, (entries) => {
    const first = entries[0];

    if (first) {
      setFilterAreaHeight(first);
    }
  });

  return (
    <Container fluid>
      <div ref={upperRef}>
        <TenanciesFilter/>
        <TenanciesFilterSelection ref={filterSelectionRef}/>
      </div>
      <Row>
        <Col>
          <TableContext.Provider value={{
            tableTitle: "Mietflächenliste",
            headers: AffiliateHeadersConfig,
            rows: tenancyList,
            metaData: metaData,
            setSortByColumn: onSortChange,
            sortByColumn: sortByColumn,
            sortOrder: sortOrder,
            affixRef: filterSelectionRef,
            loadingStatus,
            csvExportFileName: "affiliates.csv",
            csvExportUrl: csvExportUrl(),
            csvExportVisible: (getGroups()?.includes('admin') || getGroups()?.includes('editor'))
          }}>
            <Table showTablePanel={true}
                   striped={true}
                   tableClassName='tenancies-table'
                   ref={loadMoreRef}>
              <TableRow
                urlPath='affiliates'
                urlPathDetailValue='_affiliateId'/>
            </Table>
          </TableContext.Provider>
        </Col>
      </Row>
    </Container>
  );
};

const mapStateToProps = (state: IState) => {
  return {
    tenancyList: selectData(state),
    metaData: selectMetaData(state),
    activeFilter: selectActiveFilter(state),
    loadingStatus: selectIsLoading(state),
    offset: selectOffset(state),
    sortOrder: selectSortOrder(state),
    sortByColumn: selectSortByColumn(state),
    scrollTop: selectScrollTop(state),
  };
};

const mapDispatchToProps = (dispatch: any) => ({
  fetchData: (path: string) => dispatch(
    performGetRequest(path, TenanciesActionTypes.FETCH_IN_PROGRESS, TenanciesActionTypes.SET_DATA, TenanciesActionTypes.FETCH_FINISHED, true)
  ),
  //TODO why dont we use SET_DATA
  sortData: (sortOrder: SortOrder, sortByColumn: string, path: string) => dispatch(
    performSortRequest(path, sortOrder, sortByColumn, TenanciesActionTypes.SET_SORTING, TenanciesActionTypes.SET_SORTED_LIST, TenanciesActionTypes.FETCH_FINISHED)
  ),
  resetData: (path: string) => dispatch(
    performOffsetRequest(path, 0, TenanciesActionTypes.SET_OFFSET, TenanciesActionTypes.RESET_DATA, TenanciesActionTypes.FETCH_FINISHED)
  ),
  fetchMoreData: (offset: number, path: string) => dispatch(
    performOffsetRequest(path, offset, TenanciesActionTypes.SET_OFFSET, TenanciesActionTypes.SET_MORE_DATA, TenanciesActionTypes.FETCH_FINISHED)
  ),
  setScrollTop: (yPosition) => dispatch(
    setScrollTopAction(yPosition)
  )
});

export const TenanciesListView = connect(mapStateToProps, mapDispatchToProps)(TenanciesListViewWithoutStore);
