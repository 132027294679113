import React, { ReactNode } from 'react';
import { Overlay } from '../overlay';
import { useEscapeKey } from '../../../hooks/use-escape-key';
import './modal.scss';

interface IModalProps {
  children: ReactNode;
  closeEvent: () => void
}

export const Modal = React.memo((props: IModalProps) => {
  const {
    closeEvent,
    children
  } = props;

  useEscapeKey(closeEvent);

  return (
    <Overlay tabIndex={0}>
      <div className='modal'>
        {children}
      </div>
    </Overlay>
  );
});
