import { css, StyleSheet } from "aphrodite";
import { List } from "immutable";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { IState } from "../../../../shared/interfaces/state.interface";
import { Button, ButtonColor, ButtonIconPosition, ButtonSize } from "../../../button";
import { Fieldset } from "../../../fieldset/fieldset";
import { AddIcon } from "../../../icons/collection/add-icon";
import { SvgVariant } from "../../../icons/enum/svg-variant.enum";
import { GridTable } from "../../../grid-table/grid-table";
import { usePrevious } from "../../../../hooks/use-previous";
import { IRegionsParams, RegionModel } from "../../../../shared/models/region.model";
import { useRegionsState } from "./use-regions-state";
import { RegionActions } from "../../../../shared/redux/regions/regions.action";
import { RegionsDataService } from "../../../../shared/services/regions-data.service";
import { selectRegions } from "../../../../shared/redux/regions/regions.selectors";
import { RegionsDatasetHistory } from "../../../dataset-history/regions-dataset-history";
import { RegionRow } from "../../../regions-row/regions-row";
import { DeleteEntryDialog } from "../../../overlay/dialog/variants/delete-entry-dialog";

interface IRegionsViewProps {
  regions: List<RegionModel>;
  addRegion: () => void;
  fetchRegions: () => void;
  deleteRegion: (id: string) => void;
  updateRegion: (template: IRegionsParams) => void;
}

export const RegionsView = (props: IRegionsViewProps) => {
  const {
    regions = List(),
    addRegion,
    fetchRegions,
    deleteRegion,
    updateRegion
  } = props;

  const {
    state,
    setCreateNewState,
    setUpdateState
  } = useRegionsState();

  const previousRegionsSize = usePrevious(regions?.size ?? 0);

  const [showDeleteDialog, setShowDeleteDialog] = useState(false);
  const [deleteIndex, setDeleteIndex] = useState(null);


  useEffect(() => {
    if (!regions?.size) {
      fetchRegions();
    }
  }, [regions, fetchRegions, previousRegionsSize, setUpdateState]);


  const onAddRegion = () => {
    setCreateNewState();
    addRegion();
  };

  const onDelete = (templateId: string) => {
    setShowDeleteDialog(true);
    setDeleteIndex(templateId);
  };

  const onUpdate = (template: IRegionsParams) => {
    updateRegion(template);
    setUpdateState();
  };

  const onDialogClick = (isDeleteClick: boolean) => {
    setShowDeleteDialog(false);

    if (isDeleteClick) {
      deleteRegion(deleteIndex);
      setUpdateState();
    }

  };

  return (
    <>
      <RegionsDatasetHistory/>

      <Fieldset>

        <Fieldset.Legend>
          <div className={css(styles.legendTitle)}>Regionen Raster</div>

          <Button
            onClick={onAddRegion}
            btnClass={"medium-font-size bold margin-left-auto"}
            size={ButtonSize.Small}
            color={ButtonColor.Ghost}
            label={"Neue Region anlegen"}
            iconPosition={ButtonIconPosition.Left}>
            <AddIcon
              viewBox='0 0 30 30'
              color={SvgVariant.Secondary}
              titleAccess='Eintrag hinzufügen'
            />
          </Button>

        </Fieldset.Legend>

        <Fieldset.Content>
          {
            !regions?.size
              ?
              <div>Keine Regionen vorhanden.</div>
              :
              <GridTable>
                <GridTable.Head headers={["Region", "Postleitzahlen"]}/>
                <GridTable.Body rows={regions?.map((region, index) =>
                  <RegionRow
                    key={region.id}
                    autoFocus={state.isNew && index === 0}
                    data={region}
                    onDelete={() => onDelete(region.id)}
                    onUpdate={(updateRegion) => onUpdate(updateRegion)}
                  />
                )}
                />
              </GridTable>
          }
        </Fieldset.Content>

      </Fieldset>

      <DeleteEntryDialog
        onControlClick={onDialogClick}
        showDialog={showDeleteDialog}
        onClose={() => setShowDeleteDialog(false)}
      />
    </>
  );
};

const styles = StyleSheet.create({
  legendTitle: {
    fontSize: "var(--font-size-title)",
    fontWeight: "bold"
  }
});

const mapStateToProps = (state: IState) => {
  return {
    regions: selectRegions(state)
  };
};

const mapDispatchToProps = (dispatch: any) => ({
  fetchRegions: async () => {
    const res = await RegionsDataService.fetch();
    dispatch(RegionActions.set(res?.data));
  },
  deleteRegion: (id: string) => RegionsDataService.delete(id).then(() => dispatch(RegionActions.delete(id))),
  updateRegion: async (template: IRegionsParams) => {
    const res = await RegionsDataService.update(template);
    dispatch(RegionActions.update(res.data));
  },
  addRegion: async () => {
    const res = await RegionsDataService.add();
    dispatch(RegionActions.create(res.data));
  }
});

export const RegionsViewWithStore = connect(mapStateToProps, mapDispatchToProps)(RegionsView);
