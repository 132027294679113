import React, { useEffect, useReducer, useState } from "react";
import { IFieldTabArray } from "../../interfaces/field-tab-array.interface";
import { FieldArray as FormikFieldArray, FormikValues } from "formik";
import { css, StyleSheet } from "aphrodite/no-important";
import { Button, ButtonColor, ButtonIconPosition, ButtonSize } from "../../../button";
import { AddIcon } from "../../../icons/collection/add-icon";
import { SvgVariant } from "../../../icons/enum/svg-variant.enum";
import { IWithRouterProps } from "../../../../shared/interfaces/with-router-props.interface";
import { withRouter } from "react-router-dom";
import { fieldTabArrayReducer, IFieldTabArrayState } from "./field-tab-array.reducer";
import {
  setActiveTab,
  setActiveTabContent,
  setData,
  setLastAddedData,
  setPreviousData
} from "./field-tab-array.actions";
import { IFieldChangeEvent } from "../../interfaces/field-single.interface";
import { ActiveTabIcon } from "../../../icons/collection/active-tab-icon";
import { FieldArrayHelper } from "../../helpers/field-array.helper";
import { FormHelper } from "../../helpers/form.helper";
import { LogService } from "../../../../shared/services/log.service";
import { Dialog, DialogControl } from "../../../overlay/dialog";
import { useUser } from "../../../../contexts/user.context";

interface IFieldTabArrayProps extends IFieldTabArray, IWithRouterProps<{ propertyId: number, affiliateId: number }> {
  formValues: FormikValues;
  readonly?: boolean;
}

const TAB_DEFAULT_STATE: IFieldTabArrayState = {
  activeTab: null,
  activeTabContent: {},
  data: [],
  previousData: [],
  lastAddedData: null
};

const INITIAL_ACTIVE_TAB = 0;

export const FieldTabArray = React.memo(withRouter((props: IFieldTabArrayProps) => {
  const {
    tabs,
    formValues,
    name,
    addLabel,
    onDataAdd,
    onDataTransform,
    onDataDelete,
    triggerDataRefetch,
    match,
    changeHandler,
    transformMessage,
    deleteMessage,
    readonly = false,
  } = props;

  const [state, dispatch] = useReducer(fieldTabArrayReducer, TAB_DEFAULT_STATE);
  const {
    data,
    previousData,
    activeTab,
    activeTabContent,
    lastAddedData
  } = state;

  const [target, setTarget] = useState(null);
  const [showTransformDialog, setShowTransformDialog] = useState(false);
  const [showDeleteDialog, setShowDeleteDialog] = useState(false);

  const [routeParam, setRouteParam] = useState<number>(null);

  const { getGroups } = useUser();

  const [writePermissions, setWritePermissions] = useState(false);

  useEffect(() => {
    if (getGroups()?.includes("admin") || getGroups()?.includes("editor")) {
      setWritePermissions(true);
    }
  }, [getGroups]);

  useEffect(() => {
    if (!!match.params.propertyId) {
      setRouteParam(match.params.propertyId);
    }

    if (!!match.params.affiliateId) {
      setRouteParam(match.params.affiliateId);
    }
  }, [match]);

  const updateStateByFormChanges = () => {
    if (!data?.length) {
      dispatch(setActiveTab(INITIAL_ACTIVE_TAB));
    }

    if (!previousData?.length || previousData?.length !== data?.length) {
      dispatch(setPreviousData(formValues[name]));
    }

    dispatch(setData(formValues[name]));
  };
  useEffect(updateStateByFormChanges, [formValues, name, data]);

  const updateTabContentByTabChanges = () => {
    const tabContent = tabs?.[activeTab]?.selector(state);

    dispatch(setActiveTabContent(tabContent));
  };
  useEffect(updateTabContentByTabChanges, [data, activeTab]);

  const onNewDataAdd = async () => {
    if(readonly) {
      return;
    }

    const res = await onDataAdd?.(routeParam);

    if(!!res?.data) {
      dispatch(setLastAddedData(res.data));
    }

    await triggerDataRefetch?.();
  };

  const onTransformDialogControlClick = async (submitTransform: boolean) => {
    if(readonly) {
      return
    }

    if (submitTransform) {
      try {
        await onDataTransform?.(routeParam, target);
        await triggerDataRefetch?.();
      } catch (error) {
        LogService.error("onDialogControlClick: Http request failed.", error);
      }
    }
    dispatch(setLastAddedData(null));
    setTarget(null);
    setShowTransformDialog(false);
  };

  const onDeleteDialogControlClick = async (submitDelete: boolean) => {
    if(readonly) {
      return
    }

    if (submitDelete) {
      try {
        await onDataDelete?.(routeParam, target);
        await triggerDataRefetch?.();
      } catch (error) {
        LogService.error("onDialogControlClick: Http request failed.", error);
      }
    }
    dispatch(setLastAddedData(null));
    setTarget(null);
    setShowDeleteDialog(false);
  };

  const handleChange = (change: IFieldChangeEvent) => {
    if(readonly) {
      return
    }

    const updatedData = FieldArrayHelper.onDataUpdate(change, data, previousData);

    if (!updatedData?.length) {
      return;
    }

    dispatch(setPreviousData(updatedData));

    changeHandler?.({
      name,
      value: updatedData,
      fallbackName: null,
      isFallback: null
    });
  };

  const renderDataRef = (dataId: string, contentIndex: number) => {
    const dataSource = data.find((data) => data.id === dataId);
    const dataIndex = data.indexOf(dataSource);
    const dataRef = tabs[activeTab]?.newDataRef;
    const updatedDataRef = FieldArrayHelper.mapTabArrayToDataRef(
      name,
      data,
      dataIndex,
      contentIndex,
      activeTabContent.length,
      dataRef,
      !writePermissions ? {readonly} : {}
    );

    return FormHelper.renderFieldByTemplateType(
      updatedDataRef,
      {
        key: dataId,
        changeHandler: (change: IFieldChangeEvent) => handleChange(change),
        initialExpand: dataId === lastAddedData?.id,
        sourceId: dataId,
        triggerDataRefetch,
        additionalClickHandler: async () => {
          setTarget(dataId);
          if(dataSource?.isFormer) {
            setShowDeleteDialog(true);
          }
          else {
            setShowTransformDialog(true);
          }
        },
        readonly
      }
    );
  };

  return (
    <>
      {
        showTransformDialog && !readonly &&
          <Dialog
            title='Sind Sie sicher?'
            onClose={() => setShowTransformDialog(false)}
          >
            <Dialog.Message>
              {transformMessage}
            </Dialog.Message>
            <Dialog.Controls>
              <DialogControl label='Abbrechen' value={false} color={ButtonColor.Basic}
                             onClick={onTransformDialogControlClick}></DialogControl>
              <DialogControl label='Umwandeln' value={true} color={ButtonColor.Danger}
                             onClick={onTransformDialogControlClick}></DialogControl>
            </Dialog.Controls>
          </Dialog>
      }
      {
        showDeleteDialog && !readonly &&
          <Dialog
            title='Sind Sie sicher?'
            onClose={() => setShowDeleteDialog(false)}
          >
            <Dialog.Message>
              {deleteMessage}
            </Dialog.Message>
            <Dialog.Controls>
              <DialogControl label='Abbrechen' value={false} color={ButtonColor.Basic}
                             onClick={onDeleteDialogControlClick}></DialogControl>
              <DialogControl label='Löschen' value={true} color={ButtonColor.Danger}
                             onClick={onDeleteDialogControlClick}></DialogControl>
            </Dialog.Controls>
          </Dialog>
      }
      <div className={css(styles.wrapper)}>
        <ul className={css(styles.tabs)}>
          {tabs?.map(({ key, label, selector }, index) => (
            <li key={key}
                className={css(styles.tabItem, activeTab === index && styles.tabItemActive)}
                onClick={() => dispatch(setActiveTab(index))}>
              <span className={css(styles.tabLabel)}>{label} ({selector(state)?.length})</span>
              {activeTab === index && <ActiveTabIcon className={css(styles.tabSvg)}
                                                     viewBox='0 0 40 40'
                                                     color={SvgVariant.Secondary}
                                                     titleAccess='Ausgewählter Tab'/>}
            </li>
          ))}
          {!readonly && <Button type={"button"}
                  btnClass={"medium-font-size bold margin-left-auto space-bottom"}
                  size={ButtonSize.Small}
                  color={ButtonColor.Ghost}
                  iconPosition={ButtonIconPosition.Left}
                  label={addLabel}
                  onClick={async () => await onNewDataAdd()}>
            <AddIcon
              viewBox='0 0 30 30'
              color={SvgVariant.Secondary}
              titleAccess='Eintrag hinzufügen'
            />
          </Button>}
        </ul>
      </div>
      <FormikFieldArray name={name}
                        render={_ => (activeTabContent?.length > 0 && activeTabContent.map(({ id }, contentIndex) => renderDataRef(id, contentIndex)))}/>
    </>
  );
}));

const styles = StyleSheet.create({
  wrapper: {
    display: "grid",
    gridTemplateColumns: "calc(var(--form-label) + var(--size-10)) 1fr",
    gridGap: "var(--size-10)",
    marginBottom: "var(--size-10)"
  },
  tabs: {
    gridColumn: 2,
    display: "flex",
    alignItems: "flex-end",
    position: "relative",
    width: "100%",
    marginBottom: 0,
    paddingBottom: 2,
    borderBottom: "var(--base-border) solid var(--lighter-grey)"
  },
  tabItem: {
    color: "var(--black)",
    cursor: "pointer",
    padding: "var(--size-05) var(--size-10)",
    backgroundColor: "var(--lighter-grey)",
    display: "inline-block",
    borderTopLeftRadius: "var(--base-radius)",
    borderTopRightRadius: "var(--base-radius)",
    fontWeight: "bold",
    marginRight: 2,
    ":hover": {
      backgroundColor: "var(--lighter-primary)"
    }
  },
  tabLabel: {
    display: "inline-block",
    marginRight: "var(--size-100)"
  },
  tabItemActive: {
    padding: "0 0 0 var(--size-10)",
    backgroundColor: "var(--lighter-primary)"
  },
  tabSvg: {
    width: "var(--size-40)",
    height: "var(--size-40)"
  }
});
