import React, { useEffect, useReducer } from 'react';
import { FlashMessagesService } from '../../shared/services/flash-messages.service';
import { flashMessagesReducer } from './flash-messages-reducer';
import { addMessage, removeMessage } from './flash-messages.action';
import { Message } from './message/message';
import { IFlashMessage } from '../../shared/interfaces/flash-message.interface';
import { css, StyleSheet } from 'aphrodite/no-important';

const FLASH_MESSAGES_DEFAULT_STATE = {
  messages: []
}

export const FlashMessages = React.memo(() => {
  const flashMessagesService = FlashMessagesService.getInstance();
  const [ state, dispatch ] = useReducer(flashMessagesReducer, FLASH_MESSAGES_DEFAULT_STATE)

  useEffect(() => {
    const handleMessagesUpdate = (message) => {
      if (message) {
        dispatch(addMessage(message));
      }
    }

    const subscription = flashMessagesService.message$.subscribe(handleMessagesUpdate);

    return () => {
      subscription.unsubscribe();
    }
  }, [flashMessagesService.message$])

  const handleRemove = (id: number) => {
    dispatch(removeMessage(id));
  }

  return (
    <div className={css(styles.flashMessages)}>
      {state.messages?.map((message: IFlashMessage, index) => {
        return <Message key={message.id} index={index} removeHandler={handleRemove} {...message} />
      })}
    </div>
  )
})

const styles = StyleSheet.create({
  flashMessages: {
    position: 'fixed',
    zIndex: 1000,
    top: 'var(--size-20)',
    left: '50%',
    transform: 'translateX(-50%)'
  }
})
