import { IFilterParam } from '../interfaces/filter-param.interface';
import { LogService } from '../services/log.service';
import { List, fromJS } from 'immutable';
import { ActiveFilterModel } from '../models/active-filter.model';

const rangeSeperator = ';';
const listSeperator = ',';

export const mergeFilter = (stateFilter: List<ActiveFilterModel>, payloadFilter: IFilterParam ) => {
  const hasFilter = stateFilter.some((activeFilter) => activeFilter.groupKey === payloadFilter.groupKey);

  if (hasFilter) {
    return stateFilter.map((activeFilter) => activeFilter.groupKey === payloadFilter.groupKey ? new ActiveFilterModel(fromJS(payloadFilter)) : activeFilter);
  }

  return stateFilter.merge(fromJS([new ActiveFilterModel(fromJS(payloadFilter))]));
};

export const buildFilterQueryParams = (activeFilter: List<ActiveFilterModel>, defaultQuery: string = '?') => {

  let filterQueryParam = defaultQuery.slice();

  activeFilter.forEach((filter: ActiveFilterModel) => {
    if(!!filter.from || !!filter.to) {
      filterQueryParam = filterQueryParam.concat(setRangeQueryParam(filter));
    }
    else if(!!filter.list && Object.keys(filter.list).length !== 0) {
      filterQueryParam = filterQueryParam.concat(setListQueryParam(filter));
    }
    else if(!!filter.value) {
      filterQueryParam = filterQueryParam.concat(setSingleValueParam(filter));
    }
    else {
      LogService.error('filter-selection: No GroupKey defined - to build query params!');
    }
  });

  return filterQueryParam;
};

const setRangeQueryParam = (activeFilter: ActiveFilterModel) => {
  const from = stringifyValue(activeFilter?.from);
  const to = stringifyValue(activeFilter?.to);

  return `&${activeFilter.groupKey}=${from}${rangeSeperator}${to}`;
};

const setListQueryParam = (activeFilter: ActiveFilterModel) => {
  const isEmpty = !(activeFilter?.list as any).size;

  if (isEmpty) {
    return '';
  }

  const filterValueArray = [];

  for (const filter of activeFilter.list.entries()) {
    const value = stringifyValue(filter[1].get('value'));
    filterValueArray.push(encodeURIComponent(value));
  }

  return `&${activeFilter.groupKey}=${filterValueArray.join(listSeperator)}`;
};

const setSingleValueParam = (activeFilter: ActiveFilterModel) => {
  const value = stringifyValue(activeFilter?.value);

  return `&${activeFilter.groupKey}=${encodeURIComponent(value)}`;
}

const stringifyValue = (value: string | number | Date): string => {
  if((!value) || (value instanceof String && !value.length)) {
    return '';
  }

  if(value instanceof Date) {
    return value.toISOString()
  }

  return '' + value;

}
