import { IAction } from "../../interfaces/action.interface";
import { AffiliateActionTypes } from "./affiliate.action-types";

export abstract class AffiliateActions {

  static touch(updatedBy: string): IAction {
    return {
      type: AffiliateActionTypes.TOUCH_AFFILIATE,
      payload: { updatedBy }
    };
  }

}
