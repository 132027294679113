import React from "react";
import ReactQuill from "react-quill";
import "./rich-text-editor.scss";

interface IRichTextEditorProps {
  value: string;
  onChange: (content: string) => void;
  onBlur: () => void;
  readOnly?: boolean;
}

const EDITOR_MODULES_CONFIG = {
  toolbar: [
    ["bold", "italic", { "list": "bullet" }]
  ]
};

export const RichTextEditor = (props: IRichTextEditorProps) => {
  const {
    value,
    onChange,
    onBlur,
    readOnly = false
  } = props;

  return (
    <ReactQuill
      theme="snow"
      value={value}
      modules={EDITOR_MODULES_CONFIG}
      onChange={(content) => onChange(content)}
      readOnly={readOnly}
      onBlur={() => onBlur()}/>
  );
};
