import React, { useEffect, useState } from "react";
import { sliderSettings } from "../image-editor.defaults";

export interface IImageSettingsControlProps {
  label?: string,
  value: number,
  setValue: (number) => void,
  setIsTouched: (boolean) => void,
  disabled?: boolean;
}

const defaultRangeValue = (sliderSettings.max + sliderSettings.min) / 2;

export const ImageSettingsControl = React.memo((props: IImageSettingsControlProps) => {
  const {
    label,
    value,
    setValue,
    setIsTouched,
    disabled = false
  } = props;

  const [rangeValue, setRangeValue] = useState<number>(defaultRangeValue);

  useEffect(() => {
      setRangeValue(value === 0 ? defaultRangeValue : value);
  }, [value]);

  const changeHandler = (evt) => {
    setIsTouched(true);
    setValue(+evt.target.value);
    setRangeValue(evt.target.value);
  };

  return <>
    {label && <label htmlFor='brightness'>{label}</label>}
    <input
      disabled={disabled}
      onChange={changeHandler}
      type='range'
      value={rangeValue}
      min={sliderSettings.min}
      max={sliderSettings.max}
      tabIndex={0}
    />
  </>;
});
