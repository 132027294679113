import { BehaviorSubject } from 'rxjs';
import { IFlashMessage } from '../interfaces/flash-message.interface';

export class FlashMessagesService {
  private static instance: FlashMessagesService;
  readonly message$: BehaviorSubject<IFlashMessage> = new BehaviorSubject<IFlashMessage>(null);

  private constructor() {}

  static getInstance(): FlashMessagesService {
    if (!FlashMessagesService.instance) {
      FlashMessagesService.instance = new FlashMessagesService();
    }

    return FlashMessagesService.instance;
  }

  addMessage(message: IFlashMessage): void {
    FlashMessagesService.instance.message$.next(message);
  }

  static sendResponseMessage(status: number, message: string): void {
    const flashMessageService = FlashMessagesService.getInstance();

    flashMessageService.addMessage({id: Date.now(), status, text: message});
  }
}
