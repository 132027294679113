import React from "react";
import { FileType } from "../../shared/constants/file-type.enum";
import { FileUpload } from "./file-upload";
import { IPropertyMedia } from "../../shared/interfaces/property-media.interface";
import { LogService } from "../../shared/services/log.service";
import { FileService } from "../../shared/services/file-service";
import { MediaElementModel } from "../../shared/models/media-element.model";

interface IS3FileUploadProps {
  type: FileType;
  label: string;
  onChange?: (propertyImages: IPropertyMedia[]) => void;
}

export const S3FileUpload = React.memo((props: IS3FileUploadProps) => {
  const {
    type,
    label,
    onChange
  } = props;

  const uploadFile = async (file: File): Promise<IPropertyMedia> => {
    try {
      const fileName = FileService.getUniqueName(file.name);

      const result: any = await FileService.put(fileName, file);
      const imageObject = getUploadedImagesObject(result?.key, file, 0);
      return Promise.resolve(imageObject);
    } catch (error) {
      LogService.error("Failed uploading image", error);
      return Promise.reject();
    }
  };

  const uploadFiles = async (fileList: FileList): Promise<IPropertyMedia[]> => {
    const filesPromise = [];

    try {
      for (const file of fileList) {
        const uploadedMediaPromise = uploadFile(file);
        filesPromise.push(uploadedMediaPromise);
      }
      return Promise.all(filesPromise);
    } catch (error) {
      LogService.error("Failed uploading images", error);
      return Promise.reject();
    }
  };

  const handleOnChange = async (fileList: FileList): Promise<boolean> => {
    try {
      const uploadedFiles: IPropertyMedia[] = await uploadFiles(fileList);
      await onChange(uploadedFiles);
      return Promise.resolve(true);
    } catch (error) {
      return Promise.reject();
    }
  };

  const getUploadedImagesObject = (name, file: File, index): IPropertyMedia => {
    const thumbnailS3Key = FileService.getThumbnailsKey(name);

    return new MediaElementModel({
      title: file.name,
      s3Key: name,
      thumbnailS3Key,
      position: index + 1,
      fileSize: file.size,
      fileType: file.type
    })
  };

  return (
    <>
      <FileUpload
        onChange={handleOnChange}
        type={type}
        label={label}
      /></>
  );
});
