import { ISelectItem } from '../../components/inputs/interfaces/select-item.interface';

export const PropertyTypeCollectionFilter: ISelectItem[] = [
  { label: 'Wohnen', isChecked: false, value: 'housing' },
  { label: 'Gastro', isChecked: false, value: 'gastronomy' },
  { label: 'Hotel', isChecked: false, value: 'hotel' },
  { label: 'Sonstiges', isChecked: false, value: 'other' },
  { label: 'Büro', isChecked: false, value: 'office' },
  { label: 'Lager', isChecked: false, value: 'warehouse' },
  { label: 'Einzelhandel', isChecked: false, value: 'retail' }
].map((item, index) => {
  return {
    id: `property-type-${index+1}`,
    ...item
  }
});
