import { IAction } from "../../interfaces/action.interface";
import { PersonGroupActionTypes } from "./person-groups.action-types";
import { IPersonGroupParams } from "../../models/person-group.model";

export abstract class PersonGroupActions {
  static update(personGroup: IPersonGroupParams): any {
    return {
      type: PersonGroupActionTypes.UPDATE_PERSON_GROUP,
      payload: personGroup
    };
  }

  static create(payload: IPersonGroupParams): IAction {
    return {
      type: PersonGroupActionTypes.CREATE_PERSON_GROUP,
      payload
    };
  }

  static set(payload: IPersonGroupParams[]): IAction {
    return {
      type: PersonGroupActionTypes.SET_PERSON_GROUPS,
      payload
    };
  }

  static delete(id: string): IAction {
    return {
      type: PersonGroupActionTypes.DELETE_PERSON_GROUP,
      payload: id
    };
  }

  static addProperty(personGroupId: string, property: { id: string, propertyId: string, title: string }, updatedByTitle: string): IAction {
    return {
      type: PersonGroupActionTypes.ADD_PROPERTY,
      payload: { ...property, personGroupId, updatedByTitle }
    };
  }

  static removeProperty(personGroupId: string, propertyId: string, updatedByTitle: string): IAction {
    return {
      type: PersonGroupActionTypes.REMOVE_PROPERTY,
      payload: { propertyId, personGroupId, updatedByTitle }
    };
  }

}
