export enum CssSize {
  s05 = 'var(--size-05)',
  s10 = 'var(--size-10)',
  s15 = 'var(--size-15)',
  s20 = 'var(--size-20)',
  s25 = 'var(--size-25)',
  s30 = 'var(--size-30)',
  s35 = 'var(--size-35)',
  s40 = 'var(--size-40)',
  s45 = 'var(--size-45)',
  s50 = 'var(--size-50)',
  s55 = 'var(--size-55)',
  s60 = 'var(--size-60)',
  s65 = 'var(--size-65)',
  s70 = 'var(--size-70)',
  s75 = 'var(--size-75)',
  s80 = 'var(--size-80)',
  s85 = 'var(--size-85)',
  s90 = 'var(--size-90)',
  s95 = 'var(--size-95)',
  s100 = 'var(--size-100)',
  titleFontSize = 'var(--font-size-title)',
  baseFontSize = 'var(--base-font-size)',
}

export enum CssColor {
  Primary = 'var(--primary)',
  IcePrimary = 'var(--ice-primary)',
  Black = 'var(--black)',
  White = 'var(--white)',
  Grey = 'var(--grey)',
  LightGrey = 'var(--light-grey)',
  LighterGrey = 'var(--lighter-grey)',
  LighterPrimary = 'var(--lighter-primary)',
  Secondary = 'var(--secondary)',
  Green = 'var(--success)',
  Red = 'var(--danger)',
  DarkerGrey = 'var(--darker-grey)',
}

export abstract class StylesHelper {

  /**
   * This mixin creates a inset box-shadow with 1px as its default size.
   */
  static insetBorder(color: string, size: number = 1) {
    return {
      boxShadow: `inset 0 0 0 ${size}px ${color} !important`
    }
  }

  static linearGradient(deg: number = 0) {
    return {
      background: `linear-gradient(${deg}deg, var(--primary) 0%, var(--secondary) 100%)`
    }
  }
}
