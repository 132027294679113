import './table.scss';
import React, {ReactNode, useContext, useEffect, useLayoutEffect, useRef, useState} from 'react';
import {TableHeaders} from './table-headers/table-headers';
import {TablePanel} from './table-panel/table-panel';
import {TableContext} from '../../contexts/table.context';
import {LoadingStatus} from "../../shared/constants/loading-status.enum";
import {LoadingSpinnerBlueIcon} from "../icons";
import {TableFooters} from "./table-footers/table-footers";
import {StyleSheet} from "aphrodite/no-important";
import {css} from "aphrodite";
import useResizeObserver from "../../hooks/use-resize-observer";

interface ITableProps {
  striped?: boolean;
  tableClassName?: string;
  tbodyClassName?: string;
  showTablePanel?: boolean;
  children?: ReactNode;
}

/**
 * This functional Table Component is using the Table Context provided by its HOC,
 * to receive its props and to prevent multiple data pass through multiple children.
 */
export const Table = React.forwardRef((props: ITableProps, ref: any) => {
  const {
    striped,
    tableClassName,
    tbodyClassName,
    showTablePanel,
    children,
  } = props;

  const {headers, rows, loadingStatus, affixRef, xScrollable = true} = useContext(TableContext);

  const tablePanelRef = useRef();
  const contentTableRef = useRef();
  const renderTablePanel = () => showTablePanel ? <TablePanel ref={tablePanelRef}/> : <span ref={tablePanelRef}/>;

  const renderRow = () => {
    return (!rows || !rows.size) && loadingStatus === LoadingStatus.READY ?
      (
        <tr>
          <td className={'no-data-cell'} colSpan={headers?.length}>Keine Datensätze vorhanden</td>
        </tr>
      ) : children;
  }

  const renderLoadingRow = () => {
    return <caption className='loading-cell' ref={ref}>
      {(loadingStatus !== LoadingStatus.READY) && <span>
          <span className='icon-container'>
              <LoadingSpinnerBlueIcon titleAccess='Loading more...' viewBox={'0 0 16 16'} className='loading'/>
          </span>
          </span>}
    </caption>;
  }

  const [contentTableScrollLeft, setContentTableScrollLeft] = useState<number>(0);
  const [headerTableOffsetTop, setHeaderTableOffset] = useState<number>(0);
  const [contentTableTdMinWidths, setContentTableTdMinWidths] = useState<number[]>([]);

  useLayoutEffect(() => {

    const contentTableRefCurrent = contentTableRef?.current;

    const handleContentTableScroll = () => {
      window.requestAnimationFrame(() => {
        // @ts-ignore
        const newContentTableScrollLeft = contentTableRefCurrent?.scrollLeft ?? 0;

        setContentTableScrollLeft(newContentTableScrollLeft);
      });
    }

    if (xScrollable && !!contentTableRefCurrent) {
      // @ts-ignore
      contentTableRefCurrent.addEventListener('scroll', handleContentTableScroll)
    }

    return () => {
      if (xScrollable && !!contentTableRefCurrent) {
        // @ts-ignore
        return contentTableRefCurrent.removeEventListener('scroll', handleContentTableScroll);
      }
    }
  }, [loadingStatus])

  useLayoutEffect(() => {
    const tablePanelRefCurrent = tablePanelRef?.current;

    const calcTopAndSet = () => {
      // @ts-ignore
      const clientHeight = tablePanelRefCurrent.clientHeight ?? 0;
      const top = affixRef?.current?.clientHeight + clientHeight;
      setHeaderTableOffset(top)
    };

    if (!!tablePanelRefCurrent) {
      calcTopAndSet();
    }
  }, [loadingStatus]);

  const setHeadingWidths = () => {
    const contentTableRefCurrent = contentTableRef?.current;
    if (!!contentTableRefCurrent) {

      // @ts-ignore
      const firstRowTds = contentTableRefCurrent.querySelectorAll('tbody tr:first-child td');
      const newTdMinWidths = Array.from(firstRowTds)?.map((td: any) => td?.clientWidth ?? []);

      return newTdMinWidths;
    }
  }

  useLayoutEffect(() => {
    if (xScrollable) {
      const newTdMinWidths = setHeadingWidths();

      setContentTableTdMinWidths(newTdMinWidths);
    }
  }, [loadingStatus]);

  const setAffixAndTableOffsetHeight = () => {
    // @ts-ignore
    const affixAdditionalOffset = affixRef?.current?.clientHeight;

    // @ts-ignore
    const tableOffset = contentTableRef?.current?.getBoundingClientRect().top;

    let total = tableOffset + affixAdditionalOffset;

    if (!!total || total === 0) {
      //console.log("affix and tobleoffset is", total)
      document.documentElement.style.setProperty('--affix-and-table-offset-height', (total) + "px");
    }
    else {
      document.documentElement.style.removeProperty('--affix-and-table-offset-height');
    }
  }

  useEffect(() => {
    setAffixAndTableOffsetHeight();
  }, [loadingStatus])

  useResizeObserver(affixRef?.current, (entries) => {
    const first = entries[0];

    if (first) {
      setAffixAndTableOffsetHeight();
    }
  });

  useResizeObserver(contentTableRef.current, (entries) => {
    const first = entries[0];

    if (first) {
      setAffixAndTableOffsetHeight();
    }
  });


  let dynamicStyles = StyleSheet.create({
    headerTableWrapper: {
      top: headerTableOffsetTop,
    },
    headerTable: {
      transform: 'translateX(-' + (contentTableScrollLeft ?? 0) + 'px) translateZ(0)',
      willChange: 'transform',
    },
    contentTable: {
      height: 'calc(100vh - var(--affix-and-table-offset-height, 0) - var(--scrollbar-height) + var(--filter-area-height, 0) - 90px)',
      willChange: 'transform',
    }
  });

  return (
    <>
      {renderTablePanel()}
      {xScrollable && (
        <div className={css([styles.headerTableWrapper, dynamicStyles.headerTableWrapper])}>
          <table
            className={css([styles.headerTable, dynamicStyles.headerTable]) + ` table ${striped && 'striped'} ${xScrollable && 'scrollable'}`}>
            <thead>
            <TableHeaders tdMinWidths={contentTableTdMinWidths}/>
            </thead>
          </table>
        </div>)}

      <table ref={contentTableRef}
             className={css(dynamicStyles.contentTable) + ` table ${tableClassName} ${striped && 'striped'} ${xScrollable && 'scrollable'}`}>
        <tbody className={`${tbodyClassName}`}>
        {renderRow()}
        </tbody>
        {!xScrollable && (
          <thead>
          <TableHeaders tdMinWidths={contentTableTdMinWidths}/>
          </thead>

        )}
        {renderLoadingRow()}
        <tfoot className={loadingStatus !== LoadingStatus.READY ? 'loading' : ''}>
        <TableFooters tdMinWidths={contentTableTdMinWidths}/>
        </tfoot>
      </table>
    </>
  )
});

const styles = StyleSheet.create({
  headerTableWrapper: {
    display: 'flex',
    position: 'sticky',
    zIndex: 1,
    top: 150,
    backgroundColor: 'white',
    overflow: 'hidden'
  },
  headerTable: {
    overflow: 'visible'
  }
});
