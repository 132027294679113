const ADDRESS_DELIMITER = ',';
const POSTAL_CODE_CITY_DELIMITER = ' ';
const STREET_CITY_COUNTRY_SEGMENT_COUNT = 3;
const STREET_CITY_SEGMENT_COUNT = 2;

export abstract class AddressValidationHelper {

  /**
   * This method checks if the given address is valid according
   * to the following rules:
   *  - must consist of 2 - 3 segments
   *  - must contain a valid street name & number
   *  - must contain a valid postal code
   *  - must contain a valid city
   */
  static isValid(address: string): boolean {
    return (
      !!address?.length &&
      //this.hasValidSegments(address) &&
      this.hasValidStreetNameAndNumber(address) &&
      this.hasValidCity(address)
    );
  }

  static hasValidSegments(address: string): boolean {
    const segments = address.split(ADDRESS_DELIMITER);
    return (
      segments.length === STREET_CITY_COUNTRY_SEGMENT_COUNT ||
      segments.length === STREET_CITY_SEGMENT_COUNT
    );
  }

  static hasValidStreetNameAndNumber(address: string): boolean {
    const [streetAndNumberSegment] = address?.trim().split(ADDRESS_DELIMITER);
    /**
     * RegEx explanation:
     *  - not allowed to start with digits (^(?!\d+))
     *  - requires at least 2 or more characters (\w\WäöüÄÖÜß)
     *  - must include a space between street name and street number (\s+)
     *  - thereafter can be anything (.*)
     *  - must end with either or:
     *    - a digit greater than 0 ([1-9]\d*))
     *    - a ditig greater than 0 followed by 1 or 2 characters ([1-9]\d*\w{1,2}) - valid examples: 1a, 1ab, 1-2a, 2a-2b
     */
    const regex = /^(?!\d+)([\W\wäöüÄÖÜß]){2,}\s+.*([1-9]\d*\w{1,2}|[1-9]\d*)$/;

    return regex.test(streetAndNumberSegment?.trim());
  }

  static hasValidPostalCode(address: string): boolean {
    const [,postalCodeWithCity,] = address.split(ADDRESS_DELIMITER);
    const [postalCode] = postalCodeWithCity?.trim().split(POSTAL_CODE_CITY_DELIMITER);
    const regex = /^\d{5}$/;

    return regex.test(postalCode?.trim());
  }

  static hasValidCity(address: string): boolean {
    const [,postalCodeWithCity,] = address.split(ADDRESS_DELIMITER);
    const [,city] = postalCodeWithCity?.trim()?.split(POSTAL_CODE_CITY_DELIMITER) ?? [];
    const regex = /\d+/;

    return !regex.test(city);
  }
}
