import React from 'react';
import ReactDOM from 'react-dom';
import * as serviceWorker from './serviceWorker';
import {Provider} from 'react-redux'
import {store} from './shared/redux/store';
import App from './App';
import './index.scss';
import {UserProvider} from "./contexts/user.context";
import Amplify from "aws-amplify";
import awsManualConfig from "./aws-manual-config";

Amplify.configure(awsManualConfig);

ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      <UserProvider>
        <App/>
      </UserProvider>
    </Provider>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
