import { IState } from '../../interfaces/state.interface';
import { fromJS, List } from 'immutable';
import { IReducer } from '../../interfaces/reducer.interface';
import { IAction } from '../../interfaces/action.interface';
import { IntroTextsActionTypes } from './intro-texts.action-types';
import { mergeState } from '../../utils/reducer';
import { IntroTextModel } from '../../models/intro-text.model';
import { getLatestUpdatedEntry } from '../../helpers/lastest-updated-at.helper';

// TODO: Replace all new Date()'s accordingly with https://webartig.atlassian.net/browse/PUB-115
const initialState: IState = fromJS({
  templates: List<IntroTextModel>(),
  updatedAt: null,
  updatedByTitle: null
});

export const introTextsReducer: IReducer = (state = initialState, action: IAction) => {
  switch (action.type) {
    case IntroTextsActionTypes.SET_INTRO_TEXTS:
      const latestUpdated = getLatestUpdatedEntry<IntroTextModel>(action.payload);
      return mergeState(state, {
        templates: List<IntroTextModel>(action.payload?.map(data => new IntroTextModel(data))),
        updatedAt: latestUpdated?.updatedAt,
        updatedByTitle: latestUpdated?.updatedByTitle,
      })

    case IntroTextsActionTypes.DELETE_INTRO_TEXT:
      const deleteIndex = state.get('templates').findIndex((template: IntroTextModel) => template.id === action.payload);
      return mergeState(state, {
        templates: state.get('templates').remove(deleteIndex)
      })

    case IntroTextsActionTypes.UPDATE_INTRO_TEXT:
      const updateStateTemplates = state.get('templates') as List<IntroTextModel>;
      const updateIndex = updateStateTemplates.findIndex((template: IntroTextModel) => template.id === action.payload.id);
      const updateData = new IntroTextModel(action.payload);
      return mergeState(state, {
        templates: updateStateTemplates.splice(updateIndex, 1, updateData),
        updatedAt: updateData.updatedAt,
        updatedByTitle: updateData.updatedByTitle,
      })

    case IntroTextsActionTypes.CREATE_INTRO_TEXT:
      const newIntroText = new IntroTextModel({
        id: action.payload.id,
        title_de: action.payload.title_de,
        title_en: action.payload.title_en,
        firstTemplate_de: action.payload.firstTemplate_de,
        firstTemplate_en: action.payload.firstTemplate_en,
        secondTemplate_de: action.payload.secondTemplate_de,
        secondTemplate_en: action.payload.econdTemplate_en,
        thirdTemplate_de: action.payload.thirdTemplate_de,
        thirdTemplate_en: action.payload.thirdTemplate_en,
      });

      const createStateTemplates = state.get('templates') as List<IntroTextModel>;

      return mergeState(state, {
        templates: List([newIntroText, ...createStateTemplates]),
        updatedAt: action.payload.updatedAt,
        updatedByTitle: action.payload.updatedByTitle,
      });

    case IntroTextsActionTypes.ADD_POSTAL_CODE:
      const updatedState = ((state: IState) => {
        const currentTemplates = state.get('templates') as IntroTextModel[];
        const selectedTemplate = currentTemplates.find((template) => template.id === action.payload.introTextId);
        const postalCodes = List(selectedTemplate.postalCodes);
        const updatedPostalCodes = postalCodes.push(action.payload.postalCode);
        return {
          templates: currentTemplates.map(template => {
            if (template.id !== action.payload.introTextId) {
              return template;
            }

            return template.set('postalCodes', updatedPostalCodes.toJSON());
          }),
          updatedAt: new Date().toISOString(),
          updatedByTitle: action.payload.updatedByTitle,
        };
      })(state);
      return mergeState(state, updatedState);

    case IntroTextsActionTypes.REMOVE_POSTAL_CODE:
      const currentTemplates = state.get('templates') as IntroTextModel[];
      const selectedTemplate = currentTemplates.find((template) => template.id === action.payload.introTextId);
      const postalCodeIndex = selectedTemplate.postalCodes.indexOf(action.payload.postalCode);
      const updatedPostalCodes = List(selectedTemplate.postalCodes).remove(postalCodeIndex);

      return mergeState(state, {
        templates: currentTemplates.map(template => {
          if (template.id !== action.payload.introTextId) {
            return template;
          }
          return template.set('postalCodes', updatedPostalCodes.toJSON());
        }),
        updatedAt: new Date().toISOString(),
        updatedByTitle: action.payload.updatedByTitle,
      });

    default:
      return state;
  }
}
