import { ISelectItem } from '../../components/inputs/interfaces/select-item.interface';

export const PropertyStatusCollectionFilter: ISelectItem[] = [
  { label: 'Alle', isChecked: true, value: '' },
  { label: 'Neu', isChecked: false, value: 'new' },
  { label: 'In Bearbeitung', isChecked: false, value: 'inProgress' },
  { label: 'Fertig', isChecked: false, value: 'done' },
].map((item, index) => {
  return {
    id: `status-${index+1}`,
    ...item
  }
});
