import { AddressAutofill } from "../enums/autofill-address.enum";
import { InputType } from "../enums/input-type.enum";
import { TemplateType } from "../enums/template-type.enum";
import { IFormFieldset } from "../interfaces/form-fieldset.interface";
import { PropertyInputDefinitions } from "./property-input-definitions.const";
import { GroupSize } from "../enums/group-size.enum";
import { FieldSingleConfig } from "../config/field-single.config";
import { Language } from "../../../shared/constants/language.enum";

export const CreatePropertyFormDefinition: IFormFieldset[] = [
  {
    name: 'basics',
    groups: [
      {
        title: 'Basics',
        size: GroupSize.Full,
        fields: [
          FieldSingleConfig.getConfig(
          {
            name: 'title',
            label: 'Name',
          })
        ],
      },
    ],
  },
  {
    name: 'addressGroup',
    groups: [
      {
        title: 'Adresse',
        size: GroupSize.Full,
        fields: [
          {
            ...PropertyInputDefinitions.MapboxAddress,
          },
          {
            templateType: TemplateType.FIELD_SINGLE,
            inputType: InputType.TEXT,
            name: AddressAutofill.federalState,
            label: 'Bundesland',
            placeholderText: 'Wird durch Postleitzahl befüllt',
            isAutofill: true,
          },
          {
            templateType: TemplateType.FIELD_SINGLE,
            inputType: InputType.TEXT,
            name: AddressAutofill.region,
            label: 'Region',
            placeholderText: 'Wird durch Regionenraster befüllt',
            isAutofill: true,
          },
          {
            templateType: TemplateType.FIELD_SINGLE,
            inputType: InputType.TEXT,
            name: AddressAutofill.country_de,
            i18n: Language.GERMAN,
            label: 'Land',
            placeholderText: 'Wird durch Postleitzahl befüllt',
            isAutofill: true,
          },
          {
            templateType: TemplateType.FIELD_SINGLE,
            inputType: InputType.TEXT,
            name: AddressAutofill.country_en,
            i18n: Language.ENGLISH,
            label: 'Land',
            placeholderText: 'Wird durch Postleitzahl befüllt',
            isAutofill: true,
          },
        ],
      },
    ],
  },
];
