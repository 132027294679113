import { IState } from "../../interfaces/state.interface";
import { createSelector } from "../../utils/reducer";
import { AffiliateModel } from "../../models/affiliate.model";
import { TenancyModel } from "../../models/tenancy.model";
import { List } from "immutable";
import { OwnerModel } from "../../models/owner.model";
import { PropertyModel } from "../../models/property.model";
import { ISelectableObject } from "../../interfaces/selectable.interface";
import { AgentModel } from "../../models/agent.model";

//TODO refactor connector selctors to use objectID and not fieldId!!!

const baseAffiliateSelector = ['affiliate'];

export const selectAffiliate = (state: IState): AffiliateModel => state.getIn([...baseAffiliateSelector, 'data']);

export const selectIsLoading = (state: IState): AffiliateModel => state.getIn([...baseAffiliateSelector, 'isLoading']);

export const selectAffiliateOwners = (state: IState): List<OwnerModel> => state.getIn([...baseAffiliateSelector, 'owners']);

export const selectAffiliateOwnerByPropertyId = (state: IState, propertyId: number): OwnerModel => selectAffiliateOwners(state)?.find((value) => value?._propertyId === propertyId);

export const selectAffiliateTenancies = (state: IState): any => state.getIn([...baseAffiliateSelector, 'tenancies']);

export const selectAffiliateOwnerAgentObjects = (state: IState): any => state.getIn([...baseAffiliateSelector, 'ownerAgentObjects']);

export const selectAffiliateTenancyAgentObjects = (state: IState): any => state.getIn([...baseAffiliateSelector, 'tenancyAgentObjects']);

export const selectAffiliateOwnerAgentObjectsByPropertyId = (state: IState, propertyId: number): OwnerModel => selectAffiliateOwnerAgentObjects(state)?.find((value) => value?._propertyId === propertyId);

export const selectAffiliateTenancyAgentObjectsByPropertyId = (state: IState, propertyId: number): OwnerModel => selectAffiliateTenancyAgentObjects(state)?.find((value) => value?._propertyId === propertyId);

export const selectAffiliateOwnerPropertySelectorObject = (state: IState, propertyId: number): ISelectableObject => {
  return PropertyModel.getSelectableObject(selectAffiliateOwnerByPropertyId(state, propertyId)?.property);
};

export const selectAffiliateOwnersAgentAffiliateSelectorObject = (state: IState, agentId: number, agentSuffix = ''): any => {
  const ownerList: List<OwnerModel> = selectAffiliateOwners(state);
  const owner: OwnerModel = ownerList?.find((value) => value?.["_agentId" + agentSuffix] === agentId);
  return AffiliateModel.getSelectableObject(owner?.["agent" + agentSuffix]);
};

export const selectAffiliateTenancyAgentSelectorObject = (state: IState, agentId: number, agentSuffix = ''): ISelectableObject => {
  const tenancy: TenancyModel = selectAffiliateTenancies(state)?.find((value) => value?.["_agentId" + agentSuffix] === agentId);
  return AffiliateModel.getSelectableObject(tenancy?.["agent" + agentSuffix]);
};

export const selectAffiliateTenancyPropertySelectorObject = (state: IState, propertyId: number): ISelectableObject => {
  const tenancy: TenancyModel = selectAffiliateTenancies(state)?.find((value) => value?._propertyId === propertyId);
  return PropertyModel.getSelectableObject(tenancy?.property);
};

export const selectAffiliateOwnerAgentObjectsPropertySelectorObject = (state: IState, propertyId: number): ISelectableObject => {
  return PropertyModel.getSelectableObject(selectAffiliateOwnerAgentObjectsByPropertyId(state, propertyId)?.property);
};

export const selectAffiliateTenancyAgentObjectsPropertySelectorObject = (state: IState, propertyId: number): ISelectableObject => {
  const property = selectAffiliateTenancyAgentObjectsByPropertyId(state, propertyId)?.property;
  return PropertyModel.getSelectableObject(property);
};

export const selectAffiliateOwnerAgentObjectsAffiliateSelectorObject = (state: IState, agentId: number): any => {
  const agentObjects: List<AgentModel> = selectAffiliateOwnerAgentObjects(state);
  const agentObject: AgentModel = agentObjects?.find((value) => value?._affiliateId === agentId);
  return AffiliateModel.getSelectableObject(agentObject?.affiliate);
};

export const selectAffiliateTenancyAgentObjectsAffiliateSelectorObject = (state: IState, agentId: number): any => {
  const agentObjects: List<AgentModel> = selectAffiliateTenancyAgentObjects(state);
  const agentObject: AgentModel = agentObjects?.find((value) => value?._affiliateId === agentId);
  return AffiliateModel.getSelectableObject(agentObject?.affiliate);
};

export const selectAffiliateWithAddress = createSelector(
  [selectAffiliate],
  (affiliate: AffiliateModel) => {
    return affiliate?.merge({
      address: affiliate.fullAddress
    });
  }
);

export const selectAffiliateId = createSelector(
  [selectAffiliate],
  (affiliate) => affiliate.id
);

export const selectAffiliateName = createSelector(
  [selectAffiliate],
  (affiliate) => affiliate.affiliateName
);

export const selectAffiliateUpdatedAt = createSelector(
  [selectAffiliate],
  (affiliate) => affiliate.updatedAt
);

export const selectAffiliateUpdatedBy = createSelector(
  [selectAffiliate],
  (affiliate) => affiliate.updatedByTitle
);
