import { IAction } from '../../interfaces/action.interface';
import { IRegionsParams } from '../../models/region.model';
import { RegionsActionTypes } from './regions.action-types';

export abstract class RegionActions {
  static update(template: IRegionsParams): any {
    return {
      type: RegionsActionTypes.UPDATE_REGION,
      payload: template
    }
  }

  static create(payload: IRegionsParams): IAction {
    return {
      type: RegionsActionTypes.CREATE_REGION,
      payload
    };
  }

  static set(payload: IRegionsParams[]): IAction {
    return {
      type: RegionsActionTypes.SET_REGIONS,
      payload
    }
  }

  static delete(id: string): IAction {
    return {
      type: RegionsActionTypes.DELETE_REGION,
      payload: id
    }
  }

  static addPostalCode(regionId: string, postalCode: string, updatedByTitle: string): IAction {
    return {
      type: RegionsActionTypes.ADD_POSTAL_CODE,
      payload: { postalCode, regionId, updatedByTitle }
    }
  }

  static removePostalCode(regionId: string, postalCode: string, updatedByTitle: string): IAction {
    return {
      type: RegionsActionTypes.REMOVE_POSTAL_CODE,
      payload: { postalCode, regionId, updatedByTitle }
    }
  }
}
