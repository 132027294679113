import React, { useEffect, useRef, useState } from 'react';
import { IFlashMessage, IResponseStatusCode } from '../../../shared/interfaces/flash-message.interface';
import { css, StyleSheet } from 'aphrodite/no-important';
import { SuccessIcon } from '../../icons/collection/success-icon';
import { ErrorIcon } from '../../icons/collection/error-icon';
import { SvgVariant } from '../../icons/enum/svg-variant.enum';

interface IMessageProps extends IFlashMessage {
  index: number;
  removeHandler: (index: number) => void;
}

const FADE_OUT_TIMER = 4500;
const REMOVE_TIMER = 500;

export const Message = React.memo((props: IMessageProps) => {
  const {
    id,
    status,
    text,
    index,
    removeHandler
  } = props;
  const messageRef = useRef(null);
  const [ fadeOut, setFadeOut ] = useState(false);

  useEffect(() => {
    const elementRef = messageRef.current;

    const timeout = setTimeout(() => {
      if (elementRef) {
        setFadeOut(true);

        setTimeout(() => {
          removeHandler(id);
        }, REMOVE_TIMER);
      }
    }, FADE_OUT_TIMER);

    return () => {
      if (!elementRef) {
        clearTimeout(timeout);
      }
    }
  }, [id, removeHandler])

  const getStyles = () => {
    return css(
      styles.message,
      styles.fadeIn,
      status === IResponseStatusCode.ERROR ? styles.error : styles.success,
      fadeOut ? styles.fadeOut : null
    );
  }

  const getIconByStatus = () => {
    return status === IResponseStatusCode.ERROR ?
      <ErrorIcon className={css(styles.icon)} color={SvgVariant.White} viewBox={'0 0 50 50'} titleAccess={'Ein Fehler ist aufgetreten'} /> :
      <SuccessIcon className={css(styles.icon)} color={SvgVariant.White} viewBox={'0 0 50 50'} titleAccess={'Datensatz erfolgreich aktualisiert'} />
  }

  return (
    <div ref={messageRef}
         style={{ marginTop: `${60 * index}px`, zIndex: index + 1 }}
         className={getStyles()}>
      {getIconByStatus()} {text}
    </div>
  )
});

const fadeInKeyFrames = {
  'from': {
    opacity: 0,
    transform: 'translateY(-20px) translateX(-50%)'
  },
  'to': {
    opacity: 1,
    transform: 'translateY(0) translateX(-50%)'
  }
};

const fadeOutKeyFrames = {
  'from': {
    opacity: 1,
    transform: 'translateY(0) translateX(-50%)'
  },
  'to': {
    opacity: 0,
    transform: 'translateY(-20px) translateX(-50%)'
  }
}

const styles = StyleSheet.create({
  message: {
    display: 'flex',
    alignItems: 'center',
    paddingRight: 'var(--size-15)',
    color: 'var(--white)',
    borderRadius: 'var(--base-radius)',
    position: 'absolute',
    top: 0,
    left: 0,
    transform: 'translateX(-50%)',
    transition: 'margin-top 500ms cubic-bezier(1, 0.2, 0.0, 0.4)',
    whiteSpace: 'nowrap',
    height: 'var(--size-50)',
    filter: 'var(--base-drop-shadow)',
    willChange: 'filter, transform, opacity'
  },
  fadeIn: {
    animationName: fadeInKeyFrames,
    animationDuration: '500ms',
    animationFillMode: 'forward',
    animationTimingFunction: 'cubic-bezier(0.4, 0.0, 0.2, 1)',
    opacity: 1
  },
  fadeOut: {
    animationName: fadeOutKeyFrames,
    animationDuration: '500ms',
    animationFillMode: 'forward',
    animationTimingFunction: 'cubic-bezier(1, 0.2, 0.0, 0.4)',
    opacity: 0
  },
  success: {
    backgroundColor: 'var(--success)'
  },
  error: {
    backgroundColor: 'var(--danger)',
  },
  icon: {
    width: 'var(--size-50)',
    height: 'var(--size-50)'
  }
})
