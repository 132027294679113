import React from "react";
import { List } from "immutable";
import { ActiveFilterModel } from "../../shared/models/active-filter.model";
import { Button, ButtonColor, ButtonSize } from "../button";
import { SvgVariant } from "../icons/enum/svg-variant.enum";
import { ResetIcon } from "../icons/collection/reset-icon";
import { FilterNode } from "./filter-node/filter-node";

interface IFilterSelectionProps {
  activeFilter?: List<ActiveFilterModel>;
  resetFilter: (groupKey: string) => void;
  resetAllFilter: () => void;
}

export const FilterSelectionWithoutStore = React.forwardRef((props: IFilterSelectionProps, ref: any) => {

  const {
    activeFilter,
    resetFilter,
    resetAllFilter
  } = props;

  const isVisible = activeFilter?.size ? "is-visible" : "";

  const resetAllNode = !!isVisible &&
    <li key={"properties-reset-all"}>
      <Button onClick={() => resetAllFilter?.()}
              size={ButtonSize.Small}
              color={ButtonColor.Primary}
              borderRadius={"large"}
              label={"Alle Filter zurücksetzen"}>
        <ResetIcon color={SvgVariant.White} viewBox={"0 0 30 30"} titleAccess={"Alle Filter zurücksetzen"}/>
      </Button>
    </li>;

  return (
    <ul className={`filter-selection-overview ${isVisible}`} ref={ref}>
      {resetAllNode}
      {activeFilter?.map((filter: ActiveFilterModel, index: number) => <FilterNode key={`${filter.groupKey}-${index}`}
                                                                                   filter={filter}
                                                                                   resetFilter={resetFilter}/>)}
    </ul>
  );
});
