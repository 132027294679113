import { useEffect } from 'react';
import _ from "lodash";
import {useInView} from "react-intersection-observer";

export function useLoadMoreIntersectionObserver(root, currentListSize, totalListSize, isReady, loadMoreCallback) {
  const { ref:loadMoreRef, inView, entry } = useInView({
    root,
    rootMargin: '0px 0px 1500px',
    threshold: 0.1
  });

  useEffect(() => {
    const debounced = _.debounce(() => {
      //console.log("inView debounced", inView);
      //console.log("ready", isReady);

      //console.log("currentSize", currentListSize);
      //console.log("totalSize", totalListSize);

      const doLoadMore = inView && isReady && +currentListSize < +totalListSize;
      //console.log("doLoadMore", doLoadMore)
      //console.log("\n");

      if(doLoadMore) {
        loadMoreCallback?.();
      }
    }, 150);

    if(isReady && currentListSize > 0) {
      debounced();
    }

    return () => {
      if(!!debounced) {
        debounced.cancel();
      }
    }
  }, [isReady, inView, currentListSize, totalListSize, loadMoreCallback])

  return {loadMoreRef, inView, entry};
}

