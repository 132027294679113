import { IState } from '../../shared/interfaces/state.interface';
import { DatasetHistory } from './dataset-history';
import { connect } from 'react-redux';
import { selectAffiliateUpdatedAt, selectAffiliateUpdatedBy } from '../../shared/redux/affiliate/affiliate.selectors';

const mapStateToProps = (state: IState) => {
  return {
    updatedAt: selectAffiliateUpdatedAt(state),
    updatedBy: selectAffiliateUpdatedBy(state)
  };
};

export const AffiliateDatasetHistory = connect(mapStateToProps, null)(DatasetHistory);