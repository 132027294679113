import { Record } from 'immutable';

export interface IRemarkParams {
  id: string;
  content_de: string;
  content_en: string;
  date: Date;
  isVisible: boolean;
  createdAt?: Date;
  updatedAt?: Date;
  updatedByTitle?: string;
  deletedAt?: Date;
  updatedBy?: string;
}

const defaults: IRemarkParams = {
  id: '',
  content_de: '',
  content_en: '',
  date: null,
  isVisible: true,
  createdAt: null,
  updatedAt: null,
  updatedByTitle: '',
  deletedAt: null,
  updatedBy: null
};

export class RemarkModel extends Record<IRemarkParams>(defaults) {
  constructor(params?: IRemarkParams) {
    params ? super(params) : super();
  }

  with(values: IRemarkParams): this {
    return this.merge(values) as this;
  }
}
