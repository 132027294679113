import * as Yup from 'yup';
import { AddressValidationHelper } from '../../../shared/helpers/address-validation.helper';

export const AffiliateValidationSchema: Yup.ObjectSchema = Yup.object().shape({
    affiliateName: Yup.string()
        .nullable()
        .required('Dies ist ein Pflichtfeld.'),
    address: Yup.string()
        .nullable()
        .test('address', 'Die eingegeben Adresse ist nicht valide.', (value) => AddressValidationHelper.isValid(value))
        .required('Dies ist ein Pflichtfeld.')
})