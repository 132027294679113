import React, { ReactNode } from "react";
import { AmplifyAuthenticator, AmplifySignIn } from "@aws-amplify/ui-react";
import { CssSize, StylesHelper } from "../shared/helpers/styles.helper";
import { css, StyleSheet } from "aphrodite";
import { useUser } from "../contexts/user.context";

const logo = require("../assets/logo/logo.png");

interface IAuthenticationViewProps {
  children?: ReactNode;
}

export const AuthenticationContainer = React.memo((props: IAuthenticationViewProps) => {
  const { user } = useUser();

  return (<>
    {!user && <>
      <div className={css(styles.loginView)}/>
      <img src={logo}
           alt="Publity - Real Estate Management"
           className={css(styles.logo)}/>
    </>}
    <div className={css(!user ? styles.authenticator : null)}>
      <AmplifyAuthenticator usernameAlias="email">
        <AmplifySignIn slot="sign-in" usernameAlias="email" hideSignUp={true}/>
        {props.children}
      </AmplifyAuthenticator></div>
  </>);
});

const styles = StyleSheet.create({
  loginView: {
    position: "relative",
    width: "100vw",
    height: "100vh",
    ...StylesHelper.linearGradient(90)
  },
  logo: {
    position: "absolute",
    top: CssSize.s60,
    left: CssSize.s60,
    width: "365px",
    height: "auto"
  },
  authenticator: {
    position: "absolute",
    left: "50%",
    top: "50%",
    transform: "translate(-50%, -50%)"
  }
});

export default AuthenticationContainer;
