import { SettingsActionTypes } from "./settings.action-types";

export abstract class SettingsActions {

  static setLanguage(language: string) {
    return {
      type: SettingsActionTypes.SET_LANGUAGE,
      payload: {
        language
      }
    }
  }

}
