import React, {useEffect, useRef, useState} from "react";
import {css, StyleSheet} from "aphrodite/no-important";
import {connect} from "react-redux";
import {IState} from "../../../../shared/interfaces/state.interface";
import {useUser} from "../../../../contexts/user.context";
import {Fieldset} from "../../../fieldset/fieldset";
import {selectPropertyWithAddress} from "../../../../shared/redux/property/property.selectors";
import {KeyValuePairs} from "../../../../shared/types/key-value-pairs.type";
import {performPutRequest} from "../../../../shared/redux/middleware/api.middleware";
import {ApiEndpoint} from "../../../../shared/constants/api-endpoint.enum";
import {PropertyActionTypes} from "../../../../shared/redux/property/property.action-types";
import {PropertyModel} from "../../../../shared/models/property.model";
import {RichTextEditor} from "../../../inputs/rich-text-editor/rich-text-editor";
import {Formik} from "formik";
import {Language} from "../../../../shared/constants/language.enum";
import {CssColor} from "../../../../shared/helpers/styles.helper";
import {KeyCodeHelper} from "../../../../shared/helpers/key-code.helper";

interface ISpotterRemarkProps {
  property: PropertyModel;
  updateProperty?: (data: KeyValuePairs) => void;
}

export const SpotterRemark = (props: ISpotterRemarkProps) => {
  const {
    property,
    updateProperty,
  } = props;

  const {getGroups} = useUser();

  const [writePermissions, setWritePermissions] = useState(false);

  useEffect(() => {
    if (getGroups()?.includes("admin") || getGroups()?.includes("editor")) {
      setWritePermissions(true);
    }
  }, [getGroups]);

  const handleOnBlur = (spotterRemarkContent: string) => {
    if (!writePermissions) {
      return;
    }

    // @ts-ignore
    const distinctText = spotterRemarkContent !== property.get('spotterRemarkContent');

    if (distinctText ) {
      updateProperty({spotterRemarkContent, propertyId: property.propertyId})
    }
  };

  const handleOnKeyDown = (event) => {
    if(!writePermissions) {
      return;
    }

    if (KeyCodeHelper.isEnter(event.keyCode)) {
      event.target.blur();
    }
  };

  return (
    <>
      <Fieldset>
        <Fieldset.Legend withSeparator={false}>
          <div className={css(styles.legendTitle)}>Notiz</div>
        </Fieldset.Legend>
        <Fieldset.Content>
          <div>

                <textarea
                  cols={20}
                  rows={10}
                  readOnly={!writePermissions}
                  disabled={!writePermissions}
                  className={css(!writePermissions && styles.readOnly)}
                  defaultValue={property?.get("spotterRemarkContent") ?? ""}
                  onKeyDown={(event) => handleOnKeyDown(event)}
                  onBlur={({ target: { value } }) => handleOnBlur(value)}/>
          </div>
        </Fieldset.Content>
      </Fieldset>
    </>
  );
};

const styles = StyleSheet.create({
  legendTitle: {
    fontSize: "var(--font-size-title)",
    fontWeight: "bold"
  },
  readOnly: {
    color: CssColor.Grey
  }
});


const mapStateToProps = (state: IState) => {
  return {
    property: selectPropertyWithAddress(state)
  };
};

const mapDispatchToProps = (dispatch: any) => ({
  updateProperty: (data: KeyValuePairs) => dispatch(
    performPutRequest(
      ApiEndpoint.PROPERTY,
      data,
      PropertyActionTypes.FETCH_IN_PROGRESS,
      PropertyActionTypes.SET_PROPERTY,
      PropertyActionTypes.FETCH_FINISHED
    )
  ),
});

export const SpotterRemarkWithStore = connect(mapStateToProps, mapDispatchToProps)(SpotterRemark);
