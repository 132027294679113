import { Record } from 'immutable';
import { IAction } from '../../../../shared/interfaces/action.interface';
import { useReducer } from 'react';

interface IIntroTextsStateParams {
    status?: IntroTextsStatus;
}

enum IntroTextsStatus {
    initial = 'initial',
    createNew = 'createNew',
    ready = 'ready',
}

enum IntroTextsStateActionTypes {
    Set = 'Set',
    CreateNew = 'CreateNew',
}

const defaults: IIntroTextsStateParams = {
    status: IntroTextsStatus.initial,
};

class IntroTextsState extends Record<IIntroTextsStateParams>(defaults) {
    constructor(params?: IIntroTextsStateParams) {
        params ? super(params) : super();
    }

    with(values: IIntroTextsStateParams): this {
        return this.merge(values) as this;
    }
}


function reducer(state: IntroTextsState, action: IAction): IntroTextsState {
    switch (action.type) {
        case IntroTextsStateActionTypes.Set:
            return new IntroTextsState({
                ...state,
                status: IntroTextsStatus.ready
            });


        case IntroTextsStateActionTypes.CreateNew:
            return new IntroTextsState({
                ...state,
                status: IntroTextsStatus.createNew
            });

        default: return state;
    }
}

abstract class IntroTextsStateActions {
    static get set() { return { type: IntroTextsStateActionTypes.Set }; }
    static get add() { return { type: IntroTextsStateActionTypes.CreateNew }; }
}

const initialIntroTextsState: IntroTextsState = new IntroTextsState(defaults);

export function useIntroTextsState() {
    const [state, dispatch] = useReducer(reducer, initialIntroTextsState);

    const isInitial = state.status === IntroTextsStatus.initial;
    const isNew = state.status === IntroTextsStatus.createNew;
    const isReady = state.status === IntroTextsStatus.ready;

    return {
        state: { isInitial, isNew, isReady, ...state },
        setCreateNewState: () => dispatch(IntroTextsStateActions.add),
        setUpdateState: () => dispatch(IntroTextsStateActions.set)
    };
}