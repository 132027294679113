import React, { ReactNode } from 'react';
import './info-badge.scss';

interface IInfoBadeProps {
  title: string;
  children: ReactNode,
  className?: string;
  subTitle?: string;
  titleClassName?: string;
}

export const InfoBadge = (props: IInfoBadeProps) => {
  const {
    className,
    children,
    title,
    subTitle,
    titleClassName
  } = props;

  return (
    <div className={`info-badge ${className ?? ''}`}>
      <div className="icon-container">
        {children}
      </div>
      <div className={`title-container ${titleClassName ?? ''}`}>
        <span className={'title'}>{title}</span>
        {subTitle?.length && (
          <span className='sub-title'>{subTitle}</span>
        )}
      </div>
    </div>
  )
};
