import { IState } from '../../interfaces/state.interface';
import { createSelector } from 'reselect';

const basePropertyMediasSelector = ['propertyMediaVideo'];
const selectPropertyMediaVideoBase = (state: IState): IState => state.getIn([...basePropertyMediasSelector]);

export const selectPropertyMediaVideos = createSelector(
  [selectPropertyMediaVideoBase],
  (propertyMediaState) => {
    return propertyMediaState.get('videos');
  }
);

export const selectPropertyMediaVideoUpdatedAt = createSelector(
  [selectPropertyMediaVideoBase],
  (propertyMediaState) => {
    return propertyMediaState.get('updatedAt');
  }
);

export const selectMediaVideoHightlight = createSelector(
  [selectPropertyMediaVideoBase],
  (propertyMediaState) => {
    return propertyMediaState.get('highlightId') as string;
  }
)

export const selectMediaVideoSize = createSelector(
  [selectPropertyMediaVideoBase],
  (propertyMediaState) => {
    return propertyMediaState.get('videos').size as number;
  }
)
