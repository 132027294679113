import React from 'react';
import {css, StyleSheet} from 'aphrodite';

interface IMapboxSuggestionItemProps {
  value: string | any;
  text?: string;
  isValid?: boolean;
  onSelect: (value: string) => void;
  tabIndex?: number;
  [rest: string]: any;
}

export const MapboxSuggestionItem = (props: IMapboxSuggestionItemProps) => {
  const {
    value,
    text = value?.length ? value : '',
    isValid = true,
    tabIndex,
    onSelect,
    ...rest
  } = props;

  const buttonClassName = isValid ? '' : css(styles.disableItem);

  return (
    <li className='suggest-item is-result'>
      <button
        type='button'
        onClick={() => onSelect(value)}
        tabIndex={tabIndex}
        disabled={!isValid}
        className={buttonClassName}
        {...rest}
      >
        {text}
      </button>
    </li>
  );
};

const styles = StyleSheet.create({
  disableItem: {
    fontStyle: 'italic'
  }
});
