import { IState } from '../../interfaces/state.interface';
import { createSelector } from 'reselect';
import { RegionModel } from '../../models/region.model';
import { List } from 'immutable';

const baseRegionsSelector = ['regions'];

const selectRegionsBase = (state: IState): IState => state.getIn([...baseRegionsSelector]);

export const selectRegionsUpdatedAt = createSelector(
  [selectRegionsBase],
  (regionsState) => {
    return regionsState.get('updatedAt');
  }
);

export const selectRegionsUpdatedBy = createSelector(
  [selectRegionsBase],
  (regionsState) => {
    return regionsState.get('updatedByTitle');
  }
);

export const selectRegions = createSelector(
  [selectRegionsBase],
  (regionsState) => {
    return regionsState.get('regions')
  }
)

export const selectRegionById = (regionId: string) => createSelector(
  [selectRegionsBase],
  (regionsState) => {
    const regions =  regionsState.get('regions') as List<RegionModel>;
    return regions.find((region) => region.id === regionId);
  }
);

export const selectPostalCodeCountByIntroText = (regionId: string) => createSelector(
  [selectRegionById(regionId)],
  (region) => {
    return region?.postalCodes?.length;
  }
);
