import '../filter.scss';
import React from 'react';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import { PropertyTenantCollectionFilter } from '../../../shared/mock/property-tenant-collection.filter';
import { PropertyTypeCollectionFilter } from '../../../shared/mock/property-type-collection.filter';
import { Dropdown } from '../../dropdown/dropdown';
import { NumericSeparator } from '../../inputs/constants/numeric-separators.enum';
import {RangeInput, RangeInputType} from '../../inputs/numeric-range-input/range-input';
import { PropertyConditionCollectionFilter } from '../../../shared/mock/property-condition-collection.filter';
import { PropertyStatusCollectionFilter } from '../../../shared/mock/property-status-collection.filter';
import { PropertyHasImagesCollectionFilter } from '../../../shared/mock/property-has-images-collection.filter';
import { SearchInput } from '../../inputs/search-input/search-input';
import { PropertyFilterKey } from '../../../shared/constants/property-filter-key.enum';
import { CollapsableContainer } from '../../collapsable-container/collapsable-container';
import { SearchService } from '../../../shared/services/search.service';
import { FilterSelectList } from '../../filter-select-list/filter-select-list';
import { ActiveFilterModel } from '../../../shared/models/active-filter.model';
import { IFilterParam } from '../../../shared/interfaces/filter-param.interface';
import '../filter.scss';
import { ApiEndpoint } from '../../../shared/constants/api-endpoint.enum';
import {
  selectActiveFilter,
  selectAreaTypeActiveFilter,
  selectConditionActiveFilter,
  selectConstructionYearActiveFilter,
  selectAverageRentalChargeActiveFilter,
  selectHasImagesActiveFilter,
  selectLocationActiveFilter,
  selectOfficeAreaActiveFilter,
  selectOfficeAreaTypeActiveFilter,
  selectPostalCodeActiveFilter,
  selectPropertyIdActiveFilter,
  selectPurchasePriceActiveFilter,
  selectRegionActiveFilter,
  selectStatusActiveFilter,
  selectStreetActiveFilter,
  selectTitleActiveFilter,
  selectTotalAreaActiveFilter,
  selectVacancyRateActiveFilter,
  selectTenantTypeActiveFilter,
  selectOwnerActiveFilter, selectTenantActiveFilter, selectAgentActiveFilter, selectPurchaseDateActiveFilter
} from "../../../shared/redux/properties/properties.selectors";
import { resetPropertiesFilter, setPropertiesFilter } from "../../../shared/redux/middleware/filter.middleware";
import { connect } from "react-redux";
import { useCurrentLanguage } from "../../../contexts/current-language.context";
import {UnitSymbol} from "../../form/enums/unit-symbol.enum";

interface IPropertiesFilterProps {
  propertyIdActiveFilter: ActiveFilterModel,
  titleActiveFilter: ActiveFilterModel,
  streetActiveFilter: ActiveFilterModel,
  postalCodeActiveFilter: ActiveFilterModel,
  locationActiveFilter: ActiveFilterModel,
  regionActiveFilter: ActiveFilterModel,
  officeAreaActiveFilter: ActiveFilterModel,
  constructionYearActiveFilter: ActiveFilterModel,
  averageRentalChargeActiveFilter: ActiveFilterModel,
  totalAreaActiveFilter: ActiveFilterModel,
  purchasePriceActiveFilter: ActiveFilterModel,
  purchaseDateActiveFilter: ActiveFilterModel;
  vacancyRateActiveFilter: ActiveFilterModel,
  areaTypeActiveFilter: ActiveFilterModel,
  tenantTypeActiveFilter: ActiveFilterModel,
  conditionActiveFilter: ActiveFilterModel,
  statusActiveFilter: ActiveFilterModel,
  hasImagesActiveFilter: ActiveFilterModel,
  ownerActiveFilter: ActiveFilterModel;
  tenantActiveFilter: ActiveFilterModel;
  agentActiveFilter: ActiveFilterModel;

  setFilter: (filter: IFilterParam) => void;
  resetFilter: (groupKey: string) => void;
}

const PropertiesFilterWithoutStore = (props: IPropertiesFilterProps) => {
  const {
    propertyIdActiveFilter,
    titleActiveFilter,
    streetActiveFilter,
    postalCodeActiveFilter,
    locationActiveFilter,
    regionActiveFilter,
    officeAreaActiveFilter,
    constructionYearActiveFilter,
    averageRentalChargeActiveFilter,
    totalAreaActiveFilter,
    purchasePriceActiveFilter,
    purchaseDateActiveFilter,
    vacancyRateActiveFilter,
    areaTypeActiveFilter,
    tenantTypeActiveFilter,
    conditionActiveFilter,
    statusActiveFilter,
    hasImagesActiveFilter,
    ownerActiveFilter,
    tenantActiveFilter,
    agentActiveFilter,
    setFilter,
    resetFilter
  } = props;

  const currentLanguage = useCurrentLanguage();

  return (
    <>
      <CollapsableContainer>
        {{
          baseFilter: (
            <Row>
              <Col xs={6} lg={4} xl={3}>
                <SearchInput storedValue={locationActiveFilter}
                             searchService={new SearchService(`${ApiEndpoint.PROPERTY_SUGGESTIONS}?${PropertyFilterKey.LOCATION}=`)}
                             groupKey={PropertyFilterKey.LOCATION}
                             placeHolder={'Stadt'}
                             setFilter={setFilter}
                             resetFilter={resetFilter} />
              </Col>
              <Col xs={6} lg={4} xl={3}>
                <SearchInput storedValue={streetActiveFilter}
                             searchService={new SearchService(`${ApiEndpoint.PROPERTY_SUGGESTIONS}?${PropertyFilterKey.STREET}=`)}
                             groupKey={PropertyFilterKey.STREET}
                             placeHolder={'Straße'}
                             setFilter={setFilter}
                             resetFilter={resetFilter} />
              </Col>
              <Col xs={6} lg={4} xl={3}>
                <Dropdown buttonLabel={'Postleitzahl'} btnClass={'fullwidth'}>
                  <RangeInput storedSelection={postalCodeActiveFilter}
                              setFilter={setFilter}
                              resetFilter={resetFilter}
                              size={'large'}
                              showLabel={false}
                              label={'Postleitzahl'}
                              max={99999}
                              allowDecimal={false}
                              allowLeadingZeros={true}
                              thousandSeparator={NumericSeparator.None}
                              groupKey={PropertyFilterKey.POSTAL_CODE} />
                </Dropdown>
              </Col>
            </Row>
          ),
          wrappedFilter: (
            <>
              <Row>
                <Col xs={6} lg={4} xl={3}>
                  <SearchInput storedValue={propertyIdActiveFilter}
                               searchService={new SearchService(`${ApiEndpoint.PROPERTY_SUGGESTIONS}?${PropertyFilterKey.PROPERTY_ID}=`, 1)}
                               groupKey={PropertyFilterKey.PROPERTY_ID}
                               placeHolder={'Objekt-ID'}
                               setFilter={setFilter}
                               resetFilter={resetFilter} />
                </Col>
                <Col xs={6} lg={4} xl={3}>
                  <SearchInput storedValue={titleActiveFilter}
                               searchService={new SearchService(`${ApiEndpoint.PROPERTY_SUGGESTIONS}?${PropertyFilterKey.TITLE}=`)}
                               groupKey={PropertyFilterKey.TITLE}
                               placeHolder={'Objektname'}
                               setFilter={setFilter}
                               resetFilter={resetFilter} />
                </Col>
                <Col xs={6} lg={4} xl={3}>
                  <SearchInput storedValue={regionActiveFilter}
                               searchService={new SearchService(`${ApiEndpoint.PROPERTY_SUGGESTIONS}?language=${currentLanguage}&${PropertyFilterKey.REGION}=`)}
                               valueKey={'id'}
                               labelKey={'title'}
                               i18n={true}
                               groupKey={PropertyFilterKey.REGION}
                               placeHolder={'Region'}
                               setFilter={setFilter}
                               resetFilter={resetFilter} />
                </Col>
              </Row>
              <Row>
                <Col xs={6} lg={4} xl={3}>
                  <SearchInput storedValue={ownerActiveFilter}
                               searchService={new SearchService(`${ApiEndpoint.PROPERTY_SUGGESTIONS}?${PropertyFilterKey.OWNER}=`)}
                               groupKey={PropertyFilterKey.OWNER}
                               placeHolder={'Eigentümer'}
                               setFilter={setFilter}
                               resetFilter={resetFilter} />
                </Col>
                <Col xs={6} lg={4} xl={3}>
                  <SearchInput storedValue={tenantActiveFilter}
                               searchService={new SearchService(`${ApiEndpoint.PROPERTY_SUGGESTIONS}?${PropertyFilterKey.TENANT}=`)}
                               groupKey={PropertyFilterKey.TENANT}
                               placeHolder={'Mieter'}
                               setFilter={setFilter}
                               resetFilter={resetFilter} />
                </Col>
                <Col xs={6} lg={4} xl={3}>
                  <SearchInput storedValue={agentActiveFilter}
                               searchService={new SearchService(`${ApiEndpoint.PROPERTY_SUGGESTIONS}?${PropertyFilterKey.AGENT}=`)}
                               groupKey={PropertyFilterKey.AGENT}
                               placeHolder={'Makler'}
                               setFilter={setFilter}
                               resetFilter={resetFilter} />
                </Col>
              </Row>
              <Row className='filter'>
                <Col xs={6} lg={4} xl={3}>
                  <RangeInput storedSelection={officeAreaActiveFilter}
                              setFilter={setFilter}
                              resetFilter={resetFilter}
                              groupKey={PropertyFilterKey.OFFICE_AREA}
                              allowDecimal={false}
                              label={'Bürofläche in m<sup>2</sup>'}
                  />
                  <RangeInput storedSelection={constructionYearActiveFilter}
                              setFilter={setFilter}
                              resetFilter={resetFilter}
                              max={9999}
                              allowDecimal={false}
                              thousandSeparator={NumericSeparator.None}
                              groupKey={PropertyFilterKey.CONSTRUCTION_YEAR}
                              label={'Baujahr'}
                  />
                  <RangeInput storedSelection={averageRentalChargeActiveFilter}
                              setFilter={setFilter}
                              resetFilter={resetFilter}
                              groupKey={PropertyFilterKey.AVERAGE_RENTAL_CHARGE}
                              allowDecimal={false}
                              label={"Ø Mietpreis pro " + UnitSymbol.SquareMeter}
                  />
                  <RangeInput storedSelection={purchaseDateActiveFilter}
                              setFilter={setFilter}
                              resetFilter={resetFilter}
                              type={RangeInputType.DATE}
                              groupKey={PropertyFilterKey.PURCHASE_DATE}
                              label={'Kaufdatum (Eigentümer)'}
                  />
                </Col>
                <Col xs={6} lg={4} xl={3}>
                  <RangeInput storedSelection={totalAreaActiveFilter}
                              setFilter={setFilter}
                              resetFilter={resetFilter}
                              groupKey={PropertyFilterKey.TOTAL_AREA}
                              allowDecimal={false}
                              label={'Gesamtfläche in m<sup>2</sup>'}
                  />
                  <RangeInput storedSelection={purchasePriceActiveFilter}
                              setFilter={setFilter}
                              resetFilter={resetFilter}
                              groupKey={PropertyFilterKey.PURCHASE_PRICE}
                              allowDecimal={false}
                              label={'Kaufpreis in €'}
                  />
                  <RangeInput storedSelection={vacancyRateActiveFilter}
                              setFilter={setFilter}
                              resetFilter={resetFilter}
                              step={5}
                              max={100}
                              groupKey={PropertyFilterKey.VACANCY_RATE}
                              allowDecimal={false}
                              label={'Leerstand in %'}
                  />
                </Col>
                <Col xs={12} lg={8} xl={5} className={'filter-select-collection'}>
                  <div>
                    <FilterSelectList storedSelection={areaTypeActiveFilter}
                                      setFilter={setFilter}
                                      resetFilter={resetFilter}
                                      groupKey={PropertyFilterKey.AREA_TYPE}
                                      title={'Objektarten'}
                                      list={PropertyTypeCollectionFilter} />
                  </div>

                  <div>
                    <FilterSelectList storedSelection={tenantTypeActiveFilter}
                                      setFilter={setFilter}
                                      resetFilter={resetFilter}
                                      groupKey={PropertyFilterKey.TENANT_TYPE}
                                      type='radio'
                                      title={'Tenant'}
                                      list={PropertyTenantCollectionFilter}
                    />

                    <FilterSelectList storedSelection={conditionActiveFilter}
                                      setFilter={setFilter}
                                      resetFilter={resetFilter}
                                      groupKey={PropertyFilterKey.CONDITION}
                                      type='radio'
                                      title={'Zustand'}
                                      list={PropertyConditionCollectionFilter}
                    />
                  </div>

                  <div>
                    <FilterSelectList storedSelection={statusActiveFilter}
                                      setFilter={setFilter}
                                      resetFilter={resetFilter}
                                      groupKey={PropertyFilterKey.STATUS}
                                      type='radio'
                                      title={'Status'}
                                      list={PropertyStatusCollectionFilter}
                    />

                    <FilterSelectList storedSelection={hasImagesActiveFilter}
                                      setFilter={setFilter}
                                      resetFilter={resetFilter}
                                      groupKey={PropertyFilterKey.HAS_IMAGES}
                                      type='radio'
                                      title={'Bilder'}
                                      list={PropertyHasImagesCollectionFilter}
                    />
                  </div>
                </Col>
              </Row>
            </>
          )
        }}
      </CollapsableContainer>
    </>
  );
}

const mapStateToProps = (state: any) => {
  return {
    activeFilter: selectActiveFilter(state),
    propertyIdActiveFilter: selectPropertyIdActiveFilter(state),
    titleActiveFilter: selectTitleActiveFilter(state),
    streetActiveFilter: selectStreetActiveFilter(state),
    postalCodeActiveFilter: selectPostalCodeActiveFilter(state),
    locationActiveFilter: selectLocationActiveFilter(state),
    regionActiveFilter: selectRegionActiveFilter(state),
    officeAreaActiveFilter: selectOfficeAreaActiveFilter(state),
    constructionYearActiveFilter: selectConstructionYearActiveFilter(state),
    averageRentalChargeActiveFilter: selectAverageRentalChargeActiveFilter(state),
    totalAreaActiveFilter: selectTotalAreaActiveFilter(state),
    purchasePriceActiveFilter: selectPurchasePriceActiveFilter(state),
    purchaseDateActiveFilter: selectPurchaseDateActiveFilter(state),
    vacancyRateActiveFilter: selectVacancyRateActiveFilter(state),
    areaTypeActiveFilter: selectAreaTypeActiveFilter(state),
    tenantTypeActiveFilter: selectTenantTypeActiveFilter(state),
    conditionActiveFilter: selectConditionActiveFilter(state),
    statusActiveFilter: selectStatusActiveFilter(state),
    hasImagesActiveFilter: selectHasImagesActiveFilter(state),
    officeAreaTypeActiveFilter: selectOfficeAreaTypeActiveFilter(state),
    ownerActiveFilter: selectOwnerActiveFilter(state),
    tenantActiveFilter: selectTenantActiveFilter(state),
    agentActiveFilter: selectAgentActiveFilter(state),
  }
};

const mapDispatchToProps = (dispatch: any) => ({
  setFilter: (filter: IFilterParam) => dispatch(setPropertiesFilter(filter)),
  resetFilter: (groupKey: string) => dispatch(resetPropertiesFilter(groupKey))
});

export const PropertiesFilter = connect(
  mapStateToProps,
  mapDispatchToProps
)(PropertiesFilterWithoutStore);
