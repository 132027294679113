import { TemplateType } from '../enums/template-type.enum';
import { IFieldAddressArray } from '../interfaces/field-address-array.interface';

export abstract class FieldAddressArrayConfig {
  private static DEFAULT_CONFIG: IFieldAddressArray = {
    templateType: TemplateType.FIELD_ADDRESS_ARRAY,
    name: null,
    value: [],
    addLabel: null,
    identifier: null,
    fieldArrayTitle: null,
    fields: null,
    displayLabelCount: true,
    isDeleteAllowed: true
  };

  static getConfig(options: Partial<IFieldAddressArray>) {
    return {
      ...FieldAddressArrayConfig.DEFAULT_CONFIG,
      ...options
    }
  }
}
