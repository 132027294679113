import { FieldSingleConfig } from "../../config/field-single.config";
import { FieldComposeConfig } from "../../config/field-compose.config";
import { TemplateType } from "../../enums/template-type.enum";
import { InputType } from "../../enums/input-type.enum";
import { Language } from "../../../../shared/constants/language.enum";

export abstract class AffiliateInputDefinition {

  static get AffiliateName() {
    return FieldSingleConfig.getConfig({
      name: "affiliateName",
      label: "Firmenname *"
    });
  }

  static get AffilaiteId() {
    return FieldSingleConfig.getConfig({
      name: "id",
      label: "Firmen-ID",
      readonly: true
    });
  }

  static get AffiliateAddress() {
    return FieldSingleConfig.getConfig({
      templateType: TemplateType.MAPBOX_ADDRESS,
      name: "address",
      label: "Adresse *",
      searchInEveryCountry: true
    });
  }

  static getAffiliateRegion(language: Language) {
    return FieldSingleConfig.getConfig({
      name: "region_" + language,
      label: "Region",
      isAutofill: true,
      placeholderText: "Wird durch Regionenraster befüllt",
      i18n: language
    });
  }

  static getAffiliateCountry(language: Language) {
    return FieldSingleConfig.getConfig({
      name: "country_" + language,
      label: "Land",
      isAutofill: true,
      i18n: language
    });
  }

  static get AffiliateAuthorizedRepresentiveNamePhone() {
    return FieldComposeConfig.getConfig({
      fields: [
        {
          ...FieldSingleConfig.getConfig({
            name: "authorizedRepresentiveName",
            label: "Name"
          })
        },
        {
          ...FieldSingleConfig.getConfig({
            inputType: InputType.TEL,
            name: "authorizedRepresentivePhone",
            label: "Tel."
          })
        }
      ]
    });
  }

  static get AffiliateAuthorizedRepresentiveEmailWebsite() {
    return FieldComposeConfig.getConfig({
      fields: [
        {
          ...FieldSingleConfig.getConfig({
            inputType: InputType.EMAIL,
            name: "authorizedRepresentiveEmail",
            label: "E-Mail"
          })
        },
        {
          ...FieldSingleConfig.getConfig({
            name: "authorizedRepresentiveWebsite",
            label: "Website"
          })
        }
      ]
    });
  }
}
