import { Record } from "immutable";
import { IPlace } from "../interfaces/place.interface";
import { ISelectableObject } from "../interfaces/selectable.interface";

export interface IPropertyParams {
  id?: string;
  propertyId?: number;
  title?: string;
  primaryAddress?: IPlace;
  secondaryAddresses: IPlace[],
  secondaryFullStreets: string,
  allAddresses: IPlace[],
  allFullStreets: string,
  region_de?: string;
  region_en?: string;
  country_de?: string,
  country_en?: string,
  country_alpha2Code?: string,
  federalState?: string;
  constructionYear?: number;
  totalArea?: number;
  officeArea?: number;
  averageRentalCharge?: number;
  isPublity?: boolean;
  isPipeline?: boolean;
  vacancyRate?: number;
  geometry?: any;
  isMultiTenant?: boolean;
  condition?: string;
  status?: string;
  hasImages?: boolean;
  name?: string;
  phone?: string;
  email?: string;
  website?: string;
  floor?: number;
  outdoorParkingSpace?: number;
  undergroundParkingSpace?: number;
  annualNetRent?: number;
  annualNetRentDate?: string;
  renovationCost?: number;
  renovationCostDate?: string;
  constructionCost?: number;
  constructionCostDate?: string;
  areaTypes?: [];
  purchasePrices?: [];
  addresses?: IPlace[];
  owners?: [];
  propertyArea?: number;
  createdAt?: string;
  updatedAt?: string;
  updatedByTitle?: string;
  street?: string;
  streetNumber?: string;
  postalCode?: string;
  location?: string;
  tenancies?: [];
  purchasePrice?: number;
  spotterRemarkContent?: string;

  // optional
  address?: string;
}

export class PropertyModel extends Record<IPropertyParams>({
  propertyId: undefined,
  id: undefined,
  title: undefined,
  primaryAddress: undefined,
  secondaryAddresses: undefined,
  secondaryFullStreets: undefined,
  allAddresses: undefined,
  allFullStreets: undefined,
  region_de: undefined,
  region_en: undefined,
  country_de: undefined,
  country_en: undefined,
  country_alpha2Code: undefined,
  federalState: undefined,
  street: undefined, //TODO deprecated
  streetNumber: undefined,  //TODO deprecated
  postalCode: undefined,  //TODO deprecated
  location: undefined,  //TODO deprecated
  constructionYear: undefined,
  totalArea: undefined,
  officeArea: undefined,
  purchasePrice: undefined,

  averageRentalCharge: undefined,
  isPublity: undefined,
  isPipeline: undefined,
  vacancyRate: undefined,
  geometry: undefined,
  isMultiTenant: undefined,
  condition: undefined,
  status: undefined,
  hasImages: undefined,
  name: undefined,
  phone: undefined,
  email: undefined,
  website: undefined,
  floor: undefined,
  outdoorParkingSpace: undefined,
  undergroundParkingSpace: undefined,
  annualNetRent: undefined,
  annualNetRentDate: undefined,
  renovationCost: undefined,
  renovationCostDate: undefined,
  constructionCost: undefined,
  constructionCostDate: undefined,
  areaTypes: undefined,
  addresses: [],
  purchasePrices: undefined,
  owners: undefined,
  propertyArea: undefined,
  createdAt: undefined,
  updatedAt: undefined,
  updatedByTitle: undefined,
  address: undefined,
  tenancies: undefined,

  spotterRemarkContent: undefined,
}) {
  constructor(params?: any) {
    params ? super(params) : super();
  }

  with(values: IPropertyParams): this {
    return this.merge(values) as this;
  }

  get selectableObject() {
    return PropertyModel.getSelectableObject({ ...this });
  }

  //TODO switch to object instead of multiple params
  static getSelectableObject(propertyObject: Partial<{ propertyId: number, title: string, addresses: any[], primaryAddress: any }>): ISelectableObject {
    if (!propertyObject?.propertyId || !propertyObject.title) {
      return null;
    }

    const primaryAddress = propertyObject?.primaryAddress;

    const fullStreetWithLocation = !!primaryAddress && !!primaryAddress?.street ? primaryAddress?.street + (!!primaryAddress?.streetNumber ? ' ' + primaryAddress?.streetNumber : '') + (!! primaryAddress?.location ? ', ' + primaryAddress?.location : '') : '';
    const titleIfDifferentThanStreet = !!primaryAddress && propertyObject?.title !== primaryAddress?.street + ' ' + primaryAddress?.streetNumber ? ' (' + propertyObject?.title + ')' : '';

    return { id: propertyObject.propertyId, label: `${fullStreetWithLocation}${titleIfDifferentThanStreet} (#${propertyObject.propertyId})` };
  };
}
